import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import SubMessageCenter from "./SubMessageCenter";
import {
  Button,
  Card,
  Col,
  Container, FormSelect, Row
} from "shards-react";
import InfoTooltip from "../../components/common/InfoTooltip";
import {Link} from "react-router-dom";
import {
  filter_dashboard_pay_period_list,
  get_dashboard_pay_period_list,
  get_dashboard_quick_filter_name_list, get_sub_dashboard_data
} from "../../redux/actions/subscriber/subHome";


function SubHome({
                   user,
                   title,
                   quickFilterList,
                   payPeriodList,
                   filteredPayPeriodList,
                   dashboardData,
                   changePageMeta,
                   get_sub_dashboard_data,
                   get_dashboard_quick_filter_name_list,
                   get_dashboard_pay_period_list,
                   filter_dashboard_pay_period_list
                 }) {

  let initialState = {
    quickFilter: "",
    payPeriodFrom: "",
    payPeriodTo: ""
  }
  const [state, setState] = useState(initialState);
  let resetState = () => {
    setState(initialState)
    get_sub_dashboard_data()
  }

  useEffect(() => {
    if(filteredPayPeriodList.length)
      setState({...state,payPeriodFrom: filteredPayPeriodList.reduce((prev, curr)=>(prev.id < curr.id ? prev : curr)).id,payPeriodTo:filteredPayPeriodList.reduce((prev, curr)=>(prev.id > curr.id ? prev : curr)).id})
  }, [filteredPayPeriodList]);


  useEffect(() => {
    changePageMeta(`${title} - ${user.displayName}`)
    get_sub_dashboard_data()
    get_dashboard_quick_filter_name_list()
    get_dashboard_pay_period_list()
  }, []);

  useEffect(() => {
    filter_dashboard_pay_period_list(state.quickFilter)
  }, [state.quickFilter]);


  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  let onSubmit=(e)=>{
    e.preventDefault();
    get_sub_dashboard_data(state.payPeriodFrom,state.payPeriodTo)
  }

  let quickFilterOptions = quickFilterList.map(filter => <option
    value={filter}>{filter}</option>)

  let payPeriodListOptions = (payPeriodList || []).map(payPeriod => <option
    value={payPeriod.id}>{payPeriod.payPeriod}</option>)

  return (
    <>
      <Container fluid className="p-4">
        <Row className="align-items-end">
          <Col lg={4} className="px-2 py-1 d-none d-lg-block">
            <Card className="py-2 my-2">
              <h4 className="text-center">
                <div className="h3 d-inline"><i
                  className="material-icons shadow p-2 rounded-circle">groups</i>
                </div> Headcount <small className="d-inline-block"><InfoTooltip
                msg={"Total number of active workers"}/></small></h4>
                <div className="d-flex justify-content-around">
                <div>
                  <span className="h5 text-center mb-1">Active</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-search-users?status=Active" >{(dashboardData.headCounts?dashboardData.headCounts.active:"")
                  }</Link> </span>
                </div>
                <div>
                  <span className="h5 text-center mb-1">On Leave</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-search-users?status=On Leave" >{(dashboardData.headCounts?dashboardData.headCounts.onLeave:"")}</Link> </span>
                </div>
                <div>
                  <span className="h5 text-center mb-1">Total</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-search-users?status=all" >{(dashboardData.headCounts?dashboardData.headCounts.total:"")}</Link> </span>
                </div>
              </div>
            </Card>
          </Col>

          <Col lg={8} className="p-0">
            <Card>
             <form onSubmit={onSubmit}>
               <Row className="align-items-end px-3 py-3">
                 <Col xl={3} className="mb-2 mb-xl-0">
                   <label>Quick Filter</label>
                   <FormSelect name='quickFilter' onChange={onChange}
                               value={state.quickFilter}>
                     <option value=''>--None--</option>
                     {quickFilterOptions}
                   </FormSelect>
                 </Col>
                 <Col lg={6} xl={3} className="mb-2 mb-xl-0">
                   <label>Pay Period Selection</label>
                   <FormSelect name='payPeriodFrom' required onChange={onChange}
                               value={state.payPeriodFrom}>
                     <option value=''>From</option>
                     {payPeriodListOptions}
                   </FormSelect>
                 </Col>
                 <Col lg={6} xl={3} className="mb-2 mb-xl-0">
                   <FormSelect name='payPeriodTo' required onChange={onChange}
                               value={state.payPeriodTo}>
                     <option value=''>To</option>
                     {payPeriodListOptions}
                   </FormSelect>
                 </Col>

                 <Col sm={12} xl={3}
                      className="file-manager__filters__search d-flex justify-content-center justify-content-xl-end">
                   <Button type="submit"
                           className="mx-2 py-2" size='sm'
                           theme="accent">Search</Button>

                   <Button className="mx-2 py-2"
                           size='sm'
                           theme="accent" onClick={resetState}>Clear</Button>
                 </Col>


               </Row>
             </form>
              <Row className="px-3">

                <Col lg={6} className="px-2 py-1 d-block d-lg-none">
                  <Card className="py-2 my-2">
                    <h4 className="text-center">
                      <div className="h3 d-inline"><i
                        className="material-icons shadow p-2 rounded-circle">groups</i>
                      </div> Headcount <small className="d-inline-block"><InfoTooltip
                      msg={"Total number of active workers"}/></small></h4>
                     <div className="d-flex justify-content-around">
                <div>
                  <span className="h5 text-center mb-1">Active</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-search-users?status=Active" target="_blank">{(dashboardData.headCounts?dashboardData.headCounts.active:"")
                  }</Link> </span>
                </div>
                <div>
                  <span className="h5 text-center mb-1">On Leave</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-search-users?status=On Leave" target="_blank">{(dashboardData.headCounts?dashboardData.headCounts.onLeave:"")}</Link> </span>
                </div>
                <div>
                  <span className="h5 text-center mb-1">Total</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-search-users?status=all" target="_blank">{(dashboardData.headCounts?dashboardData.headCounts.total:"")}</Link> </span>
                </div>
              </div>
                  </Card>
                </Col>
                <Col lg={6} className="px-2 py-1">
                  <Card className="py-2 my-2">
                    <h4 className="text-center">
                      <div className="h3 d-inline"><i
                        className="material-icons shadow p-2 rounded-circle">add_circle_outline</i>
                      </div> New Starts <small className="d-inline-block"><InfoTooltip
                      msg={"Total number of workers whose hire date is within selected pay period"}/></small>
                    </h4>
                    <div className="h3 text-primary text-center pb-1"><Link
                      to="#">{dashboardData.newStarts}</Link></div>
                  </Card>
                </Col>

                <Col lg={6} className="px-2 py-1">
                  <Card className="py-2 my-2">
                    <h4 className="text-center">
                      <div className="h3 d-inline"><i
                        className="material-icons shadow p-2 rounded-circle">remove_circle_outline</i>
                      </div> Terminations <small
                      className="d-inline-block"><InfoTooltip
                      msg={"Total number of workers whose end date is within selected pay period"}/></small>
                    </h4>
                    <div className="h3 text-primary text-center pb-1"><Link
                      to="#">{dashboardData.termination}</Link></div>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>


          <Col lg={4} className="px-2">
            <Card className="py-2 my-2">
              <h4 className="text-center">
                <div className="h3 d-inline"><i
                  className="material-icons shadow p-2 rounded-circle">question_answer</i>
                </div> Messages <small className="d-inline-block"><InfoTooltip
                msg={"Total number of Unread, In-Process, and Assigned Messages"}/></small>
              </h4>
              <div className="d-flex justify-content-around">
                <div>
                  <span className="h5 text-center mb-1">Unread</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-message-center?status=Unread" target="_blank">{(dashboardData.messages?dashboardData.messages.unread:"")}</Link> </span>
                </div>
                <div>
                  <span className="h5 text-center mb-1">In-Process</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-message-center?status=In-Process" target="_blank">{(dashboardData.messages?dashboardData.messages.inProcess:"")}</Link> </span>
                </div>
                <div>
                  <span className="h5 text-center mb-1">Assigned</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-message-center?assigned=true" target="_blank">{(dashboardData.messages?dashboardData.messages.assigned:"")}</Link> </span>
                </div>
              </div>
            </Card>
          </Col>

          <Col lg={4} className="px-2">
            <Card className="py-2 my-2">
              <h4 className="text-center">
                <div className="h3 d-inline"><i
                  className="material-icons shadow p-2 rounded-circle">today</i>
                </div> Leaves <small className="d-inline-block"><InfoTooltip
                msg={"Total number of submitted leaves"}/></small></h4>
              <div className="h3 text-primary text-center pb-1"><Link
                to="sub-search-leaves?status=Submitted" target="_blank">{dashboardData.leaves}</Link></div>
            </Card>
          </Col>

          <Col lg={4} className="px-2">
            <Card className="py-2 my-2">
              <h4 className="text-center">
                <div className="h3 d-inline"><i
                  className="material-icons shadow p-2 rounded-circle">receipt_long</i>
                </div> Expenses <small className="d-inline-block"><InfoTooltip
                msg={"Total number of submitted and approved expenses"}/></small>
              </h4>
              <div className="d-flex justify-content-around">
                <div>
                  <span className="h5 text-center mb-1">Submitted</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-search-expenses?status=Submitted" target="_blank">{(dashboardData.expense?dashboardData.expense.submitted:"")}</Link> </span>
                </div>
                <div>
                  <span className="h5 text-center mb-1">Approved</span>
                  <span className="h5 text-center mb-1"><Link
                    to="sub-search-expenses?status=Approved" target="_blank">{(dashboardData.expense?dashboardData.expense.approved:"")}</Link> </span>
                </div>
              </div>
            </Card>
          </Col>

        </Row>
      </Container>
      <SubMessageCenter showHeading/>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  quickFilterList: state.subHome.quickFilterList,
  payPeriodList: state.subHome.payPeriodList,
  filteredPayPeriodList: state.subHome.filteredPayPeriodList,
  dashboardData: state.subHome.dashboardData
});

export default connect(mapStateToProps, {
  get_dashboard_quick_filter_name_list,
  get_dashboard_pay_period_list,
  filter_dashboard_pay_period_list,
  get_sub_dashboard_data
})(SubHome);
