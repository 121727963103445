import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import InputBox from '../../components/common/InputBox'
import CFormSelect from '../../components/common/CFormSelect'
import {connect} from 'react-redux';

import {
  get_sysadmin_subscriber_user_list,
  get_sysadmin_subscriber_profile,
  save_sysadmin_subscriber_profile,
  get_country_list,
  get_region_list,
  get_city_list,
} from '../../redux/actions/user';
import PropTypes from 'prop-types';


import {
  Button,
  Card, Col,
  Container, Form, FormInput, ListGroup, ListGroupItem,
  Row
} from "shards-react";
import SubscriberUsers from "../../components/client/SubscriberUsers";
import {setAlert} from "../../redux/actions/alert";
import CustomCard from "../../components/common/CustomCard";
import {STANDARD_FORMATS} from "../../utils/constants";
import StandardFormInput from "../../components/common/StandardFormInput";

function ManageSubscriber(
  {
    countryList,
    regionList,
    cityList,
    get_country_list,
    get_region_list,
    get_city_list,
    get_sysadmin_subscriber_user_list,
    get_sysadmin_subscriber_profile,
    save_sysadmin_subscriber_profile,
    subscriberUsers,
    subscriberProfile,
    title,
    changePageMeta
  }
) {
  let {id} = useParams();
  const history = useHistory();

  //added Client Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(subscriberProfile).length > 0) {
      changePageMeta(`${title} - ${subscriberProfile.subscriberName}`)
    }
  }, [subscriberProfile]);

  const [formData, setFormData] = useState({
    subscriberId: '',
    subscriberName: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    state: '',
    city: '',
    zip: '',
    contactNumber: '',
    linkedIn: '',
    website: '',
    finmodCompanyId: '',
    isValid: true
  });
  useEffect(() => {
    if (subscriberProfile && Object.keys(subscriberProfile).length !== 0) {
      if (subscriberProfile.subscriberId == id) {
        setFormData({
          ...formData,
          subscriberId: subscriberProfile.subscriberId,
          subscriberName: subscriberProfile.subscriberName,
          addressLine1: subscriberProfile.addressLine1,
          addressLine2: subscriberProfile.addressLine2,
          country: subscriberProfile.countryId,
          state: subscriberProfile.regionId,
          city: subscriberProfile.cityId,
          zip: subscriberProfile.zip,
          contactNumber: subscriberProfile.contactNumber,
          linkedIn: subscriberProfile.linkedIn,
          website: subscriberProfile.website,
          finmodCompanyId: subscriberProfile.finmodCompanyId
        });
      } else {
        history.replace("/admin-manage-subscriber-profile/" + subscriberProfile.subscriberId);
      }

    }
  }, [subscriberProfile]);
  useEffect(() => {
    get_country_list();

  }, []);
  const {
    subscriberId,
    subscriberName,
    addressLine1,
    addressLine2,
    country,
    state,
    city,
    zip,
    contactNumber,
    linkedIn,
    website,
    finmodCompanyId
  } = formData;


  var countryListOption = countryList.map((option) => {
    return <option value={option.countryId}>{option.countryName}</option>;
  });
  var cityListOption = cityList.map((option) => {
    return <option value={option.cityId}>{option.cityName}</option>;
  });
  var regionListOption = regionList.map((option) => {
    return <option value={option.stateProvId}>{option.stateProvName}</option>;
  });

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const onChangeStandardInput = (name,value, isValid = true) => {
    setFormData({...formData, [name]: value, isValid:isValid});
  };
  const onSubmit = (e) => {
    e.preventDefault();

    save_sysadmin_subscriber_profile(
      id,
      subscriberName,
      addressLine1,
      addressLine2,
      country,
      state,
      city,
      zip,
      contactNumber,
      linkedIn,
      website,
      finmodCompanyId
    );
  };


  useEffect(() => {
    if (id !== null && id !== '' && id !== '0' && id !== 0) {
      get_sysadmin_subscriber_profile(id);
      get_sysadmin_subscriber_user_list(id);
    }
  }, [id]);

  useEffect(() => {
    if (formData.country) {
      get_region_list(formData.country)
    }
  }, [formData.country])

  useEffect(() => {
    if (formData.state) {
      get_city_list(formData.state)
    }
  }, [formData.state])

  return (
    <Container fluid className="main-content-container p-4">
      <Card small className="mb-5  mt-2 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {subscriberId}</h4>
        <span className="iconBox"><i className="fas fa-info-circle iconStyle"/></span>
        <h4 className="m-0 section-title headerStyle">Subscriber
          Information</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3 mt-3">
            <Row>
              <Col>
                <Form id="clientDetailsForm" onSubmit={onSubmit}>
                  <Row form>
                    <InputBox id="subscriberId" name="subscriberId"
                              value={subscriberId} label="ID"
                              placeholder="Enter ID" onChange={onChange}/>
                    <InputBox id="subscriberName" name="subscriberName"
                              value={subscriberName} label="Subscriber Name"
                              placeholder="Enter Subscriber Name"
                              onChange={onChange}/>
                    <InputBox id="addressLine1" name="addressLine1"
                              value={addressLine1} label="Address Line 1"
                              placeholder="Enter Address" onChange={onChange}/>
                    <InputBox id="addressLine2" name="addressLine2"
                              value={addressLine2} label="Address Line 2"
                              placeholder="Enter Address" onChange={onChange}/>
                    <CFormSelect defaultOptionName="Select Country Name"
                                 name="country" value={country} label="Country"
                                 data={countryListOption} onChange={onChange}/>
                    <CFormSelect defaultOptionName="Select State" name="state"
                                 value={state}
                                 label="State/Province/Region"
                                 data={regionListOption} onChange={onChange}/>
                    <CFormSelect defaultOptionName="Select City" name="city"
                                 value={city} label="City"
                                 data={cityListOption} onChange={onChange}/>
                    <InputBox id="zip" name="zip" value={zip}
                              label="Zip/Postal Code"
                              placeholder="Select Zip/Postal Code"
                              onChange={onChange}/>
                    <StandardFormInput
                      id="contactNumber"
                      label="Contact Number"
                      placeholder="(xxx) xxx-xxxx"
                      standardFormat={STANDARD_FORMATS.PHONE_NO}
                      type="text"
                      required
                      name='contactNumber'
                      value={contactNumber}
                      onChangeStandardInput={onChangeStandardInput}
                    />
                    <InputBox id="linkedIn" value={linkedIn} name="linkedIn"
                              label="Enter LinkedIn"
                              placeholder="Enter LinkedIn" onChange={onChange}/>
                    <InputBox id="website" value={website} name="website"
                              label="Enter Website" placeholder="Enter Website"
                              onChange={onChange}/>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>

      <CustomCard title="Integration"
                  className="details-card my-4 w-100 d-flex personalParent"
                  icon={true} iconClass="fas fa-link iconStyle">

        <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
          <label htmlFor="finmodCompanyId">FINMOD Company ID</label>
          <FormInput
            id="finmodCompanyId"
            name='finmodCompanyId'
            value={formData.finmodCompanyId}
            onChange={onChange}
          />
        </Col>
      </CustomCard>

      <Row className="px-3 my-4">
        <Col sm="12" md="12" lg="12"
             className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

          <Button type="submit"
                  className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
                  form="clientDetailsForm"
                  disabled={!formData.isValid}>Save
          </Button>
          <Button onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }} className="mx-1 py-2 mx-md-2" size="sm" theme="accent">Close
          </Button>

        </Col>
      </Row>


      <SubscriberUsers subscriberName={subscriberName}
                       subscriberId={subscriberId}
                       subscriberUsers={subscriberUsers}/>
    </Container>
  )
}

ManageSubscriber.propTypes = {
  get_sysadmin_subscriber_user_list: PropTypes.func.isRequired,
  subscriberUsers: PropTypes.array.isRequired,
  get_sysadmin_subscriber_profile: PropTypes.func.isRequired,
  save_sysadmin_subscriber_profileL: PropTypes.func.isRequired,
  subscriberProfile: PropTypes.object.isRequired,
  countryList: PropTypes.array.isRequired,
  regionList: PropTypes.array.isRequired,
  cityList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  subscriberUsers: state.user.subscriberUsers,
  subscriberProfile: state.user.subscriberProfile,
  countryList: state.user.countryList,
  regionList: state.user.regionList,
  cityList: state.user.cityList,
});
export default connect(mapStateToProps, {
  get_sysadmin_subscriber_user_list,
  get_sysadmin_subscriber_profile,
  save_sysadmin_subscriber_profile,
  get_country_list,
  get_region_list,
  get_city_list,
  setAlert
})(ManageSubscriber);

