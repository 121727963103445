import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom';
import classNames from "classnames";
import {
  Alert,
  Card,
  Col,
  Container, Form, FormInput, ListGroup, ListGroupItem,
  Row,FormSelect
} from "shards-react";
import { connect } from 'react-redux';
import {
  save_pay_element,
  get_pay_element,
  delete_pay_element


} from '../../redux/actions/user';
import PropTypes from 'prop-types';
import ConfirmAlert from "../../components/common/ConfirmAlert";
import InfoTooltip from "../../components/common/InfoTooltip";
import { setAlert } from '../../redux/actions/alert';

function AddEditPayElement({

  save_pay_element,
  get_pay_element,
  PayElementDetails,
  delete_pay_element,
  IsPayElementSaved,
  setAlert

}) {
  let { refNum } = useParams();
  const history = useHistory();

  const [Saved, setSaved] = useState(IsPayElementSaved);
  const [IsReturn, setIsReturn] = useState("");
  const { className } = "";

  const [formData, setFormData] = useState({
    refNum: '',
    payElement:'',

  });




  useEffect(() => {
    if (refNum !== null && refNum !== '' && refNum !== '0' && refNum !== 0) {

      get_pay_element(refNum);
    }
   else{
    setFormData({
      ...formData,
      payElement:''
    });
   }
  }, [refNum]);



  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });




  useEffect(() => {
    if (PayElementDetails && Object.keys(PayElementDetails).length != 0 && refNum!= 0) {
      setFormData({
        ...formData,
        refNum: PayElementDetails.refNum,
        payElement: PayElementDetails.payElement,
        deleteAble: PayElementDetails.deleteAble,
      });
    }
  }, [PayElementDetails]);
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (IsPayElementSaved && IsPayElementSaved !== 0) {
      if (Saved !== IsPayElementSaved) {

        setSaved(IsPayElementSaved)
        if (IsReturn && IsReturn === "yes") {
          history.goBack();
        } else {

          history.replace(`/sub-add-Pay-Element/0`);
          setFormData({
            ...formData,
            payElement:'',
          })
        }

      }

    }
  }, [IsPayElementSaved]);

  let onDelete = (e) => {
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete this pay element',
      visible: true,
      onConfirm: () => {
        delete_pay_element(refNum);
        history.goBack();
      }
    })
  }


  const onSubmit = (e) => {

    if(formData.payElement === ""){
      setAlert("Please enter Pay Element", "danger");
    }
    else{

    e.preventDefault();
    var data = {
      refNum: refNum ,
      payElement: formData.payElement,
    }
    if (formData.payElement !== '') {
      save_pay_element(data);
      if (e.target.name === "saveReturn") {
        setIsReturn("yes");

      }
      else {
        setIsReturn("no");
      }
    }
  }

  };
  return (
    <>


      <Container fluid className="main-content-container p-4">
        <ConfirmAlert confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert} />
        <Card small className="mb-5 w-100">
          <h4 className="m-0 section-title idStyle">Ref# {refNum > 0 ? refNum : '0'}</h4>
          <ListGroup flush>
            <ListGroupItem className="border-card p-3">
              <Row>
                <Col sm="12" md="12" lg="12">
                  <Form id="clientDetailsForm" onSubmit={onSubmit}>
                    <Row>

                     <Col lg="4" sm="12" md="12">
                      <Row>
                      <Col lg="8" className="form-group p-2 mb-0">
                        <label htmlFor="Pay Element">Pay Element</label>
                        <span aria-hidden="true" className="required">*</span>
                        <InfoTooltip
                          msg="Element that represents the compensation and benefit types that you give to your employees" />
                        <FormInput placeholder="Enter Pay Element" name='payElement' id='payElement'
                                   value={formData.payElement}
                                   onChange={onChange} />
                      </Col>
                         </Row>
                     </Col>

                      <div  className="mt-4 pt-3">

                        {refNum == 0 &&
                          <button type="button" onClick={onSubmit} className="btn btn-primary" name="saveAddAnother">Save and Add Another</button>
                        }
                        <button type="button" onClick={onSubmit} className="btn btn-primary ml-2 " name="saveReturn">Save and Return</button>
                        {refNum > 0 &&
                          <button className="btn btn-primary ml-2 " name="delete" type="button" disabled={!PayElementDetails.deleteAble} onClick={onDelete}>Delete</button>
                        }

                        <button onClick={(e) => {
                          e.preventDefault();
                          history.goBack();
                        }} className="btn btn-primary ml-2 ">Cancel</button>

                      </div>


                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>



    </>
  )
}

AddEditPayElement.propTypes = {

  get_pay_element: PropTypes.func.isRequired,
  save_pay_element: PropTypes.func.isRequired,
  delete_pay_element: PropTypes.func.isRequired,

  setAlert: PropTypes.func.isRequired,
  IsPayElementSaved: PropTypes.number.isRequired,
  IsPayElementDeleted: PropTypes.number.isRequired,

};
const mapStateToProps = (state) => ({
  PayElementDetails: state.user.PayElementDetails,
  subscriberId: state.user.user.userRoles.filter(item => item.role === "Subscriber Admin")[0].domainId,

  IsPayElementSaved: state.user.IsPayElementSaved,
  IsPayElementDeleted: state.user.IsPayElementDeleted,
});
export default connect(mapStateToProps, {
  save_pay_element,
  get_pay_element,
  delete_pay_element,
  setAlert
})(AddEditPayElement);
