import React from 'react';
import {
  Card,
  CardHeader,
  Col, Form, FormGroup, FormInput, FormSelect,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import CustomCard from "../common/CustomCard";
import InputBox from "../common/InputBox";

function Integration({
                       dealTypes,
                       hrDealType,
                       talnetCandidateId,
                       placementId,
                       talnetClientContactId,
                       finmodCandidateId,
                       onChange
                     }) {

  var dealTypesOption = dealTypes.map((type) => {
    return <option key={type} value={type}>{type}</option>;
  });
  return (
    <CustomCard
      id="integration"
      title="Integration"
      icon={true} iconClass="fas fa-link iconStyle" noform>

      {/*<Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="hrDealType">Deal Type</label>
        <FormSelect id='hrDealType' name='hrDealType' value={hrDealType}
                    onChange={onChange} className='d-block'>
          <option value=''>Select Deal Type</option>
          {dealTypesOption}
        </FormSelect>
  </Col>*/}

      <InputBox placeholder="Enter Candidate ID" label="Candidate ID" id='talnetCandidateId' name='talnetCandidateId'
                value={talnetCandidateId}
                onChange={onChange}/>


      <InputBox placeholder="Enter Placement ID" label="Placement ID" id='placementId' name='placementId'
                value={placementId}
                onChange={onChange}/>

      <InputBox placeholder="Enter Client Contact ID" label="Client Contact ID" id='talnetClientContactId' name='talnetClientContactId'
                value={talnetClientContactId}
                onChange={onChange}/>

      <InputBox placeholder="Enter FINMOD Candidate ID" label="FINMOD Candidate ID" id='finmodCandidateId' name='finmodCandidateId'
                value={finmodCandidateId}
                onChange={onChange}/>
    </CustomCard>
  );
}

export default Integration;
