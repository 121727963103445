import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col, Container, DatePicker,
  Form, FormInput,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import PropTypes from 'prop-types';
import {useHistory, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {setAPIDate, setInputDate} from "../../utils/date";
import {
  get_mileage_rate, get_user_expense_mileage_item,
  save_user_expense_mileage_item, reset_save_item
} from "../../redux/actions/worker/expenses";
import {
 
  get_base_distance_unit_name,
} from "../../redux/actions/user";
import StandardFormInput from "../../components/common/StandardFormInput";
import {STANDARD_FORMATS} from "../../utils/constants";
import InfoTooltip from "../../components/common/InfoTooltip";

function EditMileageItem({
                           user,
                           get_mileage_rate,
                           mileageRate,
                           mileageItem,
                           get_user_expense_mileage_item,
                           sheetRefNum,
                           save_user_expense_mileage_item,
                           get_base_distance_unit_name, DistanceUnitName,
                           reset_save_item,
                           savedItem
                         }) {


  let initialState = {
    tripDate: '',
    fromLocation: '',
    toLocation: '',
    tripDescription: '',
    distanceDriven: '',
    appliedRate: 0,
    amount: 0,
    taxableFlag: 0,
    distanceUnit:'',
  }

  const [formData, setFormData] = useState(initialState);
  const [saveReturn, setSaveReturn] = useState(false)

  function resetState() {
    setFormData({
      ...initialState,
      appliedRate: mileageRate.toFixed(2)
    })
  }

  const history = useHistory();
  let {refNumber, sheetRefNumber} = useParams();

  sheetRefNumber = sheetRefNum || sheetRefNumber;

  useEffect(() => {
    if (Object.keys(mileageItem).length !== 0 && mileageItem.refNum == refNumber) {
      setFormData({
        ...formData,
        tripDate: setAPIDate(mileageItem.tripDate),
        fromLocation: mileageItem.fromLocation,
        toLocation: mileageItem.toLocation,
        tripDescription: mileageItem.tripDescription,
        distanceDriven: mileageItem.distanceDriven.toFixed(2),
        appliedRate: (refNumber === '0') ? mileageRate : mileageItem.appliedRate,
        amount: mileageItem.amount,
        taxableFlag: 0,
        distanceUnit:mileageItem.distanceUnit
      });
    }
  }, [mileageItem]);

  useEffect(() => {
    setFormData({
      ...formData,
      amount: formData.distanceDriven * formData.appliedRate
    })
  }, [formData.distanceDriven]);




  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onChangeStandardInput = (name,value) => {
    setFormData({...formData, [name]: value});
  };

  useEffect(() => {
    get_mileage_rate();
    get_base_distance_unit_name();
    
   return ()=> reset_save_item();

  }, []);

  useEffect(() => {
    if (refNumber && refNumber !== '0') {
      get_user_expense_mileage_item(refNumber);
    }
  }, [refNumber]);
  useEffect(() => {
    if (refNumber == '0' && DistanceUnitName) {
      setFormData({...formData,distanceUnit: DistanceUnitName});
    }
  }, [DistanceUnitName]);
  useEffect(() => {
    if (refNumber == '0' && mileageRate) {
      setFormData({...formData,appliedRate: (mileageRate) ? mileageRate.toFixed(2) : 0,distanceUnit: DistanceUnitName});
    }
  }, [mileageRate]);
  const onSubmit = (e) => {
    e.preventDefault();
    save_user_expense_mileage_item(
      user.userId,
      sheetRefNumber,
      refNumber,
      setInputDate(formData.tripDate),
      formData.fromLocation,
      formData.toLocation,
      formData.tripDescription,
      formData.distanceDriven,
      formData.appliedRate,
      formData.amount,
      formData.taxableFlag
    );

    if (e.nativeEvent.submitter.name === "saveReturn")
     { 
      setSaveReturn(true)
     }
    else {
      history.replace(`/add-mileage-item/${sheetRefNumber}/0`)
      resetState();
    }
  };

  useEffect(()=>{
    if(savedItem && saveReturn) history.goBack();
    }, [savedItem, saveReturn])

  return (
    <Container fluid className="main-content-container p-4">
      <Card small className="mb-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {refNumber}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form onSubmit={onSubmit}>
                  <Row form>


                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="tripDate">Trip Date</label>
                      <div>
                        <DatePicker
                          id='tripDate'
                          autoComplete='off'
                          name='tripDate'
                          dateFormat='yyyy-MM-dd'
                          selected={formData.tripDate}
                          required
                          onChange={(date) =>
                            setFormData({...formData, tripDate: date})
                          }
                          value={formData.tripDate}
                          placeholderText="YYYY-MM-DD"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="tripDescription">Trip Description</label>
                      <div>
                        <FormInput
                          id="tripDescription"
                          name='tripDescription'
                          required
                          value={formData.tripDescription}
                          onChange={onChange}
                          placeholder="Enter Trip Description"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="fromLocation">From</label>
                      <div>
                        <FormInput
                          id="fromLocation"
                          name='fromLocation'
                          value={formData.fromLocation}
                          onChange={onChange}
                          required
                          placeholder="Enter From"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="toLocation">To</label>
                      <div>
                        <FormInput
                          id="toLocation"
                          name='toLocation'
                          value={formData.toLocation}
                          onChange={onChange}
                          required
                          placeholder="Enter To"
                        />
                      </div>
                    </Col>

                    <StandardFormInput
                      id="distanceDriven"
                      name='distanceDriven'
                      label="Distance Travelled"
                      standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                      dataMaxFracDigits={2}
                      type="text"
                      value={formData.distanceDriven}
                      onChangeStandardInput={onChangeStandardInput}
                      required
                      placeholder="0.00"
                    />
<Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="distanceUnit">Distance Unit</label>
                      <InfoTooltip
                      msg="Default Base Distance Unit Configured" />
                      <div>
                        <FormInput
                          id="distanceUnit"
                          name='distanceUnit'
                          value={formData.distanceUnit}
                          onChange={onChange}
                          readOnly
                          placeholder="Enter Distance Unit"
                        />
                      </div>
                    </Col>
                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="appliedRate">Applied Rate</label>
                      <div>
                        <FormInput
                          id="appliedRate"
                          name='appliedRate'
                          value={formData.appliedRate}
                          onChange={onChange}
                          readOnly
                          placeholder="Enter Applied Rate"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="amount">Amount</label>
                      <div>
                        <FormInput
                          id="amount"
                          name='amount'
                          value={formData.amount.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}
                          onChange={onChange}
                          readOnly
                          placeholder="Enter Estimated Amount"
                        />
                      </div>
                    </Col>

                  </Row>
                  <Row className="px-3">
                    <Col sm="12" md="12" lg="12"
                         className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                      {(refNumber === '0' || ["Saved","Rejected", "Draft"].includes(mileageItem.status)) && <>
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="accent" name="saveAddAnother">Save and
                          Add
                          Another</Button>
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="accent" name="saveReturn">Save and
                          Return</Button>
                      </>}

                      <Button onClick={e => history.goBack()}
                              className="mx-1 py-2 mx-md-2" size="sm"
                              theme="accent">Cancel</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}

EditMileageItem.propTypes = {
  user: PropTypes.object.isRequired,
  get_mileage_rate: PropTypes.func.isRequired,
  mileageRate: PropTypes.number.isRequired,
  mileageItem: PropTypes.object.isRequired,
  get_user_expense_mileage_item: PropTypes.func.isRequired,
  save_user_expense_mileage_item: PropTypes.func.isRequired,
  sheetRefNum: PropTypes.string,
  DistanceUnitName: PropTypes.string,
  get_base_distance_unit_name: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  mileageRate: state.expenses.mileageRate,
  mileageItem: state.expenses.mileageItem,
  sheetRefNum: state.expenses.sheetRefNum,
  DistanceUnitName: state.user.DistanceUnitName,
  savedItem: state.expenses.savedItem,


});

export default connect(mapStateToProps, {
  get_mileage_rate,
  get_user_expense_mileage_item,
  save_user_expense_mileage_item,
  get_base_distance_unit_name,
  reset_save_item,
})(EditMileageItem);

