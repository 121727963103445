import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ReactTable from "react-table";

import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container,
  FormSelect,
  Row
} from "shards-react";
import {get_search_leave_policy} from "../../redux/actions/user";

export default function ConfigLeavesTypes({leavePolicyList, clear_leave_policy_caches}) {
  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,
  });

  useEffect(() => {
    get_search_leave_policy();
  }, [])

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const {pageSizeOptions, pageSize} = formData;
  const tableData = leavePolicyList;
  const tableColumns = [
    {
      Header: "Leave Policy",
      accessor: "policyName",
      className: "justify-content-start"
    },
    {
      Header: "Leave Carryover",
      accessor: "leavesCarryover",
      maxWidth: 200,
    },
    {
      Header: "Paid Time-Off",
      accessor: "paidTimeOff",
      maxWidth:200,
    },
    {
      Header: "Ref #",
      accessor: "leavePolicyId",
      className: "text-center",
      maxWidth:100,
      Cell: row => <Link onClick={clear_leave_policy_caches} to={'/sub-edit-leave-policy/'+row.original.leavePolicyId}>{row.original.leavePolicyId}</Link>
    }
  ];
  return (
    <div className="position-relative">
      <span className="tableHeader">Leave Policies</span>
      <Card className="tableCard p-0 w-100 border-card">
        <CardHeader className="p-0 pt-2">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col
                className="file-manager__filters__rows d-flex align-items-center col-6">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col
                className="file-manager__filters__search d-flex justify-content-end col-6">
                <Link onClick={clear_leave_policy_caches} to="/sub-add-leave-policy/0">
                  <Button className="my-2 py-2 mx-2" size='sm'
                          theme="accent">Add Leave Policy</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
