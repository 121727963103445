import React, {useState} from 'react'
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, Row,
  FormSelect
} from "shards-react";


export default function Location({
                                   get_city_detailed_list,
                                   cityDetailedList,
                                   get_region_list,
                                   countryList,
                                   regionList
                                 }) {

  const [formData, setFormData] = useState({
    country: '',
    state: '',
    city: '',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  }

  const onSubmit = (e) => {
    get_city_detailed_list(state, country);
  };


  const tableData = cityDetailedList;
  var {pageSize, pageSizeOptions, country, state} = formData;

  var countryListOption = countryList.map((option) => {
    return <option value={option.countryId}>{option.countryName}</option>;
  });
  var regionListOption = regionList.map((option) => {
    return <option value={option.stateProvId}>{option.stateProvName}</option>;
  });

  const tableColumns = [
    {
      Header: "Country",
      accessor: "country",
      className: "justify-content-start",

    },
    {
      Header: "State/Province/Region",
      accessor: "region",
      className: "justify-content-start"
    },

    {
      Header: "City",
      accessor: "cityName",
      className: "justify-content-start",
    },
    {
      Header: "Ref #",
      accessor: "ref",
      maxWidth: 100,
      Cell: row =>
        <Link
          to={`/admin-edit-location/${row.original.cityId}`}>{row.original.cityId}</Link>
    },
  ];
  return (
    <div className="mt-5 position-relative">
      <span className="tableHeader">Locations</span>
      <>
        <div noGutters
             className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">

          <div className="d-flex align-items-center p-2">
            <FormSelect name='country' onChange={(e) => {
              get_region_list(e.target.value);
              setFormData({...formData, country: e.target.value});
            }}>
              <option value=''>All Country</option>
              {countryListOption}
            </FormSelect>
          </div>
          <div className="d-flex align-items-center p-2">
            <FormSelect name='state' onChange={onChange}>
              <option value=''>All State/Province/Region</option>
              {regionListOption}
            </FormSelect>
          </div>

          <div className="d-flex align-items-center justify-content-end p-2">
            <Button onClick={() => get_city_detailed_list(state, country)}
                    className="mx-2 py-2 my-2" size='sm'
                    theme="accent">Search</Button>
          </div>
        </div>

        <Card className="tableCard p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                <Col
                  className="file-manager__filters__rows d-flex align-items-center col-6">
                  <span>Show</span>
                  <FormSelect size="sm" name='pageSize' value={pageSize}
                              onChange={onChange}>
                    {pageSizeOptions.map((size, idx) => <option key={idx}
                                                                value={size}>
                      {size} rows
                    </option>)}
                  </FormSelect>
                </Col>
                <Col
                  className="file-manager__filters__search d-flex justify-content-end col-6">
                  <Link to="admin-add-location/0">
                    <Button className="my-2 py-2 mx-2" size='sm' theme="accent">Add
                      Location</Button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={tableData}
                pageSize={Number(pageSize)}
                showPageSizeOptions={false}
                resizable={false}
                noDataText="No results found"/>
            </div>
          </CardBody>
        </Card>
      </>
    </div>
  )
}
