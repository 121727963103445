import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  DISABLE_2FA,
  DISABLE_2FA_ERROR,
  ENABLE_2FA,
  ENABLE_2FA_ERROR,
  GENERATED_2FA,
  GENERATED_2FA_ERROR,
  GET_SETTINGS,
  GET_SETTINGS_ERROR, RESET,
} from "../actions/types";

const initialState = {
  displayName:"",
  firstName:"",
  loginEmail:"",
  require2FA:null,
  userId:"",
  qrCode: null,
  qrImage: null,
  message:null,
  success:null,
  leavesNotification:false,
  expenseNotification:false,
  messageNotification:false,
  notificationSettings:{}
}


export const settings = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        ...state,
        message: action.payload.message,
        success: action.payload.success,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        message: null,
        success:null
      };

    case GENERATED_2FA:
      return {
        ...state,
        loading: false,
        qrCode: action.payload.qrCode,
        qrImage: action.payload.qrImage,
      };
    case GENERATED_2FA_ERROR:
      return {
        ...state,
        loading: false,
        qrCode: null,
        qrImage: null,
      };

    case GET_SETTINGS:
      return {
        ...state,
        ...action.payload.userProfile,
        notificationSettings: action.payload.userProfile
      };
    case GET_SETTINGS_ERROR:
      return {
        ...state,
        userProfile: null,
      };

    case ENABLE_2FA:
      return {
        ...state,
        require2FA:1
      };
    case ENABLE_2FA_ERROR:
      return {
        ...state,
      };
    case DISABLE_2FA:
      return {
        ...state,
        require2FA:0
      };
    case DISABLE_2FA_ERROR:
      return {
        ...state,
      };


    case RESET:
      return {
        ...state,
        message: null,
        success: null,
      };

    default:
      return state;
  }
}

export default settings;
