import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    Col, Form, FormSelect, DatePicker,
    ListGroup,
    ListGroupItem,
    Row,
    Container,
    Button,
} from "shards-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputBox from '../../common/InputBox';
import { useLocation } from 'react-router-dom';
import { setAlert } from "../../../redux/actions/alert";
import {
    clear_user_profile,
    get_client_list,
    get_compensation_types,
    get_country_list,
    sub_get_currency_list,
    get_deal_types_types,
    get_region_list,
    get_subscriber_list,
    get_hr_Consultant,
    get_user_profile,
    get_worker_types, reset,
    get_pay_component_list,
    get_pension_plan_frequency_list,
    get_pension_plan_list,
    save_user_profile,
    update_isSave_user_profile
} from "../../../redux/actions/user";
import { calculateEndDate, setAPIDate, setDateForTable, setInputDate } from '../../../utils/date';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { compareObjects, fixedDecimalPlaces, formatNumberAmount, toNumber } from '../../../utils/general';
import InfoTooltip from '../../common/InfoTooltip';

function PensionPlanForm(props) {
    const location = useLocation();
    const [notesCharacters, seNotesCharacters] = useState(100);
    const [disableInputs, setDisabledInputs] = useState({rpWorkerContributionAmount:true, rpClientContributionAmount:true,rpTotalClientContribution:true});
    const history = useHistory();
    const [hasError, setHasError] = useState(false);
    const formDataVal = useRef({})

    const [disabledSave, setDisabledSave] = useState(location.state.row !== undefined)

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const currentCompensation = (location.state && location.state.formData && location.state.formData.compensationInfos && location.state.formData.compensationInfos.length > 0) ? location.state.formData.compensationInfos && location.state.formData.compensationInfos[0] : [];

    const currentPlacement = (location.state && location.state.formData && location.state.formData.placementInfos && location.state.formData.placementInfos.length > 0) ? location.state.formData.placementInfos && location.state.formData.placementInfos[0] : [];

    const onMonetaryAmountChanged = (e, decimalPlaces) => {
        const value = e.target.value;

        const sanitizedValue = value.replace(/[^0-9.]/g, '');

        const parts = sanitizedValue.split('.');

        if (parts.length > 1 && parts[1].length > 2) {
            parts[1] = parts[1].slice(0, decimalPlaces);
        }

        const formattedValue = parts.join('.');

        e.target.value = formattedValue;

        onChange(e);
    };
    const onSave = async (e) => {

        e.preventDefault();

        let pensionInfos = location.state.formData.pensionInfos;
            for (const pensionInfo of pensionInfos) {
                let currentWorkerContributionStartDate = new Date(setDateForTable(pensionInfo.myMatchStartDate)).setHours(0, 0, 0, 0);
                let selectedWorkerContributionStartDate = new Date(formData.myMatchStartDate).setHours(0, 0, 0, 0);

                let currentClientContributionStartDate = new Date(setDateForTable(pensionInfo.clientMatchStartDate)).setHours(0, 0, 0, 0);
                let selectedClientContributionStartDate = new Date(formData.clientMatchStartDate).setHours(0, 0, 0, 0);

                if (selectedWorkerContributionStartDate !== selectedClientContributionStartDate) {
                    props.setAlert("Worker Contribution Start Date and Client Contribution Start Date must be the same.", "danger");
                    return 0;
                }

                if (pensionInfo.pensionPlanId !== formData.pensionPlanId && (selectedWorkerContributionStartDate === currentWorkerContributionStartDate)) {
                    props.setAlert("Details with the same WorkerContribution Start Date exists.", "danger");
                    return 0;
                }

                if (pensionInfo.pensionPlanId !== formData.pensionPlanId && (selectedClientContributionStartDate === currentClientContributionStartDate)) {
                    props.setAlert("Details with the same Client Contribution Start Date exists.", "danger");
                    return 0;
                }
            }

        let updatedFormData = { ...formData };
        //if ('myMatchStartDate' in updatedFormData) updatedFormData.myMatchStartDate = updatedFormData.myMatchStartDate=== '' ? 0 : setInputDate(updatedFormData.myMatchStartDate);
       // if ('clientMatchStartDate' in updatedFormData) updatedFormData.clientMatchStartDate = updatedFormData.clientMatchStartDate=== '' ? 0 : setInputDate(updatedFormData.clientMatchStartDate);
        if ('planStartDate' in updatedFormData) updatedFormData.planStartDate = updatedFormData.planStartDate === '' ? 0 :  setInputDate(updatedFormData.planStartDate);
        if ('myMatchStartDate' in updatedFormData) updatedFormData.myMatchStartDate =  updatedFormData.myMatchStartDate === '' ? 0 : setInputDate(updatedFormData.myMatchStartDate);
        if ('rpWorkerContributionEndDate' in updatedFormData) updatedFormData.rpWorkerContributionEndDate = updatedFormData.rpWorkerContributionEndDate === '' ? '0' : setInputDate(updatedFormData.rpWorkerContributionEndDate);
        if ('clientMatchStartDate' in updatedFormData) updatedFormData.clientMatchStartDate = updatedFormData.clientMatchStartDate === '' ? 0 : setInputDate(updatedFormData.clientMatchStartDate);
        if ('rpClientContributionEndDate' in updatedFormData) updatedFormData.rpClientContributionEndDate =  updatedFormData.rpClientContributionEndDate === '' ? '0' : setInputDate(updatedFormData.rpClientContributionEndDate);
        if ('rpMatchCapAmount' in updatedFormData) updatedFormData.rpMatchCapAmount = fixedDecimalPlaces(updatedFormData.rpMatchCapAmount, 2);
        if ('rpWorkerContribution' in updatedFormData) updatedFormData.rpWorkerContribution = fixedDecimalPlaces(updatedFormData.rpWorkerContribution,  formData.rpWorkerContributionType === 'Percentage' ? 2 : 4);
        if ('rpMarkupAmount' in updatedFormData) updatedFormData.rpMarkupAmount = fixedDecimalPlaces(updatedFormData.rpMarkupAmount, 2);
        if ('rpClientContribution' in updatedFormData) updatedFormData.rpClientContribution = fixedDecimalPlaces(updatedFormData.rpClientContribution, formData.rpClientContributionType === 'Percentage' ? 2 : 4);
        if ('rpTotalMarkupAmount' in updatedFormData) updatedFormData.rpTotalMarkupAmount = fixedDecimalPlaces(updatedFormData.rpTotalMarkupAmount, 4);
        if ('rpTotalClientContribution' in updatedFormData) updatedFormData.rpTotalClientContribution = fixedDecimalPlaces(updatedFormData.rpTotalClientContribution, 4);
        if ('rpPayrollTaxes' in updatedFormData) updatedFormData.rpPayrollTaxes = fixedDecimalPlaces(updatedFormData.rpPayrollTaxes, 3);
        if ('vestingPeriod' in updatedFormData) updatedFormData.vestingPeriod = updatedFormData.vestingPeriod === '' ? 0 : updatedFormData.vestingPeriod;
        formDataVal.current = {...updatedFormData}

        let workerContributionEndDate = calculateEndDate(updatedFormData.myMatchStartDate, 1);
        let clientContributionEndDate = calculateEndDate(updatedFormData.clientMatchStartDate, 1);

        let isSaved = false;

        if(location.state.row){

      let currentPensionForms = location.state.formData.pensionInfos;
      let updatedPensions = [];
      if (currentPensionForms) {
        updatedPensions = currentPensionForms.map(pension => {
          if (pension.pensionPlanId === updatedFormData.pensionPlanId) {
            return {
              userId:updatedFormData.userId,
              pensionPlanId: updatedFormData.pensionPlanId,
                pensionPlan: updatedFormData.pensionPlan,
                frequency: updatedFormData.frequency,
                //myMatch: updatedFormData.myMatch,
                // myMatchStartDate: updatedFormData.myMatchStartDate,
                // clientMatchStartDate: updatedFormData.clientMatchStartDate,
                // planStartDate: updatedFormData.planStartDate,
                //clientMatch: updatedFormData.clientMatch,
                planNo: updatedFormData.planNo,
                clientCap: updatedFormData.clientCap,
                vestingPeriod: updatedFormData.vestingPeriod,
                rpPlanAvailable: updatedFormData.rpPlanAvailable,
                rpPlanProvider: updatedFormData.rpPlanProvider,
                rpMatchCapAmount: updatedFormData.rpMatchCapAmount,
                myMatchStartDate: updatedFormData.myMatchStartDate,
                rpWorkerContributionEndDate: updatedFormData.rpWorkerContributionEndDate === "" ? "0" : updatedFormData.rpWorkerContributionEndDate,
                rpWorkerContributionAmount: updatedFormData.rpWorkerContributionAmount,
                rpClientPayComponentId: updatedFormData.rpClientPayComponentId,
                clientMatchStartDate: updatedFormData.clientMatchStartDate,
                rpClientContributionEndDate: updatedFormData.rpClientContributionEndDate === "" ? "0" : updatedFormData.rpClientContributionEndDate,
                rpClientContributionAmount: updatedFormData.rpClientContributionAmount,
                rpWorkerPayComponentId: updatedFormData.rpWorkerPayComponentId,
                rpWorkerContribution: updatedFormData.rpWorkerContribution,
                rpMarkup: updatedFormData.rpMarkup,
                rpMarkupAmount: updatedFormData.rpMarkupAmount,
                rpCurrency: updatedFormData.rpCurrency,
                rpClientContribution: updatedFormData.rpClientContribution,
                rpTotalMarkupAmount: updatedFormData.rpTotalMarkupAmount,
                rpTotalClientContribution: updatedFormData.rpTotalClientContribution,
                rpPayrollTaxes: updatedFormData.rpPayrollTaxes,
                //ciCompensationCurrency: updatedFormData.ciCompensationCurrency,
                rpWorkerContributionType: updatedFormData.rpWorkerContributionType,
                rpClientContributionType: updatedFormData.rpClientContributionType,
                pensionNote: updatedFormData.pensionNote,
            };
          }
          else {
            return pension;
          }


        })
      }

        location.state.formData.pensionInfos = updatedPensions;
            isSaved = true;
        }else{

            // if(formData.userId && formData.userId !== '0'){
            //     let updatedData = {userId:formData.userId, subscriberId: location.state.formData.subscriberId, pensionInfos: [{...updatedFormData}],
            //     pensionApplicable:'Yes',
            //     benefitsApplicable: location.state.formData.benefitsApplicable,
            //     retainerApplicable: location.state.formData.retainerApplicable,
            //     EAPApplicable : location.state.formData.EAPApplicable,
            //     HSAApplicable: location.state.formData.HSAApplicable}
            //     props.save_user_profile(updatedData);
            // }
            // else
            // {
            //     location.state.formData = {...location.state.formData, pensionInfos:[{...updatedFormData}, ...location.state.formData.pensionInfos], pensionApplicable:'Yes'}
            //     isSaved = true
            //  }
             location.state.formData = {...location.state.formData, pensionInfos:[{...updatedFormData}, ...location.state.formData.pensionInfos], pensionApplicable:'Yes'}
             isSaved = true
        }

        if (isSaved) {
            // myMatchStartDate

            // if(location.state.formData.pensionInfos.length > 1){
            //     location.state.formData.pensionInfos[1].rpWorkerContributionEndDate = workerContributionEndDate;
            //     location.state.formData.pensionInfos[1].rpClientContributionEndDate = clientContributionEndDate;

            // }



            if(location.state.formData.pensionInfos.length > 1){

                location.state.formData.pensionInfos.sort(function(p1, p2){return p2.myMatchStartDate - p1.myMatchStartDate});
                for(let i = 0; i < location.state.formData.pensionInfos.length; i++){
                   if(location.state.formData.pensionInfos[0].rpWorkerContributionEndDate !== ''){
                        if((i+1) < location.state.formData.pensionInfos.length ){

                            location.state.formData.pensionInfos[i+1].rpWorkerContributionEndDate = calculateEndDate(location.state.formData.pensionInfos[i].myMatchStartDate, 1);
                            location.state.formData.pensionInfos[i+1].rpClientContributionEndDate = calculateEndDate(location.state.formData.pensionInfos[i].clientMatchStartDate, 1);

                        }
                   }
                   else{

                    location.state.formData.pensionInfos[1].rpWorkerContributionEndDate = calculateEndDate(location.state.formData.pensionInfos[i].myMatchStartDate, 1);

                   }

                }
                location.state.formData.pensionInfos[0].rpWorkerContributionEndDate = '0';
                location.state.formData.pensionInfos[0].rpClientContributionEndDate = '0';


            }

            let userId = formData.userId === undefined ? 0 : formData.userId;
            let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
            history.push({
                pathname: `/manage-user-profile/${userId}`,
                search: `${roleStr}&section=benefits&subSection=pension`,
                state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
            });

        }


    }
    // useEffect(()=>{

    //     if(props.isSaveUserProfile){
    //         formDataVal.current.pensionPlanId = props.userProfile.pensionPlanInfos[0].pensionPlanId;

    //         if(location.state.sectionformDataRef.pensionInfos && location.state.sectionformDataRef.pensionInfos.length > 0 && props.userProfile.pensionPlanInfos[1]){
    //             location.state.sectionformDataRef.pensionInfos[0].rpWorkerContributionEndDate = props.userProfile.pensionPlanInfos[1].workerContributionEndDate;
    //             location.state.sectionformDataRef.pensionInfos[0].rpClientContributionEndDate = props.userProfile.pensionPlanInfos[1].clientContributionEndDate;
    //             location.state.formData.pensionInfos[0].rpWorkerContributionEndDate = props.userProfile.pensionPlanInfos[1].workerContributionEndDate;
    //             location.state.formData.pensionInfos[0].rpClientContributionEndDate = props.userProfile.pensionPlanInfos[1].clientContributionEndDate;

    //         }
    //         location.state.sectionformDataRef.pensionInfos = [{...formDataVal.current}, ...location.state.sectionformDataRef.pensionInfos]
    //         location.state.formData =  { ...location.state.formData, pensionInfos: [{ ...formDataVal.current}, ...location.state.formData.pensionInfos]}
    //         onCancel()
    //     }

    // },[props.isSaveUserProfile])

    const onCancel = ()=>{
        let userId = formData.userId === undefined ? 0 : formData.userId;
        let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
        props.update_isSave_user_profile(false);
        history.push({
            pathname: `/manage-user-profile/${userId}`,
            search: `${roleStr}&section=benefits&subSection=pension`,
            state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
        });
    }
    const onDateChange = (name, value) => {

       let pensionInfos = location.state.formData.pensionInfos;

       let hireDate = new Date(location.state.formData.hireDate).setHours(0, 0, 0, 0);
       let selectedDate = new Date(value).setHours(0, 0, 0, 0);

       if(selectedDate < hireDate){
           props.setAlert("Effective Date must not be less than the Hire Date.", "danger");
               return 0;
       }

            for (const pensionInfo of pensionInfos) {
                let currentDate = new Date(setDateForTable(pensionInfo[name])).setHours(0, 0, 0, 0); //name will be either client contribution start date of worker contribution start date

                if (pensionInfo.pensionPlanId !== formData.pensionPlanId && selectedDate === currentDate) {

                    props.setAlert(`Details with the same ${name === 'myMatchStartDate' ? 'Worker' : 'Client'} Contribution Start Date exists.`, "danger");
                    return 0;
                }
            }

        setHasError(!hasError)
        setFormData({ ...formData, [name]: value });

    };


    const onUnitFieldChange = (e, unit) => {
        let inputValue = e.target.value
        let value = (inputValue.includes(unit)) ? parseInt(inputValue.replace(unit, '')) : inputValue.substring(0, inputValue.length - 1);
        if (!value)
            value = 0
        if (value < 101)
            setFormData({ ...formData, [e.target.name]: value });
    };
    //const currentPensionPlanList = (location.state.userProfile && location.state.userProfile.pensionPlanList) ? Object.values(location.state.userProfile.pensionPlanList).flat() : [];
    //const currentPlacements = (location.state.userProfile && location.state.userProfile.pensionPlanList) ? Object.values(location.state.userProfile.pensionPlanList).flat() : [];

    const [formData, setFormData] = useState({

        userId: location.state.formData.userId,
        pensionPlan: '',
        frequency: '',
        //myMatch: '',
        myMatchStartDate: '',
        clientMatchStartDate: '',
        planStartDate: '',
        //clientMatch: '',
        planNo: '',
        clientCap: '',
        vestingPeriod: '',
        rpPlanAvailable: 'Yes',
        rpPlanProvider: '',
        rpMatchCapAmount: '',
        myMatchStartDate:  location.state.formData.pensionInfos.length > 0 ? new Date() : location.state.formData.hireDate,//location.state.userProfile.userId === undefined ? location.state.formData.hireDate : new Date(),
        rpWorkerContributionEndDate: '',
        rpWorkerContributionAmount: '',
        rpClientPayComponentId: '0',
        clientMatchStartDate: location.state.formData.pensionInfos.length > 0 ? new Date() : location.state.formData.hireDate,//location.state.userProfile.userId === undefined ? location.state.formData.hireDate : new Date(),
        rpClientContributionEndDate: '',
        rpClientContributionAmount: '',
        rpWorkerPayComponentId: '0',
        rpWorkerContribution: '',
        rpMarkup: location.state.clientMarkups && location.state.clientMarkups.pensionPlanType,
        rpMarkupAmount: location.state.clientMarkups && fixedDecimalPlaces(location.state.clientMarkups.pensionPlanAmount, 2),
        rpCurrency: location.state.formData.baseCurrency,
        rpClientContribution: '',
        rpTotalMarkupAmount: '',
        rpTotalClientContribution: '',
        rpPayrollTaxes: '',
        //ciCompensationCurrency: location.state.formData.baseCurrency,
        pensionNote: '',
        rpWorkerContributionType:'',
        rpClientContributionType:'',
        pensionPlanId: 1,

    });

    var payComponentListOption = props.payComponentList && props.payComponentList.map((cmp) => {
        return <option key={cmp.name + cmp.refNum} value={cmp.refNum}>{cmp.name}</option>;
    });
    // var pensionPlanFrequencyListOption = props.pensionPlanFrequencyList && props.pensionPlanFrequencyList.map((freq) => {
    //     return <option value={freq}>{freq}</option>;
    // });

    var currencyListOptions = props.currencyList && props.currencyList.map((option) => {
        return <option key={option.currencyCode + option.currencyId}
            value={option.currencyCode}>{option.currencySymbol + ' ' + option.currencyCode}</option>;
    });
    var pensionPlanListOption = props.pensionPlanList && props.pensionPlanList.map((plan, i) => {
        return <option key={plan + i} value={plan}>{plan}</option>;
    });

    const formatNumbers = (numbers, fixedDecimal)=>{
        const sanitizedValue = numbers.replace(/[^0-9.]/g, '');

         const parts = sanitizedValue.split('.');

         if (parts.length > 1 && parts[1].length > 2) {
             parts[1] = parts[1].slice(0, fixedDecimal);
         }

         return parts.join('.');
    }

    const onPayrollTaxes = (e)=>{

        let payrollTaxes = toNumber(e.target.value, 3)

        //If Deal Type is Uncapped - (Client Contribution Amount * (Payroll Taxes % + Retirement/Pension Plan Markup Amount)
        if(currentPlacement.length === 0 || currentPlacement.dealType !== 'Uncapped'){
            setFormData({...formData, [e.target.name] : payrollTaxes});
            return 0;
        }

        let clientcontributionAmount = formData.rpClientContributionAmount !=='' ? Number(formData.rpClientContributionAmount) : 0;
       let pensionPlanMarkupAmount = formData.rpMarkupAmount !== '' ? Number(formData.rpMarkupAmount) : 0;
        //let clientContributionAmount = formData.rpClientContributionAmount;
       let totalMarkupAmount = (clientcontributionAmount * (Number(payrollTaxes) + pensionPlanMarkupAmount));

       setFormData({...formData, 'rpPayrollTaxes': payrollTaxes, 'rpTotalMarkupAmount': parseFloat(totalMarkupAmount.toFixed(2))})
    }


    const calculateClientContributionAmount = (e)=>{

         //((Salary or Rate/ No of Pay Cycles as per the Retirement/Pension Plan Frequency)/100 * Worker Contribution %)
         let decimalPlaces = formData.rpClientContributionType === 'Percentage' ? 2 : 4;

         if(formData.rpClientContributionType === 'Flat'){
            let val = e.target.value;
            setFormData({...formData, rpClientContributionAmount : toNumber(val, decimalPlaces), [e.target.name]:toNumber(val, decimalPlaces)});

            return;
        }
         if(currentCompensation.length === 0){

            onMonetaryAmountChanged(e, decimalPlaces);
            return 0;

        }

         let frequency = 1;

         switch(formData.frequency){

             case 'Weekly' :
                 frequency =  52;
                 break;
                 case 'Bi-weekly' :
                     frequency =  26;
                     break;
                     case 'Semi-monthly' :
                         frequency =  24;
                         break;
                     case 'Monthly' :
                         frequency =  12;
                          break;
         }

         let contribution = e.target.value;

         const sanitizedValue = contribution.replace(/[^0-9.]/g, '');

         const parts = sanitizedValue.split('.');

         if (parts.length > 1 && parts[1].length > 2) {
             parts[1] = parts[1].slice(0, decimalPlaces);
         }

         const formattedclientContributionValue = parts.join('.');


         const clientContributionAmount = formData.rpClientContributionType === 'Flat' ? Number(formattedclientContributionValue) : ((Number(currentCompensation.salaryOrRate)/frequency)/100 * Number(formattedclientContributionValue));

         let totalMarkupAmount = 0.00;
         let totalClientContributionAmount = 0.00;
         //if Deal Type is flat fee
         //(Client Contribution Amount/100 * Client Contribution %)

         if(currentPlacement.length !== 0 && currentPlacement.dealType === 'Flat Fee')
         {
            totalMarkupAmount = (clientContributionAmount/100 * Number(formattedclientContributionValue));
         }
         totalClientContributionAmount = (totalMarkupAmount + clientContributionAmount)

         //if Deal Type is uncapped
         //(Client Contribution Amount * (Payroll Taxes % + Retirement/Pension Plan Markup Amount)

         //totalMarkupAmount = - (Client Contribution Amount * (Payroll Taxes % + Retirement/Pension Plan Markup Amount);


         setFormData({...formData, 'rpClientContributionAmount': clientContributionAmount.toFixed(2), 'rpClientContribution' : formattedclientContributionValue, 'rpTotalMarkupAmount' : parseFloat(totalMarkupAmount.toFixed(2)), 'rpTotalClientContribution' : parseFloat(totalClientContributionAmount.toFixed(2)) })


    }

    const calculateTotalClientContributionAmount = (e)=>{
        let numbers = toNumber(e.target.value, 4);
        setFormData({...formData, 'rpMarkupAmount' : numbers, 'rpTotalClientContribution' : parseFloat((Number(numbers) + Number(formData.rpClientContributionAmount)).toFixed(4))})

    }

    const calculateWorkerContributionAmount = (e)=>{

        //((Salary or Rate/ No of Pay Cycles as per the Retirement/Pension Plan Frequency)/100 * Worker Contribution %)
        let decimalPlaces = formData.rpWorkerContributionType === 'Percentage' ? 2 : 4;

        if(formData.rpWorkerContributionType === 'Flat'){
            let val = e.target.value;
            setFormData({...formData, rpWorkerContributionAmount : toNumber(val, decimalPlaces), [e.target.name]:toNumber(val, decimalPlaces)});

            return;
        }

        if(currentCompensation.length === 0){

            //onMonetaryAmountChanged(e, 2);
            setFormData({...formData, [e.target.name] : toNumber(e.target.value, decimalPlaces)})
            return;

        }

        if(currentPlacement.length !== 0 && ['Employee Hourly','Incorporated','Sole Proprietor',''].includes(currentPlacement.workerType)){
            onChange(e);
            setDisabledInputs({...disableInputs,rpWorkerContributionAmount:false });
            return;
        }

        let frequency = 1;

        switch(formData.frequency){

            case 'Weekly' :
                frequency =  52;
                break;
                case 'Bi-weekly' :
                    frequency =  26;
                    break;
                    case 'Semi-monthly' :
                        frequency =  24;
                        break;
                    case 'Monthly' :
                        frequency =  12;
                         break;
        }

        let contribution = e.target.value;

        const sanitizedValue = contribution.replace(/[^0-9.]/g, '');

        const parts = sanitizedValue.split('.');

        if (parts.length > 1 && parts[1].length > 2) {
            parts[1] = parts[1].slice(0, decimalPlaces);
        }

        const formattedValue = parts.join('.');



        //setFormData({...formData, 'rpWorkerContributionAmount': workerContributionAmount.toFixed(2), 'rpWorkerContribution' : formattedValue})


    }

    const calculateAmounts = ()=>{

       let clientContributionStartDate = new Date(formData.clientMatchStartDate).setHours(0, 0, 0, 0);
       let workerContributionStartDate = new Date(formData.myMatchStartDate).setHours(0, 0, 0, 0);

       let workerContributionCompensations = location.state.formData.compensationInfos.filter(compensationInfo => {

        let effectiveDate = new Date(setDateForTable(compensationInfo.ciEffectiveDate)).setHours(0, 0, 0, 0);
        let selectedPayComponent = props.payComponentList.filter(payComponent => Number(payComponent.refNum) === Number(compensationInfo.ciPayComponentId))

        return (workerContributionStartDate >= effectiveDate && selectedPayComponent && selectedPayComponent && selectedPayComponent.length > 0 && selectedPayComponent[0].name === 'Hourly')

       })

       let clientContributionCompensations = location.state.formData.compensationInfos.filter(compensationInfo => {
        let effectiveDate = new Date(setDateForTable(compensationInfo.ciEffectiveDate)).setHours(0, 0, 0, 0);
        let selectedPayComponent = props.payComponentList.filter(payComponent => Number(payComponent.refNum) === Number(compensationInfo.ciPayComponentId))

        return (clientContributionStartDate >= effectiveDate && selectedPayComponent && selectedPayComponent && selectedPayComponent.length > 0 && selectedPayComponent[0].name === 'Hourly')

       })

        //calculating client contribution amount
        let clientContribution = toNumber(formData.rpClientContribution, formData.rpClientContributionType === 'Percentage' ? 2 : 4)
        let workerContribution = toNumber(formData.rpWorkerContribution, formData.rpWorkerContributionType === 'Percentage' ? 2 : 4);

        let totalMarkupAmount = 0.00;
         let totalClientContributionAmount = 0.00;
         let clientContributionAmount = 0;
         let workerContributionAmount = 0;
         let payrollTaxes = toNumber(formData.rpPayrollTaxes, 3)
         let pensionPlanMarkupAmount = formData.rpMarkupAmount !== '' ? Number(formData.rpMarkupAmount) : 0;
        if(currentCompensation.length !== 0){

         let frequency = 0;

         switch(formData.frequency){

             case 'Weekly' :
                 frequency =  52;
                 break;
                 case 'Bi-weekly' :
                     frequency =  26;
                     break;
                     case 'Semi-monthly' :
                         frequency =  24;
                         break;
                     case 'Monthly' :
                         frequency =  12;
                          break;
         }

         if(clientContributionCompensations && clientContributionCompensations.length > 0 && frequency !== 0){
            if(formData.rpClientContributionType === 'Percentage')
            {
                clientContributionAmount = ((Number(clientContributionCompensations[0].ciSalaryOrRate)/frequency)/100 * Number(clientContribution));
            }else{
                clientContributionAmount =  Number(clientContribution);
            }
            }

         //if Deal Type is flat fee
         //(Client Contribution Amount/100 * Client Contribution %)
         if(currentPlacement.length !== 0 && currentPlacement.hrDealType === 'Flat Fee')
         {
            totalMarkupAmount = (clientContributionAmount * Number(pensionPlanMarkupAmount/100));
         }

         //if Deal Type is uncapped
         //(Client Contribution Amount * (Payroll Taxes % + Retirement/Pension Plan Markup Amount)

         //totalMarkupAmount = - (Client Contribution Amount * (Payroll Taxes % + Retirement/Pension Plan Markup Amount);


         //((Salary or Rate/ No of Pay Cycles as per the Retirement/Pension Plan Frequency)/100 * Worker Contribution %)




        if(workerContributionCompensations && workerContributionCompensations.length > 0 && frequency !== 0){
            if(['Employee Hourly','Incorporated','Sole Proprietor',''].includes(workerContributionCompensations[0].workerType)){
                setDisabledInputs({...disableInputs,rpWorkerContributionAmount:false });

            }
            if( formData.rpWorkerContributionType === 'Percentage')
            {
                workerContributionAmount = ((Number(workerContributionCompensations[0].ciSalaryOrRate)/frequency)/100 * workerContribution);
            }else{
                workerContributionAmount = Number(workerContribution);

            }
        }
        // calculate payroll tases

        if(currentPlacement.hrDealType === 'Uncapped'){

            totalMarkupAmount = (clientContributionAmount * ((Number(payrollTaxes)/100) + (pensionPlanMarkupAmount/100)));

         }
            totalMarkupAmount = parseFloat(totalMarkupAmount.toFixed(2));
            clientContributionAmount = parseFloat(clientContributionAmount.toFixed(2));
            totalClientContributionAmount = (totalMarkupAmount + clientContributionAmount);
            totalClientContributionAmount = parseFloat(totalClientContributionAmount.toFixed(2));

        }

        setFormData({...formData, 'rpPayrollTaxes': payrollTaxes, 'rpTotalMarkupAmount': totalMarkupAmount, 'rpTotalClientContribution' : totalClientContributionAmount, 'rpClientContribution' : clientContribution, 'rpClientContributionAmount': clientContributionAmount.toFixed(2), 'rpWorkerContributionAmount': workerContributionAmount.toFixed(2), 'rpWorkerContribution' : workerContribution})
    }

    useEffect(()=>{
        calculateAmounts();
    }, [formData.rpClientContribution, formData.frequency, formData.rpWorkerContribution, formData.rpTotalMarkupAmount, formData.rpPayrollTaxes, formData.myMatchStartDate, formData.clientMatchStartDate, formData.rpMarkupAmount, formData.rpWorkerContributionType, formData.rpClientContributionType])
    useEffect(() => {


        if(location.state.userProfile.firstName){
        props.changePageMeta(`Retirement Plan - ${location.state.userProfile.personalInfo.firstName} ${location.state.userProfile.personalInfo.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)
        }
        else{
            props.changePageMeta(`Retirement Plan - ${location.state.formData.firstName} ${location.state.formData.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)

        }
        if(currentPlacement.length !== 0 && ['Employee Hourly','Incorporated','Sole Proprietor',''].includes(currentPlacement.workerType)){
            setDisabledInputs({...disableInputs,rpWorkerContributionAmount:false,rpClientContributionAmount:false, rpTotalClientContribution:false });
        }

        props.get_worker_types();
        props.sub_get_currency_list();
        props.get_deal_types_types();
        props.get_compensation_types();
        props.get_pay_component_list();
        props.get_pension_plan_list();
        props.get_pension_plan_frequency_list();

        if (location.state && location.state.row) {

            let currentPension = { ...location.state.row }

            setFormData({
                ...formData,
                userId: currentPension.userId,
                pensionPlanId: currentPension.pensionPlanId,
                pensionPlan: currentPension.pensionPlan,
                frequency: currentPension.frequency,
                //myMatch: currentPension.myMatch,
                //myMatchStartDate: currentPension.myMatchStartDate,
                //clientMatchStartDate: currentPension.clientMatchStartDate,
                //planStartDate: currentPension.planStartDate,
                //clientMatch: currentPension.clientMatch,
                planNo: currentPension.planNo,
                clientCap: currentPension.clientCap,
                vestingPeriod: currentPension.vestingPeriod,
                rpPlanAvailable: currentPension.rpPlanAvailable,
                rpPlanProvider: currentPension.rpPlanProvider,
                rpMatchCapAmount: currentPension.rpMatchCapAmount,
                myMatchStartDate:setAPIDate(currentPension.myMatchStartDate),
                rpWorkerContributionEndDate: setAPIDate(currentPension.rpWorkerContributionEndDate),
                rpWorkerContributionAmount: currentPension.rpWorkerContributionAmount,
                rpClientPayComponentId: currentPension.rpClientPayComponentId,
                clientMatchStartDate: setAPIDate(currentPension.clientMatchStartDate),
                rpClientContributionEndDate: setAPIDate(currentPension.rpClientContributionEndDate),
                rpClientContributionAmount: currentPension.rpClientContributionAmount,
                rpWorkerPayComponentId: currentPension.rpWorkerPayComponentId,
                rpWorkerContribution: currentPension.rpWorkerContribution,
                rpMarkup: currentPension.rpMarkup,
                rpMarkupAmount: currentPension.rpMarkupAmount,
                rpCurrency: currentPension.rpCurrency,
                rpClientContribution: currentPension.rpClientContribution,
                rpTotalMarkupAmount: currentPension.rpTotalMarkupAmount,
                rpTotalClientContribution: currentPension.rpTotalClientContribution,
                rpPayrollTaxes: currentPension.rpPayrollTaxes,
                //ciCompensationCurrency: currentPension.ciCompensationCurrency,
                rpWorkerContributionType: currentPension.rpWorkerContributionType,
                rpClientContributionType: currentPension.rpClientContributionType,
                pensionNote: currentPension.pensionNote,



            })
        }else{
            if(location.state.formData.pensionInfos[0]){
                //setFormData({...formData, pensionPlanId : Number(location.state.formData.pensionInfos[0].pensionPlanId) + 1})
                let pensionPlanIds = location.state.formData.pensionInfos.map(pension => Number(pension.pensionPlanId));
                setFormData({...formData,
                        pensionPlanId: Math.max(...pensionPlanIds) + 1,
                        pensionPlan: location.state.formData.pensionInfos[0].pensionPlan,
                        frequency: location.state.formData.pensionInfos[0].frequency,
                        planNo: location.state.formData.pensionInfos[0].planNo,
                        clientCap: location.state.formData.pensionInfos[0].clientCap,
                        vestingPeriod: location.state.formData.pensionInfos[0].vestingPeriod,
                        rpPlanAvailable: location.state.formData.pensionInfos[0].rpPlanAvailable,
                        rpPlanProvider: location.state.formData.pensionInfos[0].rpPlanProvider,
                        rpMatchCapAmount: location.state.formData.pensionInfos[0].rpMatchCapAmount,
                        rpWorkerContributionAmount: location.state.formData.pensionInfos[0].rpWorkerContributionAmount,
                        rpClientPayComponentId: location.state.formData.pensionInfos[0].rpClientPayComponentId,
                        rpClientContributionAmount: location.state.formData.pensionInfos[0].rpClientContributionAmount,
                        rpWorkerPayComponentId: location.state.formData.pensionInfos[0].rpWorkerPayComponentId,
                        rpWorkerContribution: location.state.formData.pensionInfos[0].rpWorkerContribution,
                        rpMarkup: location.state.formData.pensionInfos[0].rpMarkup,
                        rpMarkupAmount: location.state.formData.pensionInfos[0].rpMarkupAmount,
                        rpCurrency: location.state.formData.pensionInfos[0].rpCurrency,
                        rpClientContribution: location.state.formData.pensionInfos[0].rpClientContribution,
                        rpTotalMarkupAmount: location.state.formData.pensionInfos[0].rpTotalMarkupAmount,
                        rpTotalClientContribution: location.state.formData.pensionInfos[0].rpTotalClientContribution,
                        rpPayrollTaxes: location.state.formData.pensionInfos[0].rpPayrollTaxes,
                        rpWorkerContributionType: location.state.formData.pensionInfos[0].rpWorkerContributionType,
                        rpClientContributionType: location.state.formData.pensionInfos[0].rpClientContributionType,
                        pensionNote: location.state.formData.pensionInfos[0].pensionNote,})

            }
        }

    }, [])

    useEffect(()=>{
        if(location.state.row){
            setDisabledSave(compareObjects(location.state.row, formData))
        }
    },[formData])

    return (
        <>
            <Container fluid className="main-content-container p-0">
                <Form onSubmit={onSave}>
                    <Card small className=" w-100" style={{ borderRadius: 0 }}>
                        <Row className="w-100" style={{ marginLeft: '-4px' }}>
                            <Col lg="12" className="form-group m-1 p-1">
                                <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
                                    <Row className="m-1">
                                        <Col lg="8" className="d-flex flex-row">
                                            {/*<h4 className="m-0">Placement - First Name Last Name</h4>*/}
                                        </Col>
                                        <Col lg="4" className="d-flex flex-row-reverse">
                                            <Button onClick={onCancel} className="my-2 py-2 mx-2" size='sm'
                                                theme="accent">Cancel</Button>

                                            <Button className="my-2 py-2 mx-2" size='sm' disabled={disabledSave}
                                                theme="accent">Save</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card small className="mb-4 w-100 p-3" style={{ borderRadius: 0 }}>


                        <Card small className="mb-4 mt-5 w-100">
                            <h4 className="m-0 section-title idStyle">Ref# {formData.pensionPlanId}</h4>
                            <ListGroup flush>
                                <ListGroupItem className="border-card p-3">

                                    <Row>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="pensionPlan">Retirement Plan Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} id="pensionPlan" name='pensionPlan' value={formData.pensionPlan}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Retirement Plan Type</option>
                                                {pensionPlanListOption}
                                            </FormSelect>
                                        </Col>

                                        <InputBox required={true} placeholder="Enter Retirements/Pension Plan Provider" label="Retirement Plan Provider" id='rpPlanProvider' name='rpPlanProvider'
                                            value={formData.rpPlanProvider}
                                            onChange={onChange} />

                                        <InputBox type='number' placeholder="Enter Retirement Plan Number " label="Retirement Plan Number " name='planNo' id='planNo'
                                            value={formData.planNo}
                                            onChange={onChange} />

                                        <InputBox placeholder="Enter Vesting Period" label="Vesting Period" id='vestingPeriod' name='vestingPeriod'
                                            value={formData.vestingPeriod}
                                            onChange={onChange} />

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="frequency">Retirement Plan Frequency </label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} id='frequency' name='frequency'
                                                value={formData.frequency} onChange={onChange}
                                                className='d-block'>
                                                <option value=''>Select Retirement Plan Frequency </option>
                                                <option key="Weekly" value='Weekly'>Weekly (52 Cycles)</option>
                                                <option key="Bi-weekly" value='Bi-weekly'>Bi-weekly (26 Cycles)</option>
                                                <option key="Semi-monthly" value='Semi-monthly'>Semi-monthly (24 Cycles)</option>
                                                <option key="Monthly" value='Monthly'>Monthly (12 Cycles)</option>
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Retirement Plan Currency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='rpCurrency' value={formData.rpCurrency}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Retirement Plan Currency</option>
                                                {currencyListOptions}
                                            </FormSelect>
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>


                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Worker Contribution Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <InfoTooltip msg="Select Flat if the Pension Contribution is a fixed amount.
Select Percentage if the Pension Contribution is a Percentage of the Annual Compensation."/>
                                            <FormSelect required={true} name='rpWorkerContributionType' value={formData.rpWorkerContributionType}
                                                onChange={onChange} className='d-block'>
                                                <option key='workerContributionType' value=''>Select Worker Contribution Type</option>
                                                <option key='Percentage' value='Percentage'>Percentage</option>
                                                <option key=' Flat' value='Flat'> Flat</option>

                                            </FormSelect>
                                        </Col>
                                        <InputBox required={true} label={formData.rpWorkerContributionType === 'Percentage' ? `Worker Contribution %` : formData.rpWorkerContributionType === 'Flat' ? `Worker Contribution Flat` : `Worker Contribution <% or Flat>` } id='rpWorkerContribution' name='rpWorkerContribution'
                                            value={formData.rpWorkerContribution}
                                            align={formData.rpWorkerContributionType === 'Percentage' ? 'left' : 'right'}
                                            toolTipMSG={formData.rpWorkerContributionType === 'Percentage' ? `Enter the Percentage for Pension Contribution.` : formData.rpWorkerContributionType === 'Flat' ? `Enter the Flat Amount for Pension Contribution.` : `Enter the &lt;Percentage or Flat Amount&gt; for Pension Contribution.`}
                                            placeholder={formData.rpWorkerContributionType === 'Percentage' ? '0.00' : '0.0000'}
                                            onChange={(e)=>onMonetaryAmountChanged(e, formData.rpWorkerContributionType === 'Percentage' ? 2 : 4)} />
                                            <Col lg="3" className="form-group m-0 p-2"></Col>
                                            <Col lg="3" className="form-group m-0 p-2"></Col>


                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="myMatchStartDate">Worker Contribution Start Date</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <DatePicker
                                                id='myMatchStartDate'
                                                required={true}
                                                autoComplete='off'
                                                name='myMatchStartDate'
                                                dateFormat='yyyy-MM-dd'
                                                selected={formData.myMatchStartDate}
                                                onChange={(date) =>
                                                    onDateChange("myMatchStartDate", date)
                                                }
                                                value={formData.myMatchStartDate}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="rpWorkerContributionEndDate">Worker Contribution End Date</label>
                                            <DatePicker
                                                id='rpWorkerContributionEndDate'
                                                disabled={true}
                                                autoComplete='off'
                                                name='rpWorkerContributionEndDate'
                                                dateFormat='yyyy-MM-dd'
                                                selected={formData.rpWorkerContributionEndDate}
                                                onChange={(date) =>
                                                    onDateChange("rpWorkerContributionEndDate", date)
                                                }
                                                value={formData.rpWorkerContributionEndDate}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Component (Worker)</label>
                                            <FormSelect name='rpWorkerPayComponentId' value={formData.rpWorkerPayComponentId}
                                                onChange={onChange} className='d-block'>
                                                <option value='0'>Select Pay Component (Worker)</option>
                                                {payComponentListOption}
                                            </FormSelect>
                                        </Col>

                                        <InputBox disabled={disableInputs.rpWorkerContributionAmount} placeholder="0.0000" label="Worker Contribution Amount" id='rpWorkerContributionAmount' name='rpWorkerContributionAmount'
                                            value={disableInputs.rpWorkerContributionAmount ? formatNumberAmount(formData.rpWorkerContributionAmount,'en-US', 2, 2) : formData.rpWorkerContributionAmount}
                                            align='right'
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Client Contribution Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <InfoTooltip msg="Select Flat if the Pension Contribution is a fixed amount.
Select Percentage if the Pension Contribution is a Percentage of the Annual Compensation."/>
                                            <FormSelect required={true} name='rpClientContributionType' value={formData.rpClientContributionType}
                                                onChange={onChange} className='d-block'>
                                                <option key='clientContributionType' value=''>Select Client Contribution Type</option>
                                                <option key='Percentage' value='Percentage'>Percentage</option>
                                                <option key='Flat' value='Flat'> Flat</option>
                                            </FormSelect>
                                        </Col>
                                        <InputBox required={true} label={formData.rpClientContributionType === 'Percentage' ? `Client Contribution %` : formData.rpClientContributionType === 'Flat' ? `Client Contribution Flat` : `Client Contribution < % or Flat>` } id='rpClientContribution' name='rpClientContribution'
                                            value={formData.rpClientContribution}
                                            align={formData.rpClientContributionType === 'Percentage' ? 'left' : 'right'}
                                            toolTipMSG={formData.rpClientContributionType === 'Percentage' ? `Enter the Percentage for Pension Contribution.` : formData.rpClientContributionType === 'Flat' ? `Enter the Flat Amount for Pension Contribution.` : `Enter the &lt;Percentage or Flat Amount&gt; for Pension Contribution.`}
                                           placeholder={formData.rpClientContributionType === 'Percentage' ? '0.00' : '0.0000'}
                                           onChange={(e)=>onMonetaryAmountChanged(e, formData.rpClientContributionType === 'Percentage' ? 2 : 4)} />

                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="clientMatchStartDate">Client Contribution Start Date</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <DatePicker

                                                id='clientMatchStartDate'
                                                required={true}
                                                autoComplete='off'
                                                name='clientMatchStartDate'
                                                dateFormat='yyyy-MM-dd'
                                                selected={formData.clientMatchStartDate}
                                                onChange={(date) =>
                                                    onDateChange("clientMatchStartDate", date)
                                                }
                                                value={formData.clientMatchStartDate}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="rpClientContributionEndDate">Client Contribution End Date</label>
                                            <DatePicker
                                                id='rpClientContributionEndDate'
                                                disabled={true}
                                                autoComplete='off'
                                                name='rpClientContributionEndDate'
                                                dateFormat='yyyy-MM-dd'
                                                selected={formData.rpClientContributionEndDate}
                                                onChange={(date) =>
                                                    onDateChange("rpClientContributionEndDate", date)
                                                }
                                                value={formData.rpClientContributionEndDate}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Component (Client)</label>
                                            <FormSelect name='rpClientPayComponentId' value={formData.rpClientPayComponentId}
                                                onChange={onChange} className='d-block'>
                                                <option value='0'>Select Pay Component (Client)</option>
                                                {payComponentListOption}

                                            </FormSelect>
                                        </Col>

                                        <InputBox disabled={disableInputs.rpClientContributionAmount} placeholder="0.0000" label="Client Contribution Amount" id='rpClientContributionAmount' name='rpClientContributionAmount'
                                            value={disableInputs.rpClientContributionAmount ? formatNumberAmount(formData.rpClientContributionAmount, 'en-US', 2, 2) : formData.rpClientContributionAmount}
                                            align='right'
                                            toolTipMSG="This amount is (Client Contribution without Markup)."
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />

                                        <InputBox className='word-spacing' disabled={disableInputs.rpTotalClientContribution} placeholder="0.0000" label="Total Client Contribution Amount" id='rpTotalClientContribution' name='rpTotalClientContribution'
                                            value={disableInputs.rpClientContributionAmount ? formatNumberAmount(formData.rpTotalClientContribution, 'en-US', 2, 2) : formData.rpTotalClientContribution}
                                            align='right'
                                            toolTipMSG="This amount is (Client Contribution with Markup)."
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="rpMarkup">Retirement Plan Markup Type </label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='rpMarkup' value={formData.rpMarkup}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Retirement Plan Markup Type </option>
                                                <option value='%'>%</option>
                                                <option value='Flat'>Flat</option>
                                            </FormSelect>
                                        </Col>
                                        <InputBox required={true} placeholder="Enter Retirement Plan Markup Amount " label="Retirement Plan Markup Amount " id='rpMarkupAmount' name='rpMarkupAmount'
                                            value={formData.rpMarkupAmount}
                                            align={formData.rpMarkup === '%' ? 'left' : 'right'}
                                            onChange={calculateTotalClientContributionAmount} />
                                        <InputBox required={true} disabled={true} placeholder="0.0000" label="Retirement Plan Total Markup Amount" id='rpTotalMarkupAmount' name='rpTotalMarkupAmount'
                                            value={formatNumberAmount(formData.rpTotalMarkupAmount, "en-US", 2, 2)}
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)}
                                            align='right'
                                            toolTipMSG="The Amount a company makes on Retirement Plan Services." />

                                        <InputBox required={true} placeholder="0.000" label="Payroll Taxes % " id='rpPayrollTaxes' name='rpPayrollTaxes'
                                            value={formData.rpPayrollTaxes + '%'}
                                            toolTipMSG="This is the Employer Payroll Taxes based on the Province of Employment, to be included in calculation along with Markup if Deal type is Uncapped."
                                            onChange={(e)=>onMonetaryAmountChanged(e, 3)} />

                                        {/*<InputBox placeholder="Retirement Match Cap" label="Retirement Match Cap" id='clientCap' name='clientCap'
                                            value={formData.ciCompensationCurrency + formData.clientCap}
                                            onChange={onMonetaryAmountChanged} />

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="planStartDate">Plan Start Date</label>
                                            <DatePicker
                                                id='planStartDate'
                                                name='planStartDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.planStartDate}
                                                selected={formData.planStartDate}
                                                autoComplete='off'
                                                onChange={(date) =>
                                                    onDateChange("planStartDate", date)
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="myMatchStartDate">My Match Start Date</label>
                                            <DatePicker
                                                id='myMatchStartDate'
                                                name='myMatchStartDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.myMatchStartDate}
                                                selected={formData.myMatchStartDate}
                                                autoComplete='off'
                                                onChange={(date) =>
                                                    onDateChange("myMatchStartDate", date)
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="clientMatchStartDate">Client Match Start Date</label>
                                            <DatePicker
                                                id='clientMatchStartDate'
                                                name='clientMatchStartDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.clientMatchStartDate}
                                                selected={formData.clientMatchStartDate}
                                                autoComplete='off'
                                                onChange={(date) =>
                                                    onDateChange("clientMatchStartDate", date)
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>




                                        <InputBox placeholder="Enter My Match" id="myMatch" label="My Match" name='myMatch'
                                            value={formData.myMatch + "%"}
                                            onChange={e => onUnitFieldChange(e, "%")} />

                                        <InputBox placeholder="Enter Client Match" label="Client Match" name='clientMatch' id='clientMatch'
                                            value={formData.clientMatch + "%"}
                                            onChange={e => onUnitFieldChange(e, "%")} />





                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Retirement Plan Applicable</label>
                                            <FormSelect name='rpPlanAvailable' value={formData.rpPlanAvailable}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Retirement/Pension Plan Applicable</option>
                                                <option value='Yes'>Yes</option>
                                                <option value='No'>No</option>
                                            </FormSelect>
                                        </Col>

                                        <InputBox placeholder="Enter Retirement Match Cap amount" label="Retirement Match Cap amount" id='rpMatchCapAmount' name='rpMatchCapAmount'
                                            value={formData.rpMatchCapAmount}
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />*/}







                                        {/* <InputBox placeholder="Enter Retirement/Pension Plan Markup Type" label="Retirement Plan Markup Type" id='rpMarkup' name='rpMarkup'
                value={rpMarkup}
        onChange={onChange}/>*/}




                                    </Row>

                                    <Row>
                                        <Col>
                                            <label htmlFor="pensionNote">Notes</label>
                                            <textarea placeholder='Enter notes' maxLength="100" className="form-control" value={formData.pensionNote} rows="3" id="pensionNote" name="pensionNote" onChange={(e) => {

                                                if (formData.pensionNote.length >= 99) {

                                                    props.setAlert("Notes can not exceed more than 100 charaters.", "danger")
                                                }
                                                onChange(e)
                                            }}>
                                                {formData.pensionNote}
                                            </textarea>
                                            <span>Maximum {notesCharacters} characters.</span>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Card>
                </Form>
            </Container>
        </>
    );
}

PensionPlanForm.propTypes = {
    userProfile: PropTypes.object.isRequired,
    get_user_profile: PropTypes.func.isRequired,
    user: PropTypes.object,
    workerTypes: PropTypes.array.isRequired,
    dealTypes: PropTypes.array.isRequired,
    currencyList: PropTypes.array.isRequired,
    setAlert: PropTypes.func.isRequired,
    success: PropTypes.any,
    reset: PropTypes.func.isRequired,
    payComponentList: PropTypes.array.isRequired,
    pensionPlanList: PropTypes.array.isRequired,
    pensionPlanFrequencyList: PropTypes.array.isRequired,

}

const mapStateToProps = (state) => {

    return {
        userProfile: state.user.userProfile,
        isSaveUserProfile: state.user.isSaveUserProfile,
        user: state.user.user,
        workerTypes: state.user.workerTypes,
        dealTypes: state.user.dealTypes,
        currencyList: state.user.currencyList,
        message: state.user.message,
        success: state.user.success,
        alerts: state.alert,
        moduleName: state.user.moduleName,
        payComponentList: state.user.payComponentList,
        isSaveUserPlacement: state.isSaveUserPlacement,
        pensionPlanList: state.user.pensionPlanList,
        pensionPlanFrequencyList: state.user.pensionPlanFrequencyList,
    }
}
export default connect(mapStateToProps, {

    get_worker_types,
    get_user_profile,
    clear_user_profile,
    get_subscriber_list,
    get_hr_Consultant,
    sub_get_currency_list,
    get_country_list,
    get_compensation_types,
    get_region_list,
    get_deal_types_types,
    get_client_list,
    get_pay_component_list,
    setAlert,
    reset,
    get_pension_plan_frequency_list,
    get_pension_plan_list,
    save_user_profile,
    update_isSave_user_profile
})(PensionPlanForm);
