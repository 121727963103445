import React, {useEffect, useState} from 'react'
import ReactTable from "react-table";
import classNames from "classnames";

import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container,
  Row,
  FormSelect,
  Form
} from "shards-react";
import {Link, useHistory} from "react-router-dom";
import InfoTooltip from '../../common/InfoTooltip';

export default function DistanceUnit({
                                   confirmAlert,
                                   setConfirmAlert,
                                                                  
                                   save_base_distance_unit,                                  
                                   baseDistanceUnitList,
                                   DistanceUnitId,setAlert
                                 }) {

  let history = useHistory();

  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,
    UnitId: ""
  });

  useEffect(() => {
    setFormData({...formData, UnitId: DistanceUnitId})
  }, [DistanceUnitId]);



  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };


  const tableData = baseDistanceUnitList;
  var {pageSize, pageSizeOptions} = formData;

  const tableColumns = [
    {
      Header: "Distance Unit",
      accessor: "unit",
      className: "text-center",

    },
    {
      Header: "Ref #",
      accessor: "distanceUnitId",
      maxWidth: 100,
      Cell: row =>
        <Link
          to={`/sub-edit-distance-unit/${row.original.distanceUnitId}`}>{row.original.distanceUnitId}</Link>
    },
  ];
  const { className } = "";
  const classes = classNames(className, "d-flex", "my-auto", "date-range");

  let baseDistanceUnitOptionsList = baseDistanceUnitList.map(DistanceUnit => {
    return <option value={DistanceUnit.distanceUnitId}>{DistanceUnit.unit}</option>
  });    


  let onSaveDistanceUnit = (e) => {
    if (formData.UnitId && formData.UnitId != '') {
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to change the base Distance Unit?',
      visible: true,
      onConfirm: () => {
       
        save_base_distance_unit(formData.UnitId);
     }
    })

  }else{
    setAlert("Please select Base Distance Unit", "danger");

  }
  };
  return (
    <div className="position-relative mt-5">
      <span className="tableHeader">Distance Unit</span>
      <Card className="tableCard p-0 border-card">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {
                /* Filters :: Page Size */
              }
              <Col lg="4"
                className="file-manager__filters__rows d-flex align-items-center mt-4">
                <span>Show</span>
                <FormSelect size="sm" name='pageSize' value={pageSize}
                            onChange={onChange}>
                  {pageSizeOptions.map((size, idx) => <option key={idx}
                                                              value={size}>
                    {size} rows
                  </option>)}
                </FormSelect>
              </Col>
              <Col className="d-flex align-items-center justify-content-center form-group p-2 mb-0" lg="4">
                <Form>

                  <Row>
                    <Col lg="9">
                    <label htmlFor="baseDistanceUnit">Base Distance Unit<span aria-hidden="true" className="required">*</span>
              </label>
              <InfoTooltip
                            msg="Set the Base Distance Unit to create the default for all mileage rate configuration and transactions" />
                <FormSelect name='UnitId'
                            value={formData.UnitId}
                            onChange={onChange}>
                               <option
                    value=''> Select Base Distance Unit
                  </option>
                  {/* <option
                    value=''>{(baseDistanceUnitOptionsList.length) ? "Select Base Distance Unit" : baseDistanceUnit.distanceUnitId}
                  </option> */}
                  {baseDistanceUnitOptionsList}
                </FormSelect>
                    </Col>
                    <Col lg="3" className="mt-2">
                    <Button className="mt-4" size='sm' theme="accent"
                        onClick={onSaveDistanceUnit}>Save</Button>
                    </Col>
                  </Row>
              

               
                </Form>
              </Col>
              <Col lg="4"
                className="file-manager__filters__search d-flex justify-content-end mt-3">

                <Link to="/sub-add-distance-unit/0">
                  <Button className="mt-4" size='sm' theme="accent">Add
                  Distance Unit</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable columns={tableColumns}
                        data={tableData}
                        pageSize={Number(pageSize)}
                        showPageSizeOptions={false}
                        resizable={false}
                        noDataText="No results found"/>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}




