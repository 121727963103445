import React from 'react';
import {
  Card,
  CardHeader,
  Col, Form, FormGroup, FormInput, FormSelect,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {setDateForTable} from "../../../utils/date";
import {fixedDecimalPlaces, formatNumberAmount, getFrequencyLabel, numberToMonetary} from "../../../utils/general";

function PensionPlan(props) {
  const {viewProfile} = props
  return (
    <Card id='pension' small className="details-card mb-5 w-100">
      {viewProfile?
      <CardHeader className="border-bottom">
        <h4 className="m-0 section-title">Retirement Plan</h4>
      </CardHeader>:
      <>
      <span className="iconBox"><i className="fas fa-umbrella iconStyle"></i></span>
      <h4 className="m-0 section-title headerStyle">Retirement Plan</h4>
      </>
      }
      <ListGroup flush>
        <ListGroupItem className={viewProfile?"p-3":"p-3 mt-3"}>
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Retirement/Pension Plan</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.pensionPlan}</span>
                  </Col>
                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Plan Number</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.planNo}</span>
                  </Col>

                  {/* <Col lg="3" className="form-group p-2 mb-0">
                    <label>Retirement Match Cap</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{numberToMonetary(props.clientCap)}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Plan Start Date</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{setDateForTable(props.planStartDate)}</span>
    </Col>*/}
                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Worker Contribution Start Date</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{setDateForTable(props.myMatchStartDate)}</span>
                  </Col>
                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Client Contribution Start Date</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{setDateForTable(props.clientMatchStartDate)}</span>
                  </Col>
                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Vesting Period</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.vestingPeriod} months</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>My Match</label>
                    <span className={viewProfile?" form-control ":`inputBoxClr  form-control ${props.workerContributionType === "Flat" ? ' align-input-amount-right' : ''}`}>{ props.workerContributionType === "Flat" ? formatNumberAmount(props.rpWorkerContribution || props.workerContribution, 'en-US', 4, 4) : fixedDecimalPlaces(props.rpWorkerContribution || props.workerContribution, 2) + '%'}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Client Match</label>
                    <span className={viewProfile?" form-control ":`inputBoxClr  form-control ${props.clientContributionType === "Flat" ? ' align-input-amount-right' : ''}`}>{props.clientContributionType === "Flat" ? formatNumberAmount(props.rpClientContribution || props.clientContribution, 'en-US', 4, 4) : fixedDecimalPlaces(props.rpClientContribution || props.clientContribution, 2) + '%'}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Frequency</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{getFrequencyLabel(props.pensionPlanFrequency)}</span>
                  </Col>

                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}

export default PensionPlan;
