import React, {useRef, useState} from 'react'
import {
  Col,
} from "shards-react";
import InfoTooltip from "./InfoTooltip";
import {STANDARD_FORMATS} from "../../utils/constants";
import {
  formatPhoneNumber,
  monetaryToNumber,
  numberToMonetary
} from "../../utils/general";


export default function StandardFormInput({
                                            label,
                                            subLabel,
                                            placeholder,
                                            onChangeStandardInput,
                                            name,
                                            value,
                                            id,
                                            type = "text",
                                            toolTipMSG,
                                            readOnly,
                                            disabled,
                                            required,
                                            dataMaxFracDigits = "2",
                                            standardFormat,
                                            align="left"
                                          }) {

  const ref = useRef({firstRender: true, ext: ""});
  const [state, setState] = useState({
    errors: ''
  });
  function getExt(value) {
    let ext=""
    let reversed=value.split("").reverse()
    for(var i=0;i<reversed.length; i++){
      if(reversed[i]==="0")
        ext="0"+ext
        else if(reversed[i]===".")
          ext="."+ext
      else break;
    }
    return ext
  }

  let onInputChange = (e) => {
    let value = e.target.value;
    let isValid = true;
    if (standardFormat === STANDARD_FORMATS.COMMA_SEPERATED_NUMBER) {
      let parts=value.split(".")
      if(parts[1])
        value=parts[0]+"."+parts[1].substr(0,dataMaxFracDigits)

      ref.current.firstRender = false;
      if(value.includes(".")) {
        if (value.endsWith("."))
          ref.current.ext = "."
        else if (value.endsWith("0"))
          ref.current.ext = getExt("."+value.split(".")[1])
        else ref.current.ext=""
      }
      else ref.current.ext="";
      value = monetaryToNumber(value)||0
    }else if(standardFormat === STANDARD_FORMATS.PHONE_NO){
      var pattern = new RegExp(/^[\s+()]*[0-9][-*+()0-9]*/);
      var minMaxPateern = new RegExp(/^.{7,20}$/);
      if((!pattern.test(value) || !minMaxPateern.test(value)) && value.length > 0)
      {
      setState({
        ...state , errors : 'Please enter valid phone number'
      });
      isValid = false;
      }
      else
      {
        setState({
          ...state , errors : ''
        });
        isValid = true;
      }
      value=value.replace(/[^0-9]/g, '')
    }
    onChangeStandardInput(name, value, isValid)
  }

  if (standardFormat === STANDARD_FORMATS.COMMA_SEPERATED_NUMBER) {
    if (ref.current.firstRender)
      value = numberToMonetary(value, dataMaxFracDigits)
    else value = value.toLocaleString(undefined, {
      maximumFractionDigits: dataMaxFracDigits,
      minimumFractionDigits: 0
    })
    if (ref.current.ext)
      value = value + ref.current.ext;
  }else if(standardFormat === STANDARD_FORMATS.PHONE_NO){
    value=formatPhoneNumber(value)
  }

  return (
    <Col lg="3" className="form-group p-2 mb-0">
      <label htmlFor={id === "" ? name : id}>{label}{required && <span aria-hidden="true" className="required">{' *'}</span>}</label>
      {(toolTipMSG) ? <InfoTooltip msg={toolTipMSG}/> : ""}
      <input id={id === "" ? name : id} name={name}
             data-maxFracDigits={dataMaxFracDigits} value={Number(value) === 0 ? '' : Number(value) === 0 ? '' : value} type={type}
             onChange={onInputChange} className={`form-control ${align==='right' && `align-input-amount-right`}`}
             readOnly={readOnly} disabled={disabled} placeholder={placeholder}
             required={required}/>
      <span className="subLabel">{subLabel}</span>
      <div className="invalid-feedback d-block">
            {state.errors}
          </div>
    </Col>
  )
}
