import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col, Container, FormCheckbox, 
  Row
} from "shards-react";
import PropTypes from "prop-types";
import { setDateForTable } from "../../utils/date";

import { Link, useLocation, useHistory } from 'react-router-dom';
import queryString from "querystring";
import Table from './Table';
import { formatNumberAmount, getFrequencyLabel } from '../../utils/general';
const BenefitsInfo = ({
  formData,
  userProfile,
  formDataRef,
  sectionformDataRef,
  clientMarkups
}) => {

  const [viewHistory, setViewHistory] = useState(false);
  const [isDisabledBenifits, disableBenifits] = useState(false);
  const location = useLocation();
  const history = useHistory();
  let queryParams = queryString.parse(location.search.replace("?", ""))

  const tableColumns = [
    {
      Header: <span className="text-wrap">Benefits Coverage</span>,
      accessor: "benefitsCoverage",
      maxWidth: 95,
      minWidth: 95,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",

    },
    {
      Header: <span className="text-wrap">Benefits Class</span>,
      accessor: "benefitsClass",
      maxWidth: 80,
      minWidth: 80,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
    },
    {
      Header: <span className="text-wrap">Benefits Start Date</span>,
      accessor: "benefitsStartDate",
      minWidth: 85,
      minWidth: 85,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => {

        return setDateForTable(row.benefitsStartDate)
      }
    },
    {
      Header: <span className="text-wrap">Benefits End Date</span>,
      accessor: "benefitsEndDate",
      maxWidth: 85,
      minWidth: 85,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => {

        return setDateForTable(row.benefitsEndDate)
      }
    },
    {
      Header: <span className="text-wrap">Benefits Deduction Frequency</span>,
      accessor: "biBenefitsDeductionFrequency",
      maxWidth: 135,
      minWidth: 135,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: row=> getFrequencyLabel(row.biBenefitsDeductionFrequency)

    },

    {
      Header: <span className="text-wrap">Benefits Currency</span>,
      accessor: "biBenefitsCurrency",
      maxWidth: 70,
      minWidth: 70,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",

    },

    {
      Header: <span className="text-wrap">Employer %</span>,
      accessor: "biEmployer",
      maxWidth: 88,
      minWidth: 88,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell:row=> formatNumberAmount(row.biEmployer, 'en-US', 2,2)

    },

    {
      Header: <span className="text-wrap">Employer Contribution</span>,
      accessor: "clientContribution",
      maxWidth: 95,
      minWidth: 95,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell:row=> formatNumberAmount(row.clientContribution, 'en-US', 2,2)
    },
    {
      Header: <span className="text-wrap">Worker %</span>,
      accessor: "biWorker",
      maxWidth:75,
      minWidth: 75,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell:row=> formatNumberAmount(row.biWorker, 'en-US', 2,2)
    },

    {
      Header: <span className="text-wrap">Worker Contribution</span>,
      accessor: "contribution",
      maxWidth: 95,
      minWidth: 95,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell:row=> formatNumberAmount(row.contribution, 'en-US', 2,2)

    },
    {
      Header: <span className="text-wrap">Ref#</span>,
      accessor: "benefitsId",
      maxWidth:50,
      minWidth: 50,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => (<Link to={{ pathname: `/benifits-form`, search: `?role=${queryParams.role}`, state: { userProfile, role: queryParams.role, row, formData, formDataRef, sectionformDataRef, clientMarkups } }}>{row.benefitsId == 0 ? 'New' : row.benefitsId}</Link>)
    },


  ];
  const togleHistory = () => {
    setViewHistory(!viewHistory);

  }

  useEffect(()=>{

    if(formData.benefitsApplicable === 'Yes'){

      disableBenifits(true);
    }

  },[formData]) 
  return (
    <Card id="benefits" small

      className="mb-5 w-100 d-flex personalParent">
      <span className="iconBox"><i className="fas fa-money-bill-alt iconStyle" /></span>
      <h4 className="m-0 section-title headerStyle d-inline pr-0"><spen className="d-inline">Benefits Information</spen> <spen className="d-inline" ><FormCheckbox id="history" name="history"
        className="d-inline"
        checked={isDisabledBenifits} onChange={() =>{
          formData.benefitsApplicable = formData.benefitsApplicable == 'Yes' ? 'No' :'Yes';
           disableBenifits(!isDisabledBenifits)
          }}
        toggle small></FormCheckbox></spen> </h4>

      <Container fluid className="file-manager__filters border-bottom">
        <Row className="mb-4">

          <Col lg="10"
            className="d-flex flex-row-reverse">

            <div className='d-flex flex-column ml-3' style={{ marginRight: '132px', marginTop: '17px' }}>

              <spen style={{ marginLeft: '55px' }}>
                <FormCheckbox id="history" name="history"
                  checked={viewHistory} onChange={togleHistory}
                  toggle small><spen className="font-weight-bold text-center">View Benefits History</spen></FormCheckbox>
              </spen>
              <spen className="font-weight-bold text-center">


              </spen>
              <spen className="font-weight-bold font-italic">Note - Highlighted in blue row is the active Benefits Information.</spen>
            </div>
            <div></div>

          </Col>

          <Col lg="2"
            className=" pt-0 pl-0 pb-0 pr-2 file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6" >

            <Button disabled={!isDisabledBenifits} className="my-2 py-2 pr-0 pl-0 mx-2 w-100" size='sm'
              theme="accent"
              onClick={() => {

                history.push({
                  pathname: `/benifits-form`,
                  search: `?role=${queryParams.role}`,
                  state: {
                    userProfile,
                    refNum: 0,
                    role: queryParams.role,
                    formData,
                    formDataRef, 
                    sectionformDataRef,
                    clientMarkups
                    
                  },
                });
              }}
            ><spen>Add Benefits</spen></Button>
          </Col>

        </Row>

        <div style={{ overflow: 'scroll' }}>
          {

            (formData && formData.benefitsInfos) ? <Table headers={tableColumns} rows={formData.benefitsInfos} viewHistory={viewHistory} />
              : <Table headers={tableColumns} rows={[]} viewHistory={viewHistory} />
          }
        </div>
      </Container>




    </Card>
  );
};

BenefitsInfo.propTypes = {
  benefitsInfo: PropTypes.object.isRequired
}

export default BenefitsInfo;
