import React, {useEffect, useState} from 'react';
import {
  Card,
  CardHeader,
  Col,
  Form, FormInput, FormSelect,
  ListGroup,
  ListGroupItem,
  Row,
  DatePicker
} from "shards-react";
import PropTypes from "prop-types";
import {setAPIDate, setDateForTable} from "../../utils/date";
import CustomCard from "../common/CustomCard";

const PersonalInfo = (
  {pronounList,
    maritalStatusList,
    firstName,
    middleName,
    lastName,
    pronoun,
    age,
    nickName,
    dateOfBirth,
    maritalStatus,
   
    onDateChange,
    onChange,
    isRequiredFlag,
    queryParamsRole,
    workerFlag
  }) => {


  var pronounListOption = pronounList.map((pronoun) => {
    return <option key={pronoun} value={pronoun}>{pronoun}</option>;
  });

  var maritalStatusListOptions = maritalStatusList.map((status) => {
    return <option key={status} value={status}>{status}</option>;
  });

  return (
    <CustomCard id="personal" title="Personal Information" noform className={"userprofile mb-5 w-100 d-flex"}
    icon={true} iconClass="fa-solid fa-address-card iconStyle">
      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="pronoun">Pronoun</label>
        <FormSelect value={pronoun} name='pronoun'
                    onChange={onChange} className='d-block'>
          <option value=''>Select Pronoun</option>
          {pronounListOption}
        </FormSelect>
      </Col>

     

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="firstName">Legal First Name</label>
        <span aria-hidden="true" className="required"> *</span>
        <FormInput
          id="firstName"
          placeholder="Enter First Name"
          required = {true}
          value={firstName}
          name='firstName'
          onChange={onChange}
        />
      </Col>

      
      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="lastName">Legal Last Name</label>
        <span aria-hidden="true" className="required"> *</span>
        <FormInput
          id="lastName"
          required = {true}
          placeholder="Enter Last Name"
          value={lastName}
          name='lastName'
          onChange={onChange}
        />
      </Col>
      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="middleName">Legal Middle Name</label>
     
        <FormInput
          id="middleName"
          placeholder="Enter Middle Name"
          
          value={middleName}
          name='middleName'
          onChange={onChange}
        />
      </Col>

      { workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="nickname">Nickname</label>
        <FormInput
          id="nickname"
          placeholder="Enter Nickname"
          value={nickName}
          name='nickName'
          onChange={onChange}
        />
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="dob">Date of Birth</label>
        <DatePicker
          id='dob'
          name='dateOfBirth'
          autoComplete='off'
          dateFormat='yyyy-MM-dd'
          placeholderText="YYYY-MM-DD"
          value={dateOfBirth}
          selected={dateOfBirth}
          onChange={(date) =>
            onDateChange("dateOfBirth", date)
          }
        />
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="age">Age</label>
        <FormInput
          id="age"
          placeholder="Enter Age"
          value={age}
          name='age'
          onChange={onChange}
          disabled
        />
      </Col>}

      { workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="maritalStatus">Marital Status</label>
        <FormSelect value={maritalStatus} id="maritalStatus"
                    name='maritalStatus' onChange={onChange}
                    className='d-block'>
         <option value=''>Select Marital Status</option> 
          {maritalStatusListOptions}
        </FormSelect>
      </Col>}
    </CustomCard>
  );
};

PersonalInfo.propTypes = {
  personalInfo: PropTypes.object.isRequired,
  pronounList: PropTypes.array.isRequired,
}

export default PersonalInfo;
