import React, { useEffect } from 'react';
import { connect } from "react-redux";
import {
 
  Col,
  Button,
  Container,
  Row,
  ListGroup,
  ListGroupItem,
  Card
 
} from "shards-react";

import {useHistory} from "react-router-dom";
import { Link } from "react-router-dom";



function Announcements({ }) {
    const history = useHistory();
    function goBack(e) {
        e.preventDefault();
       
          history.goBack();
      }
    

  

  return (
    <>
    
 
    <Container fluid className="main-content-container px-4 py-3">

    <Card small
                className="mb-3 w-100 details-card mt-3  d-flex personalParent">
          <span className="iconBox">
          <i class="fas fa-bullhorn iconStyle"></i>
          
           </span>
            <h4 className="m-0 section-title headerStyle">Announcements</h4>
            
            <ListGroup flush>
              <ListGroupItem className="border-card p-3 mt-3">
                <Row className="pl-4">
                <Col>
                  <u><h5><Link className="font-italic font-weight-bold">Revised Canadian Hiring Process</Link></h5></u>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
          <Card className="mb-0">
        <Row className="align-items-end">
        <div className="px-4 announcementdiv">
        <h6 className='font-weight-bold'>An Update to Our Canadian Hiring Process</h6>
        <p>As part of our ongoing compliance efforts, we conduct regular reviews of our agreements, employment documents, and federal and provincial employment law. After completing this year’s review, we are introducing the following changes to our processes,</p>
        <h6 className='font-weight-bold'>A New Letter of Intent</h6>
       <p>Hiring a new Agency Employee can be an exciting process, but mistakes or lost opportunities can create legal and financial consequences. To ensure the smoothest possible process, we’re introducing a new Letter of Intent which replaces our previous Offer Letter template. The new Letter of Intent can be used to make a formal declaration of interest to a candidate, without inadvertently creating any binding commitments. </p>
       <p>Please refrain from making an offer of employment, verbally or in writing. Instead, your candidates will receive a complete offer in the form of our Agency Employee Agreement.</p>
       <p>The new process is as follows,</p>
       <ul>
        <ol>1. Complete a new Order Form and Letter of Intent (LOI) and submit it to us for approval prior to sharing the Letter with your candidate. </ol>
        <ol>2. We will review both documents and return the LOI to you.</ol>
        <ol>3. Share the LOI with your candidate and explain that a formal Agreement will follow. Please return the LOI, signed by your candidate, to us. </ol>
        <ol>4. We will then send the Agency Employee Agreement to your candidate.</ol>
       </ul>
        <p>Your candidate must have at least five business days prior to their start date to consider the Agency Employee Agreement. The Agreement must, without exception, be signed a minimum of one day prior to their start date. If it is not signed, we will pull the Agreement so that a new start date can be settled on. </p>
        <h6 className='font-weight-bold'>An Updated Agreement for Pay Raises</h6>
        <p>To stay compliant with ongoing Employment Standards changes across the country, we will sign a most updated Employee Agreement with your worker, whenever you offer a pay raise or promotion. This agreement replaces the Schedule A pay raise process and helps to ensure that your employee is always signed under the latest and most updated Agency Employment Agreement. </p>
        <p>We have created a new Letter of Intent for all pay raises and bonuses. Please return it to us for review before presenting it to the Agency Employee.</p>
        <p>The Agency Employee must have at least five business days prior to the effective date to consider the updated Agency Employee Agreement. The Agreement must, without exception, be signed a minimum of one day prior to the effective date. Backdating the effective date will not be considered to ensure compliance. </p>
        <h6 className='font-weight-bold'>French-Only Contracts for Quebec-Based Workers</h6>
        <p>On June 1, 2022, the province of Quebec updated their Labour Code promoting the use of French in the workplace. Bill 96, An Act respecting French, the official and common language of Québec, requires that all employment contracts and documents be provided in French, including application forms and onboarding and severance documents, and introduces a host of other new employer responsibilities. Due to this new requirement, the fee for Quebec Agency Employees will increase necessitated by each contract and communication piece being translated by our Quebec employment attorney. For more information, please contact your Account Manager. </p>
        <p>We are reviewing our current process and will communicate once finalized.</p>
        </div>
        </Row>
        <Row className="px-3 pr-4">
          <Col sm="12" md="12" lg="12"
               className="mb-1 form-group m-0 d-flex align-items-end justify-content-center justify-content-md-end">
            <Button className="mx-1 py-2 mx-md-2" size="sm"
                    theme="accent" onClick={goBack}>Back</Button>
          </Col>
        </Row>
        </Card>
      </Container>
      
    </>
  );
}

const mapStateToProps = (state) => ({
 
});



export default connect(mapStateToProps, {
 
})(Announcements);
