import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col, Container,
  Form,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {setDateForTable} from "../../utils/date";
import {numberToMonetary} from "../../utils/general";
import {connect} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import {
  client_approve_mileage_item, client_clear_mileage_item,
  client_get_mileage_item, client_reject_mileage_item
} from "../../redux/actions/client/clientExpenses";
import {
    approve_sub_mileage_item, clear_sub_mileage_item, get_sub_mileage_item,
    reject_sub_mileage_item,
    save_sub_mileage_item
} from "../../redux/actions/subscriber/subExpenses";
import InfoTooltip from "../../components/common/InfoTooltip";

function ApproveMileageItem(props) {

  let {refNum, sheetRefNumber} = useParams();
  let location =useLocation();
  let approvePermissions=true;
  if(location.pathname.startsWith('/client-view-mileage-item')){
    var {
      clientMileageItem:mileageItem,
      client_get_mileage_item:getMileageItem,
      client_clear_mileage_item:clearMileageItem,
      client_approve_mileage_item:approveMileageItem,
      client_reject_mileage_item:rejectMileageItem,
      subReportStatus:reportStatus
    } = props;
    approvePermissions = props.user.userRoles.find(role => role.role === "Client User" || role.role === "Client HR" || role.role === "Client Billing" || role.role === "Client Admin").permissions.includes("Approve Expense")
  }else{
    var {
      subMileageItem:mileageItem,
      get_sub_mileage_item:getMileageItem,
      clear_sub_mileage_item:clearMileageItem,
      approve_sub_mileage_item:approveMileageItem,
      reject_sub_mileage_item:rejectMileageItem,
      clientReportStatus:reportStatus
    }
      = props;
  }


  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(mileageItem).length > 0 && mileageItem.workerName.trim()) {
      props.changePageMeta(`${props.title} - ${mileageItem.workerName}`)
    }
  }, [mileageItem]);

  useEffect(() => {
    if (
      refNum !== null &&
      refNum !== '' &&
      refNum !== '0' &&
      refNum !== 0
    ) {
      getMileageItem(refNum);
    }

    return () => {
      clearMileageItem();
    }
  }, [refNum]);

  const history = useHistory();
  const [formData, setFormData] = useState({
    tripDate: '',
    fromLocation: '',
    toLocation: '',
    tripDescription: '',
    distanceDriven: null,
    distanceUnit:'',
    appliedRate: null,
    amount: null,
    status: '',
    notes: '',
  });
  useEffect(() => {
    if (Object.keys(mileageItem).length !== 0) {
      setFormData({
        ...formData,
        tripDate: mileageItem.tripDate,
        fromLocation: mileageItem.fromLocation,
        toLocation: mileageItem.toLocation,
        tripDescription: mileageItem.tripDescription,
        distanceDriven: mileageItem.distanceDriven,
        appliedRate: mileageItem.appliedRate,
        amount: mileageItem.amount,
        status: mileageItem.status,
        notes: mileageItem.notes,
        distanceUnit:mileageItem.distanceUnit,


      });
    }
  }, [mileageItem]);
  const onSubmit = (e) => {
    e.preventDefault();
    save_sub_mileage_item(
      sheetRefNumber,
      refNum,
      formData.tripDate,
      formData.fromLocation,
      formData.toLocation,
      formData.tripDescription,
      formData.distanceDriven,
      formData.appliedRate,
      formData.amount,
      formData.status,
      formData.notes
    );
    history.push(`/sub-approve-expense-report/${sheetRefNumber}`);
  };

  return (
    <Container fluid className="main-content-container p-4">
      <Card small className="details-card mb-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {refNum}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Expense Date</label>
                      <span
                        className='text-light form-control'>{setDateForTable(mileageItem.tripDate)}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Trip Description</label>
                      <span
                        className='text-light form-control'>{mileageItem.tripDescription}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>From</label>
                      <span
                        className='text-light form-control'>{mileageItem.fromLocation}</span>
                    </Col>
                    <Col lg="3" className="form-group p-2 m-0">
                      <label>To</label>
                      <span
                        className='text-light form-control'>{mileageItem.toLocation}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Distance Travelled</label>
                      <span
                        className='text-light form-control'>{(mileageItem.distanceDriven) ? numberToMonetary(mileageItem.distanceDriven,2) : ""}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Distance Unit</label>
                      <InfoTooltip
                      msg="Default Base Distance Unit Configured" />
                      <span
                        className='text-light form-control'>{mileageItem.distanceUnit}</span>
                    </Col>
                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Applied Rate</label>
                      <span
                        className='text-light form-control'>{(mileageItem.appliedRate) ? mileageItem.appliedRate.toFixed(2) : ""}</span>
                    </Col>
                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Amount</label>
                      <span
                        className='text-light form-control'>{numberToMonetary(mileageItem.amount)}</span>
                    </Col>

                  </Row>
                  <Row className="px-3">
                    <Col sm="12" md="12" lg="12"
                         className=" form-group p-2 m-0 d-flex align-items-center justify-content-center justify-content-md-end">


                      {(approvePermissions && mileageItem.status && mileageItem.status!=="Reviewed") && <InfoTooltip msg=" A notification will be sent to all the users of this expense report"/>}

                      {(approvePermissions && mileageItem.status && mileageItem.status!=="Approved" && mileageItem.status!=="Reviewed" && reportStatus!=="Reviewed") && <Button className="mx-1 py-2 mx-md-2" size="sm"
                                     onClick={() => {
                                       approveMileageItem(refNum);
                                       history.goBack();
                                     }}
                                     title="Approve this Mileage."
                                     theme="accent">Approve</Button>}

                      {(approvePermissions && mileageItem.status && mileageItem.status!=="Rejected" && mileageItem.status!=="Reviewed" && reportStatus!=="Reviewed") && <Button className="mx-1 py-2 mx-md-2" size="sm"
                        onClick={() => {
                        rejectMileageItem(refNum);
                        history.goBack();
                      }}
                      title="Reject this Mileage."
                        theme="accent">Reject</Button>}

                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              onClick={e => history.goBack()}
                              theme="accent">Back</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}

ApproveMileageItem.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  subMileageItem: PropTypes.object.isRequired,
  get_sub_mileage_item: PropTypes.func.isRequired,
  approve_sub_mileage_item: PropTypes.func.isRequired,
  reject_sub_mileage_item: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  subMileageItem: state.subExpenses.subMileageItem,
  clientMileageItem: state.clientExpenses.clientMileageItem,
  subReportStatus:state.subExpenses.expenseSheetForSub.status,
  clientReportStatus:state.clientExpenses.expenseSheetForClient.status
});
export default connect(mapStateToProps, {
  get_sub_mileage_item,
  approve_sub_mileage_item,
  reject_sub_mileage_item,
  clear_sub_mileage_item,

  client_get_mileage_item,
  client_approve_mileage_item,
  client_reject_mileage_item,
  client_clear_mileage_item
})(ApproveMileageItem);
