import React, {useEffect, useState} from 'react';
import {
  approve_leave,
  clear_sub_leave_details,
  get_sub_leaves_types,
  get_subs_leave_details, reject_leave,
  sub_save_leave_details,
  sub_withdraw_leave
} from "../../redux/actions/user";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {setAPIDate, setDateForTable, setInputDate} from "../../utils/date";
import {
  Alert,
  Button,
  Card, Col,
  Container, DatePicker, Form, FormInput, FormSelect, FormTextarea,
  ListGroup,
  ListGroupItem, Row
} from "shards-react";

function SubscriberApproveLeave({


                                  get_sub_leaves_types,
                                  subLeaveDetails,
                                  get_subs_leave_details,
                                  clear_sub_leave_details,
                                  sub_withdraw_leave,
                                  approve_leave,
                                  reject_leave,
                                  sub_save_leave_details,
                                  changePageMeta
                                }) {

  let {refNumber} = useParams();
  const history = useHistory();

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(subLeaveDetails).length > 0 && subLeaveDetails.workerName.trim()) {
      changePageMeta(`Leave - ${subLeaveDetails.workerName}`)
    }
  }, [subLeaveDetails]);

  useEffect(() => {
    if (refNumber && refNumber !== '0') {
      get_subs_leave_details(refNumber);
    }
  }, [refNumber]);

  useEffect(() => {
    get_sub_leaves_types();

    return () => {
      clear_sub_leave_details();
    }
  }, []);

  const [formData, setFormData] = useState({
    userId: '',
    requestedDate: new Date().toISOString().slice(0, 10),
    leavePolicy: '',
    from: '',
    to: '',
    leaveDays: '',
    leaveHours: '',
    status: '',
    workerNotes: "",
    subscriberNote: "",
    deleted:false,
    leavePolicyEndDate:"",
    entitled:true
  });

  useEffect(() => {
    if (Object.keys(subLeaveDetails).length !== 0) {
      setFormData({
        ...formData,
        userId: subLeaveDetails.userId,
        allowFutureLeavesFlag:subLeaveDetails.allowFutureLeavesFlag,
        curBalance:subLeaveDetails.curBalance,
        requestedDate: setDateForTable(subLeaveDetails.requestedDate),
        leavePolicy: subLeaveDetails.leavePolicy,
        leavePolicyId: subLeaveDetails.leavePolicyId,
        from: setAPIDate(subLeaveDetails.fromDate),
        to: setAPIDate(subLeaveDetails.toDate),
        leavePolicyEndDate:setAPIDate(subLeaveDetails.leavePolicyEndDate),
        leaveDays: subLeaveDetails.leaveDays,
        leaveHours: subLeaveDetails.leaveHours,
        status: subLeaveDetails.status,
        workerNotes: subLeaveDetails.notes,
        subscriberNote: subLeaveDetails.subscriberNotes,
        deleted: subLeaveDetails.deleted,
        entitled: subLeaveDetails.entitled
      });
    }
  }, [subLeaveDetails]);

  const location = useLocation();
  useEffect(() => {
    if (typeof location.state !== 'undefined') {
      // Window.location.reload(true);
      setFormData({
        ...formData,
        userId: location.state.userId,
      });
    }
  }, [location.state]);


  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const {
    leavePolicy,
  } = formData;

  const onSubmit = (e) => {
    e.preventDefault();
    sub_save_leave_details(
      formData.userId,
      refNumber,
      formData.leavePolicyId,
      setInputDate(formData.from),
      setInputDate(formData.to),
      formData.leaveHours,
      formData.workerNotes,
      formData.subscriberNote,
    );
    history.goBack();
  };

  let widthDrawClickHandler = () => {
    sub_withdraw_leave(refNumber);
    history.goBack();
  };
  let approveClickHandler = () => {
    approve_leave(refNumber,formData.subscriberNote);
    history.goBack();
  };
  let rejectClickHandler = () => {
    reject_leave(refNumber,formData.subscriberNote);
    history.goBack();
  };
  return (
    <React.Fragment>
      {(!formData.allowFutureLeavesFlag && parseFloat(formData.leaveDays)>parseFloat(formData.curBalance)) && formData.entitled?<Alert theme="primary" className="text-white">This user do not have sufficient balance.</Alert>:''}
      {(formData.deleted)?<Alert theme="primary" className="text-white">{`This leave policy is deleted.`}</Alert>:''}
      {(!formData.deleted && (formData.leavePolicyEndDate && formData.leavePolicyEndDate<=formData.to))?<Alert theme="primary" className="text-white">{`This leave is availed later than end date of the leave policy. This leave policy will be deleted on  ${setDateForTable(setInputDate(formData.leavePolicyEndDate))}.`}</Alert>:''}
      <Container fluid className="main-content-container p-4">
        <Card small className="mb-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {refNumber}</h4>
          <ListGroup flush>
            <ListGroupItem className="border-card p-3">
              <Row>
                <Col>
                  <Form onSubmit={onSubmit}>
                    <Row form>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="requestedDate">Requested Date</label>
                        <FormInput
                          id="requestedDate"
                          placeholder="Requested Date"
                          name='requestedDate'
                          disabled
                          value={formData.requestedDate}
                          onChange={onChange}
                        />
                      </Col>

                      {/* <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="refNumber">Reference Number</label>
                        <FormInput
                          id="refNumber"
                          placeholder="Reference Number"
                          name='refNumber'
                          disabled
                          value={refNumber}
                          onChange={onChange}
                        />
                      </Col> */}

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="leavePolicy">Leave Type</label>
                        <FormInput
                          id="leavePolicy"
                          placeholder="Leave Type"
                          name='leavePolicy'
                          disabled
                          value={leavePolicy}
                          onChange={onChange}
                        />
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        {refNumber != 0 && <React.Fragment>
                          <label htmlFor="status">Status</label>
                          <FormInput
                            id="status"
                            name='status'
                            disabled
                            value={formData.status}
                          />
                        </React.Fragment>}
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="from">From</label>
                        <div>
                          <DatePicker
                            id='from'
                            autoComplete='off'
                            name='from'
                            dateFormat='yyyy-MM-dd'
                            selected={formData.from}
                            onChange={(fromDate) =>
                              setFormData({...formData, from: fromDate})
                            }
                            disabled
                            value={formData.from}
                            required
                          />
                        </div>
                      </Col>


                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="to">To</label>
                        <div>
                          <DatePicker
                            id='to'
                            autoComplete='off'
                            name='to'
                            required
                            dateFormat='yyyy-MM-dd'
                            selected={formData.to}
                            onChange={(toDate) =>
                              setFormData({...formData, to: toDate})
                            }
                            disabled
                            value={formData.to}
                          />
                        </div>
                      </Col>


                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="leaveDays">Leave Days</label>
                        <FormInput
                          id="leaveDays"
                          name='leaveDays'
                          value={formData.leaveDays}
                          disabled
                          onChange={onChange}
                        />
                      </Col>


                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="leaveHours">Leave Hours</label>
                        <FormInput
                          id="leaveHours"
                          name='leaveHours'
                          value={formData.leaveHours}
                          disabled
                          onChange={onChange}
                        />
                      </Col>

                      <Col sm="12" md="12" className="form-group p-2 m-0">
                        <label htmlFor="workerNotes">Worker Notes</label>
                        <FormTextarea
                          id="workerNotes"
                          rows="10"
                          name="workerNotes"
                          value={formData.workerNotes}
                          disabled
                          onChange={onChange}
                        />
                      </Col>

                      <Col sm="12" md="12" className="form-group p-2 m-0">
                        <label htmlFor="subscriberNote">Subscriber
                          Notes</label>
                        <FormTextarea
                          id="subscriberNote"
                          rows="10"
                          name="subscriberNote"
                          value={formData.subscriberNote}
                          onChange={onChange}
                        />
                      </Col>

                    </Row>
                    <Row className="px-3">
                      <Col sm="12" md="12" lg="12"
                           className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                        {(refNumber == 0 || (formData.status && formData.status !== "Withdrawn")) &&
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="accent">Save</Button>}

                        {refNumber != 0 && formData.status && formData.status !== "Withdrawn" && formData.status !== "Approved" &&
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="primary"
                                disabled={formData.deleted ||  (formData.leavePolicyEndDate<=formData.to)}
                                onClick={approveClickHandler}>Approve</Button>}

                        {refNumber != 0 && formData.status && formData.status !== "Withdrawn" && formData.status !== "Rejected" &&
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="primary"
                                onClick={rejectClickHandler}>Reject</Button>}

                        {refNumber != 0 && formData.status && formData.status !== "Withdrawn" &&
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="primary"
                                onClick={widthDrawClickHandler}>Withdraw</Button>}

                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="accent"
                                onClick={e => history.goBack()}>Cancel</Button>

                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    </React.Fragment>
  );
}

SubscriberApproveLeave.propTypes = {
  get_sub_leaves_types: PropTypes.func.isRequired,
  get_subs_leave_details: PropTypes.func.isRequired,
  subLeaveDetails: PropTypes.object.isRequired,
  sub_withdraw_leave: PropTypes.func.isRequired,
  sub_save_leave_details: PropTypes.func.isRequired,
  approve_leave: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  subLeaveDetails: state.user.subLeaveDetails,
});
export default connect(mapStateToProps, {
  get_sub_leaves_types,
  get_subs_leave_details,
  sub_withdraw_leave,
  sub_save_leave_details,
  clear_sub_leave_details,
  approve_leave,
  reject_leave,
})(SubscriberApproveLeave);

