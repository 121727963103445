import React, { useEffect, useState } from 'react';
import {
  Card,
  Col, Container, FormCheckbox,
  Row
} from "shards-react";
import {setDateForTable} from "../../../utils/date";
import Table from '../../manage-user-profile/Table';
import { formatNumberAmount, getFrequencyLabel } from '../../../utils/general';

function CompensationInfo(props) {

  const [viewHistory, setViewHistory] = useState(false);
  const [tableData, setTableData] = useState([])

  const togleHistory = () => {
    setViewHistory(!viewHistory);

  }

  const tableColumns = [
    {
      Header: <span className="text-wrap">Pay Type</span>,
      accessor: "payType",
      maxWidth: 100,
      minWidth: 100,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName:"text-center"

    },
    {
      Header: <span className="text-wrap">Effective Date</span>,
      accessor: "effectiveDate",
      maxWidth: 90,
      minWidth: 100,
      className: "justify-content-center text-center text-wrap text-break",
      cell: (row) => setDateForTable(row.effectiveDay),
      bodyClassName:"text-center"

    },
    // {
    //   Header: <span className="text-wrap">Pay Component</span>,
    //   accessor: "payComponentId",
    //   maxWidth: 160,
    //   minWidth: 160,
    //   className: "justify-content-center text-center text-wrap text-break",
    //   cell: (row) => {
        
    //   let payComponent = props.payComponentList !== undefined && props.payComponentList !== null ?  props.payComponentList.filter(payComponent => payComponent.refNum === Number(row.payComponentId)) : [];
      
    //  return payComponent.length > 0 ? payComponent[0].name : '';
       
    // }
    // },
    {
      Header: <span className="text-wrap">Salary or Rate</span>,
      accessor: "salaryOrRate",
      maxWidth: 104,
      minWidth: 104,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-right",
      cell : row =>  formatNumberAmount(row.salaryOrRate, "en-US", row.payType === 'Hourly' ? 4 : 2)
    },

    {
      Header: <span className="text-wrap">Compensation Currency</span>,
      accessor: "compensationCurrency",
      maxWidth: 174,
      minWidth: 174,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName:"text-center"

    },

    {
      Header: <span className="text-wrap">Pay Period Frequency</span>,
      accessor: "payPeriodFrequency",
      minWidth: 158,
      minWidth: 158,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName:"text-center",
      cell: row => getFrequencyLabel(row.payPeriodFrequency)
    },

    {
      Header: <span className="text-wrap">Notes</span>,
      accessor: "notes",
      maxWidth: 150,
      minWidth:150,
      className: "justify-content-center text-center text-wrap text-break",
      Cell: row => parseFloat(row.original.exchangeRate).toFixed(5)
    }

  ];

  useEffect(()=>{
    const groupedData = props.compensationInfos && props.compensationInfos.reduce((groups, item) => {
      const { payComponentId } = item;
    
      if (!groups[payComponentId]) {
        groups[payComponentId] = [];
      }
      groups[payComponentId].push(item);
      return groups;
    }, {});
    setTableData(groupedData);
  },[props.compensationInfos])
  //const {viewProfile} = props
  return (
    <Card id='compensation' small

      className="mb-5 w-100 d-flex personalParent">
      <span className="iconBox"><i className="fas fa-briefcase iconStyle" /></span>
      <h4 className="m-0 section-title headerStyle">Compensation Information</h4>
      
      <Container fluid className="file-manager__filters border-bottom">
        <Row className="mb-4">

          <Col lg="11"
            className="d-flex flex-row-reverse">

            <div className='d-flex flex-column ml-3 section-card-header'>
              <spen className="header-checkbox">
                <FormCheckbox id="history" name="history"
                  checked={viewHistory} onChange={togleHistory}
                  toggle small><spen className="font-weight-bold text-center">View Compensation History</spen></FormCheckbox>
              </spen>
              <spen className="font-weight-bold text-center">


              </spen>
              <spen className="font-weight-bold">Highlighted in the blue row is the active Compensation information.</spen>
            </div>
            <div></div>

          </Col>
          
          <Col lg="1"
            className=" pt-0 pl-0 pb-0 pr-2 file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6" >



          </Col>

        </Row>
       
       

        <div className='scrollabe-table-container'>
          {

            (tableData) ? Object.keys(tableData).map(key => {
              return (<Table headers={tableColumns} rows={tableData[key]} viewHistory={viewHistory} />)
            }) :
              (<Table headers={tableColumns} rows={[]} viewHistory={viewHistory} />)
          }
        </div>
      </Container>




    </Card>
  );
}


export default CompensationInfo;
