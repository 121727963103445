import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col, Container, FormCheckbox, 
  Row
} from "shards-react";
import PropTypes from "prop-types";
import { setDateForTable } from "../../utils/date";

import { Link, useLocation, useHistory } from 'react-router-dom';
import queryString from "querystring";
import Table from './Table';
import { formatNumberAmount } from '../../utils/general';
const RetainerInfo = ({
  formData,
  userProfile,
  formDataRef, 
  sectionformDataRef
}) => {

  const [viewHistory, setViewHistory] = useState(false);
  const [isDisabledRetainer, disableRetainer] = useState(false);
  const location = useLocation();
  const history = useHistory();
  let queryParams = queryString.parse(location.search.replace("?", ""))

  const tableColumns = [
    {
      Header: <span className="text-wrap">Effective Date</span>,
      accessor: "rtEffectiveDate",
      maxWidth: 90,
      minWidth: 90,
      cell :row=> setDateForTable(row.rtEffectiveDate),
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
    },
    // {
    //   Header: <span className="text-wrap">Retainer Reason</span>,
    //   accessor: "rtReason",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   className: "justify-content-center text-center text-wrap text-break",
    //   bodyClassName: "text-center",
    // },
    {
      Header: <span className="text-wrap">Retainer Weeks</span>,
      accessor: "retainerWeeks",
      minWidth: 100,
      minWidth: 100,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell:row=> formatNumberAmount(row.retainerWeeks, "en-US", 2, 2)
    },
    {
      Header: <span className="text-wrap">Hours</span>,
      accessor: "rtHours",
      maxWidth: 50,
      minWidth: 50,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
    },
    {
      Header: <span className="text-wrap">Retainer Currency</span>,
      accessor: "rtCurrency",
      maxWidth: 100,
      minWidth: 100,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
    },

    {
      Header: <span className="text-wrap">Bill Rate</span>,
      accessor: "rtPayRate",
      maxWidth: 70,
      minWidth: 70,
      className: "justify-content-center text-center text-wrap text-break pl-2 pr-2",
      bodyClassName: "text-right",
      cell : row=> row.rtPayRate && formatNumberAmount(row.rtPayRate, "en-US", 2, 2)

    },

    {
      Header: <span className="text-wrap">Current Retainer Amount</span>,
      accessor: "rtCurrentRetainerFee",
      maxWidth: 100,
      minWidth: 100,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-right",
      cell : row=> row.rtCurrentRetainerFee && formatNumberAmount(row.rtCurrentRetainerFee, "en-US", 2, 2)

    },

    {
      Header: <span className="text-wrap">Previous Retainer Amount</span>,
      accessor: "rtPreviousRetainerFee",
      maxWidth: 100,
      minWidth: 100,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-right",
      cell : row=> formatNumberAmount(row.rtPreviousRetainerFee, "en-US", 2, 2)
    },
    {
      Header: <span className="text-wrap">Notes</span>,
      accessor: "notes",
      maxWidth:150,
      minWidth: 150,
      className: "justify-content-center text-center text-wrap text-break",

    },
    {
      Header: <span className="text-wrap">Ref#</span>,
      accessor: "retainerId",
      maxWidth:50,
      minWidth: 50,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => (<Link to={{ pathname: `/retainer-form`, search: `?role=${queryParams.role}`, state: { userProfile, role: queryParams, row, formData, formDataRef, sectionformDataRef } }}>{row.retainerId == 0 ? 'New' : row.retainerId}</Link>)
    },


  ];
  const togleHistory = () => {
    setViewHistory(!viewHistory);

  }

  useEffect(()=>{

    if(formData.retainerApplicable === 'Yes'){

      disableRetainer(true);
    }

  },[formData]) 
  return (
    <Card id="RetainerInfo" small

      className="mb-5 w-100 d-flex personalParent">
      <span className="iconBox"><i className="fa-solid fa-hand-holding-dollar iconStyle" /></span>
      <h4 className="m-0 section-title headerStyle d-inline pr-0"><spen className="d-inline">Retainer Information</spen> <spen className="d-inline" ><FormCheckbox id="history" name="history"
        className="d-inline"
        checked={isDisabledRetainer} onChange={() =>{
          formData.retainerApplicable = formData.retainerApplicable == 'Yes' ? 'No' :'Yes';
           disableRetainer(!isDisabledRetainer)
          }}
        toggle small></FormCheckbox></spen> </h4>

      <Container fluid className="file-manager__filters border-bottom">
        <Row className="mb-4">

          <Col lg="10"
            className="d-flex flex-row-reverse">

            <div className='d-flex flex-column ml-3' style={{ marginRight: '132px', marginTop: '17px' }}>

              <spen style={{ marginLeft: '55px' }}>
                <FormCheckbox id="history" name="history"
                  checked={viewHistory} onChange={togleHistory}
                  toggle small><spen className="font-weight-bold text-center">View Retainer History</spen></FormCheckbox>
              </spen>
              <spen className="font-weight-bold text-center">


              </spen>
              <spen className="font-weight-bold font-italic">Note - Highlighted in blue row is the active Retainer Information.</spen>
            </div>
            <div></div>

          </Col>

          <Col lg="2"
            className=" pt-0 pl-0 pb-0 pr-2 file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6" >

            <Button disabled={!isDisabledRetainer} className="my-2 py-2 pr-0 pl-0 mx-2 w-100" size='sm'
              theme="accent"
              onClick={() => {

                history.push({
                  pathname: `/retainer-form`,
                  search: `?role=${queryParams.role}`,
                  state: {
                    userProfile,
                    refNum: 0,
                    role: queryParams,
                    formData,
                    formDataRef,
                    sectionformDataRef
                    
                  },
                });
              }}
            ><spen>Add Retainer</spen></Button>
          </Col>

        </Row>

        <div style={{ overflow: 'scroll' }}>
          {

            (formData && formData.retainerInfos) ? <Table headers={tableColumns} rows={formData.retainerInfos} viewHistory={viewHistory} />
              : <Table headers={tableColumns} rows={[]} viewHistory={viewHistory} />
          }
        </div>
      </Container>




    </Card>
  );
};


export default RetainerInfo;
