import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    Col, Form, FormSelect, DatePicker,
    ListGroup,
    ListGroupItem,
    Row,
    Container,
    Button,
} from "shards-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputBox from '../../common/InputBox';
import { useLocation } from 'react-router-dom';
import { setAlert } from "../../../redux/actions/alert";
import {
    clear_user_profile,
    get_client_list,
    get_compensation_types,
    get_country_list,
    sub_get_currency_list,
    get_deal_types_types,
    get_region_list,
    get_subscriber_list,
    get_hr_Consultant,
    get_user_profile,
    get_worker_types, reset,
    get_pay_component_list,
    save_user_profile,
    update_isSave_user_profile
} from "../../../redux/actions/user";
import { setAPIDate, setDateForTable, setInputDate } from '../../../utils/date';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { compareObjects, fixedDecimalPlaces, formatNumberAmount, toNumber } from '../../../utils/general';
import StandardFormInput from '../../common/StandardFormInput';
import { STANDARD_FORMATS } from '../../../utils/constants';

function RetainerForm(props) {


    const location = useLocation();

    const [notesCharacters, seNotesCharacters] = useState(100);
    const history = useHistory();
    const [hasError, setHasError] = useState(false);
    const formDataVal = useRef({})
    const [salaryDecimalPlaces, setSalaryDecimalPlaces] = useState(2)
    const [disabledSave, setDisabledSave] = useState(location.state.row !== undefined)

    const currentRetainers = (location.state.formData && location.state.formData.retainerInfos) ? location.state.formData.retainerInfos : [];
    const currentPlacement = (location.state.formData && location.state.formData.placementInfos) ? location.state.formData.placementInfos : undefined;
    const hoursPerWeek = (currentPlacement && currentPlacement.length > 0 && currentPlacement[0].hoursPerWeek) ? Number(currentPlacement[0].hoursPerWeek) : 0;

    const onChange = (e) => {

        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onChangeStandardInput = (name,value) => {
        setFormData({...formData, [name]: value});
      
    };

    const onMonetaryAmountChanged = (e, decimalPlaces) => {

        const value = e.target.value;

        const sanitizedValue = value.replace(/[^0-9.]/g, '');

        const parts = sanitizedValue.split('.');

        if (parts.length > 2) {
            return;
        }

        if (parts.length > 1 && parts[1].length > 2) {
            parts[1] = parts[1].slice(0, decimalPlaces);
        }

        const formattedValue = parts.join('.');

        e.target.value = formattedValue;

        onChange(e);
    };
    const onSave = async (e) => {

        e.preventDefault();

        let retainerInfos = location.state.formData.retainerInfos;
        let hireDate = new Date(location.state.formData.hireDate).setHours(0, 0, 0, 0);
        let selectedDate = new Date(formData.rtEffectiveDate).setHours(0, 0, 0, 0);
 
        if(selectedDate < hireDate){
            props.setAlert("Effective Date must not be less than the Hire Date.", "danger");
                return 0;
        }
        for (const retainerInfo of retainerInfos) {
            let currentDate = new Date(setDateForTable(retainerInfo.rtEffectiveDate)).setHours(0, 0, 0, 0);


            if (retainerInfo.retainerId !== formData.retainerId && selectedDate === currentDate) {
                props.setAlert("Details with the same Effective Date exists.", "danger");
                return 0;
            }
        }

        let updatedFormData = { ...formData };
        if ('rtEffectiveDate' in updatedFormData) updatedFormData.rtEffectiveDate = setInputDate(updatedFormData.rtEffectiveDate);
        if ('rtSalaryOrRate' in updatedFormData) updatedFormData.rtSalaryOrRate = fixedDecimalPlaces(updatedFormData.rtSalaryOrRate, salaryDecimalPlaces);
        if ('rtPayRate' in updatedFormData) updatedFormData.rtPayRate = fixedDecimalPlaces(updatedFormData.rtPayRate, 2);
        if ('retainerWeeks' in updatedFormData) updatedFormData.retainerWeeks = fixedDecimalPlaces(updatedFormData.retainerWeeks, 2);
        
        formDataVal.current = {...updatedFormData}

        let isSaved = false;
        if (location.state.row) {

            let currentRetainerForms = location.state.formData.retainerInfos;
            let updatedRetainers = [];
            if (currentRetainerForms) {
                updatedRetainers = currentRetainerForms.map(retainer => {
                    if (retainer.retainerId === updatedFormData.retainerId) {
                        return {
                            userId: updatedFormData.userId,
                            retainerApplicable: updatedFormData.retainerApplicable,
                            rtPayType: updatedFormData.rtPayType,
                            rtSalaryOrRate: updatedFormData.rtSalaryOrRate,
                            rtCurrency: updatedFormData.rtCurrency,
                            rtPayComponentId: updatedFormData.rtPayComponentId,
                            rtEffectiveDate: updatedFormData.rtEffectiveDate,
                            rtReason: updatedFormData.rtReason,
                            rtHours: updatedFormData.rtHours,
                            rtPayRate: updatedFormData.rtPayRate,
                            rtPreviousRetainerFee: updatedFormData.rtPreviousRetainerFee,
                            rtCurrentRetainerFee: updatedFormData.rtCurrentRetainerFee,
                            retainerWeeks: updatedFormData.retainerWeeks,
                            hoursPerWeek: updatedFormData.hoursPerWeek,
                            notes: updatedFormData.notes,
                            retainerId: updatedFormData.retainerId,
                        };
                    }
                    else {
                        return retainer;
                    }
                })
            }

            location.state.formData = { ...location.state.formData, retainerInfos: [...updatedRetainers], retainerApplicable: 'Yes' }

            isSaved = true;
        }
        else {
            // if(formData.userId && formData.userId !== '0'){
            //     let updatedData = {userId:formData.userId, subscriberId: location.state.formData.subscriberId, retainerInfos: [{...updatedFormData}], 
            //     retainerApplicable: 'Yes', 
            //     benefitsApplicable: location.state.formData.benefitsApplicable, 
            //     pensionApplicable:location.state.formData.pensionApplicable,
            //     EAPApplicable : location.state.formData.EAPApplicable,
            //     HSAApplicable: location.state.formData.HSAApplicable,}
            //     props.save_user_profile(updatedData);
            // }
            // else
            // {
            //     location.state.formData = { ...location.state.formData, retainerInfos: [{ ...updatedFormData }, ...location.state.formData.retainerInfos], retainerApplicable: 'Yes' }
            //     isSaved = true;
            // }
            location.state.formData = { ...location.state.formData, retainerInfos: [{ ...updatedFormData }, ...location.state.formData.retainerInfos], retainerApplicable: 'Yes' }
            isSaved = true;
           
        }



        if (isSaved) {

            if(location.state.formData.retainerInfos.length > 1){
               // location.state.formData.retainerInfos[0].rtPreviousRetainerFee = location.state.formData.retainerInfos[1].rtCurrentRetainerFee;
               calculatePreviousRetainerFee();
            }


            let userId = formData.userId === undefined ? 0 : formData.userId;
            let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
            props.update_isSave_user_profile(false);
            history.push({
                pathname: `/manage-user-profile/${userId}`,
                search: `${roleStr}&section=retainer&subsection=RetainerInfo`,
                state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
            });


        }


    }

    // useEffect(()=>{

    //     if(props.isSaveUserProfile){
    //         formDataVal.current.retainerId = props.userProfile.retainerInfos[0].retainerId;
    //         formDataVal.current.rtPreviousRetainerFee = fixedDecimalPlaces(props.userProfile.retainerInfos[0].previousRetainerFee, 2);

    //         location.state.sectionformDataRef.retainerInfos = [{...formDataVal.current}, ...location.state.sectionformDataRef.retainerInfos]
    //         location.state.formData =  { ...location.state.formData, retainerInfos: [{ ...formDataVal.current}, ...location.state.formData.retainerInfos]}
    //         onCancel()
    //     }
        
    // },[props.isSaveUserProfile])

    const onCancel = ()=>{
        let userId = formData.userId === undefined ? 0 : formData.userId;
            let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
            props.update_isSave_user_profile(false);
            history.push({
                pathname: `/manage-user-profile/${userId}`,
                search: `${roleStr}&section=retainer&subsection=RetainerInfo`,
                state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
            });

    }
    const calculateRetainerFee = (e, decimalPlaces) => {
        let name = e.target.name;
        let val = '';
        if(name !== 'rtReason'){
            val = toNumber(e.target.value,decimalPlaces)
        if(!val){
            setFormData({...formData,[name]:''});
            return;
        } 
        }
        else{
            val = e.target.value;
        }

        
        let retainerReason = name === 'rtReason' ? val : formData.rtReason;
        let retainerWeeks = name === 'retainerWeeks' ? val : formData.retainerWeeks;
        let payRate = name === 'rtPayRate' ? val : formData.rtPayRate;
        let hoursPerWeek = (location.state.formData.hoursPerWeek !== '' && location.state.formData.hoursPerWeek!== '0') ? Number(location.state.formData.hoursPerWeek) : 0;
        let hours = Number(retainerWeeks) * hoursPerWeek;

        let  currentRetainerFee = hours * Number(payRate);
         let previousRetainerFee =  0;

        switch (retainerReason) {
            case 'Anniversary':
                //(formData.userId !== '' || formData.userId !== undefined) && currentRetainers.length > 0 && currentRetainers[0].retainerWeeks < Number(formData.retainerWeeks)
                if ((formData.userId !== '' || formData.userId !== undefined) && currentRetainers.length > 0) {
                    currentRetainerFee = hours * Number(payRate);
                    //previousRetainerFee = hoursPerWeek * Number(currentRetainers[0].retainerWeeks) * Number(currentRetainers[0].rtPayRate);

                }
                break;
            case 'Anniversary/Pay Raise':
                //(formData.userId !== '' || formData.userId !== undefined) && currentRetainers.length > 0 && currentRetainers[0].retainerWeeks < Number(formData.retainerWeeks) && currentRetainers[0].rtEffectiveDate < Number(setInputDate(formData.rtEffectiveDate))
                if ((formData.userId !== '' || formData.userId !== undefined) && currentRetainers.length > 0 ) {
                    currentRetainerFee = hours * Number(payRate);
                   // previousRetainerFee = hoursPerWeek * Number(currentRetainers[0].retainerWeeks) * Number(currentRetainers[0].rtPayRate);

                }
                break;
            case 'Pay Raise':
                if ((formData.userId !== '' || formData.userId !== undefined) && currentRetainers.length > 0 ) {
                    currentRetainerFee = hours * Number(payRate);
                   // previousRetainerFee = hoursPerWeek * Number(currentRetainers[0].retainerWeeks) * Number(currentRetainers[0].rtPayRate);
                }
                break;
            case 'New Hire':
                if (formData.userId === '' || formData.userId === undefined) {
                    currentRetainerFee = hours * Number(payRate);
                    //previousRetainerFee = 0;
                 } 
                //else if (currentRetainers.length > 0 && currentRetainers[0].rtEffectiveDate === Number(setInputDate(formData.rtEffectiveDate))) {
                //     currentRetainerFee = hours * Number(payRate);
                //     previousRetainerFee = 0;

                // }
                break;
            default:
                break;
        }

        setFormData({ ...formData, rtReason: retainerReason, retainerWeeks: retainerWeeks, rtHours: hours, rtCurrentRetainerFee: fixedDecimalPlaces(currentRetainerFee, 2), rtPayRate: payRate})


    }

    const calculatePreviousRetainerFee = () =>{

        let hoursPerWeek = (location.state.formData.hoursPerWeek !== '' && location.state.formData.hoursPerWeek!== '0') ? Number(location.state.formData.hoursPerWeek) : 0;
        let previousRetainerFee = 0;
        if(location.state.formData.retainerInfos.length > 0 ){
                
            
            location.state.formData.retainerInfos.sort(function(r1, r2){return r2.rtEffectiveDate - r1.rtEffectiveDate});
           
            for(let i = 0; i < location.state.formData.retainerInfos.length; i++){
                
               if(location.state.formData.retainerInfos[i].rtReason !== 'New Hire'){
                    if((i+1) < location.state.formData.retainerInfos.length ){
                        
                        location.state.formData.retainerInfos[i].rtPreviousRetainerFee = hoursPerWeek * Number(location.state.formData.retainerInfos[i+1].retainerWeeks) * Number(location.state.formData.retainerInfos[i+1].rtPayRate);
                        if(Number(formData.retainerId) === Number(location.state.formData.retainerInfos[i].retainerId) && setInputDate(formData.rtEffectiveDate) === Number(location.state.formData.retainerInfos[i].rtEffectiveDate)){
                            previousRetainerFee = location.state.formData.retainerInfos[i].rtPreviousRetainerFee;
                        }

                    
                    }
               }
               else{


               }
               
            }
            if(formData.rtReason !== 'New Hire' && previousRetainerFee === 0){
                let filteredRetainers = (location.state.formData.retainerInfos && location.state.formData.retainerInfos.length > 0) ? location.state.formData.retainerInfos.filter(retainer =>{
                  return  Number(retainer.rtEffectiveDate) < setInputDate(formData.rtEffectiveDate)
                }) : [];
                previousRetainerFee = (filteredRetainers && filteredRetainers.length > 0) ? hoursPerWeek * Number(filteredRetainers[0].retainerWeeks) * Number(filteredRetainers[0].rtPayRate) : 0;

            }

            
        }
        setFormData({...formData, rtPreviousRetainerFee: fixedDecimalPlaces(previousRetainerFee, 2)})
    }

   
    const onDateChange = (name, value) => {


        let retainerInfos = location.state.formData.retainerInfos;
        let hireDate = new Date(location.state.formData.hireDate).setHours(0, 0, 0, 0);
        let selectedDate = new Date(value).setHours(0, 0, 0, 0);
 
        if(selectedDate < hireDate){
            props.setAlert("Effective Date must not be less than the Hire Date.", "danger");
                return 0;
        }
        for (const retainerInfo of retainerInfos) {
            let currentDate = new Date(setDateForTable(retainerInfo.rtEffectiveDate)).setHours(0, 0, 0, 0);


            if (retainerInfo.retainerId !== formData.retainerId && selectedDate === currentDate) {
                props.setAlert("Details with the same Effective Date exists.", "danger");
                return 0;
            }
        }

        setHasError(!hasError)
        setFormData({ ...formData, [name]: value });

    };
    var payComponentListOption = props.payComponentList && props.payComponentList.map((cmp) => {
        return <option key={cmp.name + cmp.refNum} value={cmp.refNum}>{cmp.name}</option>;
    });
    var currencyListOptions = props.currencyList && props.currencyList.map((option) => {
        return <option key={option.currencyCode + option.currencyId}
            value={option.currencyCode}>{option.currencySymbol + ' ' + option.currencyCode}</option>;
    });


    const [formData, setFormData] = useState({

        userId: location.state.formData.userId,
        retainerApplicable: 'Yes',
        rtPayType: '',
        rtSalaryOrRate: '',
        rtCurrency: location.state.formData.baseCurrency,
        rtPayComponentId: '0',
        rtEffectiveDate: location.state.formData.retainerInfos.length > 0 ? new Date() : location.state.formData.hireDate,//location.state.userProfile.userId === undefined ? location.state.formData.hireDate : new Date(),
        rtReason: 'New Hire',
        rtHours: '',
        rtPayRate: '',
        rtCurrentRetainerFee: '',
        rtPreviousRetainerFee: '',
        retainerWeeks: '',
        hoursPerWeek: '',
        notes: '',
        retainerId: 1,

    });


    useEffect(()=>{
        if(formData.rtReason !== '')
        {
            calculatePreviousRetainerFee()
        }
    },[formData.rtReason, formData.rtEffectiveDate])
    useEffect(()=>{
        setSalaryDecimalPlaces(formData.rtPayType === 'Hourly' ? 4 : 2)
    },[formData.rtPayType])
    useEffect(() => {

        if (location.state.userProfile.firstName) {
            props.changePageMeta(`Retainer - ${location.state.userProfile.personalInfo.firstName} ${location.state.userProfile.personalInfo.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)
        }
        else {
            props.changePageMeta(`Retainer - ${location.state.formData.firstName} ${location.state.formData.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)

        }

        props.get_worker_types();
        props.sub_get_currency_list();
        props.get_deal_types_types();
        props.get_compensation_types();
        props.get_pay_component_list();

        if (location.state && location.state.row) {

            let currentRetainer = { ...location.state.row }

            setFormData({
                ...formData,
                userId: currentRetainer.userId,
                retainerApplicable: currentRetainer.retainerApplicable,
                rtPayType: currentRetainer.rtPayType,
                rtSalaryOrRate: currentRetainer.rtSalaryOrRate,
                rtCurrency: currentRetainer.rtCurrency,
                rtPayComponentId: currentRetainer.rtPayComponentId,
                rtEffectiveDate: setAPIDate(currentRetainer.rtEffectiveDate),
                rtReason: currentRetainer.rtReason,
                rtHours: currentRetainer.rtHours,
                rtPayRate: currentRetainer.rtPayRate,
                rtPreviousRetainerFee: currentRetainer.rtPreviousRetainerFee,
                rtCurrentRetainerFee : currentRetainer.rtCurrentRetainerFee,
                retainerWeeks: currentRetainer.retainerWeeks,
                hoursPerWeek: currentRetainer.hoursPerWeek,
                notes: currentRetainer.notes,
                retainerId: currentRetainer.retainerId,

            })
        }else{
            if(location.state.formData.retainerInfos[0]){
               // setFormData({...formData, retainerId : Number(location.state.formData.retainerInfos[0].retainerId) + 1})
               var retainerIds = location.state.formData.retainerInfos.map(retainer => Number(retainer.retainerId));
               setFormData({...formData,
                 retainerId: Math.max(...retainerIds) + 1,
                 retainerApplicable: location.state.formData.retainerInfos[0].retainerApplicable,
                 rtPayType: location.state.formData.retainerInfos[0].rtPayType,
                 rtSalaryOrRate: location.state.formData.retainerInfos[0].rtSalaryOrRate,
                 rtCurrency: location.state.formData.retainerInfos[0].rtCurrency,
                 rtPayComponentId: location.state.formData.retainerInfos[0].rtPayComponentId,
                 rtReason: 'Pay Raise',
                 rtHours: location.state.formData.retainerInfos[0].rtHours,
                 rtPayRate: location.state.formData.retainerInfos[0].rtPayRate,
                 rtPreviousRetainerFee: location.state.formData.retainerInfos[0].rtPreviousRetainerFee,
                 rtCurrentRetainerFee : location.state.formData.retainerInfos[0].rtCurrentRetainerFee,
                 retainerWeeks: location.state.formData.retainerInfos[0].retainerWeeks,
                 hoursPerWeek: location.state.formData.retainerInfos[0].hoursPerWeek,
                 notes: location.state.formData.retainerInfos[0].notes})
            }
        }

    }, [])
    useEffect(()=>{
        if(location.state.row){     
            setDisabledSave(compareObjects(location.state.row, formData))
        }
    },[formData])
    return (
        <>
            <Container fluid className="main-content-container p-0">
                <Form onSubmit={onSave}>
                    <Card small className=" w-100" style={{ borderRadius: 0 }}>
                        <Row className="w-100" style={{ marginLeft: '-4px' }}>
                            <Col lg="12" className="form-group m-1 p-1">
                                <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
                                    <Row className="m-1">
                                        <Col lg="8" className="d-flex flex-row">
                                            {/*<h4 className="m-0">Placement - First Name Last Name</h4>*/}
                                        </Col>
                                        <Col lg="4" className="d-flex flex-row-reverse">
                                            <Button onClick={onCancel} className="my-2 py-2 mx-2" size='sm'
                                                theme="accent">Cancel</Button>

                                            <Button className="my-2 py-2 mx-2" size='sm' disabled={disabledSave}
                                                theme="accent">Save</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card small className="mb-4 w-100 p-3" style={{ borderRadius: 0 }}>

                        <Card small className="mb-4 mt-5 w-100">
                            <h4 className="m-0 section-title idStyle">Ref# {formData.retainerId}</h4>
                            <ListGroup flush>
                                <ListGroupItem className="border-card p-3">

                                    <Row>

                                        {/*<Col lg="3" className="form-group m-0 p-2">
                                            <label>Retainer Applicable</label>
                                            <FormSelect name='retainerApplicable' value={formData.retainerApplicable}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Retainer Applicable</option>
                                                <option value='Yes'>Yes</option>
                                                <option value='No'>No</option>
                                            </FormSelect>
    </Col>*/}
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='rtPayType' value={formData.rtPayType}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Pay Type</option>
                                                <option value='Salaried'>Salaried</option>
                                                <option value='Hourly'>Hourly</option>
                                            </FormSelect>
                                        </Col>

                                        {/*<InputBox required={true} placeholder="Enter Salary or Rate" label="Salary or Rate" id='rtSalaryOrRate' name='rtSalaryOrRate'
                                            value={formData.rtSalaryOrRate}
                                            align='right'
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)}
                                            toolTipMSG="If Pay Type is Salaried: Enter Annual Salary & Hourly: Enter Hourly Rate" />
                                        */}
                                    <StandardFormInput
                                        id="rtSalaryOrRate"
                                        name='rtSalaryOrRate'
                                        label="Salary or Rate"
                                        standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                        dataMaxFracDigits={salaryDecimalPlaces}
                                        type="text"
                                        value={formData.rtSalaryOrRate}
                                        onChangeStandardInput={onChangeStandardInput}
                                        toolTipMSG="If Pay Type is Salaried: Enter Annual Salary & Hourly: Enter Hourly Rate"
                                        required
                                        placeholder={salaryDecimalPlaces === 2 ? '0.00' : '0.0000'}
                                        align='right'   
                                        />
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Retainer Currency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='rtCurrency' value={formData.rtCurrency}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Retainer Currency</option>
                                                {currencyListOptions}
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Component</label>
                                            <FormSelect name='rtPayComponentId' value={formData.rtPayComponentId}
                                                onChange={onChange} className='d-block'>
                                                <option value='0'>Select Pay Component</option>
                                                {payComponentListOption}
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="rtEffectiveDate">Effective Date</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <DatePicker
                                                id='rtEffectiveDate'
                                                required={true}
                                                autoComplete='off'
                                                name='rtEffectiveDate'
                                                dateFormat='yyyy-MM-dd'
                                                selected={formData.rtEffectiveDate}
                                                onChange={(date) =>
                                                    onDateChange("rtEffectiveDate", date)
                                                }
                                                value={formData.rtEffectiveDate}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>

                                        {/*<Col lg="3" className="form-group m-0 p-2">
                                            <label>Retainer Reason</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='rtReason' value={formData.rtReason}
                                                onChange={calculateRetainerFee} className='d-block'>
                                                <option value=''>Select Retainer Reason</option>
                                                <option value='Anniversary'>Anniversary</option>
                                                <option value='Anniversary/Pay Raise'>Anniversary/Pay Raise</option>
                                                <option value='Pay Raise'>Pay Raise</option>
                                                <option value='New Hire'>New Hire</option>
                                            </FormSelect>
                                            </Col>*/}

                                        <InputBox required={true} placeholder="Enter Retainer Weeks" label="Retainer Weeks" id='retainerWeeks' name='retainerWeeks'
                                            value={formData.retainerWeeks}
                                            onChange={(e) => calculateRetainerFee(e,2)} />

                                        <InputBox placeholder="Enter Hours" label="Hours" id='rtHours' name='rtHours' onChange={(e) => onMonetaryAmountChanged(e, 2)}
                                            value={(formData.rtHours = (Number(formData.retainerWeeks) * (location.state.formData.hoursPerWeek)).toFixed(2))}
                                            toolTipMSG="Formula - Hours Per Week * Retainer Weeks"
                                            disabled />
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <InputBox required={true} placeholder="0.00" dataMaxFracDigits="4" label="Bill Rate" id='rtPayRate' name='rtPayRate'
                                            value={formData.rtPayRate}
                                            align='right'
                                            onChange={(e) => calculateRetainerFee(e,2)} />

                                        <InputBox placeholder="Enter Current Retainer Fee" label="Current Retainer Amount" id='rtCurrentRetainerFee' name='rtCurrentRetainerFee'
                                            value={formatNumberAmount(formData.rtCurrentRetainerFee,"en-US",2)}
                                            align='right'
                                            toolTipMSG="Formula - Hours * Pay Rate"
                                            disabled />

                                        <InputBox placeholder="Enter Previous Retainer Fee" label="Previous Retainer Amount" id='rtPreviousRetainerFee' name='rtPreviousRetainerFee'
                                            value={formatNumberAmount(formData.rtPreviousRetainerFee,"en-US",2)}
                                            align='right'
                                            toolTipMSG="Formula - Previous Hours * Previous Pay Rate"
                                            disabled />
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label htmlFor="notes">Notes</label>
                                            <textarea placeholder='Enter notes' maxLength="100" className="form-control" value={formData.notes} rows="3" id="notes" name="notes" onChange={(e) => {
                                                if (formData.notes.length >= 99) {

                                                    props.setAlert("Notes can not exceed more than 100 charaters.", "danger")
                                                }
                                                onChange(e)
                                            }}>
                                                {formData.notes}
                                            </textarea>
                                            <span>Maximum {notesCharacters} characters.</span>
                                        </Col>
                                    </Row>

                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Card>
                </Form>
            </Container>
        </>
    );
}

RetainerForm.propTypes = {
    userProfile: PropTypes.object.isRequired,
    get_user_profile: PropTypes.func.isRequired,
    user: PropTypes.object,
    workerTypes: PropTypes.array.isRequired,
    dealTypes: PropTypes.array.isRequired,
    currencyList: PropTypes.array.isRequired,
    setAlert: PropTypes.func.isRequired,
    success: PropTypes.any,
    reset: PropTypes.func.isRequired,
    payComponentList: PropTypes.array.isRequired,
    

}

const mapStateToProps = (state) => {

    return {
        userProfile: state.user.userProfile,
        isSaveUserProfile: state.user.isSaveUserProfile,
        user: state.user.user,
        workerTypes: state.user.workerTypes,
        dealTypes: state.user.dealTypes,
        currencyList: state.user.currencyList,
        // message: state.user.message,
        success: state.user.success,
        alerts: state.alert,
        // moduleName: state.user.moduleName,
        payComponentList: state.user.payComponentList,
        isSaveUserPlacement: state.isSaveUserPlacement,
    }
}
export default connect(mapStateToProps, {

    get_worker_types,
    get_user_profile,
    clear_user_profile,
    get_subscriber_list,
    get_hr_Consultant,
    sub_get_currency_list,
    get_country_list,
    get_compensation_types,
    get_region_list,
    get_deal_types_types,
    get_client_list,
    get_pay_component_list,
    setAlert,
    reset,
    save_user_profile,
    update_isSave_user_profile
})(RetainerForm);