import React from 'react';
import {
  Card,
  CardHeader,
  Col, Form,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import CustomTable from "../../../components/common/CustomTable"

function Leaves({leaves,viewProfile}) {


  const tableColumns = [
    {
      Header: "Leave Type",
      accessor: "policyName",
      minWidth: 200,
      className: "text-center"
    },
    {
      Header: "Entitled (once accrued)",
      accessor: "entitledDays",
      className: "text-center",
      Cell: row =><span>{row.original.entitledFlag? row.original.entitledDays+" days/year":"—"} </span>
    },
    {
      Header: "Balance (once accrued)",
      accessor: "balance",
      className: "text-center",
      Cell: row =><span>{row.original.entitledFlag?row.original.balance+" days":"—"}</span>
    },
    {
      Header: "Leave Category",
      accessor: "leavesCarryover",
      className: "text-center",
      Cell: row =><span>{row.original.entitledFlag?"Limited":"Unlimited"}</span>
    }
  ];

  const pageSizeOptions= [5, 10, 15, 20, 25, 30]
  const pageSize= 5


  return (
    <Card id='leaves' small className="details-card mb-5 w-100">
      {viewProfile?
      <CardHeader className="border-bottom">
        <h4 className="m-0 section-title">Leaves Information</h4>
      </CardHeader>:
      <>
      <span className="iconBox"><i className="fas fa-user-times iconStyle"></i></span>
      <h4 className="m-0 section-title headerStyle">Leaves Information</h4>
      </>
      }
      <div className="mt-4 leaveTable">
      <CustomTable
        tableColumns={tableColumns}
        tableData={leaves}
        pageSizeOptions={pageSizeOptions}
        pageSize={pageSize}
        showHeader
        />
      </div>
    </Card>
  );
}

Leaves.defaultProps={
  leaves:[]
}

export default Leaves;
