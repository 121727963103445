import React, { useEffect, useState } from 'react'
import CustomCard from '../../components/common/CustomCard'
import InputBox from '../../components/common/InputBox'
import {useHistory, useParams } from 'react-router-dom';
import {
  Card,
  Col,
  Container, Form, ListGroup, ListGroupItem,
  Row
} from "shards-react";
import { connect } from 'react-redux';
import {
    get_currency_details,
    save_currency_details,
    clear_currency_details
} from '../../redux/actions/user';
import PropTypes from 'prop-types';

function AddCurrency({
    currency,
    get_currency_details,
    save_currency_details,
    clear_currency_details
}) {
    let { id } = useParams();
    const history = useHistory();
    useEffect(() => {
        if (id !== null && id !== '' && id !== '0' && id !== 0) {
            get_currency_details(id);
        }
        return function cleanUp() {
          clear_currency_details();
        }
    }, [id]);
    const [formData, setFormData] = useState({
        currencyId: '',
        code: '',
        symbol: '',
        description: '',
    });
    useEffect(() => {
        if (currency && Object.keys(currency).length !== 0) {
            setFormData({
                ...formData,
                code: currency.currencyCode,
                symbol: currency.currencySymbol,
                description: currency.description,
            });
        }
    }, [currency]);
    const { code, symbol, description } = formData;
    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const onSubmit = (e) => {
        e.preventDefault();
        save_currency_details(id, code, symbol, description);
        history.goBack();
    };
    return (
      <Container fluid className="main-content-container p-4">
        <Card small className="mb-5 w-100">
          <h4 className="m-0 section-title idStyle">Ref# {id}</h4>
          <ListGroup flush>
            <ListGroupItem className="border-card p-3">
              <Row>
                <Col>
                  <Form id="clientDetailsForm" onSubmit={onSubmit}>
                    <Row form>

                      <InputBox placeholder="Enter Currency Code" label="Currency Code" name='code' id='code'
                                value={code} onChange={onChange} />
                      <InputBox placeholder="Enter Currency Symbol" label="Currency Symbol" name='symbol' id="symbol"
                                value={symbol} onChange={onChange} />
                      <InputBox placeholder="Enter Description" label="Description" id='description'
                                name='description'
                                value={description} onChange={onChange} />
                      <Col lg="3" className="form-group p-2 mb-0">
                        <div style={{ marginTop: "2rem" }} className="d-flex justify-content-between">
                          <button type="submit" className="btn btn-primary w-100">Save</button>
                          <button onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                          }} className="btn btn-primary ml-2 w-100">Cancel</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    )
}

AddCurrency.propTypes = {
    currency: PropTypes.object.isRequired,
    get_currency_details: PropTypes.func.isRequired,
    save_currency_details: PropTypes.func.isRequired,
    clear_currency_details: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    currency: state.user.currency,
});
export default connect(mapStateToProps, {
    get_currency_details,
    save_currency_details,
    clear_currency_details
})(AddCurrency);
