import React, { useEffect, useState } from 'react';
import CustomCard from "../common/CustomCard";
import InputBox from "../common/InputBox";
import { Col, Row, FormCheckbox, FormInput, FormSelect } from "shards-react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import './Leaves.scss'
import InfoTooltip from "../../components/common/InfoTooltip";
import { setAPIDate, setInputDate } from '../../utils/date';
import {  get_leaves_types, get_leaves_balance, get_applicable_to_worker_leaves_balance, clear_leaves_balance } from "../../redux/actions/worker/leaves";
import { fixedDecimalPlaces, formatNumberAmount } from '../../utils/general';
import { setAlert } from "../../redux/actions/alert";
function Leaves({ formData, setFormData, get_leaves_types, get_leaves_balance, leaveBalance,  leaveTypeList, setAlert, get_applicable_to_worker_leaves_balance, clear_leaves_balance}) {

  const [searchLeaveData, setSearchLeaveData] = useState({
    year: '',
    leavePolicyId : '',
    userId:''
  })
const [yearList, setYearList] = useState([])
  const onChange = (e)=>{
    setSearchLeaveData({...searchLeaveData, [e.target.name] : e.target.value})
  }
useEffect(()=> clear_leaves_balance, [])

  useEffect(()=>{
    if(formData.leavePolicies && formData.leavePolicies.length > 0 ){

      setSearchLeaveData({...searchLeaveData, leavePolicyId: formData.leavePolicies[0].applicableToWorkerFlag ? formData.leavePolicies[0].leavePolicyId : '', userId: formData.userId, year:setInputDate(new Date()).substring(0,4)})
    }
  },[formData.leavePolicies])
  useEffect(() => {

    if(searchLeaveData.leavePolicyId)
    {get_leaves_balance(formData.userId, searchLeaveData.year, searchLeaveData.leavePolicyId);}

  }, [formData.userId])

  useEffect(()=>{
    console.log('searchLeaveData.userId ', searchLeaveData.userId)
    if(searchLeaveData.userId !== '')
    {get_leaves_balance(searchLeaveData.userId, searchLeaveData.year, searchLeaveData.leavePolicyId);}
  },[searchLeaveData])
  function createListOfYears(startYear, endYear) {
    let result = [];
    for (let i = startYear; i <= endYear; i++) {
        result.push(i);
    }
    return result.reverse();
}
  useEffect(()=>{
    
   let hireDate =  setInputDate(formData.hireDate);
   let now =  setInputDate(new Date());
   let currentYear = now.substring(0,4);
   let hireYear = currentYear;
   if(hireDate.length > 0){
     hireYear =  hireDate.substring(0,4);
   }

   let yearList = createListOfYears(hireYear, currentYear);

    setYearList(yearList);
  

  },[formData.hireDate])

  var yearListOptions = yearList.map(year => {
    return <option key={year} value={year}>{year}</option>
  })

  const { leavePolicies } = formData
  var leaveTypeListOptions = leavePolicies && leavePolicies.filter(leave => leave.applicableToWorkerFlag).map(leavePolicy=>{
    return <option key={leavePolicy.policyName} value={leavePolicy.leavePolicyId}>{leavePolicy.policyName}</option>;
  });
  const leavesData = leavePolicies && leavePolicies.map((i,index) => {
    return (
      // <Col className="col-12 leavesToggle mb-2">
        <Row form className="align-items-right m-1 w-100  mb-2" key={i.policyName + index}>
          <Col lg="3">
            <label>{i.policyName}</label>
          </Col>
          <Col lg="3" className="d-flex justify-content-left">
            <FormCheckbox name="applicableToWorkerFlag"
              checked={i.applicableToWorkerFlag}
              value={i.applicableToWorkerFlag}
              onChange={ async(e) => {
                let newLeavesPolicy = leavePolicies
                let checked =  e.target.value === 'true';
                
                if(checked){
                 let leaveDetails = await get_applicable_to_worker_leaves_balance(formData.userId, yearList[0], newLeavesPolicy[index].leavePolicyId)
                 if(leaveDetails !== null && Number(leaveDetails.submittedLeaved) > 0){
                    setAlert("Cannot Toggle off Leave Type as Leave Requests are pending for Approval.", "danger")

                    return;
                  }
                  
                }

                newLeavesPolicy[index].applicableToWorkerFlag = !newLeavesPolicy[index].applicableToWorkerFlag

                let foundLeavePolicy = leavePolicies.find(lp => lp.applicableToWorkerFlag === true);
                setSearchLeaveData({...searchLeaveData, leavePolicyId: foundLeavePolicy !== undefined ? foundLeavePolicy.leavePolicyId : ''})

                setFormData({ ...formData, leavePolicies: newLeavesPolicy })
              }}
              toggle small></FormCheckbox>
          </Col>
          <Col lg="2">
            <FormInput
              // label="entitledDays"
              name='entitledDays'
              className="w-100 text-center "
              // id={"adj_" + leaveType.leaveTypeId}
              value={i.entitledFlag? i.entitledDays:"Unlimited"}
              onChange={(e) => {
                if(i.entitledFlag){
                  let newLeavesPolicy = leavePolicies
                  newLeavesPolicy[index].entitledDays = e.target.value
                  setFormData({ ...formData, leavePolicies: newLeavesPolicy })
                }
              }}
            />
          </Col>

          <Col lg="2">
            <FormInput
              name='balanceAdj'
              value={i.entitledFlag?i.balanceAdj:"—"}
              className="w-100 text-center"
              disabled
              onChange={(e) => {
                if(i.entitledFlag){
                  let newLeavesPolicy = leavePolicies
                  newLeavesPolicy[index].balanceAdj = e.target.value
                  setFormData({ ...formData, leavePolicies: newLeavesPolicy })
                }
              }}
            />
          </Col>
          <Col lg="2">
            <FormInput
              name='Unit'
              value="Days"
              className="w-100 text-center"
              disabled
              onChange={(e) => {
                if(i.entitledFlag){
                  let newLeavesPolicy = leavePolicies
                  newLeavesPolicy[index].Unit = e.target.value
                  setFormData({ ...formData, leavePolicies: newLeavesPolicy })
                }
              }}
            />
          </Col>
        </Row>
      // {</Col>}
    )
  })

  return (<Row>
    <Col lg="5">
    <CustomCard id="leaveBalance" title="Leaves Balance Information" className="leave-setting h-100" icon={true} iconClass="fa-regular fa-calendar iconStyle" noform>
    <Row className="mx-0 w-100">
    <Col lg="6">
        <label htmlFor="leavePolicyId">Leave Type</label>
        <InfoTooltip msg="Entitled Leave Types mapped." infoClass="info-icon-primary"/>
        <FormSelect value={searchLeaveData.leavePolicyId} name='leavePolicyId'
                    onChange={onChange} className='d-block'>
          <option value=''>Select Leave Type</option>
          { leaveTypeListOptions}
        </FormSelect>
    </Col>
    
    <Col lg="6">
        <label htmlFor="year">Year</label>
        <FormSelect value={searchLeaveData.year} name='year'
                    onChange={onChange} className='d-block'>
          {yearListOptions}
        </FormSelect>
    
    </Col>
          
    </Row>
    <Row className="mx-0 w-100 mt-2 ">
      <Col lg="12">
        <table  className='table-bordered shadow-sm p-3 mb-2 bg-white rounded w-100'>
           <tbody>
            <tr>
              <td><div className="d-flex justify-content-between font-weight-normal  mr-2 ml-2"><div className='h-25'>Carry Forward ({(leaveBalance && leaveBalance.allowCarryOverFlag === undefined || leaveBalance.allowCarryOverFlag === 0) ? `Off` : `On`})</div><div className={`h-25 font-weight-normal ${leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag ? 'leave-balance' : 'mr-5' : 'leave-balance'} text-center`}>{leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag ? fixedDecimalPlaces(leaveBalance.carryForward, 2) : '-' : '0.00'}</div></div></td>
            </tr>
            <tr>
              <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25 '>Entitled (once accrued)</div><div className={`h-25 font-weight-normal ${leaveBalance.entitledFlag !== undefined ?  leaveBalance.entitledFlag === true ? 'leave-balance' : 'mr-4' : 'leave-balance'} text-center`}>{leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag === true ? fixedDecimalPlaces(leaveBalance.entitledDays, 2) : 'Unlimited' : '0.00'}</div></div></td>
            </tr>
            <tr>
              <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Approved</div><div className='h-25 font-weight-normal leave-balance'>{fixedDecimalPlaces(leaveBalance.approvedLeaves, 2)}</div></div></td>
            </tr>
            <tr>
              <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Submitted</div><div className='h-25 font-weight-normal leave-balance'>{fixedDecimalPlaces(leaveBalance.submittedLeaved, 2)}</div></div></td>
            </tr>
            <tr>
              <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Lapse</div><div className={`h-25 font-weight-normal ${leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag ? 'leave-balance' : 'mr-5' : 'leave-balance'} `}>{leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag ? fixedDecimalPlaces(leaveBalance.lapse, 2) : '-' : '0.00'}</div></div></td>
            </tr>
            <tr>
              <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Entitled (once accrued) Balance Remaining{/*<InfoTooltip msg="Unused Worker Leave Balance." infoClass="info-icon-primary"/>*/}</div><div className={`h-25 text-leave-balance font-weight-bold ${leaveBalance.entitledFlag  !== undefined ? !leaveBalance.entitledFlag ?  'mr-2' : '' : 'mr-1'}`}>{leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag ? fixedDecimalPlaces(leaveBalance.balance, 2) : '-' : '0.00'} Days</div></div></td>
            </tr>
          </tbody> 
        </table>
      </Col>
    </Row>
    </CustomCard>
    </Col>
    <Col lg="7" className="p-0">
    <CustomCard id="leaves" title="Leaves Settings" className="leave-setting h-100" icon={true} iconClass="fa-solid fa-user-gear iconStyle" noform>
      <Row className="mx-0 w-100">
        <Col className="col-12 leavesToggle p-0 ">
          <Row form className="align-items-center m-1 w-100" style={{overFlow:'scroll'}}>
            <Col lg="2" className="p-0  w-100 text-left ">
              <label className="leavesLabelStyle">Leave Policy</label>
            </Col>
            <Col lg="4" className=" w-100 text-left">
              <label className="leavesLabelStyle">Applicable to Worker</label>
              <InfoTooltip
                            msg="When toggled off the Leave Policy will not be displayed on the Worker dashboard." />
            </Col>
            <Col lg="2" className="w-100 text-center">
              <label className="leavesLabelStyle">Entitled <br/> (once accrued)</label>
              {/* <InfoTooltip
                            msg="Worker's entitled time off based on the Leave Policy." /> */}
            </Col>
            <Col lg="2" className="w-100 text-center">
              <label className="leavesLabelStyle">Balance <br/>(once accrued)</label>
              {/* <InfoTooltip
                            msg="Unused worker leave balance." /> */}
            </Col>
            <Col lg="2" className="w-100 text-center">
              <label className="leavesLabelStyle">Unit</label>
              <InfoTooltip
                            msg="The unit (days/hours) of entitled time off." />
            </Col>
          </Row>
          {leavesData}
        </Col>
        
        
        
      </Row>

    </CustomCard>
    </Col>
    </Row>
  );
}


Leaves.propTypes = {
  leavePolicyList: PropTypes.array.isRequired,
  leaveTypeList: PropTypes.array.isRequired,
  leaveBalance : PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  leavePolicyList: state.user.leavePolicyList,
  leaveTypeList : state.leaves.leaveTypeList,
  leaveBalance : state.leaves.leaveBalance
});

export default connect(
  mapStateToProps, {
    get_leaves_types,
    get_leaves_balance,
    clear_leaves_balance,    
    setAlert,
    get_applicable_to_worker_leaves_balance
  })(Leaves);

