import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, FormInput,
  FormSelect,
  Row
} from "shards-react";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import ReactTable from "react-table";
import {setDateForTable, setInputDate} from "../../utils/date";
import {getStatusClass, numberToMonetary} from "../../utils/general";
import {
  get_expense_status_list
} from "../../redux/actions/worker/expenses";
import {get_subs_expense_reports, get_sub_status_list} from "../../redux/actions/user";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {clear_sub_expense_sheet} from "../../redux/actions/subscriber/subExpenses";
import queryString from "querystring";

function SubscriberExpenses({

                              get_subs_expense_reports,
                              get_expense_status_list,
                              expenseStatusList,
                              subExpenseList,
                              clear_sub_expense_sheet,
                              title,
                              changePageMeta,
                              subStatusList,
                              get_sub_status_list
                            }) {
  let location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))


  const [state, setState] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
    from: '',
    to: '',
    clientName: '',
    workerName: '',
    status: '',
    empStatus : '',
  });

  var substatusListOptions = subStatusList && subStatusList.map(
    (substatus) => {
      return <option key={substatus} value={substatus}>{substatus}</option>;
    }
  );

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (location.state && location.state.workerName)
      changePageMeta(`${title} - ${location.state.workerName}`)
  }, []);

  let {userId} = useParams();

  useEffect(() => {
    let newState = {
      ...state,
      from:queryParams.from || "",
      to:queryParams.to || "",
      clientName:queryParams.clientName || "",
      workerName:queryParams.workerName || "",
      status:queryParams.status || "",
      empStatus : queryParams.empStatus || ""
    }
    get_subs_expense_reports(userId, newState.clientName, newState.workerName, newState.from, newState.to, newState.status, newState.empStatus);
    setState(newState)
  }, [
    queryParams.from,
    queryParams.to,
    queryParams.clientName,
    queryParams.workerName,
    queryParams.status]);

  useEffect(() => {
    get_expense_status_list();
    get_sub_status_list();
 }, []);


  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const tableData = subExpenseList;
  const {pageSize, pageSizeOptions} = state;


  let onStartDateChanged = (value) => {
    setState({
      ...state,
      from: value
    })
  }

  let onEndDateChanged = (value) => {
    setState({
      ...state,
      to: value
    })
  }


  var expenseStatusListOption = expenseStatusList.map((listOption, i) => {
    return <option key={listOption + i} value={listOption}>{listOption}</option>;
  });

  const onSubmit = (e) => {
    e.preventDefault();
    get_subs_expense_reports(
      userId,
      state.clientName,
      state.workerName,
      setInputDate(state.from),
      setInputDate(state.to),
      state.status,
      state.empStatus

    );
  };

  let getRefLink = (refNum, status, userId, workerName) => {
    if (status === "Approved" || status === "Reviewed")
      return `/sub-review-expense-report/${refNum}`
    else if (status === "Saved" || status === "Rejected")
      return {pathname:`/sub-edit-expense-report/${userId}/${refNum}`,state:{workerName: workerName}}
    else
      return `/sub-approve-expense-report/${refNum}`
  };
  const tableColumns = [
    {
      Header: "Date",
      accessor: "date",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.date),
    },

    {
      Header: "Worker Name",
      accessor: "workerName",
      maxWidth: 210,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Employment Status ",
      accessor: "employmentStatus",
      maxWidth: 150,
      className: "justify-content-center text-center text-wrap text-break",
      Cell: row =>
        <span
          className={getStatusClass(row.original.employmentStatus)}>{row.original.employmentStatus}</span>,
    },

    {
      Header: "Client Name",
      accessor: "clientName",
      maxWidth: 120,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Report Name",
      accessor: "name",
      className: "justify-content-center text-center text-wrap text-break"
    },
    {
      Header: "Submitted Total",
      accessor: "submittedTotal",
      maxWidth: 140,
      className: "justify-content-end text-wrap text-break",
      Cell: row => numberToMonetary(row.original.submittedTotal)
    },

    {
      Header: "Approved Total",
      accessor: "approvedTotal",
      maxWidth: 140,
      className: "justify-content-end text-wrap text-break",
      Cell: row => numberToMonetary(row.original.approvedTotal)
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>,
    },

    {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 80,
      className: "text-center",
      Cell: row =>
        <Link
          onClick={e=>clear_sub_expense_sheet()}
          to={getRefLink(row.original.refNum, row.original.status, row.original.userId, row.original.workerName)}>{row.original.refNum}</Link>
    }
  ];
  return (
    <Container fluid className="main-content-container px-4 pb-4">

      <div 
           className="page-header tableSearch flex-column flex-md-row py-2 d-flex justify-content-end bg-white mt-4 ">

        <div className="d-flex align-items-center p-2">
          <FormInput
            name='clientName'
            value={state.clientName}
            onChange={onChange}
            placeholder="Client Name"
          />
        </div>

        <div className="d-flex align-items-center p-2">
          <FormInput
            name='workerName'
            value={state.workerName}
            onChange={onChange}
            placeholder="Worker Name"
          />
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='status' value={state.status} onChange={onChange}>
            <option value=''>All Status
            </option>
            {expenseStatusListOption}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='empStatus' value={state.empStatus}
                      onChange={onChange}>
            <option value=''>All Employment Status</option>
            {substatusListOptions}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center p-2">
          <RangeDatePicker className="justify-content-end"
                           onStartDateChanged={onStartDateChanged}
                           onEndDateChanged={onEndDateChanged} name="range"/>
        </div>
        
        <div className="d-flex align-items-center justify-content-end p-2">
          <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                  onClick={onSubmit}>Search</Button>
        </div>
      </div>


      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col lg="2"
                className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-2">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={state.pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>
              <Col lg="6" className="d-flex align-items-center">
                    <p className='m-0'>Note - This section shows Active & On Leave Users</p>
                </Col>
              <Col lg="4"
                className="file-manager__filters__search d-flex justify-content-center justify-content-md-end col-4">
                <Link to={(userId)?{pathname:'/sub-edit-expense-reports/' + userId + '/0',state:location.state}:"/add-expense-report/0"}>
                  <Button className="mx-2 py-2 my-2" size='sm'
                          theme="accent">Add Report</Button>
                </Link>
              </Col>
            

            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

SubscriberExpenses.propTypes = {
  user: PropTypes.object.isRequired,
  get_subs_expense_reports: PropTypes.func.isRequired,
  get_expense_status_list: PropTypes.func.isRequired,
  subExpenseList: PropTypes.array.isRequired,
  expenseStatusList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user.user,
  subExpenseList: state.user.subExpenseList,
  expenseStatusList: state.expenses.expenseStatusList,
  subStatusList: state.user.subStatusList,
});
export default connect(mapStateToProps, {
  get_subs_expense_reports,
  get_expense_status_list,
  clear_sub_expense_sheet,
  get_sub_status_list
})(SubscriberExpenses);
