import api from "../../../utils/api";
import {setAlert} from "../alert";
import {
  CLEAR_CLIENT_LEAVE_POLICY_DETAILS,
  CLEAR_LEAVE_POLICY_CACHES, DELETE_CLIENT_LEAVE_POLICY_DETAILS,
  DELETE_LEAVE_POLICY_DETAILS,
  DELETE_LEAVE_POLICY_LEVEL_DETAILS,
  DELETE_LEAVE_POLICY_LOCATION,
  GET_CLIENT_LEAVE_POLICY_DETAILS,
  GET_CLIENT_LEAVE_POLICY_DETAILS_ERROR,
  GET_LEAVE_POLICY_DETAILS,
  GET_LEAVE_POLICY_DETAILS_ERROR,
  GET_LEAVE_POLICY_LOCATION_DETAILS,
  GET_LEAVE_POLICY_LOCATION_DETAILS_ERROR,
  GET_LEAVE_POLICY_LOCATION_LIST,
  GET_LEAVE_POLICY_LOCATION_LIST_ERROR,
  GET_LEAVE_POLICY_NAMES_LIST,
  GET_LEAVE_POLICY_NAMES_LIST_ERROR, SAVE_CLIENT_LEAVE_POLICY_DETAILS,
  SAVE_LEAVE_POLICY_DETAILS,
  SAVE_LEAVE_POLICY_LEVEL_DETAILS,
  SAVE_LEAVE_POLICY_LEVEL_DETAILS_ERROR,
  SAVE_LEAVE_POLICY_LOCATION_DETAILS,
  SUBMIT_LEAVE_POLICY_LOCATION_DETAILS,
  SUBMIT_LEAVE_POLICY_LOCATION_DETAILS_ERROR
} from "../types";
import {get_client_leave_policy_list} from "../user";
import axios from "axios";

export const get_leave_policy_location_list = (
  leavePolicyId,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getLeavePolicyLocationList`, {
        params: {
          leavePolicyId: leavePolicyId
        }
      }
    );
    dispatch({
      type: GET_LEAVE_POLICY_LOCATION_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_POLICY_LOCATION_LIST_ERROR,
    });
  }
};


export const get_leave_policy_location_details = (
  leavePolicyId,
  leavePolicyLocationId
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getLeavePolicyLocationDetails`, {
        params: {
          leavePolicyId: leavePolicyId,
          leavePolicyLocationId: leavePolicyLocationId
        }
      }
    );
    dispatch({
      type: GET_LEAVE_POLICY_LOCATION_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_POLICY_LOCATION_DETAILS_ERROR,
    });
  }
};

export const save_leave_policy_location_details = (
  leavePolicyId,
  leavePolicyLocationId,
  countryId,
  regionId,
  minimumLeaves,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveLeavePolicyLocationDetails`, {
        params: {
          leavePolicyId: leavePolicyId,
          leavePolicyLocationId: leavePolicyLocationId,
          countryId: countryId,
          regionId: regionId,
          minimumLeaves: minimumLeaves,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch(get_leave_policy_location_list(leavePolicyId));
    dispatch({
      type: SAVE_LEAVE_POLICY_LOCATION_DETAILS,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_LEAVE_POLICY_LOCATION_DETAILS,
    });
  }
};


export const delete_leave_policy_location = (
  leavePolicyId,
  leavePolicyLocationId,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/deleteLeavePolicyLocationDetails`, {
        params: {
          leavePolicyId: leavePolicyId,
          leavePolicyLocationId: leavePolicyLocationId,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_LEAVE_POLICY_LOCATION,
      payload: {leavePolicyLocationId: leavePolicyLocationId},
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};


export const submit_leave_policy_location_details = (
  leavePolicyId,
  leavePolicyLocationNote,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/submitLeavePolicyLocationDetails`, {
        params: {
          leavePolicyId: leavePolicyId,
          leavePolicyLocationNote: leavePolicyLocationNote,
        }
      }
    );

    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SUBMIT_LEAVE_POLICY_LOCATION_DETAILS,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SUBMIT_LEAVE_POLICY_LOCATION_DETAILS_ERROR,
    });
  }
};


export const get_leave_policy_details = (
  leavePolicyId,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getLeavePolicyDetails`, {
        params: {
          leavePolicyId: leavePolicyId
        }
      }
    );

    dispatch({
      type: GET_LEAVE_POLICY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_POLICY_DETAILS_ERROR,
    });
  }
};


export const delete_leave_policy_details = (
  leavePolicyId,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveLeavePolicyDetails`, {
        params: {
          leavePolicyId: leavePolicyId,
          deleted:true
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_LEAVE_POLICY_DETAILS,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_LEAVE_POLICY_DETAILS,
    });
  }
};


export const delete_leave_policy_level_details = (
  leavePolicyId,rulesId
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/deleteAdditionalAccrualRulesDetails`, {
        params: {
          leavePolicyId: leavePolicyId,
          rulesId: rulesId
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: DELETE_LEAVE_POLICY_LEVEL_DETAILS,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_LEAVE_POLICY_DETAILS,
    });
  }
};

export const save_leave_policy_details = (formData) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveLeavePolicyDetails`, {
        params: {
          ...formData,
          deleted:false
        }
      }
    );

    if (formData.leavePolicyId === 0) {
      dispatch(setAlert("Leave policy has been saved successfully. Now you can configure the location.", "success", 4000));
    } else {
      dispatch(setAlert("Leave policy has been saved successfully.", "success"));
    }
    dispatch({
      type: SAVE_LEAVE_POLICY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_LEAVE_POLICY_DETAILS,
    });
  }
};

export const save_leave_policy_level_details = (leavePolicyId,ruleId,accrue,unit,timeUnit,afterWorkingHour,accrualOn,accrualDate,recurring) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveAdditionalAccrualRulesDetails`, {
        params: {
          leavePolicyId:leavePolicyId,
          rulesId:ruleId,
          accrue:accrue,
          unit:unit,
          timeUnit:timeUnit,
          afterWorkingFor :afterWorkingHour,
          accrualOn:accrualOn,
          accrualDate:accrualDate,
          recurring:recurring,
          deleted:false
        }
      }
    );

    if (leavePolicyId === 0) {
      dispatch(setAlert("Level has been saved successfully. Now you can configure the location.", "success", 4000));
    } else {
      dispatch(setAlert("Level has been saved successfully.", "success"));
    }
    if(res.data !=null){
      dispatch({
        type: SAVE_LEAVE_POLICY_LEVEL_DETAILS,
        payload: res.data,
      });

    }else
    {
      dispatch(setAlert(res.message, "danger"));
    }
   
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_LEAVE_POLICY_LEVEL_DETAILS_ERROR,
    });
  }
};

export const clear_leave_policy_caches = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LEAVE_POLICY_CACHES,
  });
};


export const get_leave_policy_names_list = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getLeavePolicyList`, {}
    );

    dispatch({
      type: GET_LEAVE_POLICY_NAMES_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_POLICY_NAMES_LIST_ERROR,
    });
  }
};


export const get_client_leave_policy_details = (clientId, leavePolicyId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getClientLeavePolicyDetails`, {
        params: {
          clientId: clientId,
          leavePolicyId: leavePolicyId
        }
      }
    );

    dispatch({
      type: GET_CLIENT_LEAVE_POLICY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    try{
      const res = await api.get(
        `api/subscriber/getLeavePolicyDetails`, {
          params: {
            leavePolicyId: leavePolicyId
          }
        }
      );

      dispatch({
        type: GET_CLIENT_LEAVE_POLICY_DETAILS,
        payload: res.data,
      });
    }catch (e) {
      dispatch({
        type: GET_CLIENT_LEAVE_POLICY_DETAILS_ERROR,
      });
    }
  }
};


export const save_client_leave_policy_details = (formData) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveClientLeavePolicyDetails`, {
        params: {
          ...formData,
          deleted:false
        }
      }
    );

    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_CLIENT_LEAVE_POLICY_DETAILS,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_CLIENT_LEAVE_POLICY_DETAILS,
    });
  }
};


export const delete_client_leave_policy_details = (
  clientId,
  leavePolicyId,
) => async (dispatch) => {
  try {

    const res = await api.get(
      `api/subscriber/saveClientLeavePolicyDetails`, {
        params: {
          clientId: clientId,
          leavePolicyId: leavePolicyId,
          deleted:true
        }
      }
    );

   
    dispatch({
      type: DELETE_CLIENT_LEAVE_POLICY_DETAILS,
    });
    let cancelTokenSource = axios.CancelToken.source();
    dispatch(setAlert("Leave Policy Deleted successfully", "success"));
    dispatch(get_client_leave_policy_list("0", clientId,cancelTokenSource.token))
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_CLIENT_LEAVE_POLICY_DETAILS,
    });
  }
};


export const clear_client_leave_policy_details = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CLIENT_LEAVE_POLICY_DETAILS,
  });

};
