import api from "../../../utils/api";
import {setAlert} from "../alert";
import {GET_DASHBOARD_DATA} from "../types";


export const get_dashboard_data = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/user/getDashboardData`
    );
    dispatch({
      type: GET_DASHBOARD_DATA,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
  }
};
