import React, { useEffect, useState } from 'react';
import {
  ListGroup,
  Col, Row, Card, ListGroupItem,
  FormSelect,
  FormInput
} from "shards-react";
import CustomCard from "../common/CustomCard";

import InfoTooltip from "../../components/common/InfoTooltip";
import { setAlert } from "../../redux/actions/alert";
import { connect } from "react-redux";
function ApproverSettings({ formData, setFormData, appModules,
  contactList, setAlert }) {
  const [state, setState] = useState({
    editindex: -1,
    isedit: false
  });

  const [moduleName, setModuleName] = useState(formData.moduleName);
  const [approverId, setApproverId] = useState(formData.approverId);
  const [approverName, setApproverName] = useState(formData.approverName);
  const ApproverSet = (e) => {

    let aid = e.target.value;
    let approvernm = contactList.filter(x => x.userId == e.target.value);
    if(approvernm && approvernm.length>0){
      setApproverName(approvernm[0].name);
    }else
    {

      setApproverName('');
    }
    
    setApproverId(aid);
  }
  useEffect(() => {

    setFormData({
      ...formData,
      moduleName: moduleName,
      approverId: approverId,
      approverName: approverName
    });

  }, [moduleName, approverId, approverName]);

  const handleClose = () => {
    setState({
      ...state,
      editindex: -1,
      isedit: false
    })
    setModuleName('');
    setApproverId('');
    setApproverName('');
  }
  const AddApprover = () => {
    
    if(formData.moduleName==''|| !formData.moduleName)
    {
     setAlert("Please select Module", "danger");
 return;
    }
    if(formData.approverId==''|| !formData.approverId)
    {
     setAlert("Please select Approver", "danger");
 return;
    }
    if (state.isedit && state.editindex >= 0) {
      var approvals = JSON.parse(JSON.stringify(formData.approverInfos));
      //let approvals=formData.approverInfos;

      approvals.splice(state.editindex, 1);
      let approverdup = approvals.filter(x => x.moduleName == formData.moduleName && x.approverId == formData.approverId);
      let approvermodule = approvals.filter(x => x.moduleName == formData.moduleName);
      let approverdupid = approvals.filter(x => x.approverId == formData.approverId);
     
     

      if (approverdup && (approverdup.length) > 0) {

        setAlert("Module and Approver already added", "danger");
      } else if (approvermodule && (approvermodule.length) > 0) {
        setAlert("Only one Approver is allowed per Module.", "danger");

      } //else if (approverdupid && (approverdupid.length) > 0) {
        //setAlert("Approver already added.", "danger");

      //}
      else {
       // const newItem = { moduleName: formData.moduleName, approverName: formData.approverName, approverId: formData.approverId };
        formData.approverInfos[state.editindex].moduleName=formData.moduleName;
        formData.approverInfos[state.editindex].approverName=formData.approverName;
        formData.approverInfos[state.editindex].approverId=formData.approverId;
        setFormData({
          ...formData,
          approverInfos: formData.approverInfos
        });
        setState({
          ...state,
          editindex: -1,
          isedit: false
        })
        setModuleName('');
        setApproverId('');
      }
    } else {
 if(formData.approverInfos && formData.approverInfos.length>0){
      let approverdup = formData.approverInfos.filter(x => x.moduleName == formData.moduleName && x.approverId == formData.approverId);
      let approvermodule = formData.approverInfos.filter(x => x.moduleName == formData.moduleName);
      let approverdupid = formData.approverInfos.filter(x => x.approverId == formData.approverId);
      if (approverdup && (approverdup.length) > 0) {

        setAlert("Module and Approver already added", "danger");
      } else if (approvermodule && (approvermodule.length) > 0) {
        setAlert("Only one Approver is allowed per Module.", "danger");

      } //else if (approverdupid && (approverdupid.length) > 0) {
        //setAlert("Approver already added.", "danger");

      //}
      else {
        const newItem = { moduleName: formData.moduleName, approverName: formData.approverName, approverId: formData.approverId };
        setFormData({
          ...formData,
          approverInfos: [...formData.approverInfos, newItem]
        });
        setState({
          ...state,
          editindex: -1,
          isedit: false
        })
        setModuleName('');
        setApproverId('');
      }}
      else{

        const newItem = { moduleName: formData.moduleName, approverName: formData.approverName, approverId: formData.approverId };
        var items=[];
        
        setFormData({
          ...formData,
          approverInfos: [newItem]
        });
        
        // setFormData({
        //   ...formData,
        //   approverInfos: [...formData.approverInfos, newItem]
        // });
        setState({
          ...state,
          editindex: -1,
          isedit: false
        })
        setModuleName('');
        setApproverId('');
      }

    }

    
  }


  const editData = (e, index) => {
    e.preventDefault();
    setState({
      ...state,
      editindex: index,
      isedit: true
    })

    setModuleName(formData.approverInfos[index].moduleName);
    setApproverId(formData.approverInfos[index].approverId);
    setApproverName(formData.approverInfos[index].approverName);
  }


  var appModulesOption = appModules.filter(module => module !== 'Worker Data').map((modules) => {
    return <option key={modules} value={modules}>{modules}</option>;
  });

  var contactListOptions = contactList.map((status) => {
    return <option key={status.userId} value={status.userId}>{status.name}</option>;
  });

  const approverData = formData.approverInfos && formData.approverInfos.map((i, index) => {
    return (
      <Row className="mx-0 w-100" key={i.moduleName}>
        <Col lg="3" className="form-group m-0 p-2">
          <label>Module</label>
          <FormInput value={i.moduleName}></FormInput>
        </Col>
        <Col lg="3" className="form-group m-0 p-2">
          <label>Approver</label>
          <FormInput value={i.approverName}></FormInput>
        </Col>
        <Col lg="3" className="form-group m-0 p-2"></Col>

        <Col lg="3" className="form-group m-0 p-2">
          <label></label>
          <div className='d-flex justify-content-center justify-content-md-end align-items-center'>
            <button onClick={(e) => editData(e, index)} className="m-2 btn btn-accent btn-sm">Edit</button>
          </div>
        </Col>
      </Row>

    )
  })

  return (
    <CustomCard id="approvalSettings" title="Approval Settings" icon={true} noform
      iconClass="fas fa-users iconStyle">





      <Card small className="userprofile mb-5 w-100">
        <ListGroup flush>
          <ListGroupItem className="p-3">

            <Row>


              <Col sm="12" md="6" lg="3" className="form-group m-0 p-2">
                <label htmlFor="moduleName">Module</label>
                {formData.workerFlag && 
                 <span aria-hidden="true"  className="required"> *</span>
                }
               
                <InfoTooltip msg="Select a module to set up a request approver." />
                <FormSelect className='d-block' id="moduleName"
                  placeholder="ModuleName"
                  name='moduleName'
                  onChange={(e) => {
                    setModuleName(e.target.value);
                  }}
                  value={moduleName}>
                  <option value=''>Select Module</option>
                  {appModulesOption}
                </FormSelect>
              </Col>

              <Col sm="12" md="6" lg="3" className="form-group m-0 p-2">
                <label htmlFor="approverId">Approver</label>
                {formData.workerFlag && 
                 <span aria-hidden="true"  className="required"> *</span>
                }
                <InfoTooltip msg="Select an Approver to receive and approve requests." />
                <FormSelect className='d-block' id="approverId"
                  placeholder="Approver"
                  name='approverId'
                  onChange={(e) => {
                    ApproverSet(e);
                  }}
                  value={approverId}>
                  <option value='0'>Select Approver</option>
                  {contactListOptions}
                </FormSelect>
              </Col>
              <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">&nbsp;</Col>
              <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                <label></label>
                <div className='d-flex justify-content-center justify-content-md-end align-items-center'>

                  <button type='button' onClick={AddApprover} className="m-2 btn btn-accent btn-sm">Save</button>
                  <button type='button' onClick={handleClose} className="m-2 btn btn-accent btn-sm">Cancel</button>
                </div>
              </Col>




            </Row>

          </ListGroupItem>
        </ListGroup>
      </Card>


{formData.approverInfos&& formData.approverInfos.length>0 &&
      <div className="position-relative w-100">
        <div className="tableHeader d-flex">
          <label className="text-bold m-0 mx-1" htmlFor="allowLeaveEntitlementRules">Approval List
          </label>

        </div>
        <Card small className="mb-5 w-100">
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Row>
                    {approverData}

                  </Row>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>}
    </CustomCard>
  )

} const mapStateToProps = (state) => ({

});
export default connect(mapStateToProps, {
  setAlert
})(ApproverSettings);
