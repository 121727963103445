import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ReactTable from "react-table";

import {
  Card,CardBody,
  Col,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
  CardHeader,
  FormSelect
} from "shards-react";
import InputBox from '../../components/common/InputBox';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  clear_message_category,
  delete_message_category_details, get_message_category_details,
  save_message_category_details,
  get_message_category_role_list
} from "../../redux/actions/user";
import {useHistory, useParams} from "react-router-dom";
import ConfirmAlert from "../../components/common/ConfirmAlert";

function AddEditConfigMsgCategory({
  get_message_category_details,
  clear_message_category,
  save_message_category_details,
  delete_message_category_details,
  messageCategory,
  get_message_category_role_list,
  messageCategoryRoleList
  }) {

  let {id} = useParams();
  useEffect(() => {
    if (id && id !== '0') {
      get_message_category_details(id);
    }
    return ()=>clear_message_category();
    //get_message_category_role_list('');
  }, [id]);


  const history = useHistory();
  const [formData, setFormData] = useState({
    categoryId: 0,
    categoryName: '',
    role:'Worker',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5
  });

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (messageCategory && messageCategory.categoryName) {
      setFormData({
        ...formData,
        categoryId: messageCategory.categoryId,
        categoryName: messageCategory.categoryName,
        role: messageCategory.role
      });

    }
    if(messageCategory && messageCategory.role)
    {
      get_message_category_role_list(messageCategory.role);
    }
    else
    {
      get_message_category_role_list(formData.role);
    }
  }, [messageCategory]);

  const {categoryId, categoryName, role, pageSizeOptions, pageSize} = formData;
  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to save the changes you made?',
      visible: true,
      onConfirm: () => {
        let workerRoleFlag = formData.role === "Worker";
        save_message_category_details(categoryId, categoryName, workerRoleFlag);
        history.goBack();
      }
    })


  };
  const onDelete = (e) => {
    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you happy with your choice to archive this Message Category?',
      visible: true,
      onConfirm: () => {
        delete_message_category_details(id);
        history.goBack();
      }
    })

  };

  function onChangeRole(e) {
    setFormData({...formData, [e.target.name]: e.target.value});
    get_message_category_role_list(e.target.value);

  };

  function goBack(e) {
    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to exit this page?',
      visible: true,
      onConfirm: () => {
        history.goBack();
      }
    })
  }

  const tableData = messageCategoryRoleList;
  let editCategory=(e,id)=>{
    e.preventDefault();
    history.replace("./"+id)
  };
  const tableColumns = [
    {
      Header: "Message Category",
      accessor: "categoryName",
      className: "justify-content-start"
    },
    {
      Header: "Ref #",
      accessor: "categoryId",
      className: "text-center",
      maxWidth: 100,
      Cell: row => <Link onClick={e=>editCategory(e,row.original.categoryId)}>{row.original.categoryId}</Link>
    }
  ];

  return (
    <>
    <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
    <Container fluid className="main-content-container p-4">
    <Form id="addCategoryForm" onSubmit={onSubmit}>
      <Card small className="mb-5 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {id}</h4>

        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>

                <Row form>

                <Col sm="12" md="6" lg="4" className="form-group p-2 m-0">
                <label htmlFor="roleLabel">Select Role</label>
                <div
                           className="d-flex justify-content-between mt-1">
                  <label className="custom-control custom-radio custom-control-inline">
                    <input name="role" id="workerRadio" type="radio" className="custom-control-input" value="Worker" checked={formData.role === "Worker"} onChange={onChangeRole}/>
                    <label id="workerLabel" className="custom-control-label" aria-hidden="true"></label>
                  <span className="custom-control-description">Worker Roles</span></label>

                  <label className="custom-control custom-radio custom-control-inline">
                    <input name="role" id="clientUserRadio" type="radio" className="custom-control-input" value="Client User" checked={formData.role === "Client User"} onChange={onChangeRole}/>
                    <label id="clientUserLabel" className="custom-control-label" aria-hidden="true"></label>
                  <span className="custom-control-description">Client Roles</span></label>
                </div>
                </Col>
                  <InputBox placeholder="Enter Message Category" label="Message Category" name='categoryName' id='messageCategoryName'
                              value={formData.categoryName}
                              onChange={onChange}/>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <div
                           className="d-flex justify-content-between mt-4 pt-2">
                             <button onClick={onDelete} className="btn btn-primary w-100">Archive</button>
                        <button type="submit"  className="btn btn-primary  ml-2 w-100">Save</button>
                        <button onClick={goBack} className="btn btn-primary ml-2 w-100">Cancel</button>


                      </div>
                    </Col>
                    </Row>


              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
        </Card>
        </Form>
        <div className="position-relative">
                  <span className="tableHeader">Category List - {formData.role}</span>
                  <Card className="tableCard mt-4 p-0 border-card">
                    <CardHeader className="p-0 pt-3">
                      <Container fluid className="file-manager__filters border-bottom">
                        <Row>
                          {/* Filters :: Page Size */}
                          <Col
                            className="file-manager__filters__rows d-flex align-items-center col-6">
                            <span>Show</span>
                            <FormSelect
                              size="sm"
                              name='pageSize'
                              value={pageSize}
                              onChange={onChange}
                            >
                              {pageSizeOptions.map((size, idx) => (
                                <option key={idx} value={size}>
                                  {size} rows
                                </option>
                              ))}
                            </FormSelect>
                          </Col>
                          <Col
                            className="file-manager__filters__search d-flex justify-content-end col-6 py-1 mx-1">
                          </Col>

                        </Row>
                      </Container>
                    </CardHeader>
                    <CardBody className="p-0">
                      <div className="">
                        <ReactTable
                          columns={tableColumns}
                          data={tableData}
                          pageSize={Number(pageSize)}
                          showPageSizeOptions={false}
                          resizable={false}
                          noDataText="No results found"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
    </Container>
    </>
  );
}

AddEditConfigMsgCategory.propTypes = {
  get_message_category_details: PropTypes.func.isRequired,
  save_message_category_details: PropTypes.func.isRequired,
  delete_message_category_details: PropTypes.func.isRequired,
  get_message_category_role_list: PropTypes.func.isRequired,
  messageCategory: PropTypes.object.isRequired,
  messageCategoryRoleList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  messageCategory: state.user.messageCategory,
  messageCategoryRoleList: state.user.messageCategoryRoleList,
});
export default connect(mapStateToProps, {
  get_message_category_details,
  clear_message_category,
  save_message_category_details,
  delete_message_category_details,
  get_message_category_role_list
})(AddEditConfigMsgCategory);
