import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col, Container, DatePicker,
  Form, FormCheckbox,
  FormInput, FormSelect,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {connect} from "react-redux";
import { setAlert } from "../../redux/actions/alert";
import {
  clear_client_leave_policy_details,
  delete_client_leave_policy_details,
  get_client_leave_policy_details,
  get_leave_policy_names_list, save_client_leave_policy_details
} from "../../redux/actions/subscriber/leavePolicy";
import InputBox from "../../components/common/InputBox";
import InfoTooltip from "../../components/common/InfoTooltip";
import {
  get_leave_accrual_date_type_list,
  get_leave_accrual_every_string_list, get_leave_pay_out_limit_type_list,
  get_leave_pay_out_time_type_list,
  get_leave_period_string_list,
  get_leave_quota_reset_type_list,
  save_leave_policy_level_details,delete_leave_policy_level_details
} from "../../redux/actions/user";
import {setAPIDate, setDateForTable, setInputDate} from "../../utils/date";
import {LEAVE_POLICY_DEFAULT_END_DATE} from "../../utils/constants";
import ConfirmAlert from "../../components/common/ConfirmAlert";

function SubClientCustomizeLeavePolicy({
                                         clientLeavePolicyDetails,
                                         leavePolicyNamesList,
                                         leaveQuotaResetTypeList,
                                         leavePeriodStringList,
                                         leaveAccrualDateTypeList,
                                         leaveAccrualEveryStringList,
                                         leavePayOutTimeTypeList,
                                         leavePayOutLimitTypeList,
                                         leavePolicyLevelDetails,

                                         get_client_leave_policy_details,
                                         clear_client_leave_policy_details,
                                         delete_client_leave_policy_details,
                                         save_client_leave_policy_details,
                                         get_leave_policy_names_list,
                                         get_leave_quota_reset_type_list,
                                         get_leave_period_string_list,
                                         get_leave_accrual_date_type_list,
                                         get_leave_accrual_every_string_list,
                                         get_leave_pay_out_time_type_list,
                                         get_leave_pay_out_limit_type_list,
                                         save_leave_policy_level_details,
                                         delete_leave_policy_level_details,
                                         title,
                                         changePageMeta,
                                         setAlert
                                       }) {

  const location = useLocation();

  //added Client Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(clientLeavePolicyDetails).length > 0 && clientLeavePolicyDetails.clientName) {
      changePageMeta(`${title} - ${clientLeavePolicyDetails.clientName}`)
    } else if (location.state && location.state.clientName) {
      changePageMeta(`${title} - ${location.state.clientName}`)
    }
  }, [clientLeavePolicyDetails]);

  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,

    clientId: 0,
    leavePolicyId: 0,
    leaveName: "",
    entitledFlag: true,
    entitledDays: "",
    waitingPeriod: "",
    proRateLeaves: "",
    paidTimeOff: "",
    leaveQuotaResetType: "",
    leaveQuotaResetDate: "",
    allowFutureLeaves: "",
    accrualBegins: "",
    accrualBeginType: "",
    accrualUnit: "",
    accrualUnitType: "",
    leavesAccrueEvery: "",
    accrualOnDate: "",
    firstAccrualOn: "",
    maximumAccrual: "",
    maximumAccrualType: "",
    maximumAccrualUnit:"",
    allowLeaveEntitlementRules: "",
    allowAdditionalAccrualRules: "",
    allowCarryOver: "",
    allowNegativeCarryOver: "",
    carryOverType: "",
    maximumCarryOver: "",
    maximumNegativeAllowed:"",
    maximumNegativeAllowedUnit:"",
    useCarryOverWithin: "",
    allowLeavePayout: "",
    payoutTimeType: "",
    payoutTime: "",
    payoutLimitType: "",
    payoutLimit: "",
    leavePolicyLocationNote: "",
    startDate: "",
    endDate: "",
    accrualOn:"",
    accrualLimitType:"",
    firstDay: "",
    secondDay: ""
  });

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  const [state, setState] = useState({
    items: [{ uniq:Math.random(),id: 0, accrue: "", unit: "",timeUnit:"", afterWorkingFor:"", accrualOn:"",accrualDate:"", recurring:"" }]
  });

  let history = useHistory();
  let {clientId, leavePolicyId} = useParams();

  useEffect(() => {
    if (leavePolicyLevelDetails && Object.keys(leavePolicyLevelDetails).length !== 0) {

      setFormData({
        ...formData,
        leavePolicyId: leavePolicyLevelDetails.leavePolicyId,

      });

      var currentItem = state.items[state.items.length - 1];
      currentItem.id = leavePolicyLevelDetails.additionalAccrualRulesList[leavePolicyLevelDetails.additionalAccrualRulesList.length - 1].id;
      if(clientLeavePolicyDetails && Object.keys(clientLeavePolicyDetails).length !== 0)
      {
        var shouldAdd = clientLeavePolicyDetails.additionalAccrualRulesList ? state.items.length > clientLeavePolicyDetails.additionalAccrualRulesList.length : true;
        if(shouldAdd)
          clientLeavePolicyDetails.additionalAccrualRulesList.push(currentItem);
      }
    }
  }, [leavePolicyLevelDetails]);

  useEffect(() => {
    if (clientLeavePolicyDetails && Object.keys(clientLeavePolicyDetails).length !== 0) {
      setFormData({
        ...formData,
        clientId: clientLeavePolicyDetails.clientId,
        leavePolicyId: clientLeavePolicyDetails.leavePolicyId,
        leaveName: clientLeavePolicyDetails.leaveName,
        entitledFlag: clientLeavePolicyDetails.entitledFlag,
        entitledDays: clientLeavePolicyDetails.entitledDays,
        waitingPeriod: clientLeavePolicyDetails.waitingPeriod,
        proRateLeaves: clientLeavePolicyDetails.proRateLeaves,
        paidTimeOff: clientLeavePolicyDetails.paidTimeOff,
        leaveQuotaResetType: clientLeavePolicyDetails.leaveQuotaResetType,
        leaveQuotaResetDate: setAPIDate(clientLeavePolicyDetails.leaveQuotaResetDate),
        allowFutureLeaves: clientLeavePolicyDetails.allowFutureLeaves,
        accrualBegins: clientLeavePolicyDetails.accrualBegins,
        accrualBeginType: clientLeavePolicyDetails.accrualBeginType,
        accrualUnit: clientLeavePolicyDetails.accrualUnit,
        accrualUnitType: clientLeavePolicyDetails.accrualUnitType,
        leavesAccrueEvery: clientLeavePolicyDetails.leavesAccrueEvery,
        accrualOnDate: clientLeavePolicyDetails.accrualOnDate,
        firstAccrualOn: setAPIDate(clientLeavePolicyDetails.firstAccrualOn),
        maximumAccrual: clientLeavePolicyDetails.maximumAccrual,
        maximumAccrualType: clientLeavePolicyDetails.maximumAccrualType,
        maximumAccrualUnit: clientLeavePolicyDetails.maximumAccrualUnit,
        allowLeaveEntitlementRules: clientLeavePolicyDetails.allowLeaveEntitlementRulesFlag,
        allowAdditionalAccrualRulesFlag:clientLeavePolicyDetails.allowAdditionalAccrualRulesFlag,
        allowAdditionalAccrualRules: clientLeavePolicyDetails.allowAdditionalAccrualRulesFlag,
        allowCarryOver: clientLeavePolicyDetails.allowCarryOver,
        allowNegativeCarryOver: clientLeavePolicyDetails.allowNegativeCarryOver,
        maximumNegativeAllowed: clientLeavePolicyDetails.maximumNegativeAllowed,
        maximumNegativeAllowedUnit : clientLeavePolicyDetails.maximumNegativeAllowedUnit ,
        carryOverType: clientLeavePolicyDetails.carryOverType,
        maximumCarryOver: clientLeavePolicyDetails.maximumCarryOver,
        useCarryOverWithin: clientLeavePolicyDetails.useCarryOverWithin,
        allowLeavePayout: clientLeavePolicyDetails.allowLeavePayout,
        payoutTimeType: clientLeavePolicyDetails.payoutTimeType,
        payoutTime: setAPIDate(clientLeavePolicyDetails.payoutTime),
        payoutLimitType: clientLeavePolicyDetails.payoutLimitType,
        payoutLimit: clientLeavePolicyDetails.payoutLimit,
        leavePolicyLocationNote: clientLeavePolicyDetails.leavePolicyLocationNote,
        startDate: setAPIDate(clientLeavePolicyDetails.startDate),
        endDate: setAPIDate(clientLeavePolicyDetails.endDate),
        firstDay: clientLeavePolicyDetails.firstDay,
        secondDay: clientLeavePolicyDetails.secondDay,
        accrualLimitType: clientLeavePolicyDetails.accrualLimitType
      })
      state.items = clientLeavePolicyDetails.additionalAccrualRulesList;
      state.items.map(item => {
        item.accrualDate = setAPIDate(item.accrualDate);
        item.uniq=Math.random();
      });
    } else {
      clearForm();
    }
  }, [clientLeavePolicyDetails]);


  useEffect(() => {
    get_leave_policy_names_list();
    get_leave_quota_reset_type_list();
    get_leave_period_string_list();
    get_leave_accrual_date_type_list();
    get_leave_accrual_every_string_list();
    get_leave_pay_out_time_type_list();
    get_leave_pay_out_limit_type_list();
  }, []);

  useEffect(() => {
    if (leavePolicyId && leavePolicyId !== "0") {
      get_client_leave_policy_details(clientId, leavePolicyId);
    }

    return () => {
      clear_client_leave_policy_details();
    }
  }, [leavePolicyId]);

  let addListItem = () => {
    var currentLevelCount = state.items.length + 1;
    const newItem = { uniq:Math.random(),id: 0, accrue: "", unit: "", timeUnit:"", afterWorkingFor:"", accrualOn:"",accrualDate:"", recurring:"" };
    setState({
      items: [...state.items, newItem]
    });

  };

  let saveLevel = (e, ruleId) => {

    var currentItem = state.items.filter(v=>v.id == ruleId)[0];
    ruleId = formData.leavePolicyId === 0 ? 0 : state.items.length > (clientLeavePolicyDetails.additionalAccrualRulesList?clientLeavePolicyDetails.additionalAccrualRulesList.length:0) ? 0 :ruleId;
    //save level
    save_leave_policy_level_details(formData.leavePolicyId,ruleId,clientId,currentItem.accrue,currentItem.unit,currentItem.timeUnit,currentItem.afterWorkingFor ,currentItem.accrualOn,setInputDate(currentItem.accrualDate),currentItem.recurring);
  };

  let clearForm = () => {
    setFormData({
      ...formData,
      clientId: 0,
      leavePolicyId: 0,
      leaveName: "",
      entitledDays: "",
      waitingPeriod: "",
      proRateLeaves: "",
      paidTimeOff: "",
      leaveQuotaResetType: "",
      leaveQuotaResetDate: "",
      allowFutureLeaves: "",
      accrualBegins: "",
      accrualBeginType: "",
      accrualUnit: "",
      accrualUnitType: "",
      leavesAccrueEvery: "",
      accrualOnDate: "",
      firstAccrualOn: "",
      maximumAccrual: "",
      maximumAccrualType: "",
      allowLeaveEntitlementRules: "",
      allowAdditionalAccrualRules: "",
      allowCarryOver: "",
      allowNegativeCarryOver: "",
      carryOverType: "",
      maximumCarryOver: "",
      useCarryOverWithin: "",
      allowLeavePayout: "",
      payoutTimeType: "",
      payoutTime: "",
      payoutLimitType: "",
      payoutLimit: "",
      leavePolicyLocationNote: "",
      startDate: "",
      endDate: ""
    })
  }

  let onInputChange = (e, currentId) => {

    const newArr = state.items.map(item => {
      if (item.uniq == currentId) {
        return {
          ...item,
          [e.target.name]: e.target.value
        };
      } else {
        return item;
      }
    });

    setState({
      items: newArr
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let resetList = () => {
    state.items.map(item => {
      item.accrue = "";
      item.accrualOn = "";
      item.afterWorkingFor = "";
      item.recurring = "";
      item.timeUnit = "";
      item.unit = "";
      item.accrualDate = "";
    });
  }

  let createList = () => {
    if(!state.items || state.items.length == 0)
    {
    const newItem = { uniq:Math.random(),id: 0, accrue: "", unit: "", timeUnit:"", afterWorkingFor :"", accrualOn:"",accrualDate:"", recurring:"" };
    setState({
      items: [...state.items, newItem]
    });
    }
    const { items } = state;
    var count = 0;
    return items.map(item => {
      count++;
      return (
        <div className="position-relative col-12">
          <div className="tableHeader d-flex mt-3">
            <label className="text-bold m-0 mx-1" htmlFor="allowAdditionalAccrualRulesLevel">Level {count}
            </label>

          </div>
          <Card small className="mb-5 w-100 mt-3 d-flex">
            <ListGroup flush className="">
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Row form>

                      <InputBox label="Accrue " id="accrue"
                        name="accrue"
                        value={item.accrue}
                        disabled={!formData.entitledFlag || formData.allowAdditionalAccrualRules !== "Yes"}
                        onChange={e => onInputChange(e, item.uniq)}
                        required
                        placeholder="Enter Accrue "
                        toolTipMSG= "Set how much additional balance can be accrued"
                      />

                      <Col sm="12" md="6" lg="3"
                        className="form-group p-2 m-0">
                        <label htmlFor="unit">Units</label>
                        <InfoTooltip
                            msg="Choose a unit for additional accrual" />
                        <FormSelect id="unit"
                          name='unit'
                          onChange={e => onInputChange(e, item.uniq)}
                          required
                          value={item.unit}
                          disabled={!formData.entitledFlag  || formData.allowAdditionalAccrualRules !== "Yes"}>
                          <option value=''>Select Units</option>
                          <option value='Days'>Days</option>
                          <option value='Hours'>Hours</option>
                        </FormSelect>
                      </Col>

                      <InputBox label="After Working for " id="afterWorkingFor"
                        name="afterWorkingFor"
                        value={item.afterWorkingFor}
                        disabled={!formData.entitledFlag  || formData.allowAdditionalAccrualRules !== "Yes"}
                        onChange={e => onInputChange(e, item.uniq)}
                        required
                        placeholder="Enter After Working for "
                        toolTipMSG="Set a time period where additional balance can be accrued"
                      />
                      <Col sm="12" md="6" lg="3"
                        className="form-group p-2 m-0">
                        <label htmlFor="timeUnit">Time Unit</label>
                        <InfoTooltip
                            msg="Set the time unit" />
                        <FormSelect id="timeUnit" name='timeUnit'
                          onChange={e => onInputChange(e, item.uniq)}
                          required
                          value={item.timeUnit}
                          disabled={!formData.entitledFlag  || formData.allowAdditionalAccrualRules !== "Yes"}>
                          <option value=''>Select Time Unit</option>
                          <option value='Months'>Months</option>
                          <option value='Years'>Years</option>
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3"
                        className="form-group p-2 m-0">
                        <label htmlFor="accrualOn">Accrues On</label>
                        <InfoTooltip
                            msg="Choose when additional balance accrues" />
                        <FormSelect id="accrualOn" name='accrualOn'
                          onChange={e => onInputChange(e, item.uniq)}
                          required
                          value={item.accrualOn}
                          disabled={!formData.entitledFlag  || formData.allowAdditionalAccrualRules !== "Yes"}>
                          <option value=''>Select Accrues On</option>
                          {/* <option value='Calendar'>Calendar</option>
                          <option value='HiredAnniversary'>Hired Anniversary</option>
                          <option value='Custom'>Custom</option> */}
                           {leaveQuotaResetTypeListOptions}
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3"
                        className="form-group p-2 m-0">
                        <label htmlFor="accrualDate">Date</label>
                        <InfoTooltip
                            msg="Set the custom accrual date" />
                        <div>
                        <DatePicker
                              id='accrualDate'
                              autoComplete='off'
                              name='accrualDate'
                              dateFormat='yyyy-MM-dd'
                              selected={item.accrualDate}
                              disabled={!formData.entitledFlag || item.accrualOn !== "Custom"}
                              onChange={(date) =>
                                {
                                const newArr = state.items.map(itemq => {
                                  if (itemq.uniq == item.uniq) {
                                    return {
                                      ...itemq,
                                      accrualDate: date
                                    };
                                  } else {
                                    return itemq;
                                  }
                                });

                                setState({
                                  items: newArr
                                });

                              }}
                              value={item.accrualDate}
                              required
                              placeholderText="YYYY-MM-DD"
                            />
                        </div>
                      </Col>


                      <Col sm="12" md="6" lg="3"
                        className="form-group p-2 m-0">
                        <label htmlFor="recurring">Recurring
                        </label>
                        <InfoTooltip
                            msg="Make this accrual policy recurring" />
                        <FormSelect id="recurring"
                          name='recurring'
                          onChange={e => onInputChange(e, item.uniq)}
                          required
                          value={item.recurring}
                          disabled={!formData.entitledFlag  || formData.allowAdditionalAccrualRules !== "Yes"}>
                          <option value=''>Select Recurring </option>
                          <option value='Yes'>Yes</option>
                          <option value='No'>No</option>
                        </FormSelect>
                      </Col>
                    </Row>
                    <Row>
                    <Col sm="12" md="12" lg="12"
                          className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

                          <Button className="mx-1 py-2 mx-md-2" size="sm"
                            theme="accent" type="button"
                            disabled={!formData.entitledFlag  || formData.allowAdditionalAccrualRules !== "Yes"}
                            onClick={e => deleteLevel(e, item)}>Delete</Button>
                   </Col>
                   </Row>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>


        </div>
      );
    });
  };
  let deleteLevel = (e, rule) => {


    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete Level?',
      visible: true,
      onConfirm: () => {
        var shouldDelete = clientLeavePolicyDetails.additionalAccrualRulesList ? state.items.length === clientLeavePolicyDetails.additionalAccrualRulesList.length : false;

       // var currentItem = state.items.filter(v=>v.id == ruleId)[0];

        if(shouldDelete)
        { const index = state.items.findIndex(v => v.id === rule.id); //use id instead of index
        if (index > -1) {
           state.items.splice(index, 1);
         }
         //delete_leave_policy_level_details(formData.leavePolicyId, rule.id, clientId);

          var i = clientLeavePolicyDetails.additionalAccrualRulesList.findIndex(v => v.id === rule.id);
          if (i > -1) {
            clientLeavePolicyDetails.additionalAccrualRulesList.splice(i, 1);
          }
        }else
        {
          const index = state.items.findIndex(v => v.uniq === rule.uniq); //use id instead of index
          if (index > -1) {
             state.items.splice(index, 1);
           }
        }
      }
    })
  };
  // let deleteLevel = (e, rule) => {
  //   e.preventDefault();
  //   setConfirmAlert({
  //     ...confirmAlert,
  //     confirmMsg: 'Are you sure you want to delete Level?',
  //     visible: true,
  //     onConfirm: () => {

  //       var currentItem = state.items.filter(v=>v.id == ruleId)[0];
  //       const index = state.items.findIndex(v => v.id === ruleId); //use id instead of index
  //       var shouldDelete = clientLeavePolicyDetails.additionalAccrualRulesList ? state.items.length === clientLeavePolicyDetails.additionalAccrualRulesList.length : false;
  //       if (index > -1) {
  //         state.items.splice(index, 1);
  //       }
  //       if(shouldDelete)
  //       {
  //         delete_leave_policy_level_details(formData.leavePolicyId, ruleId, clientId);

  //         var i = clientLeavePolicyDetails.additionalAccrualRulesList.findIndex(v => v.id === ruleId);
  //         if (i > -1) {
  //           clientLeavePolicyDetails.additionalAccrualRulesList.splice(i, 1);
  //         }
  //       }
  //     }
  //   })
  // };

  let onSubmit = (e) => {
    e.preventDefault();
    state.items.forEach(x => x.accrualDate = setInputDate(x.accrualDate));
    formData.additionalAccrualRulesList=JSON.stringify(state.items);
    var validateNegativeCarryover=true;
    if(formData.allowNegativeCarryOver==="Yes"){
      if(formData.maximumNegativeAllowed==="" ){
        validateNegativeCarryover=false;
        setAlert("Please Enter Max Negative Allowed.", "danger");
      }
      if(formData.maximumNegativeAllowedUnit==="" ){
        validateNegativeCarryover=false;
        setAlert("Please Enter Max Negative Allowed Unit.", "danger");
      }
    }

    if(validateNegativeCarryover)
{
    if(formData.endDate && setInputDate(formData.endDate)!==LEAVE_POLICY_DEFAULT_END_DATE && setInputDate(formData.endDate)!=clientLeavePolicyDetails.endDate){
      setConfirmAlert({
        ...confirmAlert,
        confirmMsg: `Are you sure you want to end this leave policy on ${setDateForTable(setInputDate(formData.endDate))}?`,
        visible: true,
        onConfirm: () => {
          saveLeavePolicy();
          history.goBack();
        }
      })
      return
    }
    saveLeavePolicy();
    history.goBack();
 }
  }

  let saveLeavePolicy= ()=>save_client_leave_policy_details({
    ...formData,
    leavePolicyId: leavePolicyId,
    clientId: clientId,
    leaveQuotaReset: formData.leaveQuotaResetType,
    leaveQuotaResetDate: setInputDate(formData.leaveQuotaResetDate),
    firstAccrualOn: setInputDate(formData.firstAccrualOn),
    payoutTime: setInputDate(formData.payoutTime),
    startDate: setInputDate(formData.startDate),
    endDate: (formData.endDate) ? setInputDate(formData.endDate) : LEAVE_POLICY_DEFAULT_END_DATE,
  });

  let onDelete = e => {
    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete this leave policy?',
      visible: true,
      onConfirm: () => {
        delete_client_leave_policy_details(clientId, leavePolicyId);
        history.goBack();
      }
    })
  };


  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onMaximumCarryOverChange = (e) => {

    let val = e.target.value;
    //alert(val);

    if(val >= 1)
    {
      if((formData.carryOverType === 'Percentage' || formData.carryOverType === 'Number') && val > 100)
      {
        setAlert("Value can not be more than 100.", "danger");
      }
      else if((formData.carryOverType === 'Entitlement') && (val > 3))
      {
        setAlert("Value should be between 1 and 3.", "danger");
      }
      else
        setFormData({ ...formData, [e.target.name]: e.target.value});
    }
  };

  const onChangeType = (e) => {

    //setFormData({ ...formData, [e.target.name]: e.target.value});
    if(e.target.value === 'Percentage')
    {
      setFormData({ ...formData,[e.target.name]: e.target.value,'maximumCarryOver': 50});
    }
    if(e.target.value === 'Number')
    {
      setFormData({ ...formData, [e.target.name]: e.target.value,'maximumCarryOver': 5});
    }
    if(e.target.value === 'Entitlement')
    {
      setFormData({ ...formData, [e.target.name]: e.target.value,'maximumCarryOver': 1});
    }
    if(e.target.value === '')
    {

      setFormData({ ...formData, [e.target.name]: e.target.value,'maximumCarryOver': -1});
    }

  };

  const onYesNoToggle = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: (formData[e.target.name] === "Yes") ? "No" : "Yes"
    });
  };

  var leavePolicyNamesListOptions = leavePolicyNamesList.map(option => {
    return (<option value={option.leavePolicyId}>{option.policyName}</option>)
  })

  var leaveQuotaResetTypeListOptions = leaveQuotaResetTypeList.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var leavePeriodStringListOptions = leavePeriodStringList.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var leaveAccrualDateTypeListOptions = leaveAccrualDateTypeList.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var leaveAccrualEveryStringListOptions = leaveAccrualEveryStringList.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var leavePayOutTimeTypeListOptions = leavePayOutTimeTypeList.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var leavePayOutLimitTypeListOptions = leavePayOutLimitTypeList.map(option => {
    return (<option value={option}>{option}</option>)
  })

  let weekDays = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
  let monthDays = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];

  let custom = ['Month','Year'];
  let noncustom = ['Week','Two Weeks','15 Days','Month','Year'];

  var customOptions = custom.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var noncustomOptions = noncustom.map(option => {
    return (<option value={option}>{option}</option>)
  })

  var weekDaysOptions = weekDays.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var monthDaysOptions = monthDays.map(option => {
    return (<option value={option}>{option}</option>)
  })

  let carryovertype = ['Percentage','Number','Entitlement'];

  var carryovertypeOptions = carryovertype.map(option => {
    return (<option value={option}>{option}</option>)
  })

  let isDefaultEndDate = LEAVE_POLICY_DEFAULT_END_DATE === setInputDate(formData.endDate);
  return (
    <>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Container fluid className="main-content-container p-4">
        <Form onSubmit={onSubmit}>
        <div className="position-relative">

          <Card small className="mb-5 w-100">
            <h4 className="m-0 section-title idStyle">Ref# {leavePolicyId}</h4>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>

                    <Row form>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="leavePolicyId">Leave Policy Name</label>
                        <InfoTooltip
                            msg="Name the leave policy" />
                        <FormSelect id="leavePolicyId"
                                    name='leavePolicyId'
                                    value={leavePolicyId}
                                    onChange={e => history.replace(`/sub-client-customize-leave-policy/${clientId}/${e.target.value}`)}>
                          <option value="0">Select</option>
                          {leavePolicyNamesListOptions}
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="entitledFlag">Leave Category</label>
                        <InfoTooltip
                            msg="Make the leave policy limited or unlimited" />
                        <FormSelect id="entitledFlag" name='entitledFlag'
                                    onChange={e => {
                                      if (e.target.value !== "true") {
                                        formData.clientId = 0;
                                        formData.leavePolicyId = 0;
                                        formData.entitledDays = "";
                                        formData.proRateLeaves = "";
                                        formData.leaveQuotaResetType = "";
                                        formData.leaveQuotaResetDate = "";
                                        formData.allowFutureLeaves = "";
                                        formData.accrualBegins = "";
                                        formData.accrualBeginType = "";
                                        formData.accrualUnit = "";
                                        formData.accrualUnitType = "";
                                        formData.leavesAccrueEvery = "";
                                        formData.accrualOnDate = "";
                                        formData.firstAccrualOn = "";
                                        formData.maximumAccrual = "";
                                        formData.maximumAccrualType = "";
                                        formData.allowLeaveEntitlementRules = "";
                                        formData.allowAdditionalAccrualRules = "";
                                        formData.allowAdditionalAccrualRulesFlag="";
                                        formData.allowCarryOver = "";
                                        formData.allowNegativeCarryOver = "";
                                        formData.carryOverType = "";
                                        formData.maximumCarryOver = "";
                                        formData.maximumNegativeAllowed = "";
                                        formData.maximumNegativeAllowedUnit  = "";
                                        formData.useCarryOverWithin = "";
                                        formData.allowLeavePayout = "";
                                        formData.payoutTimeType = "";
                                        formData.payoutTime = "";
                                        formData.payoutLimitType = "";
                                        formData.payoutLimit = "";
                                        formData.leavePolicyLocationNote = "";
                                      }
                                      setFormData({
                                        ...formData,
                                        entitledFlag: e.target.value === "true"
                                      })
                                    }}
                                    value={formData.entitledFlag}
                                    required>
                          <option value="true">Limited Leaves</option>
                          <option value="false">Unlimited Leaves</option>
                        </FormSelect>
                      </Col>
                      <InputBox
                        label="Entitled Leaves"
                        id="entitledDays"
                        name='entitledDays'
                        value={formData.entitledDays}
                        type="number"
                        subLabel="days"
                        disabled={!formData.entitledFlag}
                        onChange={onChange}/>



                      <InputBox
                        label="Waiting Period"
                        id="waitingPeriod"
                        name='waitingPeriod'
                        value={formData.waitingPeriod}
                        type="number"
                        subLabel="days"
                        toolTipMSG = "Apply a waiting period to the leave policy"
                        onChange={onChange}/>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="proRateLeaves">Prorate Leaves</label>
                        <InfoTooltip
                          msg="Pro-rate the leave policy"/>
                        <FormSelect id="proRateLeaves" name='proRateLeaves'
                                    onChange={onChange}
                                    value={formData.proRateLeaves}
                                    disabled={!formData.entitledFlag}
                                    required>
                          <option value=''>Select</option>
                          <option value='Yes'>Yes</option>
                          <option value='No'>No</option>
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="paidTimeOff">Paid Leave</label>
                        <InfoTooltip
                            msg="Make the leave policy paid time-off" />
                        <FormSelect id="paidTimeOff" name='paidTimeOff'
                                    onChange={onChange}
                                    value={formData.paidTimeOff}
                                    required>
                          <option value=''>Select</option>
                          <option value='Yes'>Yes</option>
                          <option value='No'>No</option>
                        </FormSelect>
                      </Col>


                    </Row>

                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </div>
        <div className="position-relative">
            <div className="tableHeader d-flex">
              <label className="text-bold m-0 mx-1" htmlFor="allowLeaveEntitlementRules">Leave Entitlement Rules
               <InfoTooltip
                  msg="Define how Employees with this Policy will Accrue their Time-Off"/></label>
              <FormCheckbox
                id="allowLeaveEntitlementRules"
                name='allowLeaveEntitlementRules'
                className="m-0"
                onChange={e => {
                  if (formData[e.target.name] === "Yes") {
                    formData[e.target.name] = "No";
                    formData.accrualBegins = "";
                    formData.accrualBeginType = "";
                    formData.accrualUnit = "";
                    formData.accrualUnitType = "";
                    formData.leavesAccrueEvery = "";
                    formData.accrualOnDate = "";
                    formData.firstAccrualOn = "";
                    formData.maximumAccrual = "";
                    formData.maximumAccrualType = ""
                  } else
                    formData[e.target.name] = "Yes"
                  setFormData({
                    ...formData,
                  })
                }}
                checked={formData.entitledFlag && (formData.allowLeaveEntitlementRules === "Yes")}
                toggle small/>
            </div>
            <Card small className="mb-5 w-100">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Row form>

                      <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="leaveQuotaResetType">Reset
                            Date</label>
                            <InfoTooltip
                            msg="Choose a reset date for the leave policy" />
                          <FormSelect id="leaveQuotaResetType"
                                      name='leaveQuotaResetType'
                                      onChange={onChange}
                                      value={formData.leaveQuotaResetType}
                                      disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules != "Yes"}
                                      required>
                            <option value=''>Select Reset
                              Date
                            </option>
                            {leaveQuotaResetTypeListOptions}
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="leaveQuotaResetDate">Custom Reset
                            Date</label>
                            <InfoTooltip
                            msg="Choose a custom reset date for the policy" />
                          <div>
                            <DatePicker
                              id='leaveQuotaResetDate'
                              autoComplete='off'
                              name='leaveQuotaResetDate'
                              dateFormat='yyyy-MM-dd'
                              selected={formData.leaveQuotaResetDate}
                              disabled={!formData.entitledFlag || formData.leaveQuotaResetType !== "Custom"}
                              onChange={(date) =>
                                setFormData({
                                  ...formData,
                                  leaveQuotaResetDate: date
                                })
                              }
                              value={formData.leaveQuotaResetDate}
                              required
                              placeholderText="YYYY-MM-DD"
                            />
                          </div>
                        </Col>
                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="allowFutureLeaves">Allow Future
                            Leaves</label>
                            <InfoTooltip
                            msg="Allow workers to take leaves not yet accrued" />
                          <FormSelect id="allowFutureLeaves"
                                      name='allowFutureLeaves'
                                      onChange={e => {
                                        formData[e.target.name]=e.target.value;
                                        if (formData[e.target.name] === "No") {

                                          formData.allowNegativeCarryOver = "";
                                          formData.maximumNegativeAllowed = "";
                                          formData.maximumNegativeAllowedUnit  = "";
                                        } else
                                          formData[e.target.name] = "Yes"
                                        setFormData({
                                          ...formData,
                                        })
                                      }
                                      }

                                      value={formData.allowFutureLeaves}
                                      disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules != "Yes"}
                                      required>
                            <option value=''>Select Allow Future
                              Leaves
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </FormSelect>
                        </Col>


                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="leavesAccrueEvery">Leaves Accrue Every</label>
                          <InfoTooltip
                            msg="Define how leave accrues" />
                          <FormSelect id="leavesAccrueEvery" name='leavesAccrueEvery'
                                      onChange={onChange}
                                      required
                                      value={formData.leavesAccrueEvery}
                                      disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}>
                            <option value=''>Select Leaves Accrue Every</option>
                            {leaveQuotaResetTypeListOptions}
                            {/* <option value='Calendar'>Calendar</option>
                            <option value='HiredAnniversary'>Hired Anniversary</option>
                            <option value='Custom'>Custom</option> */}

                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="accrualOnDate">On Date</label>
                          <InfoTooltip
                            msg="Set a custom accrual date" />
                          <FormSelect id="accrualOnDate"
                                      name='accrualOnDate'
                                      onChange={onChange}
                                      required
                                      value={formData.accrualOnDate}
                                      disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}>
                            <option value=''>Select On Date</option>
                            {formData.leavesAccrueEvery === "Custom" ? customOptions : noncustomOptions}
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="firstDay">First Day
                            </label>
                            <InfoTooltip
                            msg="Set the first accrual date within your selected time period" />
                            <FormSelect id="firstDay"
                                      name='firstDay'
                                      onChange={onChange}
                                      required
                                      value={formData.firstDay}
                                      disabled={formData.allowLeaveEntitlementRules != "Yes" ? true : !formData.entitledFlag ? true : formData.accrualOnDate === "Year"}>
                            <option value=''>Select First Day</option>
                            {formData.accrualOnDate === "Week" ? weekDaysOptions : monthDaysOptions}
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="secondDay">Second  Day
                            </label>
                            <InfoTooltip
                            msg="Set the second accrual date within your selected time period" />
                            <FormSelect id="secondDay"
                                      name='secondDay'
                                      onChange={onChange}
                                      required
                                      value={formData.secondDay}
                                      //disabled={!formData.entitledFlag || formData.accrualOnDate == "Year" || formData.accrualOnDate == "Week" || formData.accrualOnDate == "Month"}>
                                      disabled={formData.allowLeaveEntitlementRules != "Yes" ? true : !formData.entitledFlag ? true : formData.accrualOnDate === "Year" || formData.accrualOnDate == "Year" || formData.accrualOnDate == "Week" || formData.accrualOnDate == "Month"}>
                            <option value=''>Select Second  Day</option>
                            {monthDaysOptions}
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="accrualLimitType">Accrual Limit Type </label>
                          <InfoTooltip
                            msg="Set the accrual type" />
                          <FormSelect id="accrualLimitType"
                                      name='accrualLimitType'
                                      onChange={onChange}
                                      required
                                      value={formData.accrualLimitType}
                                      disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}>
                            <option value=''>Select Accrual Limit Type</option>

                            <option value='Days'>Days</option>
                            <option value='Hours'>Hours</option>
                          </FormSelect>
                        </Col>

                        <InputBox label="Max Accrual" id="maximumAccrual"
                                  name="maximumAccrual"
                                  value={formData.maximumAccrual}
                                  disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}
                                  onChange={onChange}
                                  required
                                  placeholder="Enter Max Accrual"
                                  toolTipMSG= "Create a maximum for leave accrual"
                        />

                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="maximumAccrualUnit">Max Accrual
                          Unit </label>
                          <InfoTooltip
                            msg="Choose a unit for the maximum accrual" />
                          <FormSelect id="maximumAccrualUnit"
                                      name='maximumAccrualUnit'
                                      onChange={onChange}
                                      required
                                      value={formData.maximumAccrualUnit}
                                      disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}>
                            <option value=''>Select Accrual
                          Unit</option>

                            <option value='Days'>Days</option>
                            <option value='Hours'>Hours</option>
                          </FormSelect>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </div>

          <div className="position-relative">
            <div className="tableHeader d-flex">
              <label className="text-bold m-0 mx-1" htmlFor="allowAdditionalAccrualRules">Additional Accrual Rules
                 <InfoTooltip
                  msg="Define how Employees with this Policy will Accrue their Additional Time-Off"/></label>
              <FormCheckbox
                id="allowAdditionalAccrualRules"
                name='allowAdditionalAccrualRules'
                className="m-0"
                onChange={e => {
                  if (formData[e.target.name] === "Yes") {
                    formData[e.target.name] = "No";
                    resetList();
                  } else
                    formData[e.target.name] = "Yes"
                  setFormData({
                    ...formData,
                  })
                }}
                checked={formData.entitledFlag && (formData.allowAdditionalAccrualRules === "Yes")}
                toggle small/>
            </div>
            <Card small className="mb-5 w-100 ">


              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row >
                    <Col>
                      <Row form style={{ height: "400px", overflow:"auto"}}>
                      {createList()}
                      </Row>


                      <Row className="px-3">

                        <Col sm="12" md="12" lg="12"
                          className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                          <Button className="mx-1 py-2 mx-md-2" size="sm"
                            theme="accent" type="button" onClick={addListItem}  disabled={!formData.entitledFlag || formData.allowAdditionalAccrualRules !== "Yes"}
                          >Add Level</Button>


                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </div>

          <div className="position-relative">
            <div className="tableHeader d-flex">
              <label className="text-bold m-0 mx-1" htmlFor="allowCarryOver">Leave
                Carryover Rules
                <InfoTooltip
                  msg="Define Carryover in % or Cap Amount or Entitlement"/></label>
              <FormCheckbox
                id="allowCarryOver"
                name='allowCarryOver'
                className="m-0"
                onChange={e => {
                  if (formData[e.target.name] === "Yes") {
                    formData[e.target.name] = "No";
                    formData.allowNegativeCarryOver = "";
                    formData.carryOverType = "";
                    formData.maximumCarryOver = "";
                    formData.useCarryOverWithin = "";
                    formData.maximumNegativeAllowed = "";
                    formData.maximumNegativeAllowedUnit  = "";
                  } else
                    formData[e.target.name] = "Yes"
                  setFormData({
                    ...formData,
                  })
                }
                }
                checked={formData.entitledFlag && (formData.allowCarryOver === "Yes")}
                toggle small/>
            </div>
            <Card small className="mb-5 w-100">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Row form>

                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="allowNegativeCarryOver">Allow Negative
                            Carryover</label>
                            <InfoTooltip
                            msg="Allow workers to take leaves not yet accrued" />
                          <FormSelect id="allowNegativeCarryOver"
                                      name='allowNegativeCarryOver'
                                      onChange={onChange}
                                      disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes" || formData.allowFutureLeaves !== "Yes"}
                                      value={formData.allowNegativeCarryOver}>
                            <option value=''>Select Allow Negative
                              Carryover
                            </option>
                            <option value='No'>No</option>
                            <option value='Yes'>Yes</option>
                          </FormSelect>
                        </Col>

                        <InputBox label="Max Negative Allowed"
                          id="maximumNegativeAllowed"
                          name="maximumNegativeAllowed"

                          value= {formData.maximumNegativeAllowed}
                          disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes" || formData.allowNegativeCarryOver !== "Yes"}
                          onChange={onChange}

                          placeholder="Enter Max Negative Allowed"
                          type='number'
                          toolTipMSG= "Set maximum Negative allowed"
                        />
                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="maximumNegativeAllowedUnit">Max Negative Allowed Unit</label>
                          <InfoTooltip
                            msg="Choose the Unit for Maximum Negative allowed" />
                          <FormSelect id="maximumNegativeAllowedUnit"
                            name='maximumNegativeAllowedUnit'
                            onChange={onChange}
                            disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes" || formData.allowNegativeCarryOver !== "Yes"}
                            value={formData.maximumNegativeAllowedUnit}>
                            <option value=''>Select Max Negative Allowed Unit
                            </option>
                            <option value='Days'>Days</option>
                            <option value='Hours'>Hours</option>
                          </FormSelect>
                        </Col>
                        </Row>
                        <Row form>
                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="carryOverType">Carryover Type
                            </label>
                            <InfoTooltip
                            msg="Define the Carryover Type as a %, or a flat cap, or multiple of their basic entitlement" />
                          <FormSelect id="carryOverType"
                                      name='carryOverType'
                                      onChange={onChangeType}
                                      disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes"}
                                      value={formData.carryOverType}>
                                    <option value=''>Select Carryover Type

                                  </option>

                                  {carryovertypeOptions}
                          </FormSelect>
                        </Col>

                        <InputBox label="Max Carryover"
                                  id="maximumCarryOver"
                                  name="maximumCarryOver"

                                  value={formData.maximumCarryOver}
                                  disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes"}
                                  onChange={onMaximumCarryOverChange}
                                  toolTipMSG= "Set the carryover maximum"
                                  placeholder="Enter Max Carryover"
                                  type='text'
                                 />

                        <InputBox label="Use Carryover Within" subLabel="days"
                                  id="useCarryOverWithin"
                                  name="useCarryOverWithin"
                                  placeholder="Enter Use Carryover Within"
                                  value={formData.useCarryOverWithin}

                                  disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes" || formData.allowCarryOver !== "Yes"}
                                  toolTipMSG= "Set an expiration period for carryover"
                                  onChange={onChange}/>

                      </Row>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </div>

          <div className="position-relative">
            <div className="tableHeader d-flex">
              <label className="text-bold m-0 mx-1" htmlFor="allowLeavePayout">Leave
                Payout Rules</label>
              <FormCheckbox
                id="allowLeavePayout"
                name='allowLeavePayout'
                className="m-0"
                onChange={e => {
                  if (formData[e.target.name] === "Yes") {
                    formData[e.target.name] = "No";
                    formData.payoutTimeType = "";
                    formData.payoutTime = "";
                    formData.payoutLimitType = "";
                    formData.payoutLimit = "";
                  } else
                    formData[e.target.name] = "Yes"
                  setFormData({
                    ...formData,
                  })
                }
                }
                checked={formData.entitledFlag && (formData.allowLeavePayout === "Yes")}
                toggle small/>
            </div>
            <Card small className="mb-4 w-100">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>

                      <Row form>

                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="payoutTimeType">Payout Time</label>
                          <FormSelect id="payoutTimeType" name='payoutTimeType'
                                      onChange={onChange}
                                      value={formData.payoutTimeType}
                                      disabled={!formData.entitledFlag || formData.allowLeavePayout !== "Yes"}
                                      required>
                            <option value=''>Select Payout Time</option>
                            {leavePayOutTimeTypeListOptions}
                          </FormSelect>
                        </Col>



                        <InputBox label="Payout Limit" id="payoutLimit"
                                  name="payoutLimit"
                                  placeholder="Enter Payout Limit"
                                  value={formData.payoutLimit}
                                  disabled={!formData.entitledFlag || formData.allowLeavePayout !== "Yes"}
                                  onChange={onChange}/>

                        <Col sm="12" md="6" lg="3"
                             className="form-group p-2 m-0">
                          <label htmlFor="payoutLimitType">Payout Limit
                            Type</label>
                          <FormSelect id="payoutLimitType"
                                      name='payoutLimitType'
                                      onChange={onChange}
                                      value={formData.payoutLimitType}
                                      disabled={!formData.entitledFlag || formData.allowLeavePayout !== "Yes"}
                                      required>
                            <option value=''>Select Duration</option>
                            {leavePayOutLimitTypeListOptions}
                          </FormSelect>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </div>

          <Card small className="mb-5 w-100">
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>

                    <Row form>
                      <Col sm="12" md="6" lg="3"
                           className="form-group p-2 m-0">
                        <label htmlFor="startDate">Start Date</label>
                        <div>
                          <DatePicker
                            id='startDate'
                            autoComplete='off'
                            name='startDate'
                            dateFormat='yyyy-MM-dd'
                            selected={formData.startDate}
                            onChange={(date) =>
                              setFormData({...formData, startDate: date})
                            }
                            value={formData.startDate}
                            placeholderText="YYYY-MM-DD"
                          />
                        </div>
                      </Col>

                      <Col sm="12" md="6" lg="3"
                           className="form-group p-2 m-0">
                        <label htmlFor="endDate">End Date</label>
                        <InfoTooltip
                            msg="If discontinued, enter the End Date of the Leave Policy" />
                        <div>
                          <DatePicker
                            id='endDate'
                            autoComplete='off'
                            name='endDate'
                            dateFormat='yyyy-MM-dd'
                            selected={(!isDefaultEndDate) ? formData.endDate : ""}
                            onChange={(date) =>
                              setFormData({...formData, endDate: date})
                            }
                            value={(!isDefaultEndDate) ? formData.endDate : ""}
                            placeholderText="YYYY-MM-DD"

                          />
                        </div>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>


          {/*action buttons*/}
          <Row className="px-3">
            <Col sm="12" md="12" lg="12"
                 className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
              <Button className="mx-1 py-2 mx-md-2" size="sm"
                      theme="accent" type="submit"
              >Save</Button>
              {
                (formData.leavePolicyId && formData.leavePolicyId !== "0") ?
                  <Button className="mx-1 py-2 mx-md-2" size="sm"
                          theme="accent" onClick={onDelete}>Delete</Button> : ""
              }
              <Button className="mx-1 py-2 mx-md-2" size="sm"
                      theme="accent"
                      onClick={e => history.goBack()}>Cancel</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  clientLeavePolicyDetails: state.leavePolicy.clientLeavePolicyDetails,
  leavePolicyNamesList: state.leavePolicy.leavePolicyNamesList,
  leaveQuotaResetTypeList: state.user.leaveQuotaResetTypeList,
  leavePeriodStringList: state.user.leavePeriodStringList,
  leaveAccrualDateTypeList: state.user.leaveAccrualDateTypeList,
  leaveAccrualEveryStringList: state.user.leaveAccrualEveryStringList,
  leavePayOutTimeTypeList: state.user.leavePayOutTimeTypeList,
  leavePayOutLimitTypeList: state.user.leavePayOutLimitTypeList,
  leavePolicyLevelDetails: state.leavePolicy.leavePolicyLevelDetails
});
export default connect(mapStateToProps, {
  get_client_leave_policy_details,
  get_leave_policy_names_list,
  delete_client_leave_policy_details,
  save_client_leave_policy_details,
  clear_client_leave_policy_details,

  get_leave_quota_reset_type_list,
  get_leave_period_string_list,
  get_leave_accrual_date_type_list,
  get_leave_accrual_every_string_list,
  get_leave_pay_out_time_type_list,
  get_leave_pay_out_limit_type_list,
  save_leave_policy_level_details,
  delete_leave_policy_level_details,
  setAlert
})(SubClientCustomizeLeavePolicy)
