import React, {useEffect} from 'react';
import {
  Alert,
  Container,
  Row
} from "shards-react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import PersonalInfo from "../../components/worker/view-profile/PersonalInfo";
import ContactInfo from "../../components/worker/view-profile/ContactInfo";
import UserJourney from "../../components/worker/view-profile/UserJourney";
import PensionPlan from "../../components/worker/view-profile/PensionPlan";
import PerksInfo from "../../components/worker/view-profile/PerksInfo";
import BenefitsInfo from "../../components/worker/view-profile/BenefitsInfo";
import Leaves from "../../components/worker/view-profile/Leaves";
import EmploymentInfo
  from "../../components/worker/view-profile/EmploymentInfo";

import TabBar from "../../components/worker/view-profile/TabBar";
import {
  get_artisan_journey,
  view_artisan_profile
} from "../../redux/actions/worker/profile";
import Allowances from "../../components/worker/view-profile/Allowances";
import CompensationInfo from '../../components/worker/view-profile/CompensationInfo';
function ViewProfile(props) {

  let userPermissions = props.user.userRoles.find(role => role.role === "Worker").permissions

  useEffect(() => {
    props.view_artisan_profile(props.user.userId)
    if (userPermissions.includes("Journey Information"))
      props.get_artisan_journey(props.user.userId)
  }, []);


  let hiddenSections = [];
  if (Object.keys(props.artisanProfile).length > 0) {
    if (!userPermissions.includes("Allowance") || !props.artisanProfile.allowanceTypes || props.artisanProfile.allowanceTypes.filter(type => type.amount !== 0).length === 0)
      hiddenSections.push("allowances")
    if (!userPermissions.includes("Benefits") || ["benefitsCoverage", "benefitsClass", "benefitsCertificateNo", "benefitsStartDate", "contribution", "clientContribution"].filter(key => props.artisanProfile[key] !== "0" && props.artisanProfile[key] !== 0 && props.artisanProfile[key] !== "").length === 0)
      hiddenSections.push("benefits")
    if (!userPermissions.includes("HSA Information") || ["HSAProvider", "HSAAmount", "HSACarryOver", "HSAWaitingPeriod"].filter(key => props.artisanProfile[key] !== "0" && props.artisanProfile[key] !== 0 && props.artisanProfile[key] !== "").length === 0)
      hiddenSections.push("perks")
    if (!userPermissions.includes("Pension Information") || ["pensionPlan", "planNo", "clientCap", "planStartDate", "vestingPeriod", "myMatch", "clientMatch", "pensionPlanFrequency"].filter(key => props.artisanProfile[key] !== "0" && props.artisanProfile[key] !== 0 && props.artisanProfile[key] !== "").length === 0)
      hiddenSections.push("pension")
  }
  if (!userPermissions.includes("Personal Information")) hiddenSections.push("personal")
  if (!userPermissions.includes("Contact Information")) hiddenSections.push("contact")
  if (!userPermissions.includes("Employment Information") && !userPermissions.includes("Compensation")) hiddenSections.push("employment")
  if (!userPermissions.includes("Leaves")) hiddenSections.push("leaves")
  if (!userPermissions.includes("Journey Information")) hiddenSections.push("userJourney")

  return (
    <Container fluid className="main-content-container px-0">
      <div className='profileNav offset-md-3 col-lg-10 offset-lg-2 p-0'>
        <Container fluid className="px-0">
          <Alert theme="white"
                 className="border-bottom text-danger show px-4 mb-0">
            Your profile is view only. For changes, please contact your HR
            representative.
          </Alert>
        </Container>

        <TabBar hiddenSections={hiddenSections}/>
      </div>

      <Row className='px-4 m-0 view-user-profile main'>

        <div className={"w-100 position-relative"}>
          <h4 className="m-0 section-title idStyle">Ref# {props.artisanProfile.userId}</h4>
          {(!hiddenSections.includes("personal")) &&
          <PersonalInfo
            {...props.artisanProfile}
            viewProfile={false}
          />
          }

          {(!hiddenSections.includes("contact")) && <ContactInfo
            {...props.artisanProfile}
            viewProfile={false}
          />}

          {(!hiddenSections.includes("employment")) && <EmploymentInfo
            {...props.artisanProfile}
            userPermissions={userPermissions}
            viewProfile={false}
          />}
          {(!hiddenSections.includes("employment")) && <CompensationInfo
            compensationInfos={props.artisanProfile.compensationInfo}
            userPermissions={userPermissions}
            viewProfile={false}
          />}
          {/*(!hiddenSections.includes('allowances')) && <Allowances
            allowanceTypes={props.artisanProfile.allowanceTypes}/>*/}

          {(!hiddenSections.includes('leaves')) && <Leaves
            leaves={props.artisanProfile.leavePolicies || []}
            viewProfile={false}
          />}

          {(!hiddenSections.includes('benefits')) && <BenefitsInfo
            {...props.artisanProfile}
            viewProfile={false}
          />}

          {(!hiddenSections.includes('perks')) && <PerksInfo
            {...props.artisanProfile}
            viewProfile={false}
          />}

          {(!hiddenSections.includes('pension')) && <PensionPlan
            {...props.artisanProfile}
            viewProfile={false}
          />}

          { (!hiddenSections.includes('userJourney')) && <UserJourney
            artisanJourney={props.artisanJourney}
            viewProfile={false}
          />}
        </div>

      </Row>
    </Container>
  );
}

ViewProfile.propTypes = {
  artisanProfile: PropTypes.object.isRequired,

  view_artisan_profile: PropTypes.func.isRequired,
  get_artisan_journey: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  artisanProfile: state.profile.artisanProfile,
  artisanJourney: state.profile.artisanJourney
})

export default connect(mapStateToProps, {
  view_artisan_profile,
  get_artisan_journey
})(ViewProfile);

