import React, {useEffect, useState} from 'react';
import {
  Card,
  CardHeader,
  Col,
  Form, FormInput, FormSelect,


  ListGroup,
  ListGroupItem,
  Row

} from "shards-react";
import InputBox from "../common/InputBox";
import PropTypes from "prop-types";
import {setAPIDate, setDateForTable} from "../../utils/date";
import CustomCard from "../common/CustomCard";
import {STANDARD_FORMATS} from "../../utils/constants";
import StandardFormInput from "../common/StandardFormInput";
import InfoTooltip from '../common/InfoTooltip';

const IndependentContractorInfo = (
  {

    contractorsCompanyName,
    GSTOrHSTTax,
    GSTOrHSTTaxPercentage,
    GSTOrHSTTaxAmount,
    taxNumber,
    workersCompExemption,
    workersWCBNumber,
    onChange,
    isRequiredFlag,
    workerType,
    onUnitFieldChange,
    onChangeStandardInput
  }) => {





  return (
    <CustomCard id="independentContractor" className="mb-5 w-100 d-flex personalParent" title=" Independent Contractor" noform>


      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="contractorsCompanyName">Contractor's Company Name</label>
        <span aria-hidden="true" className="required"> *</span>
        <InfoTooltip msg="Required for Incorporated or Sole-Proprietor contractor Types"/>
        <FormInput
          id="contractorsCompanyName"
          placeholder="Enter Contractor's Company Name"
          required = {true}
          value={contractorsCompanyName}
          name='contractorsCompanyName'
          onChange={onChange}
        />
      </Col>


      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="GSTOrHSTTax">GST/HST Tax (Yes/No)</label>
        <FormSelect value={GSTOrHSTTax} id="GSTOrHSTTax"
                    name='GSTOrHSTTax' onChange={onChange}
                    className='d-block'>
          <option value=''>Select GST/HST Tax</option>
          <option value='Yes'>Yes</option>
          <option value='No'>No</option>
        </FormSelect>
      </Col>

      {/* <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="GSTOrHSTTaxPercentage">GST/HST Tax %</label>
        <FormInput
          id="GSTOrHSTTaxPercentage"
          placeholder="Enter GST/HST Tax %"
          value={GSTOrHSTTaxPercentage}
          name='GSTOrHSTTaxPercentage'
          onChange={onChange}
        />

      </Col> */}

      <InputBox  placeholder="0.00 %" label="GST/HST Tax %" name='GSTOrHSTTaxPercentage' id='GSTOrHSTTaxPercentage'
                value={GSTOrHSTTaxPercentage + "%"}
                onChange={e => onUnitFieldChange(e, "%", 2)}/>

     <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="GSTOrHSTTaxAmount">GST/HST Tax Amount</label>
        <FormInput
          id="GSTOrHSTTaxAmount"
          placeholder="0.00"
          value={GSTOrHSTTaxAmount}
          name='GSTOrHSTTaxAmount'
          onChange={e => onUnitFieldChange(e, "%", 2)}
        />
      </Col>

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="taxNumber">Tax Number</label>
        <span aria-hidden="true" className="required"> *</span>
        <InfoTooltip msg="Required for Incorporated or Sole-Proprietor contractor Types"/>
        <FormInput
          id="taxNumber"
          required
          placeholder="Enter Tax Number"
          value={taxNumber}
          name='taxNumber'
          onChange={onChange}
        />
      </Col>

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="workersCompExemption">Workers Comp Exemption</label>
        <FormSelect value={workersCompExemption} id="workersCompExemption"
                    name='workersCompExemption' onChange={onChange}
                    className='d-block'>
          <option value=''>Select Workers Comp Exemption</option>
          <option value='Yes'>Yes</option>
          <option value='No'>No</option>
        </FormSelect>
      </Col>

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="workersWCBNumber">Worker's WCB Number</label>
        <FormInput
          id="workersWCBNumber"
          placeholder="Enter WCB Number"
          value={workersWCBNumber}
          name='workersWCBNumber'
          onChange={onChange}
        />
      </Col>

    </CustomCard>
  );
};

IndependentContractorInfo.propTypes = {
  IndependentContractorInfo: PropTypes.object.isRequired,

}

export default IndependentContractorInfo;
