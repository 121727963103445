import React from 'react'
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, FormInput,
  FormSelect, ListGroup,
  Row, ListGroupItem,
  Form
} from "shards-react";


export default function CustomCard({
                                     id,
                                     children,
                                     icon,
                                     iconClass,
                                     title,
                                     className,
                                     noform
                                   }) {
  return (
    <Card id={id} small
          className={(className) ? className : "details-card mb-5 w-100 d-flex personalParent"}>
      <>
        {icon ?
          <span className="iconBox"><i className={iconClass}></i></span> :
          <img className="avatarImg"
               src={require("../../images/avatars/img_avatar2.png")}
               alt="avatar"/>}
        <h4 className="m-0 section-title headerStyle">{title}</h4>
      </>
      <ListGroup flush>

        <ListGroupItem className="p-3 mt-3">
          <Row>
            <Col>
              {(noform) ? <Row form>
                {children}
              </Row> : <Form>
                <Row form>
                  {children}
                </Row>
              </Form>
              }
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
}
