import {SET_ERROR} from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        [action.payload.inputId]: action.payload.msg
      }
    default:
      return state;
  }
}
