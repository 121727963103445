import React, {useEffect, useState, useRef} from 'react';
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, Form,
  FormSelect, FormTextarea, ListGroup, ListGroupItem,
  Row
} from "shards-react";
import {Link, useHistory, useLocation} from "react-router-dom";
import ReactTable from "react-table";
import {
  setDateForTableInHrMinSec,
  setInputDate
} from "../../utils/date";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
  delete_note, get_note_details,
  get_note_list, save_note
} from "../../redux/actions/subscriber/subscriberUsers";

function SubscriberAddEditNotes({noteList, note, get_note_list, save_note,get_note_details,delete_note}) {

  const [state, setState] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });

  let history=useHistory();
  let location = useLocation();
  const notesRef = useRef(null);

  const [userInfo, setUserInfo] = useState({
    userIdUser: null,
    userNameUser: '',
    noteType: null,
    noteTypeText: '',
  });

  useEffect(() => {
    if (location.state) {
      setUserInfo({
        ...userInfo,
        userIdUser: location.state.userId,
        userNameUser: location.state.userName,
        noteType: location.state.noteType,
        noteTypeText: location.state.noteTypeText,
      });
    }
  }, [location.state]);

  const {userIdUser, userNameUser, noteType, noteTypeText} = userInfo;

  useEffect(() => {
    if (location.state && userIdUser && noteType) {
      get_note_list(userIdUser, noteType);
    }
  }, [userInfo]);

  useEffect(() => {
    if(userInfo.userIdUser && userInfo.noteType)
    {
      get_note_list(userInfo.userIdUser,userInfo.noteType);
    }
  }, [note]);


  const [currentNoteData, setCurrentNote] = useState({
    refNum: 0,
    date: setInputDate(new Date()),
    noteText: '',
    userName: '',
  });


  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const onEditNote =(refNum) => {
    get_note_details(refNum);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await save_note(
      userInfo.userIdUser,
      currentNoteData.refNum,
      0,
      userInfo.noteType,
      currentNoteData.noteText
    );

    
    clearCurrentNote();
    window.scrollTo(0, 0);
    
    if(Number(currentNoteData.refNum) !== 0){
      onCancel()

    }

    
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    if(currentNoteData.refNum !== '0')
    {
      delete_note(currentNoteData.refNum);
      clearCurrentNote();
    }
    window.scrollTo(0, 0);

  };

  const clearCurrentNote = () => {
    setCurrentNote({
      ...currentNoteData,
      refNum : 0,
      date: setInputDate(new Date()),
      noteText : '',
      userName : userInfo.userNameUser,
    });

    if(notesRef.current){
      notesRef.current.scrollIntoView();
    }
  };


  useEffect(() => {
    if (Object.keys(note).length !== 0 && note) {
      setCurrentNote({
        ...currentNoteData,
        refNum: note.refNum,
        date: note.date,
        noteText: note.noteText,
        userName: note.userName
      });
    }
  }, [note]);

  const tableData = noteList;
  const {pageSize, pageSizeOptions} = state;


  const tableColumns = [
    {
      Header: "Date Time",
      accessor: "date",
      maxWidth: 180,
      Cell: row =>
        setDateForTableInHrMinSec(row.original.date),
    },
    {
      Header: "User",
      accessor: "userName",
      maxWidth: 160,
      className: "justify-content-start text-left text-wrap"
    },
    {
      Header: "Note",
      accessor: "noteText",
      className: "justify-content-start",
    },

    {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 100,
      headerClassName:"shadow-none",
      Cell: row =>
        <Link onClick={e=>{
          e.preventDefault()
          onEditNote(row.original.refNum)
          if(notesRef.current){
            notesRef.current.scrollIntoView();
          }
        }
        }>{row.original.refNum}</Link>
    },
  ];

  const onCancel = ()=>{
    let userId = userInfo.userIdUser;
        let roleStr = (userInfo.userIdUser !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
        let noteTypes = ['subscriberNotes','pensionNotes','severanceNotes']
        history.push({
            pathname: `/manage-user-profile/${userId}`,
            search: `${roleStr}&section=notes&subSection=${noteTypes[Number(userInfo.noteType)-1]}`,
            state: {formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
        });
}
  return (
    <Container fluid className="main-content-container px-4 p-4" >

      <Card className="p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col xl="4" lg="3"
                   className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6 order-lg-0">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col xl="2" lg="3"
                   className="file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6 order-lg-3">

                <Button className="mx-2 py-2" size='sm' 
                        theme="accent" onClick={clearCurrentNote}>Add New Note</Button>
              </Col>

              <Col xl="3" lg="3"
                   className="d-flex align-items-center text-nowrap mt-2 mt-lg-0 col-12 order-lg-1">
                <label className="m-0">Worker Name:</label>
                <span className='px-2'>{userNameUser}</span>
              </Col>

              <Col xl="3" lg="3"
                   className="d-flex align-items-center text-nowrap mt-2 mt-lg-0 col-12 order-lg-2">
                <label className="m-0">Note Type:</label>
                <span className='px-2'>{noteTypeText}</span>
              </Col>

            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0" >
          <div className="" >
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              sorted={[{ id: 'refNum', desc: true }]}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>

      <Card small className="my-4 w-100">
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form onSubmit={onSubmit}>
                  <Row form>
                    <Col sm="12" md="12" className="form-group p-2 m-0">
                      <label htmlFor="noteText" ref={notesRef}
>Note - Ref # {currentNoteData.refNum}</label>
                      <FormTextarea
                        id="noteText"
                        rows="10"
                        name="noteText"
                        value={currentNoteData.noteText}
                        onChange={e => {
                          setCurrentNote({
                            ...currentNoteData,
                            noteText: e.target.value
                          });
                        }}
                      />
                    </Col>

                  </Row>
                  <Row className="px-3">
                    <Col sm="12" md="12" lg="12"
                         className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              theme="accent" disabled={currentNoteData.noteText === "" || currentNoteData.noteText === note.noteText}>Save</Button>
                      <Button className="mx-1 py-2 mx-md-2" size="sm" disabled={Number(currentNoteData.refNum) === 0}
                              theme="accent" onClick={onDeleteClick}>Delete</Button>
                      <Button className="mx-1 py-2 mx-md-2" size="sm" 
                              theme="accent" onClick={onCancel}>Cancel</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}


SubscriberAddEditNotes.propTypes = {
  user: PropTypes.object.isRequired,
  get_note_list: PropTypes.func.isRequired,
  noteList: PropTypes.array.isRequired,
  get_note_details: PropTypes.func.isRequired,
  save_note: PropTypes.func.isRequired,
  delete_note: PropTypes.func.isRequired,
  note: PropTypes.object.isRequired,

};
const mapStateToProps = (state) => ({
  user: state.user.user,
  noteList: state.subscriberUsers.noteList,
  note: state.subscriberUsers.note,
});
export default connect(mapStateToProps, {
  get_note_list,
  get_note_details,
  save_note,
  delete_note

})(SubscriberAddEditNotes);
