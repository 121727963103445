import React, {useState, useEffect} from 'react'
import {Container, FormSelect, FormInput, Button} from "shards-react";
import CustomTable from '../../../components/common/CustomTable';
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import {get_search_leave_policy} from '../../../redux/actions/user';
import {Link} from "react-router-dom";
import {clear_leave_policy_caches} from "../../../redux/actions/subscriber/leavePolicy";


function SubLeavePolicy({leavePolicyList, get_search_leave_policy,clear_leave_policy_caches}) {

  const [formData, setFormData] = useState({
    policyId: '',
    policyName: '',
    leaveCarryover: '',
    paidTimeOff: '',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });

  const {
    policyId,
    policyName,
    leaveCarryover,
    paidTimeOff,
    pageSize,
    pageSizeOptions
  } = formData;

  useEffect(() => {
    get_search_leave_policy(policyId,
      policyName,
      leaveCarryover,
      paidTimeOff)
  }, [])

  const onSubmit = (e) => {
    e.preventDefault()
    get_search_leave_policy(policyId,
      policyName,
      leaveCarryover,
      paidTimeOff)
  }


  const leaveCarryoverData = [
    {id: 1, leaveCar: "Yes"},
    {id: 2, leaveCar: "No"},
  ]
  var leaveData = leaveCarryoverData.map((type) => {
    return <option value={type.leaveCar}>{type.leaveCar}</option>;
  });

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const tableColumns = [
    {
      Header: "ID",
      accessor: "leavePolicyId",
      maxWidth: 100,
      className: "text-center",
      Cell: row =><Link onClick={clear_leave_policy_caches} to={"/sub-edit-leave-policy/"+row.original.leavePolicyId}>{row.original.leavePolicyId}</Link>
    },
    {
      Header: "Policy Name",
      accessor: "policyName",
      className: "justify-content-start"
    },
    {
      Header: "Leave Carryover",
      accessor: "leavesCarryover",
      maxWidth: 200,
      className: "text-center"
    },
    {
      Header: "Paid Time-Off",
      accessor: "paidTimeOff",
      className: "text-center",
      maxWidth:200
    }
  ];


  return (
    <Container fluid className="main-content-container px-4 py-4">
      <div noGutters
           className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
        <form className="row mx-0" onSubmit={onSubmit}>
          <div className="d-flex align-items-center p-2">
            <FormInput
              name='policyId'
              value={policyId}
              onChange={onChange}
              placeholder="Policy ID"
            />
          </div>
          <div className="d-flex align-items-center p-2">
            <FormInput
              name='policyName'
              value={policyName}
              onChange={onChange}
              placeholder="Policy Name"
            />
          </div>
          <div className="d-flex align-items-center p-2">
            <FormSelect name='leaveCarryover' value={leaveCarryover}
                        onChange={onChange}>
              <option value=''>Leave Carryover</option>
              {leaveData}
            </FormSelect>
          </div>
          <div className="d-flex align-items-center p-2">
            <FormSelect name='paidTimeOff' value={paidTimeOff}
                        onChange={onChange}>
              <option value=''>Paid Time-Off</option>
              {leaveData}
            </FormSelect>
          </div>
          <div className="d-flex align-items-center justify-content-end p-2">
            <Button type="submit" className="mx-2 py-2 my-2" size='sm'
                    theme="accent">Search</Button>
          </div>
        </form>
      </div>
      <CustomTable
        pageSizeOptions={pageSizeOptions}
        pageSize={pageSize}
        onChange={onChange}
        tableColumns={tableColumns}
        AddButton="Add Leave Policy"
        addButtonOnClick={clear_leave_policy_caches}
        addPath="/sub-add-leave-policy/0"
        tableData={leavePolicyList}
        showHeader/>
    </Container>
  )
}

SubLeavePolicy.propTypes = {
  leavePolicyList: PropTypes.array.isRequired,
  get_search_leave_policy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  leavePolicyList: state.user.leavePolicyList
});

export default connect(
  mapStateToProps, {
    get_search_leave_policy,
    clear_leave_policy_caches,
  })(SubLeavePolicy);
