import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col, Container, DatePicker,
  Form, FormInput, FormSelect,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {useHistory, useParams, useLocation} from "react-router-dom";
import loadingGif from '../../images/loading-gif.gif';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
  get_base_currency,
  exp_get_currency_list,
} from "../../redux/actions/user";
import {setAPIDate, setInputDate} from "../../utils/date";
import {RECEIPT_FILE_SIZE_LIMIT, RECEIPT_ALL_FILE_SIZE_LIMIT} from "../../utils/constants";
import {monetaryToNumber, numberToMonetary, bytesToMB} from "../../utils/general";
import {
  clear_user_expense_item_receipt_file,
  get_expense_type_list,
  get_user_expense_item, get_user_expense_item_receipt_file,
  save_user_expense_item, upload_receipt, reset_save_item
} from "../../redux/actions/worker/expenses";
import {setAlert} from "../../redux/actions/alert";
import InfoTooltip from "../../components/common/InfoTooltip";
import {b64toBlob} from "../../utils/file";

function EditExpenseItem({
                           user,
                           get_user_expense_item,
                           expenseItem,
                           baseCurrency,
                           get_expense_type_list,
                           exp_get_currency_list,
                           reset_save_item,
                           expenseCategoryList,
                           currencyList,
                           save_user_expense_item,
                           get_base_currency,
                           receiptFile,
                           receiptFileName,
                           clear_user_expense_item_receipt_file,
                           get_user_expense_item_receipt_file,
                           upload_receipt,
                           setAlert,
                           sheetRefNum,
                           subscriberId,
                           savedItem
                         }) {

  const history = useHistory();
  const location = useLocation();
  
  let {refNumber, sheetRefNumber} = useParams();
  sheetRefNumber = sheetRefNum || sheetRefNumber
  const [saveReturn, setSaveReturn] = useState(false)


  let initialState = {
    expenseDate: '',
    type: '',
    status: '',
    description: '',
    currency: '',
    baseCurrency,
    grossAmount: "",
    taxAmount: "",
    receiptAmount: "",
    exchangeRate: "",
    netAmount: "",
    fileName: '',
    fileData: null,
    uploading: false,
    receiptBlob: "",
    fileInputKey:(new Date().getTime()),
    fileSize:0
  };
  const [formData, setFormData] = useState(initialState);

  function resetState() {
    setFormData({
      ...initialState,
      fileInputKey: (new Date().getTime())
    })
  }


  useEffect(() => {
    if (Object.keys(expenseItem).length !== 0) {
      setFormData({
        ...formData,
        expenseDate: setAPIDate(expenseItem.expenseDate),
        type: expenseItem.type,
        status: expenseItem.status,
        description: expenseItem.description,
        currency: expenseItem.currency,
        baseCurrency: expenseItem.baseCurrency,
        grossAmount: numberToMonetary(expenseItem.grossAmount),
        taxAmount: numberToMonetary(expenseItem.taxAmount),
        receiptAmount: numberToMonetary(expenseItem.receiptAmount),
        exchangeRate: numberToMonetary(expenseItem.exchangeRate, 5),
        netAmount: numberToMonetary(expenseItem.netAmount),
        fileName: expenseItem.receiptFileName,
        receiptBlob: ""
      });
    }
  }, [expenseItem]);

  useEffect(() => {
    if (receiptFile) {
      setFormData({
        ...formData,
        fileName: receiptFileName || formData.fileName,
        uploading: false,
        receiptBlob: b64toBlob(receiptFile)
      })
    }
  }, [receiptFileName, receiptFile])


  useEffect(() => {
    let receiptAmount = numberToMonetary(monetaryToNumber(formData.grossAmount) + monetaryToNumber(formData.taxAmount))
    setFormData({
      ...formData,
      receiptAmount: (receiptAmount && receiptAmount !== 'NaN') ? receiptAmount : 0,
    })
  }, [formData.grossAmount, formData.taxAmount]);

  useEffect(() => {
    let netAmount = numberToMonetary(monetaryToNumber(formData.receiptAmount) * (monetaryToNumber(formData.exchangeRate) || 1))
    setFormData({
      ...formData,
      netAmount: (netAmount && netAmount !== 'NaN') ? netAmount : 0
    })
  }, [formData.receiptAmount, formData.exchangeRate]);

  useEffect(() => {
    if (baseCurrency.currencyCode === formData.currency) {
      setFormData({...formData, exchangeRate: "1.00000"})
    }
  }, [baseCurrency.currencyCode, formData.currency]);


  if (expenseItem.refNum != refNumber) {
    expenseItem = {};
  }

  useEffect(() => {
    if (refNumber && refNumber !== '0') {
      get_user_expense_item(refNumber);
    }
  }, [refNumber]);


  useEffect(() => {
    get_expense_type_list();
    exp_get_currency_list(subscriberId);
    get_base_currency();
    return () => {
      clear_user_expense_item_receipt_file();
      reset_save_item();
    }
  }, []);

  useEffect(() => {
    if (expenseItem && expenseItem.receiptFileName) {
      get_user_expense_item_receipt_file(user.userId, refNumber);
    }
  }, [expenseItem])


  if (expenseCategoryList !== [] || currencyList !== []) {
    var expenseCategoryListItems = expenseCategoryList.map((catergory) => {
      return <option key={catergory.expenseType + catergory.expenseTypeId}
        value={catergory.expenseType}>{catergory.expenseType}</option>;
    });
    var currencyListItems = currencyList.map((currency) => {
      return (
        <option key={currency.currencyCode + currency.currencyId}  value={currency.currencyCode}>
          {currency.currencySymbol + " " + currency.currencyCode}
        </option>
      );
    });
  }

  let receiptDoc = <img src={loadingGif}
                        style={{width: '50px'}}
                        alt="loader"/>;

  if ((refNumber == 0 || (formData.type && !formData.fileName)) && !formData.uploading)
    receiptDoc = <label>No receipt attached</label>

  if (formData.fileName.toLowerCase().endsWith(".pdf") && receiptFile && !formData.uploading)
    receiptDoc = <object
      className='receipt-viewer w-100 h-100 border receipt-viewer-pdf'
      type="application/pdf"
      data={formData.receiptBlob}
      title="Receipt Preview"/>

  if (!formData.uploading && (formData.fileName.toLowerCase().endsWith(".jpg") || formData.fileName.toLowerCase().endsWith(".jpeg") || formData.fileName.toLowerCase().endsWith(".png")))
    receiptDoc = <img
      className='receipt-viewer mh-75 mw-100'
      src={formData.receiptBlob}
      alt="receipt"/>


  const fileUploader = (e) => {
    const reader = new FileReader();
    const imageFile = e.target.files[0];
    if (imageFile.size > RECEIPT_FILE_SIZE_LIMIT) {
      setAlert("File size is greater than 2mb", "danger");
      return false
    }
    var totalReceiptFileSize = location.state ? location.state.totalFileSize : 0;
    var fileSizeMB = bytesToMB(imageFile.size)
    if (fileSizeMB + totalReceiptFileSize > RECEIPT_ALL_FILE_SIZE_LIMIT) {
      setAlert("File size is greater than 20mb", "danger");
      location.state.totalFileSize = fileSizeMB + totalReceiptFileSize;
      return false
    }
    if(!imageFile.name.toLowerCase().match(/\.(jpg|jpeg|png|pdf)$/))
    {
      setAlert("Please upload only pdf, jpg, jpeg and png file type", "danger");
      setFormData({
        ...formData,
        fileName: '',
        receiptBlob: ""
      })
      return false
    }
    if(!imageFile.name.toLowerCase().match(/\.(pdf)$/))
    {
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        upload_receipt(imageFile, sheetRefNum,user.userId);
        setFormData({
            ...formData,
            fileData: imageFile,
            uploading: true,
            fileSize:imageFile.size
        });
      };
      img.onerror = () => {
        setAlert("Invalid file content", "danger");
        setFormData({
          ...formData,
          fileName: '',
          receiptBlob: ""
        })
      };
      img.src = e.target.result;    
    }
  reader.readAsDataURL(imageFile);
  }
  else
  {
    upload_receipt(imageFile,sheetRefNum,user.userId);
        setFormData({
            ...formData,
            fileData: imageFile,
            uploading: true,
            fileSize:imageFile.size
        });
  }
  };

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onMonetaryAmountChanged = (e) => {
    let maxFracDigits = (e.target.getAttribute("data-maxFracDigits") || 2);
    let value = e.target.value.replace(/^\D+/g, '');
    let values = value.split('.');
    if (values.length > 1)//if value contains fraction digits
      value = monetaryToNumber(values[0]).toLocaleString() + "." + values[1].replace(/\D/g, '').substr(0, maxFracDigits)
    else
      value = monetaryToNumber(value).toLocaleString()
    setFormData({
      ...formData,
      [e.target.name]: (value && value !== 'NaN') ? value : 0
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formData.uploading)
      return false
    save_user_expense_item(
      user.userId,
      sheetRefNumber,
      refNumber,
      setInputDate(formData.expenseDate),
      formData.type,
      formData.description,
      formData.currency,
      formData.baseCurrency,
      monetaryToNumber(formData.grossAmount),
      monetaryToNumber(formData.taxAmount),
      monetaryToNumber(formData.receiptAmount),
      monetaryToNumber(formData.exchangeRate),
      monetaryToNumber(formData.netAmount),
      '',// taxableFlag,
      formData.fileName
    );

    if (e.nativeEvent.submitter.name === "saveReturn"){//history.goBack();
     
        setSaveReturn(true)
      }
    else {
      history.replace(`/add-expense-item/${sheetRefNumber}/0`,location.state);
      resetState();
    }
  };

  useEffect(()=>{
    if(savedItem && saveReturn) history.goBack();
    }, [savedItem, saveReturn])

  useEffect(() => {
    //custom validation
    if (monetaryToNumber(formData.grossAmount) <= 0)
      document.getElementById("grossAmount").setCustomValidity('Gross amount should be greater than zero');
    else
      document.getElementById("grossAmount").setCustomValidity('');

    if (monetaryToNumber(formData.exchangeRate) <= 0)
      document.getElementById("exchangeRate").setCustomValidity('Exchange rate should be greater than zero');
    else
      document.getElementById("exchangeRate").setCustomValidity('');

  });

  return (
    <Container fluid className="main-content-container p-4">
      <Card small className="mb-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {refNumber}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col
                      className={"col-12 col-lg-6"}>
                      <Row form>
                        <Col sm="12" md="6"
                             lg="6"
                             className="form-group p-2 m-0">
                          <label htmlFor="expenseDate">Date</label>
                          <div>
                            <DatePicker
                              id='expenseDate'
                              autoComplete='off'
                              name='expenseDate'
                              dateFormat='yyyy-MM-dd'
                              value={formData.expenseDate}
                              selected={formData.expenseDate}
                              required
                              onChange={(date) =>
                                setFormData({...formData, expenseDate: date})
                              }
                              placeholderText="YYYY-MM-DD"
                            />
                          </div>
                        </Col>


                        <Col sm="12" md="6"
                             lg="6"
                             className="form-group p-2 m-0">
                          <label htmlFor="type">Expense Category</label>
                          <FormSelect id="type"
                                      name='type'
                                      value={formData.type}
                                      onChange={onChange}
                                      required
                                      className='d-block'>
                            <option value=''>Select Expense Category</option>
                            {expenseCategoryListItems}
                          </FormSelect>
                        </Col>

                        <Col sm="12"
                             className="form-group p-2 m-0">
                          <label htmlFor="description">Description</label>
                          <div>
                            <FormInput
                              id="description"
                              name='description'
                              required
                              value={formData.description}
                              onChange={onChange}
                              placeholder="Enter Description"
                              maxLength="100"
                            />
                          </div>
                        </Col>

                        <Col sm="12"
                             className="form-group p-2 pr-4 m-0">
                          <label htmlFor="grossAmount">Gross</label>
                          <InfoTooltip msg="For meals: add up meal sub-total and Tips here."/>
                          <div>
                            <FormInput
                              id="grossAmount"
                              className="w-50"
                              autocomplete="off"
                              required
                              placeholder="0.00"
                              name='grossAmount'
                              value={formData.grossAmount === 0 ? '' : formData.grossAmount}
                              onChange={onMonetaryAmountChanged}
                            />
                          </div>
                        </Col>

                        <Col sm="12"
                             className="form-group p-2 pr-4 m-0">
                          <label htmlFor="taxAmount">Tax Amount</label>
                          <div>
                            <FormInput
                              id="taxAmount"
                              className="w-50"
                              required
                              name='taxAmount'
                              placeholder="0.00"
                              value={formData.taxAmount === 0 ? '' : formData.taxAmount}
                              onChange={onMonetaryAmountChanged}
                            />
                          </div>
                        </Col>

                        <Col sm="12"
                             className="form-group p-2 pr-4 m-0">
                          <label htmlFor="receiptAmount">Receipt Total</label>
                          <div>
                            <FormInput
                              id="receiptAmount"
                              className="w-50"
                              required
                              name='receiptAmount'
                              placeholder="0.00"
                              value={formData.receiptAmount}
                              onChange={onMonetaryAmountChanged}
                              readOnly
                            />
                          </div>
                        </Col>


                        <Col sm="12" md="6"
                             lg="6"
                             className="form-group p-2 pr-4 m-0">
                          <label htmlFor="currency">Currency</label>
                          <FormSelect id="currency"
                                      name='currency'
                                      value={formData.currency}
                                      required
                                      onChange={onChange}
                                      className='d-block'>
                            <option value=''>Select Currency</option>
                            {currencyListItems}
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6"
                             lg="6"
                             className="form-group p-2 m-0">
                          <label htmlFor="exchangeRate">Exchange Rate</label>
                          <div>
                            <FormInput
                              id="exchangeRate"
                              data-maxFracDigits="5"
                              required
                              disabled={baseCurrency.currencyCode === formData.currency}
                              name='exchangeRate'
                              value={formData.exchangeRate === 0 ? '' : formData.exchangeRate}
                              onChange={onMonetaryAmountChanged}
                              placeholder="0.00000"
                            />
                          </div>
                        </Col>

                        <Col sm="12"
                             className="form-group p-2 pr-3 m-0">
                          <label htmlFor="netAmount">Net Amount</label>
                          <div>
                            <FormInput
                              id="netAmount"
                              required
                              className="w-50"
                              name='netAmount'
                              placeholder={(baseCurrency.currencySymbol ? baseCurrency.currencySymbol : "") + " " + '0.00'}
                              value={(baseCurrency.currencySymbol ? baseCurrency.currencySymbol : "") + " " + formData.netAmount}
                              onChange={onMonetaryAmountChanged}
                              readOnly
                            />
                          </div>
                        </Col>
                      
                        <Col sm="12" md="6"
                             lg="6"
                             className="form-group p-2 m-0">
                          <label htmlFor="receipt">
                          Receipt Upload 
                            </label>
                           
                          <InfoTooltip cssClassName="tooltipwidth text-left" Container="" target="#tooltipBox" id="tooltipBox"
                            msg="<div className='row'>
                            <strong className='pl-3'>UPLOAD INSTRUCTIONS</strong>
                            <div className='col-12'><small className='text-bold'>1) Receipts should be 2MB or less.</small></div>
                            <div className='col-12'><small className='text-bold'>2) Expense Reports should be 20MB or less.</small></div>
                            <div className='col-12'><small className='text-bold'>3) Acceptable File formats are PDF, JPEG and PNG.</small></div>
                           </div>" />
                          <div>
                          
                            <div className='file-upload-wrapper'>
                              <input
                                key={formData.fileInputKey}
                                autoComplete='off'
                                name='file-upload-field'
                                type='file'
                                className='file-upload-field'
                                id='receipt'
                                onChange={fileUploader}
                              />
                            </div>
                          </div>
                        </Col>

                      </Row>
                    </Col>


                    <Col
                      className="form-group col-12 col-lg-6 p-2 px-3 m-0 d-flex flex-column">
                      <label>Receipt</label>
                    
                      <div
                        className="d-flex align-items-center justify-content-center w-100 h-100 border">
                        {receiptDoc}
                      </div>

                    </Col>

                  </Row>
                  <Row className="px-3">
                    <Col sm="12" md="12" lg="12"
                         className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

                      {(!expenseItem.status || ["Saved", "Rejected", "Draft"].includes(expenseItem.status)) && <>
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="accent" name="saveAddAnother"
                                disabled={formData.uploading}>Save and Add
                          Another</Button>
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="accent" name="saveReturn"
                                disabled={formData.uploading}>Save and
                          Return</Button></>}


                      <Button onClick={e => history.goBack()}
                              className="mx-1 py-2 mx-md-2" size="sm"
                              theme="accent">Cancel</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}

EditExpenseItem.propTypes = {
  user: PropTypes.object.isRequired,
  expenseItem: PropTypes.object.isRequired,
  get_user_expense_item: PropTypes.func.isRequired,
  get_expense_type_list: PropTypes.func.isRequired,
  exp_get_currency_list: PropTypes.func.isRequired,
  expenseCategoryList: PropTypes.array.isRequired,
  currencyList: PropTypes.array.isRequired,
  save_user_expense_item: PropTypes.func.isRequired,
  receiptFile: PropTypes.any.isRequired,
  receiptFileName: PropTypes.string.isRequired,
  upload_receipt: PropTypes.func.isRequired,
  sheetRefNum: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  expenseItem: state.expenses.expenseItem,
  currencyList: state.user.currencyList,
  expenseCategoryList: state.expenses.expenseCategoryList,
  receiptFile: state.expenses.receiptFile,
  receiptFileName: state.expenses.receiptFileName,
  sheetRefNum: state.expenses.sheetRefNum,
  baseCurrency: state.user.baseCurrency,
  subscriberId: state.user.user.userRoles[0].domainId,
  savedItem: state.expenses.savedItem

});

export default connect(mapStateToProps, {
  get_base_currency,
  get_user_expense_item,
  get_expense_type_list,
  exp_get_currency_list,
  save_user_expense_item,
  clear_user_expense_item_receipt_file,
  get_user_expense_item_receipt_file,
  reset_save_item,
  upload_receipt,
  setAlert
})(EditExpenseItem);

