import React, {useEffect, useState} from 'react';
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, Form,
  FormSelect, FormTextarea, ListGroup, ListGroupItem,
  Row
} from "shards-react";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import ReactTable from "react-table";
import {
  setDateForTableInHrMinSec
} from "../../utils/date";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
  clear_note_lists,
  delete_client_note,
  get_client_note_details, get_client_note_list,
  save_client_note
} from "../../redux/actions/subscriber/subscriberUsers";
import queryString from "querystring";

function SubscriberAddEditClientNotes({
                                        noteList,
                                        note,
                                        get_client_note_list,
                                        save_client_note,
                                        get_client_note_details,
                                        delete_client_note,
                                        clear_note_lists
                                      }) {

  let location = useLocation();
  let {id, refNum} = useParams();
  let queryParams = queryString.parse(location.search.replace("?", ""))
  const [state, setState] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
    noteText:""
  })

  let history = useHistory();

  useEffect(() => {
    if ((id !== null && id !== '' && id !== '0' && id !== 0)) {
          get_client_note_list(0,id);
        }
    return ()=>{
      clear_note_lists()
    }
  }, [id]);

  useEffect(() => {
    if (refNum !== "0")
      get_client_note_details(id, refNum)
    else
      setState({
        ...state,
        noteText: '',
      });
  }, [refNum]);


  useEffect(() => {
    if (note) {
      setState({
        ...state,
        noteText: note.noteText,
      });
    }
  }, [note]);


  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    save_client_note(
      id,
      refNum,
      state.noteText
    );
  };

  const onDeleteClick = async (e) => {
    e.preventDefault();
    await delete_client_note(id, refNum);
    history.replace("/edit-client-notes/" + id + "/0?clientName="+queryParams.clientName)
  };


  const tableData = noteList;
  const {pageSize, pageSizeOptions} = state;


  const tableColumns = [
    {
      Header: "Date Time",
      accessor: "date",
      maxWidth: 180,
      Cell: row =>
        setDateForTableInHrMinSec(row.original.date),
    },
    {
      Header: "User",
      accessor: "userName",
      maxWidth: 160,
      className: "justify-content-start text-left text-wrap"
    },
    {
      Header: "Note",
      accessor: "noteText",
      className: "justify-content-start",
    },

    {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 100,
      headerClassName: "shadow-none",
      Cell: row =>
        <Link onClick={e => {
          e.preventDefault();
          history.replace("/edit-client-notes/" + id + "/" + row.original.refNum+"?clientName="+queryParams.clientName)
        }}>{row.original.refNum}</Link>
    },
  ];

  return (
    <Container fluid className="main-content-container px-4 p-4">

      <Card className="p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col xl="4" lg="3"
                   className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6 order-lg-0">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col xl="2" lg="3"
                   className="file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6 order-lg-3">

                <Button className="mx-2 py-2" size='sm'
                        theme="accent"
                        onClick={e => history.replace("/edit-client-notes/" + id + "/0?clientName="+queryParams.clientName)}>Add
                  New Note</Button>
              </Col>

              <Col lg={{size: 3, offset: 3}}
                   className="d-flex align-items-center text-nowrap mt-2 mt-lg-0 col-12 order-lg-1">
                <label className="m-0">Client Name:</label>
                <span className='px-2'>{queryParams.clientName}</span>
              </Col>

            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              sorted={[{id: 'refNum', desc: true}]}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>

      <Card small className="my-4 w-100">
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form onSubmit={onSubmit}>
                  <Row form>
                    <Col sm="12" md="12" className="form-group p-2 m-0">
                      <label htmlFor="noteText">Note - Ref # {refNum}</label>
                      <FormTextarea
                        id="noteText"
                        rows="10"
                        name="noteText"
                        value={state.noteText}
                        onChange={e => {
                          setState({
                            ...state,
                            noteText: e.target.value
                          });
                        }}
                      />
                    </Col>

                  </Row>
                  <Row className="px-3">
                    <Col sm="12" md="12" lg="12"
                         className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              theme="accent">{(refNum==="0"?"Add":"Save")}</Button>
                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              theme="accent" disabled={refNum === "0"}
                              onClick={onDeleteClick}>Delete</Button>
                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              theme="accent"
                              onClick={history.goBack}>Cancel</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}


SubscriberAddEditClientNotes.propTypes = {
  user: PropTypes.object.isRequired,
  get_client_note_list: PropTypes.func.isRequired,
  noteList: PropTypes.array.isRequired,
  get_client_note_details: PropTypes.func.isRequired,
  save_client_note: PropTypes.func.isRequired,
  delete_client_note: PropTypes.func.isRequired,
  note: PropTypes.object.isRequired,

};
const mapStateToProps = (state) => ({
  user: state.user.user,
  noteList: state.subscriberUsers.noteList,
  note: state.subscriberUsers.note,
});
export default connect(mapStateToProps, {
  get_client_note_list,
  get_client_note_details,
  save_client_note,
  delete_client_note,
  clear_note_lists
})(SubscriberAddEditClientNotes);
