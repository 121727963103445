import React, {useState, useEffect} from 'react'
import CustomTable from '../../common/CustomTable'
import {
  get_client_leave_policy_list,
  get_client_leave_policy_detail, clear_client_leaves_list
} from '../../../redux/actions/user';
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import {delete_client_leave_policy_details} from "../../../redux/actions/subscriber/leavePolicy";

function Leaves({
                  subscriberId,
                  clientId,
                  clientName,
                  confirmAlert,
                  setConfirmAlert,
                  get_client_leave_policy_list,
                  leavePolicyList,
                  delete_client_leave_policy_details,
                  get_client_leave_policy_detail,
                  setAlert,
                  clear_client_leaves_list
                }) {
  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,
  });
  const {pageSize, pageSizeOptions} = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source();
    if (clientId && clientId !== '0') {
      get_client_leave_policy_list(subscriberId, clientId, cancelTokenSource.token);
    }

    return () => {
      cancelTokenSource.cancel();
      clear_client_leaves_list();
    }
  }, [])

  const tableColumns = [
    {
      Header: "ID",
      accessor: "leavePolicyId",
      maxWidth: 200,
      className: "text-center",
      Cell: row =>
        <Link
          to={`/sub-client-customize-leave-policy/${clientId}/${row.original.leavePolicyId}`}>{row.original.leavePolicyId}</Link>
    },
    {
      Header: "Leave Policy Name",
      accessor: "policyName",
      className: "text-center"
    },
    {
      Header: "Entitled Leaves",
      accessor: "entitledDays",
      className: "justify-content-end"
    },
    {
      Header: "Action",
      accessor: "leavePolicyId",
      className: "text-center",
      Cell: row =>
        <Link onClick={() => {
          setConfirmAlert({
            ...confirmAlert,
            confirmMsg: 'Are you sure you want to delete this leave policy?',
            visible: true,
            onConfirm: () => {              
              delete_client_leave_policy_details(clientId, row.original.leavePolicyId);
            }
          })

        }}>Delete</Link>
    }
  ];

  const history = useHistory();
  let addLeavePolicyHandler = (e) => {
    e.preventDefault();
    if (clientId && clientId != "0")
      history.push({
        pathname: `/sub-client-customize-leave-policy/${clientId}/0`,
        state: {clientName: clientName}
      });
    else
      setAlert("First save client profile and then try again.", "danger");
  };
  return (
    <div>
      <CustomTable
        pageSizeOptions={pageSizeOptions}
        pageSize={pageSize}
        onChange={onChange}
        tableColumns={tableColumns}
        tableData={leavePolicyList}
        roundedTop
        addButtonOnClick={addLeavePolicyHandler}
        AddButton="Add Leave Policy"
        showHeader
        headerLabel="Leave Policies"
        headerIconClass="fa fa-calendar-alt iconStyle"
      />
    </div>
  )
}

Leaves.propTypes = {
  leavePolicyList: PropTypes.array.isRequired,
  get_client_leave_policy_list: PropTypes.func.isRequired,
  delete_client_leave_policy_details: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  leavePolicyList: state.user.leavePolicyList
});

export default connect(
  mapStateToProps, {
    get_client_leave_policy_list,
    delete_client_leave_policy_details,
    get_client_leave_policy_detail,
    clear_client_leaves_list
  })(Leaves);
