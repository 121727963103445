import React, {useEffect, useState} from 'react';
import {Col, Nav} from "shards-react";

function TabBar({hiddenSections}) {
  const [componentState, setComponentState] = useState({
    activeSection: '',
  })

  let sections=[
    {
      id:"personal",
      title:"Personal",
    },

    {
      id:"contact",
      title:"Contact",
    },

    {
      id:"employment",
      title:"Employment",
    },
    {
      id:"compensation",
      title:"Compensation",
    },

    // {
    //   id:"allowances",
    //   title:"Allowances",
    // },

    {
      id:"leaves",
      title:"Leaves",
    },

    {
      id:"benefits",
      title:"Benefits",
    },

    {
      id:"perks",
      title:"HSA",
    },

    {
      id:"pension",
      title:"Retirement Plan",
    },

    {
      id:"userJourney",
      title:"User Journey",
    },
  ].filter(section=>!hiddenSections.includes(section.id))

  useEffect(() => {
    if(!componentState.activeSection)
      setComponentState({
        ...componentState,
        activeSection: sections.length?sections[0].id:'personal'
      })
  }, [componentState.activeSection]);


  const scrollInto = (id) => {
    document.getElementById(id).getElementsByClassName('section-title')[0].scrollIntoView();
    setComponentState({...componentState, activeSection: id})
  }

  const isSectionVisible = (rect) => {
    return (rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) - 100) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth))
  }

  const setActiveSection = () => {
    for (let i = 0; i < sections.length; i++) {
      let rect = document.querySelector(`#${sections[i].id} .section-title`).getBoundingClientRect()
      if (isSectionVisible(rect)) {
        if (componentState.activeSection !== sections[i].id) {
          setComponentState({...componentState, activeSection: sections[i].id})
        }
        break
      }
    }
  }

  useEffect(() => {
    window.onscroll = () => {
      setActiveSection()
    }
    return () => {
      window.onscroll = null
    }
  }, [hiddenSections,componentState.activeSection]);


  const sectionLinks=sections.map(section=>{
    return <a
      className={'nav-link ' + (componentState.activeSection === section.id ? 'active' : '')}
      onClick={() => scrollInto(section.id )}>{section.title}</a>
  })

  return (
    <Col sm={12} md={8} lg={9} xl={10}
         className='wrapper d-flex mx-md-2 align-items-end order-md-0'>
      <Nav tag="nav" className="flex-nowrap overflow-auto">
        {sectionLinks}
      </Nav>
    </Col>
  );
}
export default TabBar;
