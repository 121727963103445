import React, { useEffect, useState } from 'react'
import InputBox from '../../components/common/InputBox'
import { useHistory, useParams } from 'react-router-dom';
import classNames from "classnames";
import {
  Alert,
  Card,
  Col,
  Container, Form, FormInput, ListGroup, ListGroupItem,
  Row
} from "shards-react";
import { connect } from 'react-redux';
import {
  save_distance_unit_details,
  get_distance_unit_details,
  delete_distance_details


} from '../../redux/actions/user';
import PropTypes from 'prop-types';
import ConfirmAlert from "../../components/common/ConfirmAlert";
import InfoTooltip from "../../components/common/InfoTooltip";
import { setAlert } from '../../redux/actions/alert';

function AddEditDistanceUnit({

  DistanceUnitDetails,
  save_distance_unit_details,
  get_distance_unit_details,
  delete_distance_details,
  IsDistanceDeleted,
  IsDistanceSaved,
  setAlert

}) {
  let { id } = useParams();
  const history = useHistory();
  const [Deleted, setDeleted] = useState(IsDistanceDeleted);
  const [Saved, setSaved] = useState(IsDistanceSaved);
  const [IsReturn, setIsReturn] = useState("");
  const { className } = "";
  const classes = classNames(className, "d-flex", "my-auto", "date-range");

  useEffect(() => {
    if (id !== null && id !== '' && id !== '0' && id !== 0) {

      get_distance_unit_details(id);
    } else {
      setFormData({
        ...formData,
        distanceUnitId:'',
        distanceUnits: ''
      });
    }

  }, [id]);
  const [formData, setFormData] = useState({
    distanceUnitId: '',
    distanceUnits: ''

  });

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });




  useEffect(() => {
    if (DistanceUnitDetails && Object.keys(DistanceUnitDetails).length != 0 && id != 0) {
      setFormData({
        ...formData,
        distanceUnitId: DistanceUnitDetails.distanceUnitId,
        distanceUnits: DistanceUnitDetails.unit,

      });
    }
  }, [DistanceUnitDetails]);
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (IsDistanceDeleted && IsDistanceDeleted != 0) {
      if (Deleted != IsDistanceDeleted) {

        setDeleted(IsDistanceDeleted)

        history.goBack();
      }

    }
  }, [IsDistanceDeleted]);
  useEffect(() => {
    if (IsDistanceSaved && IsDistanceSaved != 0) {
      if (Saved != IsDistanceSaved) {

        setSaved(IsDistanceSaved)
        if (IsReturn && IsReturn === "yes") {
          history.goBack();
        } else {

          history.replace(`/sub-add-distance-unit/0`);
          setFormData({
            ...formData,
            distanceUnits: ''
          })
        }

      }

    }
  }, [IsDistanceSaved]);
  let onDelete = (e) => {
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete this distance unit',
      visible: true,
      onConfirm: () => {
        delete_distance_details(id);
      }
    })
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (formData.distanceUnits && formData.distanceUnits != '') {
      save_distance_unit_details(id, formData.distanceUnits);
      if (e.nativeEvent.submitter.name === "saveReturn") {
        setIsReturn("yes");

      }
      else {
        setIsReturn("no");
      }
    }
    else {

      setAlert("Please enter Distance Unit", "danger");
    }
  };
  return (
    <>


      <Container fluid className="main-content-container p-4">
        <ConfirmAlert confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert} />
        <Card small className="mb-5 w-100">
          <h4 className="m-0 section-title idStyle">Ref# {id > 0 ? id : 'New'}</h4>
          <ListGroup flush>
            <ListGroupItem className="border-card p-3">
              <Row>
                <Col sm="12" md="12" lg="12">
                  <Form id="clientDetailsForm" onSubmit={onSubmit}>
                    <Row>


                      <Col lg="3" className="form-group p-2 mb-0">
                        <label htmlFor="Distance Unit">Distance Unit</label>
                        <span aria-hidden="true" className="required">*</span>
                        <InfoTooltip
                          msg="The Measurement unit for Distance travelled" />
                        <FormInput placeholder="Enter Distance Unit" name='distanceUnits' id='distanceUnits'
                          value={formData.distanceUnits}
                          onChange={onChange} />
                      </Col>
                      <Col lg="9" className="mt-4 pt-3">
                        {id == 0 &&
                          <button type="submit" className="btn btn-primary " name="saveAddAnother" disabled={formData.uploading}>Save and Add Another</button>
                        }
                        <button type="submit" className="btn btn-primary ml-2 " name="saveReturn" disabled={formData.uploading}>Save and Return</button>
                        {id > 0 &&
                          <button className="btn btn-primary ml-2 " name="delete" type="button" onClick={onDelete}>Delete</button>
                        }
                        <button onClick={(e) => {
                          e.preventDefault();
                          history.goBack();
                        }} className="btn btn-primary ml-2 ">Cancel</button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>



    </>
  )
}

AddEditDistanceUnit.propTypes = {

  get_distance_unit_details: PropTypes.func.isRequired,
  save_distance_unit_details: PropTypes.func.isRequired,
  delete_distance_details: PropTypes.func.isRequired,
  IsDistanceDeleted: PropTypes.number.isRequired,
  IsDistanceSaved: PropTypes.number.isRequired,

};
const mapStateToProps = (state) => ({
  DistanceUnitDetails: state.user.DistanceUnitDetails,
  subscriberId: state.user.user.userRoles.filter(item => item.role === "Subscriber Admin")[0].domainId,
  IsDistanceDeleted: state.user.IsDistanceDeleted,
  IsDistanceSaved: state.user.IsDistanceSaved,
});
export default connect(mapStateToProps, {
  save_distance_unit_details,
  get_distance_unit_details,
  delete_distance_details,
  setAlert
})(AddEditDistanceUnit);
