import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col, Container, DatePicker,
  Form, FormInput,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import PropTypes from 'prop-types';
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {setAPIDate, setInputDate} from "../../utils/date";
import {
  get_mileage_rate
} from "../../redux/actions/worker/expenses";
import {get_sub_mileage_item, save_sub_mileage_item} from "../../redux/actions/subscriber/subExpenses";
import StandardFormInput from "../../components/common/StandardFormInput";
import {STANDARD_FORMATS} from "../../utils/constants";
import InfoTooltip from "../../components/common/InfoTooltip";

function SubEditMileageItem({
                              user,
                              get_mileage_rate,
                              mileageRate,
                              mileageItem,
                              get_sub_mileage_item,
                              save_sub_mileage_item,
                              sheetRefNum,
                              changePageMeta,
                              title
                            }) {

  let initialState={
    tripDate: '',
    fromLocation: '',
    toLocation: '',
    tripDescription: '',
    distanceDriven: '',
    distanceUnit:'',
    appliedRate: "0.00",
    amount: "0.00",
    taxableFlag: 0,
  };
  const [formData, setFormData] = useState(initialState);
  function resetState() {
    setFormData({
      ...initialState,
      appliedRate: mileageRate.toFixed(2)
    })
  }

  const history = useHistory();
  let {userId,refNumber, sheetRefNumber} = useParams();
  sheetRefNumber=sheetRefNum || sheetRefNumber;
  const location = useLocation();

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if(location.state)
      changePageMeta(`${title} - ${location.state.workerName}`)
  }, []);

  useEffect(() => {
    if (Object.keys(mileageItem).length !== 0 && mileageItem.refNum == refNumber) {
      setFormData({
        ...formData,
        tripDate: setAPIDate(mileageItem.tripDate),
        fromLocation: mileageItem.fromLocation,
        toLocation: mileageItem.toLocation,
        tripDescription: mileageItem.tripDescription,
        distanceUnit:mileageItem.distanceUnit,
        distanceDriven: mileageItem.distanceDriven.toFixed(2),
        appliedRate: (refNumber === '0') ? mileageRate : mileageItem.appliedRate,
        amount: mileageItem.amount,
        taxableFlag: 0,
      });
    }
  }, [mileageItem]);

  useEffect(() => {
    setFormData({
      ...formData,
      amount: formData.distanceDriven * formData.appliedRate
    })
  }, [formData.distanceDriven]);

  useEffect(() => {
    if (refNumber === '0') {
      setFormData({
        ...formData,
        appliedRate: (mileageRate) ? mileageRate.toFixed(2) : 0
      })
    }
  }, [mileageRate]);


  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onChangeStandardInput = (name,value) => {
    setFormData({...formData, [name]: value});
  };

  useEffect(() => {
    get_mileage_rate(userId);
  }, []);

  useEffect(() => {
    if (refNumber && refNumber !== '0') {
      get_sub_mileage_item(refNumber);
    }
  }, [refNumber]);

  const onSubmit = (e) => {
    e.preventDefault();
    save_sub_mileage_item(
      userId,
      sheetRefNumber,
      refNumber,
      setInputDate(formData.tripDate),
      formData.fromLocation,
      formData.toLocation,
      formData.tripDescription,
      formData.distanceDriven,
      formData.appliedRate,
      formData.amount,
      formData.taxableFlag
    );

    if (e.nativeEvent.submitter.name === "saveReturn")
      history.goBack();
    else{
      history.replace(`/sub-add-mileage-item/${userId}/${sheetRefNumber}/0`);
      resetState();
    }
  };

  return (
    <Container fluid className="main-content-container p-4">
      <Card small className="mb-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {refNumber}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form onSubmit={onSubmit}>
                  <Row form>


                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="tripDate">Trip Date</label>
                      <div>
                        <DatePicker
                          id='tripDate'
                          autoComplete='off'
                          name='tripDate'
                          dateFormat='yyyy-MM-dd'
                          selected={formData.tripDate}
                          required
                          onChange={(date) =>
                            setFormData({...formData, tripDate: date})
                          }
                          value={formData.tripDate}
                          placeholderText="YYYY-MM-DD"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="tripDescription">Trip Description</label>
                      <div>
                        <FormInput
                          id="tripDescription"
                          name='tripDescription'
                          required
                          value={formData.tripDescription}
                          onChange={onChange}
                          placeholder="Enter Trip Description"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="fromLocation">From</label>
                      <div>
                        <FormInput
                          id="fromLocation"
                          name='fromLocation'
                          value={formData.fromLocation}
                          onChange={onChange}
                          required
                          placeholder="Enter From"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="toLocation">To</label>
                      <div>
                        <FormInput
                          id="toLocation"
                          name='toLocation'
                          value={formData.toLocation}
                          onChange={onChange}
                          required
                          placeholder="Enter To"
                        />
                      </div>
                    </Col>

                    <StandardFormInput
                      id="distanceDriven"
                      name='distanceDriven'
                      label="Distance Travelled"
                      standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                      dataMaxFracDigits={2}
                      type="text"
                      value={formData.distanceDriven}
                      onChangeStandardInput={onChangeStandardInput}
                      required
                      placeholder="Enter Kilometers Driven"
                    />

<Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="distanceUnit">Distance Unit</label>
                      <InfoTooltip
                      msg="Default Base Distance Unit Configured" />
                      <div>
                        <FormInput
                          id="distanceUnit"
                          name='distanceUnit'
                          value={formData.distanceUnit}
                          onChange={onChange}
                          readOnly
                          placeholder="Enter Distance Unit"
                        />
                      </div>
                    </Col>
                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="appliedRate">Applied Rate</label>
                      <div>
                        <FormInput
                          id="appliedRate"
                          name='appliedRate'
                          value={formData.appliedRate}
                          onChange={onChange}
                          readOnly
                          placeholder="Enter Applied Rate"
                        />
                      </div>
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="amount">Amount</label>
                      <div>
                        <FormInput
                          id="amount"
                          name='amount'
                          value={formData.amount.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}
                          onChange={onChange}
                          readOnly
                          placeholder="Enter Estimated Amount"
                        />
                      </div>
                    </Col>

                  </Row>
                  <Row className="px-3">
                    <Col sm="12" md="12" lg="12"
                         className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              theme="accent" name="saveAddAnother">Save and Add
                        Another</Button>
                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              theme="accent" name="saveReturn">Save and
                        Return</Button>
                      <Button onClick={e => history.goBack()}
                              className="mx-1 py-2 mx-md-2" size="sm"
                              theme="accent">Cancel</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}

SubEditMileageItem.propTypes = {
  user: PropTypes.object.isRequired,
  get_mileage_rate: PropTypes.func.isRequired,
  mileageRate: PropTypes.number.isRequired,
  mileageItem: PropTypes.object.isRequired,
  get_user_expense_mileage_item: PropTypes.func.isRequired,
  save_user_expense_mileage_item: PropTypes.func.isRequired,
  sheetRefNum: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  mileageRate: state.expenses.mileageRate,
  mileageItem: state.subExpenses.subMileageItem,
  sheetRefNum: state.subExpenses.sheetRefNum,
});

export default connect(mapStateToProps, {
  get_mileage_rate,
  get_sub_mileage_item,
  save_sub_mileage_item,
})(SubEditMileageItem);

