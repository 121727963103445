import React from 'react'
import {
  Col,
} from "shards-react";
import InfoTooltip from "./InfoTooltip";

export default function InputBox({ label, subLabel, placeholder,onChange,name,value, id, type="text",toolTipMSG,readOnly, disabled,required,dataMaxFracDigits="2", className = "", align="left"}) {
  return (
    <Col lg="3" className="form-group p-2 mb-0">
      <label htmlFor={id === ""?name:id} className={className}>{label}{required && <span aria-hidden="true" className="required"> *</span>}</label>
      {(toolTipMSG)?<InfoTooltip msg={toolTipMSG}/>:""}
      <input id={id === ""?name:id} name={name} data-maxFracDigits={dataMaxFracDigits} value={value} type={type} onChange={onChange}  className={`form-control ${align==='right' && `align-input-amount-right`}`} readOnly={readOnly} disabled={disabled} placeholder={placeholder} required={required} />
      <span className= {type === "number"?"subLabel mr-4" : "subLabel"}>{subLabel}</span>
    </Col>
  )
}