import React, {useState} from 'react'
import ReactTable from "react-table";
import {Link} from "react-router-dom";


import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, Row,
  FormSelect
} from "shards-react";

export default function Currency({currencyList}) {

  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,
  });

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };


  const tableData = currencyList;
  var {pageSize, pageSizeOptions} = formData;

  const tableColumns = [
    {
      Header: "Currency Code",
      accessor: "currencyCode",
      className: "text-center",

    },
    {
      Header: "Currency Symbol",
      accessor: "currencySymbol",
    },

    {
      Header: "Description",
      accessor: "description",
      className: "justify-content-start",

    },
    {
      Header: "Ref #",
      accessor: "currencyId",
      maxWidth: 100,
      Cell: row =>
        <Link
          to={`/admin-edit-currency/${row.original.currencyId}`}>{row.original.currencyId}</Link>
    },
  ];
  return (
    <div className="position-relative">
      <span className="tableHeader">Currency</span>
      <Card className="tableCard p-0 border-card">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {
                /* Filters :: Page Size */
              }
              <Col
                className="file-manager__filters__rows d-flex align-items-center col-6">
                <span>Show</span>
                <FormSelect size="sm" name='pageSize' value={pageSize}
                            onChange={onChange}>
                  {pageSizeOptions.map((size, idx) => <option key={idx}
                                                              value={size}>
                    {size} rows
                  </option>)}
                </FormSelect>
              </Col>
              <Col
                className="file-manager__filters__search d-flex justify-content-end col-6">
                <Link to="/admin-add-currency/0">
                  <Button className="my-2 py-2 mx-2" size='sm' theme="accent">Add
                    Currency</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable columns={tableColumns}
                        data={tableData}
                        pageSize={Number(pageSize)}
                        showPageSizeOptions={false}
                        resizable={false}
                        noDataText="No results found"/>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}




