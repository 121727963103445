import * as React from 'react';
import {
  Col, FormInput, FormSelect
} from "shards-react";
import {useEffect} from "react";
import CustomCard from "../common/CustomCard";
import {STANDARD_FORMATS} from "../../utils/constants";
import StandardFormInput from "../common/StandardFormInput";

export const ContactInfo = ({
                              countryList,
                              regionList,
                              cityList,
                              preferredEmailList,
                              get_region_list,
                              get_city_list,
                              addressLine1,
                              addressLine2,
                              addressType,
                              cityId,
                              countryId,
                              emailPersonal,
                              emailWork,
                              linkedIn,
                              phoneHome,
                              phoneMobile,
                              phoneWork,
                              phoneWorkExt,
                              preferredEmail,
                              regionId,
                              onChange,
                              onChangeStandardInput,
                              zip,
                              queryParamsRole,
                              workerFlag
                            }) => {

  useEffect(() => {
    if (countryId) {
      get_region_list(countryId)
    }
  }, [countryId])

  useEffect(() => {
    if (regionId) {
      get_city_list(regionId)
    }
  }, [regionId])


  var preferredEmailListOption = preferredEmailList.map((email) => {
    return <option key={email} value={email}>{email}</option>;
  });

  var cityListOption = cityList.map((option) => {
    return <option key={option.cityId}
                   value={option.cityId}>{option.cityName}</option>;
  })
  var regionListOption = regionList.map((option) => {
    return <option key={option.stateProvId}
                   value={option.stateProvId}>{option.stateProvName}</option>;
  })
  var countryListOption = countryList.map((option) => {
    return <option key={option.countryId}
                   value={option.countryId}>{option.countryName}</option>;
  })

  return (
    <CustomCard id="contact" title="Contact Information"  icon={true} iconClass="fas fa-id-badge iconStyle" className={"userprofile mb-5 w-100 d-flex personalParent"} noform>
      
      {workerFlag && (<><Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="addressType">Address Type</label>
        
        <FormSelect name='addressType' 
                    
                    value={addressType}
                    onChange={onChange} className='d-block'>
          <option value=''>Select Address Type</option>
          <option value='Permanent'>Permanent</option>
          <option value='Temporary'>Temporary</option>
        </FormSelect>
      </Col>
      <Col lg="3" className="form-group m-0 p-2"></Col>
      <Col lg="3" className="form-group m-0 p-2"></Col>
      <Col lg="3" className="form-group m-0 p-2"></Col></>)


      }


      {workerFlag && <Col lg="6" className="form-group m-0 p-2">
        <label htmlFor="addressline1">Address Line 1</label>
        <FormInput
          id="addressline1"
          placeholder="Enter Address Line 1"
          value={addressLine1}
          name='addressLine1'
          onChange={onChange}
        />
      </Col>}

      {workerFlag && <Col lg="6" className="form-group m-0 p-2">
        <label htmlFor="addressline2">Address Line 2</label>
        <FormInput
          id="addressline2"
          placeholder="Enter Address Line 2"
          value={addressLine2}
          name='addressLine2'
          onChange={onChange}
        />
      </Col>}
     

      { workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="country">Country</label>
        <span aria-hidden="true" className="required"> *</span>
        <FormSelect name='countryId' 
                    required = {true}
                    value={countryId}
                    onChange={onChange} className='d-block'>
          <option value=''>Select Country Name</option>
          {countryListOption}
        </FormSelect>
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="state">State/Province of Residence</label>
        <span aria-hidden="true" className="required"> *</span>
        <FormSelect name='regionId'
                    required = {true}
                    value={regionId}
                    onChange={onChange} className='d-block'>
          <option value=''>Select State/Province</option>
          {regionListOption}
        </FormSelect>
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="city">City</label>
        <FormSelect name='cityId' value={cityId}
                    onChange={onChange} className='d-block'>
          <option value=''>Select City</option>
          {cityListOption}
        </FormSelect>
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="zipcode">ZIP/Postal Code</label>
        <FormInput
          id="zipcode"
          placeholder="Enter ZIP Code"
          value={zip}
          name='zip'
          onChange={onChange}
        />
      </Col>}

      {workerFlag && <StandardFormInput
        id="phoneHome"
        label="Phone - Home"
        placeholder="(xxx) xxx-xxxx"
        standardFormat={STANDARD_FORMATS.PHONE_NO}
        type="text"
        name='phoneHome'
        value={phoneHome}
        onChangeStandardInput={onChangeStandardInput}
      />}

      <StandardFormInput
        id="phoneWork"
        label="Phone - Work"
        placeholder="(xxx) xxx-xxxx"
        standardFormat={STANDARD_FORMATS.PHONE_NO}
        type="text"
        name='phoneWork'
        value={phoneWork}
        onChangeStandardInput={onChangeStandardInput}
      />

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="phoneWorkExt">Phone - Work Ext</label>
        <FormInput
          id="phoneWorkExt"
          placeholder="xxxxx"
          type="number"
          min={5}
          value={phoneWorkExt}
          name='phoneWorkExt'
          onChange={onChange}
          //onChangeStandardInput={onChangeStandardInput}
        />
      </Col>}

      {workerFlag && <StandardFormInput
        id="phoneMobile"
        label="Phone - Mobile"
        placeholder="(xxx) xxx-xxxx"
        standardFormat={STANDARD_FORMATS.PHONE_NO}
        type="text"
        name='phoneMobile'
        value={phoneMobile}
        onChangeStandardInput={onChangeStandardInput}
      />}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="emailPersonal">Email - Personal</label>
        <FormInput
          id="emailPersonal"
          placeholder="Enter Email - Personal"
          value={emailPersonal}
          name='emailPersonal'
          onChange={onChange}
        />
      </Col>}

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="emailWork">Email - Work</label>
        {!workerFlag && <span aria-hidden="true" className="required"> *</span>}
        <FormInput
        required={!workerFlag}
          id="emailWork"
          placeholder="Enter Email - Work"
          value={emailWork}
          name='emailWork'
          onChange={onChange}
        />
      </Col>

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="prefEmail">Preferred Email</label>
        {(emailWork || emailPersonal) && <span aria-hidden="true" className="required"> *</span>}
        <FormSelect value={preferredEmail} required={emailWork || emailPersonal}
                    name='preferredEmail' onChange={onChange}
                    className='d-block'>
          <option value=''>Select Preferred Email</option>
          {preferredEmailListOption}
        </FormSelect>
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="linkedin">LinkedIn</label>
        <FormInput
          id="linkedin"
          placeholder="Enter Linkedin Url"
          value={linkedIn}
          name='linkedIn'
          onChange={onChange}
        />
      </Col>}
      
      
    </CustomCard>

  )
}


export default ContactInfo;
