import api from "../../../utils/api";
import {
  GET_CLIENT_VIEW_WORKER_LEAVE,
  GET_CLIENT_VIEW_WORKER_LEAVE_ERROR,
  SEARCH_WORKER_LEAVES_LIST,
  SEARCH_WORKER_LEAVES_LIST_ERROR,
  CLEAR_LEAVE_DETAILS,
  CLEAR_WORKER_LEAVE_DETAILS,
  GET_CLIENT_LEAVE_POLICY_NAME_LIST_ERROR,
  GET_CLIENT_LEAVE_POLICY_NAME_LIST,
  APPROVE_LEAVE,
  APPROVE_LEAVE_ERROR,
  REJECT_LEAVE,
  REJECT_LEAVE_ERROR
} from "../types";
import {setAlert} from "../alert";
import {redirect} from "../user";

export const search_worker_leaves_list = (
  workerName,
  leaveStatus,
  leaveType,
  fromDate,
  toDate,
  empStatus
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/searchLeavesList`, {
        params: {
          workerName: workerName,
          leaveStatus: leaveStatus,
          leaveType: leaveType,
          fromDate: fromDate,
          toDate: toDate,
          empStatus: empStatus
        }
      }
    );
    dispatch({
      type: SEARCH_WORKER_LEAVES_LIST,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      if (error.response.status === 401)
        dispatch(redirect('/error403'))
      else
        dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: SEARCH_WORKER_LEAVES_LIST_ERROR,
    });
  }
};
export const get_client_view_worker_leave = (
  refNum,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/getLeaveDetails?refNum=${refNum}`
    );
    dispatch({
      type: GET_CLIENT_VIEW_WORKER_LEAVE,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_VIEW_WORKER_LEAVE_ERROR,
    });
  }
};

export const clear_leave_details = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LEAVE_DETAILS,
  });
};
export const clear_worker_leave_details = () => async (dispatch) => {
  dispatch({
    type: CLEAR_WORKER_LEAVE_DETAILS,
  });
};


export const get_client_leave_policy_name_list = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/getClientLeavePolicyNameList`
    );
    dispatch({
      type: GET_CLIENT_LEAVE_POLICY_NAME_LIST,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success && error.response.status !== 401) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_LEAVE_POLICY_NAME_LIST_ERROR,
    });
  }
};

export const approve_leave = (userId, refNum, clientNote) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/approveLeave?userId=${userId}&refNum=${refNum}&clientNote=${clientNote}`
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: APPROVE_LEAVE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: APPROVE_LEAVE_ERROR,
    });
  }
};

export const reject_leave = (userId, refNum, clientNote) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/rejectLeave`,
      {
        params: {
          userId: userId,
          refNum: refNum,
          clientNote: clientNote
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: REJECT_LEAVE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: REJECT_LEAVE_ERROR,
    });
  }
};