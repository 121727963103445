import {
  FILTER_DASHBOARD_PAY_PERIOD_LIST,
  GET_DASHBOARD_PAY_PERIOD_LIST,
  GET_DASHBOARD_QUICK_FILTER_NAME_LIST,
  GET_SUB_DASHBOARD_DATA
} from "../../actions/types";


const initialState = {
  dashboardData: {},
  quickFilterList:[],
  payPeriodList:[],
  filteredPayPeriodList:[]
}


export const home = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUB_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload.data,
      };

    case GET_DASHBOARD_QUICK_FILTER_NAME_LIST:
      return {
        ...state,
        quickFilterList: action.payload.data,
      };

      case GET_DASHBOARD_PAY_PERIOD_LIST:
      return {
        ...state,
        payPeriodList: action.payload.data,
      };

      case FILTER_DASHBOARD_PAY_PERIOD_LIST:
      return {
        ...state,
        filteredPayPeriodList: action.payload.data,
      };

    default:
      return state;
  }
}

export default home;
