import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container,
  FormInput,
  FormSelect, Row
} from "shards-react";
import {setDateForTable, setInputDate} from "../../utils/date";
import {fixedDecimalPlaces, getStatusClass} from "../../utils/general";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  get_search_leave_policy,
  search_leaves,
  get_sub_status_list,
  get_subs_leave_details
} from "../../redux/actions/user";
import {
  get_entitled_leaves_list,
  get_leaves_status,
  get_leaves_balance,
  clear_leaves_balance
} from "../../redux/actions/worker/leaves";
import queryString from "querystring";
import InfoTooltip from '../../components/common/InfoTooltip';
import CustomCard from '../../components/common/CustomCard';

function SubscriberLeaves({
                            user,
                            leavePolicyList,
                            leaveStatusList,
                            subLeavesList,
                            entitledLeaveList,
                            get_leaves_status,
                            get_search_leave_policy,
                            get_entitled_leaves_list,
                            search_leaves,
                            title,
                            changePageMeta,
                            subStatusList,
                            get_sub_status_list,
                            leaveBalance,
                            get_leaves_balance,
                            clear_leaves_balance
                          }) {

  let location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))

  const [formData, setFormData] = useState({
    clientName: '',
    worker: '',
    from: '',
    to: '',
    status: '',
    leaveType: '',
    empStatus : '',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });
  const [yearList, setYearList] = useState([])
  const [searchLeaveData, setSearchLeaveData] = useState({
    year: '',
    leavePolicyId : '',
    userId:''
  })

  useEffect(() => {
    get_search_leave_policy();
    get_leaves_status();
    get_sub_status_list();

    return  clear_leaves_balance;
  }, []);

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (location.state && location.state.workerName)
      changePageMeta(`${title} - ${location.state.workerName}`)
  }, []);


  const {clientName, worker, from, to, status, leaveType, empStatus} = formData;
  const {userId} = useParams();

  if (!userId)
    entitledLeaveList = []

  useEffect(() => {
    let newState = {
      ...formData,
      clientName: queryParams.clientName || "",
      worker: queryParams.worker || "",
      from: queryParams.from || "",
      to: queryParams.to || "",
      status: queryParams.status || "",
      leaveType: queryParams.leaveType || "",
      empStatus: queryParams.empStatus || ""
    }
    search_leaves(userId, newState.clientName, newState.worker, newState.from, newState.to, newState.status, newState.leaveType, newState.empStatus);
    if (userId)
      get_entitled_leaves_list(userId)
    setFormData(newState)
  }, [queryParams.clientName,
    queryParams.worker,
    queryParams.from,
    queryParams.to,
    queryParams.status,
    queryParams.leaveType]);

    function createListOfYears(startYear, endYear) {
      let result = [];
      for (let i = startYear; i <= endYear; i++) {
          result.push(i);
      }
      return result.reverse();
  }
    useEffect(()=>{
      if(location.state && location.state.hireDate)
      {
        let hireDate =  String(location.state.hireDate ? location.state.hireDate : setInputDate(new Date()));
        let now =  setInputDate(new Date());
        let currentYear = now.substring(0,4);
        let hireYear = currentYear;
        if(hireDate.length > 0){
          hireYear =  hireDate.substring(0,4);
        }
    
        let yearList = createListOfYears(hireYear, currentYear);
    
        setYearList(yearList);
      }
   
     },[location.state])

     useEffect(()=>{
      if(searchLeaveData.userId !== '')
      {get_leaves_balance(searchLeaveData.userId, searchLeaveData.year, searchLeaveData.leavePolicyId);}
    },[searchLeaveData])
    useEffect(()=>{
    if(location.state && location.state.hireDate)  
    {
      if(entitledLeaveList && entitledLeaveList.length > 0){
        setSearchLeaveData({...searchLeaveData, leavePolicyId: entitledLeaveList[0].leavePolicyId, userId: location.state.userId, year:setInputDate(new Date()).substring(0,4)})
      }
    }
  },[entitledLeaveList])
     const handleChangeSearchLeaves = (e)=> setSearchLeaveData({...searchLeaveData, [e.target.name]:e.target.value})

  const onSubmit = (e) => {
    e.preventDefault();
    const fromDate = setInputDate(from);
    const toDate = setInputDate(to);
    search_leaves(userId, clientName, worker, fromDate, toDate, status, leaveType, empStatus);
  };
  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  let onStartDateChanged = (value) => {
    setFormData({
      ...formData,
      from: value
    })
  }

  let onEndDateChanged = (value) => {
    setFormData({
      ...formData,
      to: value
    })
  }

  const tableData = subLeavesList;
  var {pageSize, pageSizeOptions} = formData;
  
  var yearListOptions = yearList.map(year => {
    return <option key={year} value={year}>{year}</option>
  })
  var leaveTypesBalance = entitledLeaveList && entitledLeaveList.map(leavePolicy=>{
    return <option key={leavePolicy.policyName + leavePolicy.leavePolicyId} value={leavePolicy.leavePolicyId}>{leavePolicy.policyName}</option>;
  });
  const tableColumns = [
    {
      Header: "Client",
      accessor: "clientName",
      className: "justify-content-start text-left text-wrap text-break",
      Cell: row =>
        <Link
          to={`/sub-manage-client-profile/${row.original.clientId}`}>{row.original.clientName}</Link>
    },
    {
      Header: "Worker Name",
      accessor: "workerName",
      className: "justify-content-start text-left text-wrap text-break",
      Cell: row =>
        <Link
          to={`/manage-user-profile/${row.original.userId}`}>{row.original.workerName}</Link>
    },
    {
      Header: "Employment Status",
      accessor: "employmentStatus",
      className: "justify-content-center text-center text-wrap text-break",
      Cell: row =>
        <span
          className={getStatusClass(row.original.employmentStatus)}>{row.original.employmentStatus}</span>,
    },
    {
      Header: "Requested",
      accessor: "requestedDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.requestedDate)
    },
    {
      Header: "Leave Type",
      accessor: "leaveType",
      className: "justify-content-center text-center text-wrap text-break",
    },

    {
      Header: "From",
      accessor: "fromDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.fromDate)
    },

    {
      Header: "To",
      accessor: "toDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.toDate)
    },
    {
      Header: "Time Off",
      accessor: "timeOff",
      maxWidth: 80,
      className: "justify-content-end",
      Cell: row => row.original.timeOff.toFixed(2)
    },

    {
      Header: "Balance (once accrued)",
      accessor: "balance",
      className: "justify-content-end",
      maxWidth: 80,
      Cell: row => row.original.balance.toFixed(2)
    },

    {
      Header: "Status",
      accessor: "status",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>,
    },
    {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 80,
      Cell: row =>
        <Link
          to={`/sub-approve-leave/${row.original.refNum}`}>{row.original.refNum}</Link>
    },
  ];
  const entitledLeavesCards = entitledLeaveList.map(leave => {
    return <div className="pb-2 px-1 leaveCard">
      <Card className="leave-box">
        <CardBody className="p-0 leaveCardBody">
          <Row form className="m-0">
            <Col lg="12" className="form-group p-2 px-3 leaveLabel text-center">
              <label
                className="text-bold box-header text-uppercase m-0">{leave.policyName}
              </label>
            </Col>
            <Col lg="12"
                 className="form-group d-flex justify-content-between px-3">
              <label>Entitled</label>
              <span
                className='text-right'>{leave.entitledFlag ? leave.entitledDays + " days" : "Unlimited"}</span>
            </Col>

            <Col lg="12"
                 className="form-group d-flex justify-content-between px-3">
              <label>Balance</label>
              <span
                className='text-right'>{leave.entitledFlag ? leave.balance + " days" : "—"}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  })

  var leaveTypeOptions = leavePolicyList.map((leavePolicy, i) => {
    return (
      <option key={leavePolicy.leavePolicyId + i} value={leavePolicy.policyName}>
        {leavePolicy.policyName}
      </option>
    );
  });
  var leaveStatusOptions = leaveStatusList.map((status) => {
    return <option key={status} value={status}>{status}</option>;
  });

  var substatusListOptions = subStatusList && subStatusList.map(
    (substatus) => {
      return <option key={substatus} value={substatus}>{substatus}</option>;
    }
  );
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row className='entitled-leaves mt-4 flex-row flex-nowrap overflow-auto'>
        { location.state && (<Col lg="5">
            <CustomCard id="leaveBalance" title="Leaves Balance Information" className="mt-5 mb-4" icon={true} iconClass="fa-regular fa-calendar iconStyle" noform>
              <Row className="mx-0 w-100">
                <Col lg="6">
                  <label htmlFor="leaveType">Leave Type</label>
                  <InfoTooltip msg="Entitled Leave Types mapped." infoClass="info-icon-primary" />
                  <FormSelect value={searchLeaveData.leavePolicyId} name='leavePolicyId'
                    onChange={handleChangeSearchLeaves} className='d-block'>
                    <option value=''>Select Leave Type</option>
                    {leaveTypesBalance}
                  </FormSelect>
                </Col>

                <Col lg="6">
                  <label htmlFor="year">Year</label>
                  <FormSelect value={searchLeaveData.year} name='year'
                    onChange={handleChangeSearchLeaves} className='d-block'>
                    {yearListOptions}
                  </FormSelect>

                </Col>

              </Row>
              <Row className="mx-0 w-100 mt-2 ">
                <Col lg="12">
                  <table className='table-bordered shadow-sm p-3 mb-2 bg-white rounded w-100'>
                  <tbody>
                    <tr>
                    <td><div className="d-flex justify-content-between font-weight-normal  mr-2 ml-2"><div className='h-25'>Carry Forward ({(leaveBalance && leaveBalance.allowCarryOverFlag === undefined || leaveBalance.allowCarryOverFlag === 0) ? `Off` : `On`})</div><div className={`h-25 font-weight-normal ${leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag ? 'leave-balance' : 'mr-5' : 'leave-balance'} text-center`}>{leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag ? fixedDecimalPlaces(leaveBalance.carryForward, 2) : '-' : '0.00'}</div></div></td>
                    </tr>
                    <tr>
                      <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25 '>Entitled (once accrued)</div><div className={`h-25 font-weight-normal ${leaveBalance.entitledFlag !== undefined ?  leaveBalance.entitledFlag ? 'leave-balance' : 'mr-4': 'leave-balance'} text-center`}>{leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag ? fixedDecimalPlaces(leaveBalance.entitledDays, 2) : 'Unlimited' : '0.00'}</div></div></td>
                    </tr>
                    <tr>
                      <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Approved</div><div className='h-25 font-weight-normal leave-balance'>{fixedDecimalPlaces(leaveBalance.approvedLeaves, 2)}</div></div></td>
                    </tr>
                    <tr>
                      <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Submitted</div><div className='h-25 font-weight-normal leave-balance'>{fixedDecimalPlaces(leaveBalance.submittedLeaved, 2)}</div></div></td>
                    </tr>
                    <tr>
                      <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Lapse</div><div className={`h-25 font-weight-normal ${leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag ? 'leave-balance' : 'mr-5' : 'leave-balance'} `}>{leaveBalance.entitledFlag !== undefined ? leaveBalance.entitledFlag ? fixedDecimalPlaces(leaveBalance.lapse, 2) : '-' : '0.00'}</div></div></td>
                    </tr>
                    <tr>
                      <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Entitled (once accrued) Balance Remaining{/*<InfoTooltip msg="Unused Worker Leave Balance." infoClass="info-icon-primary"/>*/}</div><div className={`h-25 text-leave-balance font-weight-bold ${leaveBalance.entitledFlag  !== undefined ?  !leaveBalance.entitledFlag ?  'mr-2' : '' : 'mr-1'}`}>{leaveBalance.entitledFlag  !== undefined ? leaveBalance.entitledFlag ? fixedDecimalPlaces(leaveBalance.balance, 2) : '-' : '0.00'} Days</div></div></td>
                    </tr>
                  </tbody>
                  </table>
                </Col>
              </Row>
            </CustomCard>
          </Col>)}
      </Row>
      <div
           className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='clientName'
            value={formData.clientName}
            onChange={onChange}
            placeholder="Client Name"
          />
        </div>

        <div className="d-flex align-items-center p-2">
          <FormInput
            name='worker'
            value={formData.worker}
            onChange={onChange}
            placeholder="Worker Name"
          />
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='status' value={formData.status}
                      onChange={onChange}>
            <option value=''>All Status</option>
            {leaveStatusOptions}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='leaveType' value={formData.leaveType}
                      onChange={onChange}>
            <option value=''>All Types</option>
            {leaveTypeOptions}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center p-1">
          <FormSelect name='empStatus' value={formData.empStatus}
                      onChange={onChange}>
            <option value=''>All Employment Status</option>
            {substatusListOptions}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center p-2">
          <RangeDatePicker className="justify-content-end"
                           onStartDateChanged={onStartDateChanged}
                           onEndDateChanged={onEndDateChanged} name="range"/>
        </div>

        <div className="d-flex align-items-center justify-content-end p-2">
          <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                  onClick={onSubmit}>Search</Button>
        </div>
      </div>


      <Card className="tableCard p-0">
        <CardHeader className="p-0">
        <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/*Filters :: Page Size*/}
              <Col lg="2"
                   className="file-manager__filters__rows d-flex align-items-center col-12">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col lg="10"
                   className="file-manager__filters__search d-flex justify-content-center justify-content-lg-start col-12 mt-3 mb-3">
               <p
                  className="m-0 ">{`Note - This section shows Active & On Leave Users`}</p>
              </Col>


            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

SubscriberLeaves.propTypes = {
  user: PropTypes.object.isRequired,
  leavePolicyList: PropTypes.array.isRequired,
  leaveStatusList: PropTypes.array.isRequired,
  subLeavesList: PropTypes.array.isRequired,
  get_search_leave_policy: PropTypes.func.isRequired,
  get_leaves_status: PropTypes.func.isRequired,
  search_leaves: PropTypes.func.isRequired,
  leaveBalance : PropTypes.object.isRequired
  //get_subs_leave_details: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user.user,
  leavePolicyList: state.user.leavePolicyList,
  leaveStatusList: state.leaves.leaveStatusList,
  subLeavesList: state.user.subLeavesList,
  entitledLeaveList: state.leaves.entitledLeaveList,
  subStatusList: state.user.subStatusList,
  leaveBalance : state.leaves.leaveBalance

});
export default connect(mapStateToProps, {
  get_search_leave_policy,
  get_entitled_leaves_list,
  get_leaves_status,
  search_leaves,
  get_sub_status_list,
  get_leaves_balance,
  clear_leaves_balance
})(SubscriberLeaves);

