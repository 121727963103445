import React, {useState} from 'react';
import {
  Button,
  Card, Col,
  Form, FormInput, FormSelect,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {Link} from "react-router-dom";
import InfoTooltip from "../../components/common/InfoTooltip";

function AccountSettings({
                           loginEmail,
                           userId,
                           employmentStatusList,
                           hrConsultantList,
                           salesConsultantList,
                           subStatusList,
                           subStatus,
                           hrConsultantId,
                           salesConsultantId,
                           salesConsultant,
                           employmentStatus,
                           subscriberId,
                           requiredTwoFA,
                           accountStatus,
                           subscriberList,
                           hrConsultant,
                           confirmAlert,
                           setConfirmAlert,
                           user_disable_2fa,
                           set_user_account_status,
                           set_user_password,
                           firstName,
                           lastName,
                           nickName,
                           clientName,
                           messageCategoryRole,
                           clientAdminFlag,
                           isRequiredFlag,
                           clientUserFlag,
                           workerFlag,
                           clientHRFlag,
                           clientBillingFlag,
                           phoneMobile,
                           phoneHome,
                           phoneWork,
                           preferredEmail,
                           emailWork,
                           emailPersonal,
                           setAlert,
                           onChange,queryParamsRole
                         }) {


  const [passwordState, setPasswordState] = useState({
    password: "",
    confirmPassword: "",
    isMinimum8ch:false,
    is1Number:false,
    is1Uppercase:false,
    is1Lowercase:false,
    is1SpecialCh:false,
    isBothMatch:false,
    showPassword:false,
    showConfirmPassword:false,
    
  });

  const passwordStrength = (e) => {
       
    const passwordValue= e.target.value;    
    const passwordLength= passwordValue.length;

    const LowerRegExp = /[a-z]/;
    const UpperRegExp = /[A-Z]/;
    const NumberRegExp = /(?=.*?[0-9])/;;
    const SpecialCRegExp = /(?=.*?[#!@$%^&*])/;   

    var varMaxMatch=false;
    if(passwordValue.length>=8){
      varMaxMatch=true;
    }
    var varMatch=false;
    if(passwordValue===passwordState.confirmPassword){
      varMatch=true;
    }
    setPasswordState({...passwordState, [e.target.name]: e.target.value,is1Number:NumberRegExp.test(passwordValue),is1Uppercase:UpperRegExp.test(passwordValue),is1Lowercase:LowerRegExp.test(passwordValue),is1SpecialCh:SpecialCRegExp.test(passwordValue),isMinimum8ch:varMaxMatch,isBothMatch:varMatch })
   
  }
  const passwordMatch = (e) => {
    
    const passwordValue= e.target.value;  
    var varMatch=false;
    if(passwordValue===passwordState.password){
      varMatch=true;
    }    
    setPasswordState({...passwordState, [e.target.name]: e.target.value,isBothMatch:varMatch })
   
  };

  let disable2Fa = () => {
    setConfirmAlert({
      confirmMsg: "Are you sure you want to disable 2FA?",
      visible: true,
      onConfirm: () => {
        user_disable_2fa(userId);
        setConfirmAlert({
          ...confirmAlert,
          visible: false
        })
      }
    })
  };
  let deactivateAccount = () => {
    setConfirmAlert({
      confirmMsg: "Are you sure you want to deactivate the account?",
      visible: true,
      onConfirm: () => {
        set_user_account_status(userId, 0);
        setConfirmAlert({
          ...confirmAlert,
          visible: false
        })
      }
    })
  };

  let activateAccount = () => {
    setConfirmAlert({
      confirmMsg: "Are you sure you want to activate the account?",
      visible: true,
      onConfirm: () => {
        set_user_account_status(userId, 1);
        setConfirmAlert({
          ...confirmAlert,
          visible: false
        })
      }
    })
  };

  var subscriberListOption = subscriberList && subscriberList.map((status) => {
    return (
      <option key={status.subscriberName + status.subscriberId} value={status.subscriberId}>{status.subscriberName}</option>
    );
  });
 
  var salesConsultantListOption = salesConsultantList && salesConsultantList.map((status) => {
    return (
      <option key={status.userName} value={status.userId}>{status.userName}</option>
    );
  });
  
  var hrConsultantListOption = hrConsultantList && hrConsultantList.map((status) => {
    return (
      <option key={status.userName + status.userId} value={status.userId}>{status.userName}</option>
    );
  });
  var employmentStatusListOption = employmentStatusList && employmentStatusList.map((status) => {
    return <option key={status} value={status}>{status}</option>;
  });

  var substatusListOptions = subStatusList && subStatusList.map(
    (substatus) => {
      return <option key={subStatus} value={substatus}>{substatus}</option>;
    }
  );


  let setPasswordHandler = () => {
    if (passwordState.password === passwordState.confirmPassword)
      set_user_password(userId, passwordState.password);
    else
      setAlert("Passwords do not match", "danger");
  };

  let queryParams ={
    userName:firstName + " " + lastName + (nickName ? ` (${nickName})` : ""),
    clientName:clientName,
    role:messageCategoryRole,
    hrConsultant:hrConsultant,
    phone:phoneMobile || phoneHome || phoneWork
  }
  if(preferredEmail==="Work")
    queryParams.email=emailWork
  else if(preferredEmail==="Personal")
    queryParams.email=emailPersonal
  else
    queryParams.email=emailPersonal || emailWork




  let queryString=Object.keys(queryParams)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(queryParams[k]))
    .join('&');
  return (
    <Card id="accountSettings" small
          className="mb-5 w-100 d-flex personalParent">
      <span className="iconBox"><i className="fa fa-cog iconStyle"/></span>
      <h4 className="m-0 section-title headerStyle">Account Settings</h4>
      <h4 className="m-0 section-title idStyle">Ref# {userId}</h4>
      <ListGroup flush>

        <ListGroupItem className="p-3 mt-3">
          <Row>
            <Col>

                <Row form>

                  <Col lg="3" className="form-group m-0 p-2">
                    <label htmlFor="loginEmail">Login Email</label>
                    <span aria-hidden="true" className="required"> *</span>
                    <FormInput
                      id="loginEmail"
                      required
                      type="email"
                      autoComplete="off"
                      placeholder="Enter Login Email"
                      value={loginEmail}
                      name='loginEmail'
                      onChange={onChange}
                    />
                  </Col>

                  <Col lg="3" className="form-group m-0 p-2">
                    <label htmlFor="subscriberId">Subscriber</label>
                    <span aria-hidden="true" className="required"> *</span>
                    <FormSelect value={subscriberId}
                                required
                                name='subscriberId' onChange={onChange}
                                className='d-block'>
                      <option value=''>Select Subscriber</option>
                      {subscriberListOption}
                    </FormSelect>
                  </Col>
                  
                  <Col lg="3" className="form-group m-0 p-2">
                    <label>Account Status</label>
                    <Button className="w-100" size="sm"
                            onClick={(accountStatus) ? deactivateAccount : activateAccount}
                            theme="accent">{(accountStatus) ? "Deactivate" : "Activate"}</Button>
                  </Col>
                </Row>
                <Row form>
                  {/*<Col lg="3" className="form-group m-0 p-2">
                    <label htmlFor="hrConsultant">HR Consultant</label>
                    <InfoTooltip
                            msg="List of Subscriber Admin & HR Users" />
                    <FormSelect value={hrConsultantId} id="hrConsultantId"
                                name='hrConsultantId' onChange={onChange}
                                className='d-block'>
                      <option value=''>Select HR Consultant
                      </option>
                      {hrConsultantListOption}
                    </FormSelect>
                    <FormInput
                      id="hrConsultant"
                      required = {queryParamsRole === "Worker"}
                      placeholder="Enter HR Consultant"
                      value={hrConsultant}
                      name='hrConsultant'
                      onChange={onChange}
                    />
                  </Col>*/ }

                  {/*<Col lg="3" className="form-group m-0 p-2">
                    <label htmlFor="salesConsultant">Sales
                      Consultant</label>
                      <InfoTooltip
                            msg="List of Subscriber Users" />
                       <FormSelect value={salesConsultantId} id="salesConsultantId"
                                name='salesConsultantId' onChange={onChange}
                                className='d-block'>
                      <option value=''>Select Sales Consultant
                      </option>
                      {salesConsultantListOption}
                      </FormSelect>
                     <FormInput
                      id="salesConsultant"
                      placeholder="Enter Sales Consultant"
                      value={salesConsultant}
                      name='salesConsultant'
                      onChange={onChange}
                    /> 
                  </Col>*/}

                 {/*  <Col lg="3" className="form-group m-0 p-2">
                    <label htmlFor="employmentStatus"> old Employment
                      Status</label>
                    <FormSelect value={employmentStatus}
                                name='employmentStatus' onChange={onChange}
                                className='d-block'>
                      <option value=''>Select Employment
                        Status
                      </option>
                      {employmentStatusListOption}
                    </FormSelect>
                  </Col>
                  */}

                  {/*<Col lg="3" className="form-group m-0 p-2">
                    <label htmlFor="subStatus"> Employment Status</label>
                    <FormSelect id="subStatus" value={subStatus}
                                name='subStatus' onChange={onChange}
                                className='d-block'>
                      <option value=''>Select  Employment Status</option>
                      {substatusListOptions}
                       <option value='Active'>Active</option>
                      <option value='Inactive'>Inactive</option>
                      <option value='OnLeave'>On Leave</option>
                      <option value='Internal'>Internal</option> 
                    </FormSelect>
                  </Col>*/}
                  {/*<Col lg="3" className="form-group m-0 p-2">

                </Col>*/}
                  

                 {/* <Col lg="3" className="form-group m-0 p-2">

              </Col>*/}


                  <Col lg="3" className="form-group m-0 p-2">
                    <label htmlFor="password">Password</label>
                    <InfoTooltip
                            msg="Below are the mandatory password parameters" />
                    <div className="input-group input-group-seamless"> 
                    <FormInput id="password" 
                    type={passwordState.showPassword?"text":"password"}
                               autoComplete="new-password"
                               name="password"
                               className={"form-control rounded-right"}
                               value={passwordState.password}
                               placeholder="Enter Password"
                               onChange={passwordStrength}
                              
                              />
                               <div className="input-group-append">
                               <span className="input-group-text"><i onClick={e=>{setPasswordState({...passwordState,showPassword:!passwordState.showPassword})}} className={`fa ${passwordState.showPassword?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                    </div> 
                    </div>
                 </Col>

                  <Col lg="3" className="form-group m-0 p-2">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <div className="input-group input-group-seamless"> 
                    <FormInput id="confirmPassword" 
                    type={passwordState.showConfirmPassword?"text":"password"}
                               autoComplete="new-password"
                               name="confirmPassword"
                               className={"form-control rounded-right"}
                               value={passwordState.confirmPassword}
                               placeholder="Enter Password"
                               onChange={passwordMatch}
                             />
                              <div className="input-group-append">
                              <span className="input-group-text"><i onClick={e=>{setPasswordState({...passwordState,showConfirmPassword:!passwordState.showConfirmPassword})}} className={`fa ${passwordState.showConfirmPassword?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                    </div> 
                    </div>
                  </Col>
                  <Col lg="3" className="form-group m-0 p-2">
                    <Button className="w-100 mt-30 pb-2" size="sm" disabled={((passwordState.isBothMatch && passwordState.isMinimum8ch && passwordState.is1SpecialCh && passwordState.is1Number && passwordState.is1Uppercase && passwordState.is1Lowercase && passwordState.password !=='')?false:true)}
                            theme="accent" onClick={setPasswordHandler}>Set
                      Password</Button>
                  </Col>

                  <Col lg="3" className="form-group m-0 p-2">

                    <Button className="w-100 mt-30 pb-2" size="sm"
                            theme="accent" disabled={!requiredTwoFA}
                            onClick={disable2Fa}>Disable 2FA</Button>
                  </Col>

                  </Row>
                  <Row>
                  
                  <Col lg="6" md="6" className="form-group m-0 p-2">
                   
                 <div className="passwordborder ml-2">
                   <label className={"col-md-7 "} ><small><i className={"pr-2  fa "+ (passwordState.isMinimum8ch ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>Minimum 8 characters</small></label>
                      <label className={"col-md-5"}><small><i className={"pr-2 fa "+ (passwordState.is1Number ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>  1 number (0-9)</small></label>
                      <label className={"col-md-7"}><small><i className={"pr-1 fa "+ (passwordState.is1Uppercase ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i> 1 uppercase letter (A-Z)</small></label>
                      <label className={"col-md-5"}><small><i className={"pr-2 fa "+ (passwordState.is1Lowercase ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>1 lowercase letter (a-z)</small></label>
                      <label className={"col-md-7 text-wrap"}><small><i className={"pr-2 fa "+ (passwordState.is1SpecialCh ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>1 special character (!, @, #, $, %, ^, &, or *)</small></label>
                      <label className={"col-md-5"}><small><i className={"pr-2 fa "+ (passwordState.isBothMatch ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>Both passwords match</small></label>
                    
                      </div>
                  </Col>
                  {(clientAdminFlag ||
                    clientUserFlag ||
                    workerFlag ||
                    clientHRFlag ||
                    clientBillingFlag) &&
                  <>
                    <Col lg="3" md="3" className="form-group my-0 p-2">
                      <Link
                        to={`/sub-new-message/0/${userId}/${messageCategoryRole}`}>
                        <Button className="w-100 mt-30" size="sm"
                                theme="accent">New
                          Message</Button>
                      </Link>
                    </Col>

                    <Col lg="3" md="3" className="form-group m-0 p-2">
                      <Link
                        to={"/sub-message-activity/" + userId + `?${queryString}`}>
                        <Button className="w-100 mt-30" size="sm"
                                theme="accent">Manage Messages</Button>
                      </Link>

                    </Col>
                  </>}
                  
                  
                </Row>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}

export default AccountSettings;
