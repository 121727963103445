import CBoxLayout from "../layouts/CBoxLayout";
import WorkerHome from "../views/worker/WorkerHome";
import ViewProfile from "../views/worker/ViewProfile";
import ManageLeaves from "../views/worker/ManageLeaves";
import EditLeave from "../views/worker/EditLeave";
import ExpenseReports from "../views/worker/ExpenseReports";
import EditExpenseReport from "../views/worker/EditExpenseReport";
import {Fragment} from "react";
import ExpenseItemReceiptPopup
  from "../components/worker/edit-expense-report/ExpenseItemReceiptPopup";
import EditExpenseItem from "../views/worker/EditExpenseItem";
import EditMileageItem from "../views/worker/EditMileageItem";
import UserAddEditMessage from "../views/worker/UserAddEditMessage";
import MessageCenter from "../views/worker/MessageCenter";
import ViewMessageAttachmentPopUp from "../components/common/ViewMessageAttachmentPopUp";

export default [
  {
    path: "/worker-home",
    layout: CBoxLayout,
    component: WorkerHome,
    title:"Home",
    subtitle:"Worker",
    roles:["Worker"]
  },

  {
    path: "/view-profile",
    layout: CBoxLayout,
    component: ViewProfile,
    title:"Profile",
    subtitle:"View",
    roles:["Worker"]
  },
  {
    path: "/manage-leaves",
    layout: CBoxLayout,
    component: ManageLeaves,
    title:"Leaves",
    subtitle:"Manage",
    permission:"Leaves Module",
    roles:["Worker"]
  },

  {
    path: "/edit-leave/:ref",
    layout: CBoxLayout,
    component: EditLeave,
    title:"Leave",
    subtitle:"Edit",
    permission:"Leaves Module",
    roles:["Worker"]
  },

  {
    path: "/add-leave/:ref",
    layout: CBoxLayout,
    component: EditLeave,
    title:"Leave",
    subtitle:"Apply for",
    permission:"Leaves Module",
    roles:["Worker"]
  },

  {
    path: "/view-expenses",
    layout: CBoxLayout,
    component: ExpenseReports,
    title:"Expense Reports",
    subtitle:"View",
    permission:"Expense Module",
    roles:["Worker"]
  },

  {
    path: "/add-expense-report/:sheetRefNumber",
    layout: CBoxLayout,
    component: EditExpenseReport,
    title:"Expense Report",
    subtitle:"Add",
    permission:"Expense Module",
    roles:["Worker"]
  },

  {
    path: "/edit-expense-report/:sheetRefNumber",
    layout: CBoxLayout,
    component: EditExpenseReport,
    title:"Expense Report",
    subtitle:"Edit",
    permission:"Expense Module",
    roles:["Worker"]
  },
  {
    path: "/expense-item-receipt-popup/:userId/:refNumber",
    layout: Fragment,
    component: ExpenseItemReceiptPopup,
    title:"Expense Report",
    subtitle:"Edit",
    permission:"Expense Module",
    roles:["Worker"]
  },
  {
    path: "/view-attachment-popup/:userId/:refNumber/:messageId/:systemFileName/:subPath",
    layout: Fragment,
    component: ViewMessageAttachmentPopUp,
    title:"Expense Report",
    subtitle:"Edit",
    permission:"Expense Module",
    roles:["Worker"]
  },


  {
    path: "/add-expense-item/:sheetRefNumber/:refNumber",
    layout: CBoxLayout,
    component: EditExpenseItem,
    title:"Expense Item",
    subtitle:"Add",
    permission:"Expense Module",
    roles:["Worker"]
  },

  {
    path: "/edit-expense-item/:sheetRefNumber/:refNumber",
    layout: CBoxLayout,
    component: EditExpenseItem,
    title:"Expense Item",
    subtitle:"Edit",
    permission:"Expense Module",
    roles:["Worker"]
  },



  {
    path: "/add-mileage-item/:sheetRefNumber/:refNumber",
    layout: CBoxLayout,
    component: EditMileageItem,
    title:"Mileage Item",
    subtitle:"Add",
    permission:"Expense Module",
    roles:["Worker"]
  },

  {
    path: "/edit-mileage-item/:sheetRefNumber/:refNumber",
    layout: CBoxLayout,
    component: EditMileageItem,
    title:"Mileage Item",
    subtitle:"Edit",
    permission:"Expense Module",
    roles:["Worker"]
  },
  {
    path: "/user-edit-message/:refNum",
    layout: CBoxLayout,
    component: UserAddEditMessage,
    title:"Message",
    subtitle:"Edit",
    roles:["Worker"]
  },


  {
    path: "/user-add-message/:refNum/:msgCategory",
    layout: CBoxLayout,
    component: UserAddEditMessage,
    title:"Message",
    subtitle:"Add",
    roles:["Worker"]
  },

  {
    path: "/message-center",
    layout: CBoxLayout,
    component: MessageCenter,
    title:"Center",
    subtitle:"Message",
    roles:["Worker"]
  },
]
