import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CustomCard from '../../components/common/CustomCard'
import InputBox from '../../components/common/InputBox'
import CFormSelect from '../../components/common/CFormSelect'
import {  useHistory, useParams } from 'react-router-dom';
import {
  Card, Col,
  Container, Form, ListGroup, ListGroupItem,
  Row
} from "shards-react";
import PropTypes from 'prop-types';
import {
    get_country_list,
    get_region_list,
    get_city_details,
    save_city_details,
    clear_city_details
} from '../../redux/actions/user'

function AddCurrency(
    {
        countryList,
        regionList,
        city,
        get_country_list,
        get_region_list,
        save_city_details,
        get_city_details,
        clear_city_details
    }
) {
    let { id } = useParams();
    useEffect(() => {
        get_country_list()
        return function cleanUp() {
          clear_city_details()
        }
    }, [])

    useEffect(() => {
        if (id && id != 0) {
            get_city_details(id)
        }
    }, [id])
    const [formData, setFormData] = useState({
        country: '',
        region: '',
        city: '',
        cityId: 0
    })

    useEffect(() => {
        if (city && Object.keys(city).length !== 0) {
            setFormData(
                {
                    ...formData,
                    region: city.region.regionId,
                    country: city.region.country.countryId,
                    city: city.cityName,
                    cityId: city.cityId
                }
            )
        }
    }, [city])
    const history = useHistory();
    useEffect(() => {
        if (formData.country) {
            get_region_list(formData.country)
        }
    }, [formData.country])

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSave = (e) => {
        e.preventDefault()
        save_city_details(formData.cityId, formData.city, formData.region)
        history.push('/admin-configuration');
    }

    const goBack = (e) => {
        e.preventDefault()
        history.goBack()
    }

    var countryListOption = countryList.map((option) => {
        return <option value={option.countryId}>{option.countryName}</option>;
    });

    var regionListOption = regionList.map((option) => {
        return <option value={option.stateProvId}>{option.stateProvName}</option>;
    });
    return (
      <Container fluid className="main-content-container p-4">
        <Card small className="mb-5 w-100">
          <h4 className="m-0 section-title idStyle">Ref# {id}</h4>
          <ListGroup flush>
            <ListGroupItem className="border-card p-3">
              <Row>
                <Col>
                  <Form id="clientDetailsForm">
                    <Row form>
                      <CFormSelect defaultOptionName="Select Country Name" id="country" label="Country" name='country' value={formData.country} onChange={onChange} data = {countryListOption} />
                      <CFormSelect defaultOptionName="Select Province" id="region" label="State/Province/Region" name='region' value={formData.region} onChange={onChange} data={regionListOption} />
                      <InputBox placeholder="Select City" id="city" label="City" name='city' value={formData.city} onChange={onChange} />
                      <Col lg="3" className="form-group p-2 mb-0">
                        <div style={{ marginTop: "2rem" }} className="d-flex justify-content-between">
                          <button type="submit"  onClick={onSave} className="btn btn-primary w-100">Save</button>
                          <button onClick={(e)=>goBack(e)} className="btn btn-primary ml-2 w-100">Close</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    )
}


AddCurrency.propTypes = {
    countryList: PropTypes.array.isRequired,
    regionList: PropTypes.array.isRequired,
    city: PropTypes.object.isRequired,
    get_country_list: PropTypes.func.isRequired,
    get_region_list: PropTypes.func.isRequired,
    save_city_details: PropTypes.func.isRequired,
    get_city_details: PropTypes.func.isRequired,
    clear_city_details: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    countryList: state.user.countryList,
    regionList: state.user.regionList,
    city: state.user.city
});
export default connect(mapStateToProps, {
    get_country_list,
    get_region_list,
    save_city_details,
    get_city_details,
    clear_city_details
})(AddCurrency);
