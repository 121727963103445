import React, {useState} from 'react';
import {
  Card, CardBody,
  CardHeader,
  Col,
  Container, FormCheckbox,
  FormSelect,
  Row
} from "shards-react";
import {
  getStatusClass,
  getValueInNativeDataType, monetaryToNumber, numberToMonetary
} from "../../../utils/general"
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import {setDateForTable} from "../../../utils/date";
import PropTypes from "prop-types";

function ExpenseItemsCard(props) {

  const [state, setState] = useState({
    pageSizeOptions: [10, 15, 20, 25, 30],
    pageSize: 10,
  });


  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };




  const onChangeGrossAmount = (e, refNum, key) => {
    e.preventDefault();
    props.editableKeyToFocus.current = key;
    let value = getValueInNativeDataType(e);
    let expenseItemList = props.expenseItemList.map(item => {
      if (item.refNum === refNum) {
        item.grossAmount = value;
        item.receiptAmount=numberToMonetary(monetaryToNumber(item.grossAmount)+monetaryToNumber(item.taxAmount));
        item.netAmount=numberToMonetary(monetaryToNumber(item.receiptAmount)*monetaryToNumber(item.exchangeRate))
      }
      return item;
    })

     props.updateExpenseItemList(expenseItemList);
  };
  const onChangeTaxAmount = (e, refNum, key) => {
    e.preventDefault();
    props.editableKeyToFocus.current = key;
    let value = getValueInNativeDataType(e);
    let expenseItemList = props.expenseItemList.map(item => {
      if (item.refNum === refNum) {
        item.taxAmount = value;
        item.receiptAmount=numberToMonetary(monetaryToNumber(item.grossAmount)+monetaryToNumber(item.taxAmount));
        item.netAmount=numberToMonetary(monetaryToNumber(item.receiptAmount)*monetaryToNumber(item.exchangeRate));
      }
      return item;
    })

     props.updateExpenseItemList(expenseItemList);
  };
  const onChangeExchangeRate = (e, refNum, key) => {
    e.preventDefault();
    props.editableKeyToFocus.current = key;
    let value = getValueInNativeDataType(e);
    let expenseItemList = props.expenseItemList.map(item => {
      if (item.refNum === refNum) {
        item.exchangeRate = value;
        item.receiptAmount=numberToMonetary(monetaryToNumber(item.grossAmount)+monetaryToNumber(item.taxAmount));
        item.netAmount=numberToMonetary(monetaryToNumber(item.receiptAmount)*monetaryToNumber(item.exchangeRate))
      }
      return item;
    })

     props.updateExpenseItemList(expenseItemList);
  };

  const onToggleExpenseItemsData = (name, refNum) => {
    let expenseItemList = props.expenseItemList.map(item => {
      if (item.refNum === refNum) {
        item[name] = (item[name] === 1) ? 0 : 1;
      }
      return item;
    })

    props.updateExpenseItemList(expenseItemList);
  }

  const openReceipt = (userId, refNum) => {
    window.open(`/expense-item-receipt-popup/${userId}/${refNum}`, "customWindow", "width=500, height=1040");
  }

  const tableData = (props.expenseItemList) ? props.expenseItemList : []

  var {pageSize, pageSizeOptions} = state;

  if (tableData.length < 10) {
    pageSize = tableData.length
    pageSizeOptions = [tableData.length]
  }
  const tableColumns = [
    {
      Header: <span className="text-wrap">Date</span>,
      accessor: "expenseDate",
      maxWidth: 90,
      Cell: row =>
        setDateForTable(row.original.expenseDate),
    },
    {
      Header: <span className="text-wrap">Expense Category</span>,
      accessor: "type",
      maxWidth: 90,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: <span className="text-wrap">Description</span>,
      accessor: "description",
      minWidth: 160,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: <span className="text-wrap">Gross</span>,
      accessor: "grossAmount",
      maxWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => (row.original.status !== "Reviewed" ) ?
        <input key={"grossAmount_" + row.original.refNum}
               autoFocus={"grossAmount_" + row.original.refNum === props.editableKeyToFocus.current}
               value={row.original.grossAmount} name="grossAmount"
               data-type="monetary"
               onChange={e => onChangeGrossAmount(e, row.original.refNum, "grossAmount_" + row.original.refNum)}
               className="form-control"/> : row.original.grossAmount

    },

    {
      Header: <span className="text-wrap">Tax Amount</span>,
      accessor: "taxAmount",
      maxWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => (row.original.status !== "Reviewed" ) ?
        <input key={"taxAmount" + row.original.refNum}
               autoFocus={"taxAmount" + row.original.refNum === props.editableKeyToFocus.current}
               value={row.original.taxAmount} name="taxAmount"
               data-type="monetary"
               onChange={e => onChangeTaxAmount(e, row.original.refNum, "taxAmount" + row.original.refNum)}
               className="form-control"/> : row.original.taxAmount
    },

    {
      Header: <span className="text-wrap">Receipt Total</span>,
      accessor: "receiptAmount",
      minWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => <span
        className="w-100 text-wrap text-right">{row.original.receiptAmount}</span>
    },

    {
      Header: <span className="text-wrap">Exchange Rate</span>,
      accessor: "exchangeRate",
      maxWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => (row.original.status !== "Reviewed" ) ?
        <input key={"exchangeRate" + row.original.refNum}
               autoFocus={"exchangeRate" + row.original.refNum === props.editableKeyToFocus.current}
               value={row.original.exchangeRate} name="exchangeRate"
               data-type="monetary"
               onChange={e => onChangeExchangeRate(e, row.original.refNum, "exchangeRate" + row.original.refNum)}
               className="form-control"/> : row.original.exchangeRate
    },
    {
      Header: <span className="text-wrap">Net Amount</span>,
      accessor: "netAmount",
      minWidth: 110,
      className: "justify-content-end text-wrap text-break overflow-visible",
      Cell: row => <span
        className="w-100 text-wrap text-right">{row.original.netAmount}</span>,
      Footer: <div className="d-flex w-100 justify-content-end text-nowrap">
        <span>Sub Total: {props.status === undefined ? '0.00' : numberToMonetary(props.submittedItemTotal)}</span>
      </div>

    },

    {
      Header: <span className="text-wrap">Receipt</span>,
      maxWidth: 60,
      Cell: row => (row.original.receiptFileName) ? <Link
        onClick={e => openReceipt(row.original.userId, row.original.refNum)}>Receipt</Link> : ''

    },

    {
      Header: <span className="text-wrap">Status</span>,
      accessor: "status",
      maxWidth: 80,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>
    },

    {
      Header: <span className="text-wrap">Ref#</span>,
      accessor: "refNum",
      maxWidth: 60,
      className: "text-center",
      Cell: row =>
        <Link
          to={`/${props.approveItemRoute}/${props.sheetRefNumber}/${row.original.refNum}`}>{row.original.refNum}</Link>
    }
  ];


  tableColumns.splice(10, 0, {
    Header: "Taxable",
    accessor: "taxable",
    maxWidth: 55,
    className: "align-items-center text-wrap text-break",
    Cell: row => <FormCheckbox checked={row.original.taxable} name="taxable"
                               className="ml-2"
                               disabled={row.original.status === "Reviewed"}
                               onChange={e => (row.original.status!=="Reviewed")?onToggleExpenseItemsData("taxable", row.original.refNum):null}/>
  });


  if (props.status !== "Reviewed") {
    tableColumns.splice(11, 0, {
      Header: <span className="text-wrap">Action</span>,
      maxWidth: 60,
      Cell: row =>(row.original.status==="Rejected" )?<Link title="Approve this expense item." onClick={e => reviewExpenseItem(e, row.original.refNum)}>Approve</Link>:<Link title="Reject this expense item." onClick={e => rejectExpenseItem(e, row.original.refNum)}>Reject</Link>
    });
  }


  let rejectExpenseItem = (e, refNum) => {
    e.preventDefault();
    props.rejectExpenseItem(refNum);
  };

  let reviewExpenseItem = (e, refNum) => {
    e.preventDefault();
    props.reviewExpenseItem(refNum);
  };

  return (
    <Card className="p-0 mb-4">
      <span className="tableHeader">Review Expense</span>
      <CardHeader className="p-0 pt-2 mt-1 border-card">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col xl="4" lg="3"
                 className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6 order-lg-0">
              <span>Show</span>
              <FormSelect
                size="sm"
                name='pageSize'
                value={pageSize}
                onChange={onChange}
              >
                {pageSizeOptions.map((size, idx) => (
                  <option key={idx} value={size}>
                    {size} rows
                  </option>
                ))}
              </FormSelect>
            </Col>


          </Row>
        </Container>
      </CardHeader>
      <CardBody className="p-0">
        <div className="">
          <ReactTable
            className="expenseItemsTable"
            columns={tableColumns}
            data={tableData}
            pageSize={Number(pageSize)}
            showPageSizeOptions={false}
            resizable={false}
            noDataText="No results found"
          />
        </div>
      </CardBody>
    </Card>
  );
}

ExpenseItemsCard.propTypes = {
  expenseItemList: PropTypes.array.isRequired,
  submittedItemTotal: PropTypes.string.isRequired,
  approvedItemTotal: PropTypes.string.isRequired,
  sheetRefNumber: PropTypes.string.isRequired,
}

ExpenseItemsCard.defaultProps = {
  expenseItemList: [],
  submittedItemTotal: "",
  approvedItemTotal: "",
  sheetRefNumber: '',
}

export default ExpenseItemsCard;

