import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Container, Row, Col} from "shards-react";

import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";
import CBoxNavbar from "../components/layout/CBoxNavbar";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import Alerts from "../components/common/Alerts";
import {unredirect} from "../redux/actions/user";

const CBoxLayout = ({
                      user,
                      children,
                      noNavbar,
                      noFooter,
                      title,
                      subtitle,
                      permission,
                      roles,
                      redirectTo,
                      isAuthenticated,
                      unredirect
                    }) => {

  const location = useLocation();
  const history = useHistory();

  if (!isAuthenticated) {
    return <Redirect to='/login'/>
  }

  if (redirectTo) {
    if (location.pathname !== redirectTo)
      return <Redirect to={redirectTo}/>
    else unredirect()
  }

  if (roles && user.userRoles.filter(userRole => (roles.includes(userRole.role) && (permission ? userRole.permissions.includes(permission) : true))).length <= 0) {
    return <Redirect to='/error403'/>
  }

  // tracks page title and subtitle changes
  const [pageMeta, setPageMeta] = useState({
    title: title,
    subtitle: subtitle
  });

  //This method is passed to all the page components which allows pages to change the default title and subtitle
  const changePageMeta = (title = pageMeta.title, subtitle = pageMeta.subtitle) => {
    setPageMeta({
      title: title,
      subtitle: subtitle
    })
  }

  let prevLocation = useLocation();
  //listens to the page changes and scrolls page to top whenever page changed
  useEffect(() => {
    const unlisten = history.listen((nextLocation) => {
      if (prevLocation.pathname.split("/")[1] !== nextLocation.pathname.split("/")[1])
        window.scrollTo(0, 0);

    });
    return () => {
      unlisten();
    }
  }, []);

  return (
    <Container fluid>
      <Row>
        <MainSidebar/>
        <Col
          className="main-content p-0"
          lg={{size: 10, offset: 2}}
          md={{size: 9, offset: 3}}
          sm="12"
          tag="main"
        >
          {!noNavbar &&
          <CBoxNavbar title={pageMeta.title} subtitle={pageMeta.subtitle}/>}
          <Alerts/>
          {React.cloneElement(children, {changePageMeta: changePageMeta})}
          {!noFooter && <MainFooter
            classes={(location.pathname.replace(/\/$/, "") === '/dashboard') ? 'fixed-bottom' : ''}/>}
        </Col>
      </Row>
    </Container>
  )
}

CBoxLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,

  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

CBoxLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  redirectTo: state.user.redirectTo
})

export default connect(mapStateToProps, {
  unredirect
})(CBoxLayout);
