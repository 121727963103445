import React ,{useEffect,useState} from 'react'
import {
  Container
} from "shards-react";
import Currency from './Currency'
import Location from './Location'
import {
    get_city_detailed_list,
    get_currency_list,
    get_country_list,
    get_region_list,
    get_city_list,
} from '../../redux/actions/user';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


function Configuration(
  {
    isAuthenticated,
    user,
    get_city_detailed_list,
    get_currency_list,
    get_country_list,
    cityDetailedList,
    get_region_list,
    get_city_list,
    currencyList,
    countryList,
    regionList,
    cityList,
  }
) {

  useEffect(() => {
    get_currency_list();
    get_country_list();

}, []);
  return (
    <Container fluid className="main-content-container px-4 py-4">
      <Currency currencyList={currencyList} />
      <Location user={user}
            get_city_detailed_list={get_city_detailed_list}
            get_region_list={get_region_list}
            get_city_list={get_city_list}
            currencyList={currencyList}
            countryList={countryList}
            regionList={regionList}
            cityDetailedList={cityDetailedList}
            cityList={cityList}/>
    </Container>
  );
}


Configuration.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  get_currency_list: PropTypes.func.isRequired,
  get_country_list: PropTypes.func.isRequired,
  get_region_list: PropTypes.func.isRequired,
  get_city_detailed_list: PropTypes.func.isRequired,
  get_city_list: PropTypes.func.isRequired,
  currencyList: PropTypes.array.isRequired,
  countryList: PropTypes.array.isRequired,
  regionList: PropTypes.array.isRequired,
  cityList: PropTypes.array.isRequired,
  cityDetailedList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  currencyList: state.user.currencyList,
  countryList: state.user.countryList,
  regionList: state.user.regionList,
  cityList: state.user.cityList,
  cityDetailedList: state.user.cityDetailedList,
});
export default connect(mapStateToProps, {
  get_currency_list,
  get_country_list,
  get_region_list,
  get_city_list,
  get_city_detailed_list,
})(Configuration);
