import React, { useEffect } from 'react';
import { connect } from "react-redux";
import {
  Card,
  Col,
  Container,
  Row,
  ListGroup,
  ListGroupItem
} from "shards-react";
import InfoTooltip from "../../components/common/InfoTooltip";
import { Link } from "react-router-dom";
import ClientMessageCenter from "./ClientMessageCenter";
import {get_client_dashboard_data} from "../../redux/actions/client/clientHome";


function ClientHome({ user, title, dashboardData, changePageMeta,get_client_dashboard_data }) {

  useEffect(() => {
    changePageMeta(`${title} - ${user.clientName}`)
    get_client_dashboard_data()
  }, []);

  let userPermissions = user.userRoles.find(role => role.role === "Client User" || role.role === "Client HR" || role.role === "Client Billing" || role.role === "Client Admin").permissions

  return (
    <>
    
 
      <Container fluid className="px-4 pt-4 pb-3">
      <Card small
                className="mb-5 w-100 details-card mt-3 mb-4 d-flex personalParent">
          <span className="iconBox">
          <i class="fas fa-bullhorn iconStyle"></i>          
           </span>
            <h4 className="m-0 section-title headerStyle">Announcements</h4>
            <ListGroup flush>
              <ListGroupItem className="border-card p-3 mt-3">
                <Row className="pl-4">
                  <Col>
                  <u><h5><Link className="font-italic font-weight-bold" to="/client-announcements">Revised Canadian Hiring Process</Link></h5></u>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
          
        <Row className="align-items-end" form>
          <Col sm={12} lg={3} className="px-2 mb-2 mb-lg-0">
            <Card className="py-2">
              <h4 className="text-center"><div className="h3 d-inline"><i className="material-icons shadow p-2 rounded-circle">groups</i></div> Workers <small className="d-inline-block"><InfoTooltip msg={"Total number of active workers"} /></small></h4>
              <div className="h3 text-primary text-center pb-1"><Link to="/client-search-clientWorkers?employmentStatus=Active" target="_blank">{dashboardData.workers || 0}</Link> </div>
            </Card>
          </Col>
          <Col sm={12} lg={3} className="px-2 mb-2 mb-lg-0">
            <Card className="py-2">
              <h4 className="text-center"><div className="h3 d-inline"><i className="material-icons shadow p-2 rounded-circle">question_answer</i></div> Messages  <small className="d-inline-block"><InfoTooltip msg={"Total number of unread messages"} /></small></h4>
              <div className="h3 text-primary text-center pb-1"><Link  to="client-message-center?status=Unread" target="_blank">{dashboardData.messages || 0}</Link> </div>
            </Card>
          </Col>
          {userPermissions.includes("Leaves Module") && <Col sm={12} lg={3} className="px-2 mb-2 mb-lg-0">
            <Card className="py-2">
              <h4 className="text-center">
                <div className="h3 d-inline"><i
                  className="material-icons shadow p-2 rounded-circle">today</i>
                </div>
                Leaves <small className="d-inline-block"><InfoTooltip
                msg={"Total number of submitted leaves"}/></small></h4>
              <div className="h3 text-primary text-center pb-1"><Link
                to="client-search-leaves?leaveStatus=Submitted"
                target="_blank">{dashboardData.leaves || 0}</Link></div>
            </Card>
          </Col>}
          {userPermissions.includes("Expense Module") && <Col sm={12} lg={3} className="px-2">
            <Card className="py-2">
              <h4 className="text-center">
                <div className="h3 d-inline"><i
                  className="material-icons shadow p-2 rounded-circle">receipt_long</i>
                </div>
                Expenses <small className="d-inline-block"><InfoTooltip
                msg={"Total number of submitted expenses"}/></small></h4>
              <div className="h3 text-primary text-center pb-1"><Link
                to="client-search-expenses?expenseStatus=Submitted"
                target="_blank">{dashboardData.expense || 0}</Link></div>
            </Card>
          </Col>}
        </Row>
      </Container>
      <ClientMessageCenter showHeading />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  dashboardData: state.clientHome.dashboardData
});



export default connect(mapStateToProps, {
  get_client_dashboard_data
})(ClientHome);
