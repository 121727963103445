import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {
  Button,
  Card, CardBody,
  CardHeader, Col, Container, DatePicker, Form,
  FormInput,
  FormSelect, FormTextarea, Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import {useHistory, useParams, useLocation} from "react-router-dom";
import {
  clear_conversation_details,
  sub_get_conversation_detail,
  get_message_category_list,
  get_message_status_list,
  search_user_messages,
  sub_send_message, sub_set_conversation_status, get_user_profile,
  get_user_list_role,
  get_assigned_message_info_list,
  save_assigned_message_info,
  update_assigned_message_info,
  delete_assigned_message_info,
  upload_attachment,
  remove_attachment,
  remove_all_attachments
} from "../../redux/actions/user";
import {
  setAPIDate,
  utcTimestampToLocalTimeString
} from "../../utils/date";
import {
  get_subscriber_note_list,
  save_note
} from "../../redux/actions/subscriber/subscriberUsers";
import {getStatusClass, validateFileName, validateDuplicateFileName} from "../../utils/general";
import UserInfoCard
  from "../../components/subscriber/message-acitivy/UserInfoCard";
import ConfirmAlert from "../../components/common/ConfirmAlert";
import {setDateForTable} from "../../utils/date";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import InfoTooltip from "../../components/common/InfoTooltip";
import {setAlert} from "../../redux/actions/alert";
import DisplayMessageFiles from '../../components/common/DisplayMessageFiles';
import queryString from "querystring";
function SubMessageActivity({
  get_user_profile,
  sub_get_conversation_detail,
  get_message_status_list,
  get_message_category_list,
  search_user_messages,
  sub_send_message,
  get_subscriber_note_list,
  sub_set_conversation_status,
  clear_conversation_details,
  save_note,
  get_user_list_role,
  get_assigned_message_info_list,
  save_assigned_message_info,
  update_assigned_message_info,
  delete_assigned_message_info,
  IsAssigneeDeleted,
  IsSaveAssignee,
  upload_attachment,

  user,
  userProfile,
  messageCategoryList,
  messageStatusList,
  conversation,
  subscriberNoteList,
  userMessages,
  changePageMeta,
  title,
  roleUserList,
  assignedMsgList,
  subscriberId,
  setAlert,
  remove_attachment,
  remove_all_attachments
}) {

  let initialState = {
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
    keywords: "",
    categoryName: "",
    status: "",
    assignRole: "",
    assignUser: "",
    filterState:{
      page: 0
    }
  };
  initialState.tableState=initialState.filterState
  let {userId, refNum} = useParams();
  let history = useHistory();
  const [formData, setFormData] = useState(initialState);
  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  const [conversationState, setConversationState] = useState({
    status: "",
    msg: "",
    notes: ""
  });
  const [attachmentFiles, setAttachmentFiles] = useState([])
  const fileRef = useRef();
 const [attachmentFileNames, setAttachmentFileNames]  = useState([]);
  const attachmentFileNamesRef = useRef([]);
const location = useLocation();

let queryParams = queryString.parse(location.search.replace("?", ""));

 const [uploadingAttachment, setUploadingAttachment] = useState(false);
  const handleUpload = async (e) => {
    e.preventDefault();

        const supportedExtensions = ['.doc', '.docx', '.png','.jpeg', '.pdf']
    var file = e.target.files[0];
    let fileSize = file !== undefined ? file.size / (1024 * 1024) : 0;
    let fileExtension = file.name.substring(file.name.lastIndexOf('.'), file.name.length);
    fileRef.current.value = ""

    if(validateDuplicateFileName(file.name, attachmentFiles)) return;

    let valid = validateFileName(file.name);

    if(!valid){
      setAlert("File name should only contain letters [a-z, A-Z], numbers [0-9], space [' '], period [.], underscore [_] and hyphen [-].","danger");
      return;
    }
    
    if(!supportedExtensions.includes(fileExtension.toLowerCase())){
      setAlert("Please attach JPEG, PNG, PDF, DOC only.","danger");
      return;
    }
    
    // if(attachmentFiles.length === 8){
    //   setAlert("Maximum 8 Attachments Allowed.","danger");
    //   return;
    // }

    for(let file of attachmentFiles){
      fileSize = fileSize + (file.size / (1024 * 1024))
    }

    if(fileSize > 8){
      setAlert("Maximum File Size Exceeded.","danger");
      return;
    }
    //setUploadingAttachment(true);
    setAttachmentFiles([...attachmentFiles, file]);
    let fileName = await upload_attachment(file,user.userId, 'subscriber');

   if(fileName === null){
    let filteredAttachments = attachmentFiles.filter(file1 => file1.name !== file.name);
    setAttachmentFiles([...filteredAttachments]);
    return;
   }
    if(fileName && !fileName.includes(file.name)){
      return;
    }
    //setUploadingAttachment(false);
    //attachmentFileNames.push(fileName)
    attachmentFileNamesRef.current.push(fileName);
    setAttachmentFileNames([...attachmentFileNamesRef.current]);
    

  }

  const removeFile = async (file)=>{

    if(uploadingAttachment) return;

    
    let filteredAttachments = attachmentFiles.filter(f => f.name !== file.name);
    let fileName = attachmentFileNamesRef.current.filter(fileName => fileName && fileName.includes(file.name));
    if(fileName.length === 0)
    {
      //setAttachmentFiles([...filteredAttachments]);
      return;
    }
    
    let response = await remove_attachment(user.userId, fileName[0], 'subscriber');
    setAttachmentFiles([...filteredAttachments]);
    attachmentFileNamesRef.current = [...attachmentFileNamesRef.current.filter(f => f !== fileName[0])];
    setAttachmentFileNames([...attachmentFileNames.filter(f => f !== fileName[0])])
  }
 const removeAllAttachmnts = (attachmentFileNames)=>{

  let attachmentFiles = attachmentFileNames.toString();
  remove_all_attachments(user.userId, attachmentFiles, 'subscriber');

 }

  const renderFiles = ()=>{
   return  (<div className=' pr-2 text-nowrap overflow-auto'>{attachmentFiles.map(file=>{
    
      return(
        <span className='border rounded mr-2 pl-1 pr-1' key={file.name}>
          <small className='font-weight-bold'>
            <span className='file-badge-text'>{(file.name.length > 27) ? file.name.substring(0,25) + file.name.substring(file.name.lastIndexOf('.'),file.name.length)  : file.name}</span>
            <i className="fa-solid fa-xmark text-small ml-1" style={{color:'#ec2b2b'}} role="button" onClick={()=> removeFile(file)} ></i>
          </small>
        </span>
      )
    })}
    </div> 
    )
  }
  useEffect(() => {
    setConversationState({
      ...conversationState,
      status: conversationState.notes
    })  

    if(queryParams && queryParams.status){
       setFormData({ ...formData, status: queryParams.status});
    }
   
  }, [conversation.status]);


  useEffect(() => {
    if (refNum)
    {
      sub_get_conversation_detail(refNum)
      get_assigned_message_info_list(refNum)
    }
    else
      get_user_profile(userId)
  }, [refNum]);


  useEffect(() => {
    if (userId) {
      search_user_messages(userId, queryParams.categoryName, queryParams.status)
      get_subscriber_note_list(userId)
    }
  }, [userId]);

  useEffect(() => {
    get_message_category_list()
    get_message_status_list()

    return () => {
      clear_conversation_details();
      removeAllAttachmnts(attachmentFileNamesRef.current);
    }
  }, []);

  // useEffect(()=> {
  //   return removeAllAttachmnts;
  // }, [attachmentFileNames])

  useEffect(() => {
    setConversationState({...conversation, msg: ""})
  }, [conversation.messages]);

  useEffect(() => {
    setConversationState({...conversation, notes: ""})
  }, [subscriberNoteList]);


  //scrolls notes/messages area to the bottom cause new messages/notes are always in the bottom
  useEffect(() => {
    let area = document.getElementsByClassName('conversations-area')
    area[0].scrollTop = area[0].scrollHeight
    area[1].scrollTop = area[1].scrollHeight
  }, [refNum, conversation, subscriberNoteList]);


  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onRoleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value, assignUser:''});
  };

  let onAssignedMsgStatusChange = async (status, assignedMsgRow) => {
    await update_assigned_message_info(assignedMsgRow.refNum, refNum,assignedMsgRow.assignToRole, assignedMsgRow.assignToUserName,status);
    get_assigned_message_info_list(refNum);
  }

  const onChangeConversationState = (e) => {
    setConversationState({
      ...conversationState,
      [e.target.name]: e.target.value
    });
  };

  let sendMSG = (e) => {
    e.preventDefault();
    sub_send_message(conversation.refNum, userId, conversation.categoryName, conversation.categoryId, conversationState.status, conversationState.msg || '', "", JSON.stringify(attachmentFileNamesRef.current) );
    setAttachmentFiles([]);
    setAttachmentFileNames([]);
    attachmentFileNamesRef.current = []
  }

  let addNote = (e) => {
    e.preventDefault();
    save_note(userId, 0, conversation.refNum, 1, conversationState.notes);
  }

  let onSubmit = (e) => {
    e.preventDefault();
    search_user_messages(userId, formData.categoryName, formData.status, formData.keywords)
  }

  let clearState = () => {
    setFormData(initialState)
    search_user_messages(userId)
  };
  useEffect(() => {
    if(IsAssigneeDeleted && IsAssigneeDeleted!=false){
      get_assigned_message_info_list(refNum);
                           }
                          }, [IsAssigneeDeleted]);
  let deleteAssignment = async (deleteRefNum) => {
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete this assignment?',
      visible: true,
      onConfirm: () => {
        IsAssigneeDeleted=false;
         delete_assigned_message_info(deleteRefNum);
        
      }
    })
    
  }
  useEffect(() => {
    if(IsSaveAssignee && IsSaveAssignee!=false){
      get_assigned_message_info_list(refNum);
      
      setFormData({ ...formData, assignRole: "",assignUser:"" });
                           }
                          }, [IsSaveAssignee]);

  let saveAssignedMessageInfo = async () => {
    if(formData.assignRole===""){
      setAlert("Please Select Role","danger")

    }
    if(formData.assignUser===""){
      setAlert("Please Select User","danger")

    }
    else{
      await save_assigned_message_info(0, refNum,formData.assignRole, formData.assignUser);
     

    }
    
  }

  let categoryRole = (conversation.role === "Worker") ? "Worker" : "Client User";
  let messageCategoryOptions = messageCategoryList.filter(category => category.role === categoryRole).map(category =>
    <option key={category.categoryName}
      value={category.categoryName}>{category.categoryName}</option>)

  let messageStatusOptions = messageStatusList.map(status => <option key={status}
    value={status}>{status}</option>)

  let current = new Date();
  let month = current.getMonth()+1;
  let todayDate = `${current.getFullYear()}${month > 9 ? month : '0'+month}${current.getDate()}`;
  
  let roleUserOptions = roleUserList.map(user =>
    <option key={user.name}
      value={user.userId}>{user.name}</option>)

      const tableColumns = [
        {
          Header: "User Role",
          accessor: "assignToRole",
       
          className: "justify-content-start text-left text-wrap text-break"
        },
    
        {
          Header: "User Name",
          accessor: "assignToUserName",
          
          className: "justify-content-start text-left text-wrap text-break"
        },
        {
          Header: "Date",
          accessor: "createdDate",
       
          minWidth: 100,
          Cell: row =>
            setDateForTable(row.original.assignedDate),
        },
        {
          Header: "Status",
          accessor: "status",
          maxWidth: 150,
          className: "text-center",
          Cell: row =>
              <FormSelect name='status' value={row.original.status}
              onChange={e=>onAssignedMsgStatusChange(e.target.value,row.original)}>
              <option value=''>All Status</option>
              {messageStatusOptions}
            </FormSelect>,
        },
    
        {
          Header: "Action",
          accessor: "",
         
          className: "text-center",
          Cell: row => <Link onClick={e=>deleteAssignment(row.original.refNum)}>Delete</Link>
        }]

  let msgRead;
  let messageItems = ((conversation.refNum && (conversation.refNum == refNum || !refNum)) ? conversation.messages : []).map(message => {
    if (user.userId !== message.userId) {
      //non-logged in user's messages will float to left
      msgRead = true
      return (
        <div className="d-flex flex-column mb-3 mr-5 pr-5" key={message.messageTime + message.messageId}>
          <span
            className="small">{utcTimestampToLocalTimeString(message.messageTime)} by {message.userName}</span>
          <span>{message.messageBody}</span>
          {message.messageAttachments  && message.messageAttachments.map(attachment =>{
            return <DisplayMessageFiles 
            setConfirmAlert={setConfirmAlert} 
            confirmAlert={confirmAlert} 
            key={conversation.refNum + attachment.messageId + attachment.systemFileName} 
            refNumber={conversation.refNum}  
            userId={message.userId} 
            attachment={attachment} 
            sender={false}/>
          })}
        </div>)
    } else {
      return (
        <div className="d-flex flex-column align-items-end mb-3 ml-5 pl-5 pr-2" key={message.messageTime + message.messageId}>
          <span
            className="small">{utcTimestampToLocalTimeString(message.messageTime)} by {message.userName}</span>
          <span>{message.messageBody}</span>
          {message.messageAttachments  && message.messageAttachments.map(attachment =>{
            return <DisplayMessageFiles 
            setConfirmAlert={setConfirmAlert} 
            confirmAlert={confirmAlert} 
            key={conversation.refNum + attachment.messageId + attachment.systemFileName} 
            refNumber={conversation.refNum}  
            userId={message.userId} 
            attachment={attachment} 
            sender={true}/>
          })}
          
        </div>)
    }
  })

  useEffect(() => {
    if (conversation.status === "Unread" && msgRead) {
      sub_set_conversation_status(userId, conversation.refNum, "In-Process", false)
    }
  }, [conversation]);

  let notesItems = (subscriberNoteList || []).map(note => {
    return <div className="message mb-3">
      <span
        className="d-block small">{utcTimestampToLocalTimeString(note.date)} by {note.userName}</span>
      <span className="d-block ">{note.noteText}</span>
    </div>
  })

  let userMesssagesItems = (userMessages || []).map(message => {
    return <a key={message.messageId + message.messageTime} onClick={e => {
      history.replace("/sub-message-activity/" + userId + "/" + message.conversationId + "?status=" + formData.status)
    }}
              className={"message-item d-block border-bottom p-2 " + (refNum == message.conversationId ? "active" : "")}>
      <span className="d-block">{message.categoryName}</span>
      <span
        className="text-dark">{(message.messageBody.length > 40 ? message.messageBody.substr(0, 40) + "..." : message.messageBody)}</span>
    </a>
  })



  let onBackClick = () => {
    if (conversationState.msg || conversationState.notes) {
      setConfirmAlert({
        ...confirmAlert,
        confirmMsg: 'Are you sure you want to exit this page? You will lose unsent data.',
        visible: true,
        onConfirm: () => {
          history.goBack();
        }
      })
    } else
      history.goBack();
  };

  useEffect(()=> {
    
    setUploadingAttachment(attachmentFiles.length !== attachmentFileNames.length)
  }, [attachmentFiles, attachmentFileNames])

  return (
    <>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Container fluid className="main-content-container px-4 py-3">
        <UserInfoCard conversation={conversation} userProfile={userProfile}
                      userId={userId} title={title} changePageMeta={changePageMeta}/>

        <Row>
          <Col sm={12} lg={4} className="messages-col">
            <div className="position-relative messages-col h-100 d-flex">
            <span className="iconBox"><i
              className="fas fa-search iconStyle"/></span>
              <h4 className="m-0 section-title headerStyle">Search Messages</h4>
              <Card className="tableCard my-4 p-0 border-card">
                <CardHeader className="p-0 pt-3 border-card">
                  <Container fluid
                             className="file-manager__filters border-bottom">
                    <Form onSubmit={onSubmit}>
                      <Row className="px-3">

                        <Col className="p-0 col-12">
                          <div className="d-flex align-items-center p-2">
                            <FormInput
                              name='keywords'
                              value={formData.keywords}
                              onChange={onChange}
                              placeholder="Enter Keywords"
                            />
                          </div>
                        </Col>

                        <Col className="p-0 col-12">
                          <div className="d-flex align-items-center p-2">
                            <FormSelect name='categoryName'
                                        value={formData.categoryName}
                                        onChange={onChange}>
                              <option value=''>All Categories</option>
                              {messageCategoryOptions}
                            </FormSelect>
                          </div>
                        </Col>

                        <Col className="p-0 col-12">
                          <div className="d-flex align-items-center p-2">
                            <FormSelect name='status' value={formData.status}
                                        onChange={onChange}>
                              <option value=''>All Status</option>
                              {messageStatusOptions}
                            </FormSelect>
                          </div>
                        </Col>

                        <Col
                          className="p-0 col-12 d-flex justify-content-between">
                          <Button className="mx-2 py-2 my-2 w-100" size='sm'
                                  theme="accent" type="submit">Search</Button>
                          <Button className="mx-2 py-2 my-2 w-100" size='sm'
                                  theme="accent" type="button"
                                  onClick={clearState}>Clear</Button>
                        </Col>
                      </Row>
                    </Form>
                  </Container>
                  <span className="d-block border-bottom text-center py-2">
                  User Messages
                </span>
                </CardHeader>
                <CardBody className="p-0 overflow-auto messages-list">
                  {userMesssagesItems.length === 0 &&
                  <p className="text-center text-light mt-2">No results
                    found</p>}
                  {userMesssagesItems}
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col sm={12} lg={8} className="fixed-right">
            <div className="position-relative mb-5">
            <span className="iconBox"><i
              className="fa-solid fa-comment-dots iconStyle"/></span>
              <h4 className="m-0 section-title headerStyle">Conversations</h4>
              <Card className="tableCard my-4 border-card">
                <CardHeader className="p-0 py-3 border-card">
                  <Container fluid
                             className="file-manager__filters border-bottom">
                    <Row className="px-3">

                      <Col className="p-0 col-12 col-md-6 col-lg">
                        <div className="d-flex align-items-center p-2">
                          <FormInput
                            name='category'
                            value={conversation.categoryName}
                            placeholder="Category"
                            readOnly
                            className={(refNum)?"bg-white":""}
                          />
                        </div>
                      </Col>

                      <Col className="p-0 col-12 col-md-6 col-lg">
                        <div className="d-flex align-items-center p-2">
                          <DatePicker
                            id='createdDate'
                            autoComplete='off'
                            name='createdDate'
                            dateFormat='yyyy-MM-dd'
                            value={setAPIDate(conversation.createdDate)}
                            selected={setAPIDate(conversation.createdDate)}
                            required
                            placeholderText="YYYY-MM-DD"
                            readOnly
                            className={(refNum)?"bg-white":""}
                          />
                        </div>
                      </Col>

                      <Col className="p-0 col-12 col-md-6 col-lg">
                        <div className="d-flex align-items-center p-2">
                          <FormSelect name='status'
                                      className={getStatusClass(conversationState.status).replace("text", "border")}
                                      value={conversationState.status}
                                      onChange={e => {
                                        sub_set_conversation_status(userId, conversation.refNum, e.target.value)
                                        onChangeConversationState(e)
                                      }}
                                      disabled={!refNum}>
                            <option value="Unread" className={(conversationState.status !== "Unread"?"d-none":"")}>Unread</option>
                            <option value="In-Process">In-Process</option>
                            <option value="Closed">Closed</option>
                          </FormSelect>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </CardHeader>
                <CardBody className="p-2 border-card">
                  <div className="conversations-area overflow-auto">
                    {messageItems}
                  </div>
                  <Form onSubmit={sendMSG}>
                  <div>
                  <InputGroup className="mb-2">
                   
                    <FormTextarea id="msgBox" rows="2" name="msg" className="message-box"
                                  value={conversationState.msg} required={attachmentFileNames.length === 0}
                                  placeholder="Enter a message"
                                  disabled={!refNum}
                                  onChange={onChangeConversationState}/>
                                  
                                  <InputGroupAddon type="prepend">
                          
                      <InputGroupText className="rounded-right pr-0" onClick={()=> fileRef.current.click()} role="button">
                     
                        <i className="fa-solid fa-paperclip text-dark iconStyle" style={{rotate:'180deg'}}></i>
                        <InfoTooltip infoClass="mt-n4"
                        cssClassName="text-start custom-tooltip-inner"
                        msg="<div align='left'><b class='float-left'>Upload Instructions</b><br/>
                        1. Maximum upload size is 8MB. <br/>
                        2. Allowed file types are PDF, JPEG, PNG, DOC. <br/>
                        3. File name should only contain letters [a-z, A-Z], numbers [0-9], space [' ' ], period[.], underscore [ _ ] and hyphen[-]. </div>"/>
                        </InputGroupText>
                    </InputGroupAddon>
                    
                    <input ref={fileRef} onChange={handleUpload} type="file" hidden  accept=".pdf, .jpeg, .png, .docx, .doc"/>
                  </InputGroup>
                  {renderFiles()}
                  </div>
                    
                    <Button className="mx-2 py-2 my-4 float-right" size='sm'
                            theme="accent" type="submit"
                            disabled={conversationState.status === "Closed" || !refNum || uploadingAttachment}>Send</Button>
                    <Button className="mx-2 py-2 my-4 float-right" size='sm'
                            theme="accent" type="button"
                            disabled={!refNum}
                            onClick={e => setConversationState({
                              ...conversationState,
                              msg: ""
                            })}>Clear</Button>
                  </Form>
                </CardBody>
              </Card>
            </div>

            <div className="position-relative mb-5">
              <span className="iconBox"><i
                className="fas fa fa-share-alt iconStyle" /></span>
              <h4 className="m-0 section-title headerStyle">Assign Message
              <InfoTooltip
                        msg="Assign message to a role group or an individual user"/>
              </h4>
              <Card className="tableCard my-4 border-card">
                <CardHeader className="p-0 py-3 border-card">
                  <Container fluid
                    className="file-manager__filters border-bottom">
                    <Row className="px-3">

                      <Col className="p-0 col-12 col-md-6 col-lg">
                        <div className="d-flex align-items-center p-2">
                        <FormSelect name='assignRole'
                              value={formData.assignRole}
                              onChange={e => {
                                onRoleChange(e)
                                get_user_list_role(subscriberId, e.target.value)
                              }}>
                              <option value=''>Select Role</option>
                              <option value="SubscriberUser">Subscriber - User</option>
                              <option value="SubscriberHr">Subscriber - HR</option>
                              <option value="SubscriberBilling">Subscriber - Billing</option>
                              <option value="SubscriberAdmin">Subscriber - Admin</option>
                            </FormSelect>                       
                          </div>
                      </Col>

                      <Col className="p-0 col-12 col-md-6 col-lg">
                        <div className="d-flex align-items-center p-2">
                        <FormSelect name='assignUser'
                              value={formData.assignUser}
                              onChange={onChange}>
                              <option value=''>Select User</option>
                              {roleUserOptions}
                            </FormSelect>
                        </div>
                      </Col>

                      <Col className="p-0 col-12 col-md-6 col-lg">
                        <div className="d-flex align-items-center p-2">
                        <DatePicker
                            id='assignDate'
                            autoComplete='off'
                            name='assignDate'
                            dateFormat='yyyy-MM-dd'
                            value={setAPIDate(todayDate)}
                            selected={setAPIDate(todayDate)}
                            required
                            placeholderText="YYYY-MM-DD"
                            readOnly
                            className={(refNum) ? "bg-white" : ""}
                          />
                        </div>
                      </Col>
                      
                    </Row>
                    <Row>
                    <Col
                  className="file-manager__filters__rows d-flex align-items-center col-6">
                  <span>Show</span>
                  <FormSelect size="sm" name='pageSize' value={formData.pageSize}
                              onChange={onChange}>
                    {formData.pageSizeOptions.map((size, idx) => <option key={idx}
                                                                value={size}>
                      {size} rows
                    </option>)}
                  </FormSelect>
                </Col>
                <Col
                  className="file-manager__filters__search d-flex justify-content-end col-6">
                  <Button className="my-2 py-2 mx-2" size='sm'
                      theme="accent" type="button"
                      disabled={!refNum}
                      onClick={clearState}>Clear</Button>
                      <Button className="my-2 py-2 mx-2" size='sm'
                      theme="accent" type="button"
                      disabled={!refNum}
                      onClick={saveAssignedMessageInfo}>Assign</Button>
                </Col>


                    <div className="file-manager__filters__search d-flex justify-content-end col-6 col">
                        
                        </div>
                      
                      </Row>
                      <Row>
              

            </Row>
                  </Container>
                </CardHeader>
                <CardBody className="p-2 border-card">
                <ReactTable
            className="assignedMsgItemsTable"
            columns={tableColumns}
            data={assignedMsgList}
            pageSize={Number(formData.pageSize)}
            showPageSizeOptions={false}
            resizable={false}
            noDataText="No results found"
          />
                </CardBody>
              </Card>
            </div>

            <div className="position-relative">
            <span className="iconBox"><i
              className="fas fa-clipboard iconStyle"/></span>
              <h4 className="m-0 section-title headerStyle">Subscriber Notes
                (Internal Only)</h4>
              <Card className="tableCard my-4 p-0 border-card">
                <CardBody className="p-2 pt-5 border-card">
                  <div className="conversations-area notes-area overflow-auto">
                    {notesItems}
                  </div>
                  <Form onSubmit={addNote}>
                    <FormTextarea id="noteBox" name="notes" required
                                  onChange={onChangeConversationState}
                                  placeholder="Enter a note"
                                  value={conversationState.notes} rows="2"/>
                    <Button disabled={!conversationState.notes || conversationState.notes.length === 0} className="mx-2 py-2 my-2 float-right" size='sm'
                            theme="accent" type="submit">Add Note</Button>
                    <Button className="mx-2 py-2 my-2 float-right" size='sm'
                            theme="accent" type="button"
                            onClick={e => setConversationState({
                              ...conversationState,
                              notes: ""
                            })}>Clear</Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Row className="px-3">
          <Col sm="12" md="12" lg="12"
               className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
            <Button className="mx-1 mb-2 py-2 mx-md-2" size="sm"
                    theme="accent" onClick={onBackClick}>Back</Button>
          </Col>
        </Row>

      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  userProfile: state.user.userProfile,
  conversation: state.user.conversation,
  userMessages: state.user.userMessages,
  messageCategoryList: state.user.messageCategoryList,
  messageStatusList: state.user.messageStatusList,
  subscriberNoteList: state.subscriberUsers.subscriberNoteList,
  roleUserList: state.user.roleUserList,
  assignedMsgList: state.user.assignedMsgList,
  IsAssigneeDeleted: state.user.IsAssigneeDeleted,
  IsSaveAssignee: state.user.IsSaveAssignee,
  numPages: state.user.numPages,
  subscriberId: state.user.user.userRoles.filter(item => item.role.startsWith("Subscriber"))[0].domainId,
  messageAttachment : state.user.messageAttachment
});

export default connect(mapStateToProps, {
  get_user_profile,
  sub_get_conversation_detail,
  get_message_category_list,
  get_message_status_list,
  search_user_messages,
  sub_send_message,
  upload_attachment,
  get_subscriber_note_list,
  sub_set_conversation_status,
  clear_conversation_details,
  save_note,
  get_user_list_role,
  get_assigned_message_info_list,
  save_assigned_message_info,
  update_assigned_message_info,
  delete_assigned_message_info,
  setAlert,
  remove_attachment,
  remove_all_attachments
})(SubMessageActivity);
