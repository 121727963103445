import api from "../../../utils/api";
import {
  GET_ARTISAN_JOURNEY, GET_ARTISAN_JOURNEY_ERROR,
  GET_HIRE_DATE,
  GET_HIRE_DATE_ERROR,
  VIEW_ARTISAN_PROFILE,
  VIEW_ARTISAN_PROFILE_ERROR
} from "../types";
import {setAlert} from "../alert";

export const view_artisan_profile = () => async (dispatch) => {
  try {
    const res = await api.get(`api/user/viewArtisanProfile`);
    dispatch({
      type: VIEW_ARTISAN_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: VIEW_ARTISAN_PROFILE_ERROR,
    });
  }
};

export const get_hire_date = () => async (dispatch) => {
  try {
    const res = await api.get(`api/user/getHireDate`);
    dispatch({
      type: GET_HIRE_DATE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_HIRE_DATE_ERROR,
    });
  }
};


export const get_artisan_journey = (
  userId
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/user/getArtisanJourney?userId=${userId}`
    );
    dispatch({
      type: GET_ARTISAN_JOURNEY,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success && error.response.status !== 401) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: GET_ARTISAN_JOURNEY_ERROR,
    });
  }
};
