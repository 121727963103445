import CBoxLayout from "../layouts/CBoxLayout";
import Reports from "../views/worker/Reports";
import Workers from "../views/client/ClientWorkers";
import ClientLeaves from "../views/client/ClientLeaves";
import ClientViewLeave from "../views/client/ClientViewLeave";
import ClientExpenses from "../views/client/ClientExpenses";
import ClientViewExpenseReport from "../views/client/ClientViewExpenseReport";
import ApproveMileageItem from "../views/subscriber/ApproveMileageItem";
import ApproveExpenseItem from "../views/subscriber/ApproveExpenseItem";
import ClientViewWorkerProfile from "../views/client/ClientViewWorkerProfile";
import ClientViewProfile from "../views/client/ClientViewProfile";
import ClientMessageCenter from "../views/client/ClientMessageCenter";
import ClientAddEditMessage from "../views/client/ClientAddEditMessage";
import ClientHome from "../views/client/ClientHome";
import Announcements from "../views/client/Announcements";
export default [
  {
    path: "/client-home",
    layout: CBoxLayout,
    component: ClientHome,
    title:"Home",
    subtitle:"Client",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"]
  },
  {
    path: "/reports",
    layout: CBoxLayout,
    component: Reports,
    title:"Reports",
    subtitle:"View",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"]
  },

  {
    path: "/client-search-clientWorkers",
    layout: CBoxLayout,
    component: Workers,
    title:"Workers",
    subtitle:"Search",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"]
  },
  {
    path: "/client-search-leaves",
    layout: CBoxLayout,
    component: ClientLeaves,
    title:"Leaves",
    subtitle:"Search",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"],
    permission:"Leaves Module",
  },

  {
    path: "/client-view-worker-leave/:refNum",
    layout: CBoxLayout,
    component: ClientViewLeave,
    title:"Leave",
    subtitle:"View",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"],
    permission:"Leaves Module",
  },
  {
    path: "/client-announcements",
    layout: CBoxLayout,
    component: Announcements,
    title:"Announcements",
    subtitle:"Client",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"]
  },
  {
    path: "/client-search-expenses",
    layout: CBoxLayout,
    component: ClientExpenses,
    title:"Expenses",
    subtitle:"Search",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"],
    permission:"Expense Module",
  },

  {
    path: "/client-view-expense-sheet/:sheetRefNum",
    layout: CBoxLayout,
    component: ClientViewExpenseReport,
    title:"Expense Report",
    subtitle:"Approve",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"],
    permission:"Expense Module",
  },

  {
    path: "/client-view-mileage-item/:sheetRefNum/:refNum",
    layout: CBoxLayout,
    component: ApproveMileageItem,
    title:"Mileage Item",
    subtitle:"Approve",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"],
    permission:"Expense Module",
  },

  {
    path: "/client-view-expense-item/:sheetRefNum/:refNumber",
    layout: CBoxLayout,
    component: ApproveExpenseItem,
    title:"Expense Item",
    subtitle:"Approve",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"],
    permission:"Expense Module",
  },

  {
    path: "/client-view-worker-profile/:id",
    layout: CBoxLayout,
    component: ClientViewWorkerProfile,
    title:"Worker Profile",
    subtitle:"View",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"]
  },

  {
    path: "/client-view-profile",
    layout: CBoxLayout,
    component: ClientViewProfile,
    title:"Profile",
    subtitle:"View",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"]
  },

  {
    path: "/client-message-center",
    layout: CBoxLayout,
    component: ClientMessageCenter,
    title:"Center",
    subtitle:"Message",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"]
  },

  {
    path: "/client-edit-message/:refNum",
    layout: CBoxLayout,
    component: ClientAddEditMessage,
    title:"Message",
    subtitle:"Edit",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"]
  },

  {
    path: "/client-add-message/:refNum/:msgCategory",
    layout: CBoxLayout,
    component: ClientAddEditMessage,
    title:"Message",
    subtitle:"Add",
    roles:["Client User","Client HR", "Client Billing", "Client Admin"]
  },
]
