import React, {useState} from 'react';
import {
  Button, Card, CardBody,
  CardHeader,
  Col,
  Container,
  FormSelect,
  Row
} from "shards-react";
import {getStatusClass, numberToMonetary} from "../../../utils/general"
import {Link, useLocation} from "react-router-dom";
import ReactTable from "react-table";
import {setDateForTable} from "../../../utils/date";
import PropTypes from "prop-types";

function ExpenseItemsCard(props) {

  let location=useLocation();
  const [state, setState] = useState({
    pageSizeOptions: [10, 15, 20, 25, 30],
    pageSize: 10,
  });

  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const openReceipt = (sheetRefNumber, refNum) => {
    window.open(`/expense-item-receipt-popup/${sheetRefNumber}/${refNum}`, "customWindow", "width=500, height=1040");
  }

  const tableData = (props.expenseItemList) ? props.expenseItemList : []

  var {pageSize, pageSizeOptions} = state;

  if (tableData.length < 10) {
    pageSize = tableData.length
    pageSizeOptions = [tableData.length]
  }
  const tableColumns = [
    {
      Header: <span className="text-wrap">Date</span>,
      accessor: "expenseDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.expenseDate),
    },
    {
      Header: <span className="text-wrap">Expense Category</span>,
      accessor: "type",
      maxWidth: 90,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: <span className="text-wrap">Description</span>,
      accessor: "description",
      minWidth: 160,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: <span className="text-wrap">Gross</span>,
      accessor: "grossAmount",
      maxWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => <span
        className="w-100 text-wrap text-right">{numberToMonetary(row.original.grossAmount)}</span>
    },

    {
      Header: <span className="text-wrap">Tax Amount</span>,
      accessor: "taxAmount",
      maxWidth: 80,
      className: "justify-content-end text-wrap text-break",
      Cell: row => <span
        className="w-100 text-wrap text-right">{numberToMonetary(row.original.taxAmount)}</span>
    },

    {
      Header: <span className="text-wrap">Receipt Total</span>,
      accessor: "receiptAmount",
      minWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => <span
        className="w-100 text-wrap text-right">{numberToMonetary(row.original.receiptAmount)}</span>
    },

    {
      Header: <span className="text-wrap">Exchange Rate</span>,
      accessor: "exchangeRate",
      maxWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => parseFloat(row.original.exchangeRate).toFixed(5),
    },
    {
      Header: <span className="text-wrap">Net Amount</span>,
      accessor: "netAmount",
      minWidth: 130,
      className: "justify-content-end text-wrap text-break overflow-visible",
      Cell: row => <span
        className="w-100 text-wrap text-right">{numberToMonetary(row.original.netAmount)}</span>,
      Footer: <div className="d-flex w-100 justify-content-end text-nowrap">
        <span>Sub Total: {props.status === undefined ? '0.00' :  numberToMonetary(props.status === 'Draft' ? props.draftItemTotal :props.submittedItemTotal )}</span>
      </div>
    },

    {
      Header: <span className="text-wrap">Receipt</span>,
      maxWidth: 70,
      className: "text-center text-wrap text-break",
      Cell: row => (row.original.receiptFileName) ? <Link
        onClick={e => openReceipt(row.original.userId, row.original.refNum)}>Receipt</Link> : ''

    },

    {
      Header: <span className="text-wrap">Status</span>,
      accessor: "status",
      maxWidth: 80,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>
    },

    {
      Header: <span className="text-wrap">Ref#</span>,
      accessor: "refNum",
      maxWidth: 60,
      className: "text-center",
      Cell: row =>
        <Link to={{pathname:`/${props.editItemRoute||"edit-expense-item"}/${props.sheetRefNumber}/${row.original.refNum}`,state:location.state}} onClick={e=>props.onEditExpense(row.original.refNum)}>{row.original.refNum}</Link>
    }
  ];

  return (
    <Card className="p-0 mb-4">
      <span className="tableHeader">Add Expense</span>
      <CardHeader className="p-0 pt-2 border-card">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col lg="10"
                 className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6 order-lg-0">
              <span>Show</span>
              <FormSelect
                size="sm"
                name='pageSize'
                value={pageSize}
                onChange={onChange}
              >
                {pageSizeOptions.map((size, idx) => (
                  <option key={idx} value={size}>
                    {size} rows
                  </option>
                ))}
              </FormSelect>
            </Col>

            <Col lg="2"
                 className="file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6">

              <Button className="mx-2 py-2" size='sm' disabled={(["Withdrawn","Approved","Reviewed","Submitted"].includes(props.status))}
                      theme="accent" onClick={props.onAddExpense}>Add
                Expense</Button>

            </Col>

          </Row>
        </Container>
      </CardHeader>
      <CardBody className="p-0">
        <div className="">
          <ReactTable
            columns={tableColumns}
            data={tableData}
            pageSize={Number(pageSize)}
            showPageSizeOptions={false}
            resizable={false}
            className="expenseItemsTable"
            noDataText="No results found"
          />
        </div>
      </CardBody>
    </Card>
  );
}

ExpenseItemsCard.propTypes = {
  expenseItemList: PropTypes.array.isRequired,
  submittedItemTotal: PropTypes.string.isRequired,
  approvedItemTotal: PropTypes.string.isRequired,
  sheetRefNumber: PropTypes.string.isRequired,
}

ExpenseItemsCard.defaultProps = {
  expenseItemList: [],
  submittedItemTotal: "",
  approvedItemTotal: "",
  sheetRefNumber: '',
}

export default ExpenseItemsCard;
