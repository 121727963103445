import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  Col,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
  Container
} from "shards-react";
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import {logout} from "../../../../redux/actions/user";
import InfoTooltip from "../../../../components/common/InfoTooltip";

 class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      visibleRole: false,
      selectedRole:this.props.user.selectedrole
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
    this.toggleRoleActions = this.toggleRoleActions.bind(this);
  }

   
  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }
  toggleRoleActions() {
    this.setState({
      visibleRole: !this.state.visibleRole
    });
  }
   toggleSwitchRole = (eventRef,r) => {
       
    this.setState({
      selectedRole:eventRef
    });
    var user=this.props.user;
    user["selectedrole"]=eventRef;
    
    localStorage.setItem("user", JSON.stringify(user));
    if (eventRef.startsWith("Subscriber")) {
      window.location.replace(window.location.origin+'/sub-home');
     
    } else if (eventRef.startsWith("Client")) {
      window.location.replace(window.location.origin+'/client-home');
    
    } else if (eventRef === "Worker") {
      window.location.replace(window.location.origin+'/worker-home');
    
    }  else if (eventRef.startsWith("System")) {
      window.location.replace(window.location.origin+'/admin-search-subscribers');
     
    }

   
   // window.location.href=window.location.host;
  };
  

  render() {
    return (
      <Container className="p-0">
        {this.props.user.userRoles.length > 1 &&
      <NavItem className=" d-flex justify-content-center align-items-center border-right" tag={Dropdown} caret toggle={this.toggleRoleActions}>
      <Row noGutters className="page-header ">
            <Col className={`d-flex d-md-inline-block align-items-center`}>
              <Col xs="12" sm="12" className="ml-sm-auto mr-sm-auto  pl-2 p-0 pr-2 py-1">
                 <span  className="text-uppercase page-subtitle">Switch Role</span>
                 <InfoTooltip
                            msg="Select the role you want to switch to" />
                 <DropdownToggle caret tag={NavLink} className="text-nowrap px-0 pt-0 w-100 pb-0 align-items-center">
          <span className="d-inline-block font-weight-bold">{this.state.selectedRole}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visibleRole} className="w-100 pb-0 align-items-center">

        {this.props.user.userRoles.map((r) => (
                        <DropdownItem  tag={Link} onClick={this.toggleSwitchRole.bind(this,r.role)} className="border-bottom w-100 align-items-center">
                        {r.role}
                      </DropdownItem>
                      
                      ))}
         
         
         
        </Collapse>
             </Col>
            </Col>
          </Row>
                           
                          
       
      </NavItem>}
      <NavItem className="user-actions d-flex justify-content-center align-items-center" tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <span className="d-inline-block user-name">{this.props.user.displayName}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="/account">
            <i className="material-icons">&#xE7FD;</i> Account
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} onClick={()=>{this.props.logout()}} className="text-danger">
           <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
      </Container>
    );
  }
}

UserActions.propTypes={
   user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
}

const mapStatesToProps=(state)=>({
  user: state.user.user,
})

export default connect(mapStatesToProps,{logout})(UserActions)
