import React, {useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Card, CardBody, CardFooter, CardHeader, Col,
  Container, Form, FormGroup, FormInput, ListGroup,
  ListGroupItem, Nav, NavItem, NavLink, Row
} from "shards-react";
import {connect} from "react-redux";
import {
  login
} from "../redux/actions/user";
import PropTypes from "prop-types";
import {Redirect, useHistory} from "react-router-dom";
import {setAlert} from "../redux/actions/alert";
import {change_password, enable_2fa, generate_2fa} from "../redux/actions/settings";

function EnableTwoFA({
                       user,
                       qrCode,
                       qrImage,
                       require2FA,
                       alerts,
                       generate_2fa,
                       enable_2fa,
                     }) {


  const history = useHistory();
  const [formData, setFormData] = useState({
    userId: user.userId,
    tfaCode: "",
  });
  const {tfaCode} = formData;


  // generate 2 fa
  useEffect(() => {
    generate_2fa(user.userId);
  }, []);

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    enable_2fa(tfaCode);
  };

  if(require2FA){
    history.goBack();
  }

  return (
    <>
      <Container fluid
                 className="main-content-container p-4">
        <Row noGutters className="align-items-stretch">
          <Col className="col-12 pl-2">
            <Card className="h-100 overflow-hidden">
              <CardBody className="overflow-hidden">
                <Row>
                  <Col lg="3" className="col-12 d-flex justify-content-center pb-4 pb-lg-0">
                    {/* QR Image */}
                    <img
                      className="img-thumbnail mx-auto"
                      style={{width:"200px",
                        transform: "scale(1.4)"}}
                      src={qrImage}
                      alt="QR Code"
                    />
                  </Col>
                  <Col lg="5" className="col-12">
                    <div className="twoaf-key text-center px-4 pb-4">
                      <label className="d-block">Scan the QR code and enter the code bleow</label>
                      <small>2FA Key: <span>{qrCode}</span></small>
                    </div>
                    <Form onSubmit={onSubmit}>
                      <FormGroup>
                        <label htmlFor="tfaCode">2FA Code</label>
                        <FormInput
                          type="password"
                          name="tfaCode"
                          value={tfaCode}
                          id="tfaCode"
                          required
                          placeholder="2FA Code"
                          onChange={onChange}
                        />
                      </FormGroup>

                      <div className="d-flex justify-content-center justify-content-lg-end">
                        <Button className="py-2" size="sm"
                                theme="accent" type="submit">Enable 2FA</Button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

EnableTwoFA.propTypes = {
  user: PropTypes.object.isRequired,
  generate_2fa: PropTypes.func.isRequired,
  enable_2fa: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user.user,
  alerts: state.alert,
  require2FA: state.settings.require2FA,
  qrCode: state.settings.qrCode,
  qrImage: state.settings.qrImage,
});

export default connect(mapStateToProps, {generate_2fa, enable_2fa})(
  EnableTwoFA
);
