import React from 'react';
import { useLocation } from 'react-router-dom'
import {
  Col, Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  Nav,
  Navbar, NavbarBrand,
  Row
} from "shards-react";
import MainFooter from "../components/layout/MainFooter";
import {Link, NavLink} from "react-router-dom";

function GuestLayout({children, noFooter=true}) {

  const location = useLocation();
  return (
    <Container fluid>
      <Row>
        <Col
          className="main-content p-0"
          sm="12"
          tag="main"
        >
          {/*navbar*/}
          <div>
            <Container fluid={true} className="p-0">
              <Navbar type="light"  className="align-items-stretch flex-md-nowrap p-0 px-2 pb-2 pb-md-0 px-md-4 justify-content-between">
                <NavbarBrand
                  className={`mr-0 `}
                  href="#"
                  style={{lineHeight: "25px"}}
                >
                  <div className="d-table m-auto">
                    <img
                      id="main-logo"
                      className={`d-inline-block align-top mr-1`}
                      style={{maxWidth: "12rem"}}
                      src={require("../images/logo.png")}
                      alt="Shards Dashboard"
                    />

                  </div>
                </NavbarBrand>
                <Nav tag="nav" navbar className="flex-row align-items-center">
                  {/*navlinks*/}
                  <NavLink className={"px-2 text-dark"} tag={Link} activeClassName={"text-decoration-underline"}  to="/" exact={true}>Home</NavLink>
                  {/*<NavLink className={"px-2 text-dark"} tag={Link}  to="/login">Features</NavLink>*/}
                  {/*<NavLink className={"px-2 text-dark"} tag={Link}  to="/login">Pricing</NavLink>*/}
                  <NavLink className={"ml-4 px-3 py-1 rounded text-dark text-uppercase nav-signing-btn"} activeClassName={"text-decoration-underline"} tag={Link}  to="/login">Sign In</NavLink>
                  {/*[end] navlinks*/}
                </Nav>

              </Navbar>
            </Container>
          </div>
          {/*[end] navbar*/}
          {children}
          {!noFooter && <MainFooter classes='fixed-bottom'/>}
        </Col>
      </Row>
    </Container>
  );
}

export default GuestLayout;
