import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { get_role_list, get_sub_status_list, search_sysadmin_user_list } from '../../redux/actions/user';
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, FormInput,
  FormSelect,
  Row
} from "shards-react";


function SystemAdminUsers({
  user,
  subStatusList,
  roleList,
  get_sub_status_list,
  get_role_list,
  systemUsers,
  search_sysadmin_user_list,
}) {

  useEffect(() => {

  }, []);
  // if(!isAuthenticated)
  // {
  //     return <Redirect to='/login' />;
  // }
  const history = useHistory()

  const [formData, setFormData] = useState({
    subscriberName: '',
    clientName: '',
    userId: '',
    userName: '',
    email: '',
    substatus: '',
    role: '',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const searchUser = () => {
    search_sysadmin_user_list(formData.subscriberName, formData.clientName, formData.userId, formData.userName, formData.email, formData.substatus, formData.role);
  }

  useEffect(() => {
    searchUser();
    get_sub_status_list();
    get_role_list();
  }, [])

  const tableData = systemUsers;
  var { pageSize, pageSizeOptions } = formData;

  const tableColumns = [
    {
      Header: "Subscriber",
      accessor: "Subscriber",
      maxWidth: 200,
      className: "justify-content-start text-left text-wrap text-break",
      Cell: row =>
        <Link to={`/admin-manage-subscriber-profile/${row.original.subscriberId}`}>{row.original.subscriberName}</Link>
    },
    {
      Header: "Client",
      accessor: "client",
      maxWidth: 150,
      className: "justify-content-start text-left text-wrap text-break",
      Cell: row =>
        <Link to={`/admin-manage-client-profile/${row.original.subscriberId}/${row.original.clientId}`}>{row.original.clientName}</Link>
    },
    {
      Header: "User ID",
      accessor: "userId",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <Link to={`/admin-manage-user-profile/${row.original.userId}?role=${row.original.role}`}>{row.original.userId}</Link>
    },
    {
      Header: "User Name",
      accessor: "userName",
      maxWidth: 300,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Login Email",
      accessor: "loginEmail",
      className: "justify-content-start text-left text-wrap text-break",
      minWidth: 100
    },
    {
      Header: "Sub-Status",
      accessor: "subStatus",
      maxWidth: 100,
      className: "justify-content-center"
    },

    {
      Header: "Role",
      accessor: "role",
      maxWidth: 150,
      minWidth: 100,
      className: "text-center"
    }
  ];

  var subStatusRow = subStatusList.map((status) =>{
    return <option value={status}>{status}</option>;
});
var roles = roleList.map((role) =>{
    return <option value={role}>{role}</option>;
});


  return (
    <Container fluid className="main-content-container px-4 py-4">
      <div noGutters className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
        <div className="d-flex align-items-center p-2">
          <FormSelect name='role' value={formData.role} onChange={onChange}>
            <option value=''>Role
            </option>
            {roles}
          </FormSelect>
        </div>
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='subscriberName'
            value={formData.subscriberName}
            onChange={onChange}
            placeholder="Subscriber"
          />

        </div>
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='clientName'
            value={formData.clientName}
            onChange={onChange}
            placeholder="Client"
          />
        </div>
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='userId'
            value={formData.userId}
            onChange={onChange}
            placeholder="User ID"
          />
        </div>
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='userName'
            value={formData.userName}
            onChange={onChange}
            placeholder="User Name"
          />
        </div>
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='email'
            value={formData.email}
            onChange={onChange}
            placeholder="Email"
          />
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='substatus' value={formData.substatus} onChange={onChange}>
            <option value=''>All Status
            </option>
            {subStatusRow}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center justify-content-end p-2">
          <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
            onClick={searchUser}>Search</Button>
        </div>
      </div>
      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col className="file-manager__filters__rows d-flex align-items-center col-6">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col
                className="file-manager__filters__search d-flex justify-content-end col-6">
                <Link to={'/manage-user-profile/0'}>
                  <Button className="my-2 py-2 mx-2" size='sm'
                    theme="accent">Add User</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

SystemAdminUsers.propTypes = {
  // isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  subStatusList: PropTypes.array.isRequired,
  roleList: PropTypes.array.isRequired,
  get_sub_status_list: PropTypes.func.isRequired,
  get_role_list: PropTypes.func.isRequired,
  systemUsers: PropTypes.array.isRequired,
  search_sysadmin_user_list: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  subStatusList: state.user.subStatusList,
  roleList: state.user.roleList,
  systemUsers: state.user.systemUsers,
})

export default connect(mapStateToProps, {
  get_sub_status_list,
  get_role_list,
  search_sysadmin_user_list,
})(SystemAdminUsers);
