import React, {useEffect, useState} from 'react'
import ReactTable from "react-table";


import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container,
  Row,
  FormSelect,
  Form
} from "shards-react";
import {Link, useHistory} from "react-router-dom";
import InfoTooltip from '../../common/InfoTooltip';


export default function Currency({
                                   confirmAlert,
                                   setConfirmAlert,
                                   baseCurrency,
                                   save_base_currency,
                                   currencyList
                                 }) {

  let history = useHistory();

  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,
    baseCurrencyId: ""
  });

  useEffect(() => {
    setFormData({...formData, baseCurrencyId: baseCurrency.currencyId})
  }, [baseCurrency]);


  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };


  const tableData = currencyList;
  var {pageSize, pageSizeOptions} = formData;

  const tableColumns = [
    {
      Header: "Currency Code",
      accessor: "currencyCode",
      className: "text-center",

    },
    {
      Header: "Currency Symbol",
      accessor: "currencySymbol",
    },
    {
      Header: "Ref #",
      accessor: "currencyId",
      maxWidth: 100,
      Cell: row =>
        <Link
          to={`/sub-edit-currency/${row.original.currencyId}`}>{row.original.currencyId}</Link>
    },
  ];

  let currencyOptionsList = currencyList.map(currency => {
    return <option key={currency.currencySymbol+currency.currencyCode} value={currency.currencyId}>{currency.currencySymbol+" "+currency.currencyCode}</option>
  });

  let onSaveCurrency = (e) => {
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to change the base currency?',
      visible: true,
      onConfirm: () => {
        save_base_currency(formData.subscriberId, formData.baseCurrencyId);
      }
    })


  };
  return (
    <div className="position-relative mt-5">
      <span className="tableHeader">Currency</span>
      <Card className="tableCard p-0 border-card">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {
                /* Filters :: Page Size */
              }
              <Col
                className="file-manager__filters__rows d-flex align-items-center mt-4">
                <span>Show</span>
                <FormSelect size="sm" name='pageSize' value={pageSize}
                            onChange={onChange}>
                  {pageSizeOptions.map((size, idx) => <option key={idx}
                                                              value={size}>
                    {size} rows
                  </option>)}
                </FormSelect>
              </Col>
              <Col className="d-flex align-items-center justify-content-center form-group p-2 mb-0" lg="4">
                <Form>

                  <Row>
                    <Col lg="9">
                    <label htmlFor="baseDistanceUnit">Base Currency<span aria-hidden="true" className="required">*</span>
              </label>
              <InfoTooltip
                            msg="Set the Base Currency to create the default for all financial transactions" />
                 <FormSelect name='baseCurrencyId'
                            value={formData.baseCurrencyId}
                            onChange={onChange}>
                  <option
                    value=''>Select Base Currency
                  </option>
                  {currencyOptionsList}
                </FormSelect>
                    </Col>
                    <Col lg="3" className="mt-2">
                    <Button className="mt-4" size='sm' theme="accent"
                        onClick={onSaveCurrency}>Save</Button>
                    </Col>
                  </Row>
              

               
                </Form>
              </Col>
              <Col lg="4"
                className="file-manager__filters__search d-flex justify-content-end mt-3">

                <Link to="/sub-add-currency/0">
                  <Button className="mt-4" size='sm' theme="accent">Add
                    Currency</Button>
                    </Link>
              </Col>
              
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable columns={tableColumns}
                        data={tableData}
                        pageSize={Number(pageSize)}
                        showPageSizeOptions={false}
                        resizable={false}
                        noDataText="No results found"/>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}




