import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { get_country_list, get_region_list, search_sub_holidays } from '../../redux/actions/user';
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, FormInput,
  FormSelect,
  Row
} from "shards-react";
import RangeDatePicker from '../../components/common/RangeDatePicker';
import { setDateForTable, setInputDate } from '../../utils/date';



function Holiday({
  user,
  isAuthenticated,
  get_country_list,
  countryList,
  get_region_list,
  regionList,
  subHolidayList,
  search_sub_holidays,
}) {



  let onStartDateChanged = (value) => {
    setFormData({
      ...formData,
      fromDate: value
    })
  }

  let onEndDateChanged = (value) => {
    setFormData({
      ...formData,
      toDate: value
    })
  }

  const history = useHistory()

  const [formData, setFormData] = useState({
    fromDate: '',
    toDate: '',
    holidayName: '',
    countryId: '',
    regionId: '',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });

  const { fromDate, toDate, holidayName, countryId, regionId } = formData;

  useEffect(() => {
    get_country_list();
    search_sub_holidays(fromDate, toDate, holidayName, countryId, regionId);
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    search_sub_holidays(setInputDate(fromDate) , setInputDate(toDate) , holidayName , countryId, regionId);
};
if(countryList !== [])
    {
        var countryDropdown = countryList.map((country) => {
            return <option value={country.countryName + country.countryId}>{country.countryName}</option>;
        });
    }
    if(regionList !== [])
    {
        var regionDropdown = regionList.map((region) => {
            return <option value={region.regionId}>{region.regionName}</option>;
        });

      if(regionList.length>0)
        regionDropdown.splice(0, 0, <option value="0">All</option>)
    }




  const tableData = subHolidayList.sort((a, b) => a.holidayId > b.holidayId ? 1 : -1);
  var { pageSize, pageSizeOptions } = formData;

  const tableColumns = [
    {
      Header: "Ref #",
      accessor: "holidayId",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <Link to={`/sub-edit-holiday/${row.original.holidayId}`}>{row.original.holidayId}</Link>
    },
    {
      Header: "Holiday Date",
      accessor: "holidayDate",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
      <span>{setDateForTable(row.original.date)}</span>
    },
    {
      Header: "Holiday Name",
      accessor: "holidayName",
      className: "justify-content-start"
    },
    {
      Header: "Country",
      accessor: "country",
      className: "justify-content-start"
    },
    {
      Header: "Region",
      accessor: "region",
      className: "justify-content-start",
      Cell: row => (row.original.region) ? row.original.region : "All"
    }
  ];



  return (
    <Container fluid className="main-content-container px-4 py-4">
      <div noGutters className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
        <form className="row mx-0" onSubmit={onSubmit}>
          <div className="d-flex align-items-center p-2">
            <RangeDatePicker className="justify-content-end"
              onStartDateChanged={onStartDateChanged}
              onEndDateChanged={onEndDateChanged} name="range" />
          </div>
          <div className="d-flex align-items-center p-2">
            <FormInput
              name='holidayName'
              value={holidayName}
                                          onChange={onChange}
              placeholder="Holiday"
            />
          </div>
          <div className="d-flex align-items-center p-2">
            <FormSelect name='countryId' className="mw-160" value={countryId} onChange={(e)=>{onChange(e);get_region_list(e.target.value);}}>
              <option value=''>Country
              </option>
              {countryDropdown}
            </FormSelect>
          </div>
          <div className="d-flex align-items-center p-2">
            <FormSelect name='regionId' className="mw-160" value={regionId} onChange={onChange}>
              <option value=''>Region
              </option>
              {regionDropdown}
            </FormSelect>
          </div>

          <div className="d-flex align-items-center justify-content-end p-2">
            <Button type="submit" className="mx-2 py-2 my-2" size='sm' theme="accent">Search</Button>
          </div>
        </form>
      </div>
      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col className="file-manager__filters__rows d-flex align-items-center col-6">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col
                className="file-manager__filters__search d-flex justify-content-end col-6">
                <Link to="/sub-add-holiday/0">
                  <Button className="my-2 py-2 mx-2" size='sm'
                    theme="accent">Add Holiday</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}


Holiday.propTypes = {
  isAuthenticated: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  countryList: PropTypes.array.isRequired,
  regionList: PropTypes.array.isRequired,
  subHolidayList: PropTypes.array.isRequired,
  get_country_list: PropTypes.func.isRequired,
  get_region_list: PropTypes.func.isRequired,
  search_sub_holidays: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  countryList: state.user.countryList,
  regionList: state.user.regionList,
  subHolidayList: state.user.subHolidayList,
});

export default connect(
  mapStateToProps, {
  get_country_list,
  get_region_list,
  search_sub_holidays
})(Holiday);
