export const LAYOUT_TYPES = {
  DEFAULT: 'DEFAULT',
  HEADER_NAVIGATION: 'HEADER_NAVIGATION',
  ICON_SIDEBAR: 'ICON_SIDEBAR'
}
export const RECEIPT_FILE_SIZE_LIMIT=2097152;
export const RECEIPT_ALL_FILE_SIZE_LIMIT=20;
export const LEAVE_POLICY_DEFAULT_END_DATE="20501231";
export const STANDARD_FORMATS={
  COMMA_SEPERATED_NUMBER:1,
  PHONE_NO:2,
};
