import React, {useEffect, useState} from 'react';
import {
  Card,
  Col,
  Container, Form, ListGroup, ListGroupItem,
  Row
} from "shards-react";
import InputBox from '../../components/common/InputBox';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  clear_expense_type,
  delete_expense_type_details,
  get_expense_type_details,
  save_expense_type_details,
} from "../../redux/actions/user";
import {useHistory, useParams} from "react-router-dom";
import ConfirmAlert from "../../components/common/ConfirmAlert";


function AddEditConfigExpese({
                               get_expense_type_details,
                               clear_expense_type,
                               save_expense_type_details,
                               delete_expense_type_details,
                               expenseType

                             }) {

  let {id} = useParams();
  useEffect(() => {
    if (id && id !== '0') {
      get_expense_type_details(id);
    }
    return ()=>clear_expense_type();
  }, [id]);

  const history = useHistory();
  const [formData, setFormData] = useState({
    expenseTypeId: 0,
    expenseTypeName: '',
    finmodPayTypeId:''
  });
  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });
  useEffect(() => {
    if (expenseType.expenseTypeId || expenseType.expenseType) {
      setFormData({
        ...formData,
        expenseTypeId: expenseType.expenseTypeId,
        expenseTypeName: expenseType.expenseType,
        finmodPayTypeId: expenseType.finmodPayTypeId,
      });
    }
  }, [expenseType]);

  const {expenseTypeId, expenseTypeName, finmodPayTypeId} = formData;
  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const onSubmit = (e) => {
    e.preventDefault();
    save_expense_type_details(expenseTypeId, expenseTypeName,finmodPayTypeId);
    history.goBack();
  };
  const onDelete = (e) => {
    e.preventDefault();

    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete this expense type?',
      visible: true,
      onConfirm: () => {
        delete_expense_type_details(expenseTypeId);
        history.goBack();
      }
    })
  };


  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container fluid className="main-content-container p-4">
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Card small className="mb-5 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {id}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form id="clientDetailsForm" onSubmit={onSubmit}>
                  <Row form>
                    <InputBox placeholder="Enter Expense Type" label="Expense Type" name='expenseTypeName' id='expenseTypeName'
                              value={formData.expenseTypeName}
                              onChange={onChange}/>

                    <InputBox placeholder="Enter FINMOD Pay Type ID" label="FINMOD Pay Type ID" name='finmodPayTypeId' id='finmodPayTypeId'
                              value={formData.finmodPayTypeId}
                              onChange={onChange}/>


                    <Col lg="3" className="form-group p-2 mb-0">
                      <div
                           className="d-flex justify-content-between  mt-4 pt-2">
                        <button type="submit" className="btn btn-primary w-100">Save
                        </button>
                        <button onClick={onDelete}
                                className="btn btn-primary ml-2 w-100">Delete
                        </button>
                        <button onClick={goBack}
                                className="btn btn-primary ml-2 w-100">Close
                        </button>
                      </div>
                    </Col>

                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}

AddEditConfigExpese.propTypes = {
  get_expense_type_details: PropTypes.func.isRequired,
  save_expense_type_details: PropTypes.func.isRequired,
  delete_expense_type_details: PropTypes.func.isRequired,
  expenseType: PropTypes.object.isRequired,

};
const mapStateToProps = (state) => ({
  expenseType: state.user.expenseType,

});
export default connect(mapStateToProps, {
  get_expense_type_details,
  clear_expense_type,
  save_expense_type_details,
  delete_expense_type_details,

})(AddEditConfigExpese);
