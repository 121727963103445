import CBoxLayout from "../layouts/CBoxLayout";
import SubscriberAddEditNotes from "../views/subscriber/SubscriberAddEditNotes";
import Users from "../views/subscriber/SubscriberUsers";
import SubscriberLeaves from "../views/subscriber/SubscriberLeaves";
import SubscriberAddEditLeave from "../views/subscriber/SubscriberAddEditLeave";
import SubscriberExpenses from "../views/subscriber/SubscriberExpenses";
import SubscriberApproveExpenseReport
  from "../views/subscriber/SubscriberApproveExpenseReport";
import ApproveExpenseItem from "../views/subscriber/ApproveExpenseItem";
import ApproveMileageItem from "../views/subscriber/ApproveMileageItem";
import SubEditExpenseReport from "../views/subscriber/SubEditExpenseReport";
import SubEditExpenseItem from "../views/subscriber/SubEditExpenseItem";
import SubEditMileageItem from "../views/subscriber/SubEditMileageItem";
import SubscriberClients from "../views/subscriber/SubscriberClients";
import SubscriberManageClientProfile
  from "../views/subscriber/SubscriberManageClientProfile";
import Configuration from "../views/subscriber/Configuration";
import AddEditConfigLeaves from "../views/subscriber/AddEditConfigLeaves";
import AddEditConfigAllowance from "../views/subscriber/AddEditConfigAllowance";
import AddEditConfigExpense from "../views/subscriber/AddEditConfigExpense";
import AddEditConfigRange from "../views/subscriber/AddEditConfigRange";
import Holiday from "../views/subscriber/Holiday";
import AddEditHoliday from "../views/subscriber/AddEditHoliday";
import LeaveLocationConfig from "../views/subscriber/LeaveLocationConfig";
import SubLeavePolicy from "../views/subscriber/SubLeavePolicy/SubLeavePolicy";
import SubEditLeavePolicy from "../views/subscriber/SubEditLeavePolicy";
import SubClientCustomizeLeavePolicy
  from "../views/subscriber/SubClientCustomizeLeavePolicy";
import SubscriberApproveLeave from "../views/subscriber/SubscriberApproveLeave";
import SubscriberReviewExpenseReport
  from "../views/subscriber/SubscriberReviewExpenseReport";
import ReviewExpenseItem from "../views/subscriber/ReviewExpenseItem";
import ReviewMileageItem from "../views/subscriber/ReviewMileageItem";
import AddCurrency from "../views/subscriber/AddCurrency";
import AddEditConfigMsgCategory from "../views/subscriber/AddEditConfigMsgCategory";
import SubMessageCenter from "../views/subscriber/SubMessageCenter";
import SubMessageActivity from "../views/subscriber/SubMessageActivity";
import SubNewMessage from "../views/subscriber/NewMessage";
import SubscriberAddEditClientNotes
  from "../views/subscriber/SubscriberAddEditClientNotes";
import SubHome from "../views/subscriber/SubHome";
import ManagePermissions from "../views/subscriber/ManagePermissions";
import SubscriberReports from "../views/subscriber/SubscriberReports";
import AddEditDistanceUnit from "../views/subscriber/AddEditDistanceUnit";
import AddEditPayComponent from "../views/subscriber/AddEditPayComponent";
import AddEditPayElement from "../views/subscriber/AddEditPayElement";
import CopmpensationForm from '../components/manage-user-profile/forms/CopmpensationForm'
import BenifitsInfoForm from "../components/manage-user-profile/forms/BenefitsInfoForm";
import PensionPlanForm from "../components/manage-user-profile/forms/PensionPlanForm";
import PlacementDataForm from "../components/manage-user-profile/forms/PlacementDataForm";
import RetainerForm from "../components/manage-user-profile/forms/RetainerForm";
import EAPForm from "../components/manage-user-profile/forms/EAPForm";
import HSAForm from "../components/manage-user-profile/forms/HSAForm";
export default [
  {
    path: "/sub-home",
    layout: CBoxLayout,
    component: SubHome,
    title:"Home",
    subtitle:"Subscriber",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-add-edit-notes",
    layout: CBoxLayout,
    component: SubscriberAddEditNotes,
    title:"Notes",
    subtitle:"Add/Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-search-users",
    layout: CBoxLayout,
    component: Users,
    title:"Users",
    subtitle:"Search",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/benifits-form",
    layout: CBoxLayout,
    component: BenifitsInfoForm,
    title:"Benefits",
    subtitle:"Search",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/retainer-form",
    layout: CBoxLayout,
    component: RetainerForm,
    title:"Benefits",
    subtitle:"Search",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/EAP-info-form",
    layout: CBoxLayout,
    component: EAPForm,
    title:"Benefits",
    subtitle:"Search",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/HSA-info-form",
    layout: CBoxLayout,
    component: HSAForm,
    title:"Benefits",
    subtitle:"Search",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-search-leaves",
    layout: CBoxLayout,
    component: SubscriberLeaves,
    exact:true,
    title:"Leaves",
    subtitle:"Search",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/compensation-info-form",
      layout: CBoxLayout,
      component: CopmpensationForm,
      title:"Placement",
      subtitle:"Add",
      roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/pension-info-form",
      layout: CBoxLayout,
      component: PensionPlanForm,
      title:"Retirement Plan",
      subtitle:"Add",
      roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/placement-info-form",
      layout: CBoxLayout,
      component: PlacementDataForm,
      title:"Retirement Plan",
      subtitle:"Add",
      roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-search-leaves/:userId",
    layout: CBoxLayout,
    component: SubscriberLeaves,
    exact:true,
    title:"Leaves",
    subtitle:"Manage",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-add-leave/refNumber",
    layout: CBoxLayout,
    component: SubscriberAddEditLeave,
    title:"Leaves",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-edit-leave/:refNumber",
    layout: CBoxLayout,
    component: SubscriberAddEditLeave,
    title:"Leave",
    subtitle:"Apply for",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-approve-leave/:refNumber",
    layout: CBoxLayout,
    component: SubscriberApproveLeave,
    title:"Leave",
    subtitle:"Manage",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-search-expenses",
    layout: CBoxLayout,
    exact: true,
    component: SubscriberExpenses,
    title:"Expenses",
    subtitle:"Search",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-search-expenses/:userId",
    layout: CBoxLayout,
    exact: true,
    component: SubscriberExpenses,
    title:"Expenses",
    subtitle:"Manage",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-approve-expense-report/:sheetRefNumber",
    layout: CBoxLayout,
    component: SubscriberApproveExpenseReport,
    title:"Expense Report",
    subtitle:"Approve",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-review-expense-report/:sheetRefNumber",
    layout: CBoxLayout,
    component: SubscriberReviewExpenseReport,
    title:"Expense Report",
    subtitle:"Review",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-approve-expense-item/:sheetRefNumber/:refNumber",
    layout: CBoxLayout,
    component: ApproveExpenseItem,
    title:"Expense Item",
    subtitle:"Approve",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-review-expense-item/:sheetRefNumber/:refNumber",
    layout: CBoxLayout,
    component: ReviewExpenseItem,
    title:"Expense Item",
    subtitle:"Review",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-approve-mileage-item/:sheetRefNumber/:refNum",
    layout: CBoxLayout,
    component: ApproveMileageItem,
    title:"Mileage Item",
    subtitle:"Approve",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-review-mileage-item/:sheetRefNumber/:refNum",
    layout: CBoxLayout,
    component: ReviewMileageItem,
    title:"Mileage Item",
    subtitle:"Review",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-edit-expense-reports/:userId/:sheetRefNumber",
    layout: CBoxLayout,
    component: SubEditExpenseReport,
    title:"Expense Report",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-add-expense-item/:userId/:sheetRefNumber/:refNumber",
    layout: CBoxLayout,
    component: SubEditExpenseItem,
    title:"Expense Item",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-add-mileage-item/:userId/:sheetRefNumber/:refNumber",
    layout: CBoxLayout,
    component: SubEditMileageItem,
    title:"Mileage Item",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-edit-expense-report/:userId/:sheetRefNumber",
    layout: CBoxLayout,
    component: SubEditExpenseReport,
    title:"Expense Report",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-edit-expense-item/:userId/:sheetRefNumber/:refNumber",
    layout: CBoxLayout,
    component: SubEditExpenseItem,
    title:"Expense Item",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-edit-mileage-item/:userId/:sheetRefNumber/:refNumber",
    layout: CBoxLayout,
    component: SubEditMileageItem,
    title:"Mileage Item",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },


  {
    path: "/sub-search-clients",
    layout: CBoxLayout,
    component: SubscriberClients,
    title:"Clients",
    subtitle:"Search",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-manage-client-profile/:id",
    layout: CBoxLayout,
    component: SubscriberManageClientProfile,
    title:"Company Profile",
    subtitle:"Manage",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/edit-client-notes/:id/:refNum",
    layout: CBoxLayout,
    component: SubscriberAddEditClientNotes,
    title:"Client Notes",
    subtitle:"Add/Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-manage-permissions/:userId",
    layout: CBoxLayout,
    component: ManagePermissions,
    title:"Manage Permissions",
    subtitle:"Subscriber",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-configuration",
    layout: CBoxLayout,
    component: Configuration,
    title:"Configuration",
    subtitle:"Subscriber",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-add-edit-leavetype/:id",
    layout: CBoxLayout,
    component: AddEditConfigLeaves,
    title:"Leave Type",
    subtitle:"Add / Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-add-allowance-type/:id",
    layout: CBoxLayout,
    component: AddEditConfigAllowance,
    title:"Allowance Type",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-edit-allowance-type/:id",
    layout: CBoxLayout,
    component: AddEditConfigAllowance,
    title:"Allowance Type",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-add-expense-type/:id",
    layout: CBoxLayout,
    component: AddEditConfigExpense,
    title:"Expense Type",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-edit-expense-type/:id",
    layout: CBoxLayout,
    component: AddEditConfigExpense,
    title:"Expense Type",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-edit-mileage-range/:refNumber",
    layout: CBoxLayout,
    component: AddEditConfigRange,
    title:"Mileage Rate",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-add-mileage-range/:refNumber",
    layout: CBoxLayout,
    component: AddEditConfigRange,
    title:"Mileage Rate",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },


  {
    path: "/sub-message-center",
    layout: CBoxLayout,
    component: SubMessageCenter,
    title:"Center",
    subtitle:"Message",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-search-holidays",
    layout: CBoxLayout,
    component: Holiday,
    title:"Holidays",
    subtitle:"Search",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-edit-holiday/:refNum",
    layout: CBoxLayout,
    component: AddEditHoliday,
    title:"Holidays",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-message-activity/:userId",
    layout: CBoxLayout,
    component: SubMessageActivity,
    exact: true,
    title:"Activity",
    subtitle:"Message",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-message-activity/:userId/:refNum",
    layout: CBoxLayout,
    component: SubMessageActivity,
    title:"Activity",
    subtitle:"Message",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-add-holiday/:refNum",
    layout: CBoxLayout,
    component: AddEditHoliday,
    title:"Holidays",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-add-currency/:id",
    layout: CBoxLayout,
    component: AddCurrency,
    title:"Currency",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-edit-currency/:id",
    layout: CBoxLayout,
    component: AddCurrency,
    title:"Currency",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-leave-policy-location-config/:leavePolicyId",
    layout: CBoxLayout,
    component: LeaveLocationConfig,
    title:"Regional Leaves",
    subtitle:"Configure",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-search-leave-policy",
    layout: CBoxLayout,
    component: SubLeavePolicy,
    title:"Leave Policy",
    subtitle:"Search",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-edit-leave-policy/:leavePolicyId",
    layout: CBoxLayout,
    component: SubEditLeavePolicy,
    title:"Leave Policy",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-add-leave-policy/:leavePolicyId",
    layout: CBoxLayout,
    component: SubEditLeavePolicy,
    title:"Leave Policy",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-generate-reports",
    layout: CBoxLayout,
    component: SubscriberReports,
    title:"Reports",
    subtitle:"Generate",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-client-customize-leave-policy/:clientId/:leavePolicyId",
    layout: CBoxLayout,
    component: SubClientCustomizeLeavePolicy,
    title:"Leave Policy",
    subtitle:"Customize",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-edit-message-catagory/:id",
    layout: CBoxLayout,
    component: AddEditConfigMsgCategory,
    title:"Message Category",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-add-message-catagory/:id",
    layout: CBoxLayout,
    component: AddEditConfigMsgCategory,
    title:"Message Category",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/sub-new-message/:id/:userId/:role",
    layout: CBoxLayout,
    component: SubNewMessage,
    title:"New Message",
    subtitle:"Subscriber",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },


  {
    path: "/sub-add-distance-unit/:id",
    layout: CBoxLayout,
    component: AddEditDistanceUnit,
    title:"Distance Unit",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },


  {
    path: "/sub-add-Pay-Component/:id",
    layout: CBoxLayout,
    component: AddEditPayComponent,
    title:"Pay Component",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },


  {
    path: "/sub-edit-Pay-Component/:id",
    layout: CBoxLayout,
    component: AddEditPayComponent,
    title:"Pay Component",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },


  {
    path: "/sub-edit-distance-unit/:id",
    layout: CBoxLayout,
    component: AddEditDistanceUnit,
    title:"Distance Unit",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },
  {
    path: "/sub-add-Pay-Element/:refNum",
    layout: CBoxLayout,
    component: AddEditPayElement,
    title:"Pay Element",
    subtitle:"Add",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },


  {
    path: "/sub-edit-Pay-Element/:refNum",
    layout: CBoxLayout,
    component: AddEditPayElement,
    title:"Pay Element",
    subtitle:"Edit",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },


];
