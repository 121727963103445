import React, {useEffect} from 'react';
import {
  Button,
  Card,
  Col,
  Container, Form,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {setDateForTable} from "../../utils/date";
import {numberToMonetary} from "../../utils/general";
import {connect} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import {
  client_approve_expense_item,
  client_clear_expense_item,
  client_get_expense_item, client_reject_expense_item
} from "../../redux/actions/client/clientExpenses";
import loadingGif from "../../images/loading-gif.gif";
import {b64toBlob} from "../../utils/file";
import {get_user_expense_item_receipt_file} from "../../redux/actions/worker/expenses";
import {
  approve_sub_expense_item, clear_sub_expense_item, get_sub_expense_item,
  reject_sub_expense_item
} from "../../redux/actions/subscriber/subExpenses";
import InfoTooltip from "../../components/common/InfoTooltip";

function ApproveExpenseItem(props) {

  let {refNumber} = useParams();
  let location = useLocation();
  let approvePermissions=true;
  if (location.pathname.startsWith('/client-view-expense-item')) {
    var {
      clientExpenseItem: expenseItem,
      client_get_expense_item: getExpenseItem,
      client_clear_expense_item: clearExpenseItem,
      client_approve_expense_item: approveExpenseItem,
      client_reject_expense_item: rejectExpenseItem,
      subReportStatus:reportStatus
    } = props
    approvePermissions = props.user.userRoles.find(role => role.role === "Client User" || role.role === "Client HR" || role.role === "Client Billing" || role.role === "Client Admin").permissions.includes("Approve Expense")
  } else {
    var {
      get_sub_expense_item: getExpenseItem,
      clear_sub_expense_item: clearExpenseItem,
      approve_sub_expense_item: approveExpenseItem,
      reject_sub_expense_item: rejectExpenseItem,
      subExpenseItem: expenseItem,
      clientReportStatus:reportStatus
    } = props
  }

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(expenseItem).length > 0 && expenseItem.workerName.trim()) {
      props.changePageMeta(`${props.title} - ${expenseItem.workerName}`)
    }
  }, [expenseItem]);

  useEffect(() => {
    if (expenseItem && expenseItem.receiptFileName) {
      props.get_user_expense_item_receipt_file(expenseItem.userId, refNumber);
    }
  }, [expenseItem])

  useEffect(() => {
    if (
      refNumber !== null &&
      refNumber !== '' &&
      refNumber !== '0' &&
      refNumber !== 0
    ) {
      getExpenseItem(refNumber);
    }

    return () => {
      clearExpenseItem();
    }
  }, [refNumber]);

  const history = useHistory();


  let receiptDoc = <img src={loadingGif}
                        style={{width: '50px'}}
                        alt="loader"/>;

  if (Object.keys(expenseItem).length && !expenseItem.receiptFileName)
    receiptDoc = <label>No receipt attached</label>

    if (expenseItem.receiptFileName && expenseItem.receiptFileName.toLowerCase().endsWith(".pdf") && props.receiptFile)
    {
      receiptDoc = <object
        className='receipt-viewer w-100 h-100 border receipt-viewer-pdf'
        type="application/pdf"
        data={b64toBlob(props.receiptFile)}
        title="Receipt Preview"/>
    }
    if (expenseItem.receiptFileName && (expenseItem.receiptFileName.toLowerCase().endsWith(".png") || expenseItem.receiptFileName.toLowerCase().endsWith(".jpg") || expenseItem.receiptFileName.toLowerCase().endsWith(".jpeg")) && props.receiptFile)
    {
      receiptDoc = <img
        className='receipt-viewer mh-75 mw-100'
        src={b64toBlob(props.receiptFile)}
        alt="receipt"/>
    }


  return (
    <Container fluid className="main-content-container p-4">
      <Card small className="details-card mb-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {refNumber}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form>
                  <Row>
                    <Col className={"col-12 col-lg-6"}>
                      <Row form>

                        <Col lg="6" className="form-group p-2 m-0">
                          <label>Date</label>
                          <span
                            className='text-light form-control'>{setDateForTable(expenseItem.expenseDate)}</span>
                        </Col>

                        <Col lg="6" className="form-group p-2 m-0">
                          <label>Expense Category</label>
                          <span
                            className='text-light form-control'>{expenseItem.type}</span>
                        </Col>


                        <Col lg="12" className="form-group p-2 m-0">
                          <label>Description</label>
                          <span
                            className='text-light form-control'>{expenseItem.description}</span>
                        </Col>

                        <Col lg="12" className="form-group p-2 pr-4 m-0">
                          <label>Gross</label>
                          <InfoTooltip msg="For meals: add up meal sub-total and Tips here."/>
                          <span
                            className='text-light form-control w-50'>{numberToMonetary(expenseItem.grossAmount)}</span>
                        </Col>

                        <Col lg="12" className="form-group p-2 pr-4 m-0">
                          <label>Tax Amount</label>
                          <span
                            className='text-light form-control w-50'>{numberToMonetary(expenseItem.taxAmount)}</span>
                        </Col>

                        <Col lg="12" className="form-group p-2 pr-4 m-0">
                          <label>Receipt Total</label>
                          <span
                            className='text-light form-control w-50'>{numberToMonetary(expenseItem.receiptAmount)}</span>
                        </Col>

                        <Col lg="6" className="form-group p-2 m-0">
                          <label>Currency</label>
                          <span
                            className='text-light form-control'>{expenseItem.currency}</span>
                        </Col>

                        <Col lg="6" className="form-group p-2 m-0">
                          <label>Exchange Rate</label>
                          <span
                            className='text-light form-control'>{(expenseItem.exchangeRate) ? expenseItem.exchangeRate.toFixed(5) : ""}</span>
                        </Col>


                        <Col lg="6" className="form-group p-2 m-0">
                          <label>Net Amount</label>
                          <span
                            className='text-light form-control'>{numberToMonetary(expenseItem.netAmount)}</span>
                        </Col>

                      </Row>
                    </Col>
                    <Col
                      className="form-group col-12 col-lg-6 p-2 px-3 m-0 d-flex flex-column">
                      <div>
                        <label>Receipt</label>
                        {(Boolean(Object.keys(expenseItem).length && !expenseItem.receiptFileName)) && <InfoTooltip
                          msg="No receipt attached.This expense item has been submitted as taxable"/>}
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-center w-100 h-100 border">
                        {receiptDoc}
                      </div>

                    </Col>
                  </Row>
                  <Row className="px-3">
                    <Col sm="12" md="12" lg="12"
                         className=" form-group p-2 m-0 d-flex align-items-center justify-content-center justify-content-md-end">


                      {(approvePermissions && expenseItem.status && expenseItem.status !== "Reviewed") &&
                      <InfoTooltip msg=" A notification will be sent to all the users of this expense report"/>}

                      {(approvePermissions && expenseItem.status && expenseItem.status !== "Approved" && expenseItem.status !== "Reviewed" && reportStatus!=="Reviewed"  && expenseItem.status !== "Withdrawn" && reportStatus!=="Withdrawn" ) &&
                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              onClick={() => {
                                approveExpenseItem(refNumber);
                                history.goBack();
                              }}
                              title="Approve this expense item." 
                              theme="accent">Approve</Button>}

                      {(approvePermissions && expenseItem.status && expenseItem.status !== "Rejected" && expenseItem.status !== "Reviewed" && reportStatus!=="Reviewed" && expenseItem.status !== "Withdrawn" && reportStatus!=="Withdrawn") &&
                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              onClick={() => {
                                rejectExpenseItem(refNumber);
                                history.goBack();
                              }}
                              title="Reject this expense item."
                              theme="accent">Reject</Button>}

                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              onClick={e => history.goBack()}
                              theme="accent">Back</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}

ApproveExpenseItem.propTypes = {
  get_sub_expense_item: PropTypes.func.isRequired,
  approve_sub_expense_item: PropTypes.func.isRequired,
  reject_sub_expense_item: PropTypes.func.isRequired,
  subExpenseItem: PropTypes.object.isRequired,
  receiptFile: PropTypes.any.isRequired,
};
const mapStateToProps = (state) => ({
  user:state.user.user,
  subExpenseItem: state.subExpenses.subExpenseItem,
  clientExpenseItem: state.clientExpenses.clientExpenseItem,
  receiptFile: state.expenses.receiptFile,
  subReportStatus:state.subExpenses.expenseSheetForSub.status,
  clientReportStatus:state.clientExpenses.expenseSheetForClient.status
});
export default connect(mapStateToProps, {
  get_user_expense_item_receipt_file,
  get_sub_expense_item,
  clear_sub_expense_item,
  approve_sub_expense_item,
  reject_sub_expense_item,

  client_get_expense_item,
  client_clear_expense_item,
  client_approve_expense_item,
  client_reject_expense_item
})(ApproveExpenseItem);

