import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";
import "./assets/fontawesome-6.4.2/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";
import {Provider} from "react-redux";
import store from "./redux/store";
import "../src/assets/custom.css"
import "../src/assets/override.css"
import "../src/assets/responsive.css"

export default () => (
  <Provider store={store}>
    <Router>
      <div>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                  <route.layout {...props} title={route.title}
                                subtitle={route.subtitle} permission={route.permission} roles={route.roles}>
                    <route.component {...props} title={route.title}
                                     subtitle={route.subtitle}/>
                  </route.layout>
                );
              })}
            />
          );
        })}
      </div>
    </Router>
  </Provider>
);
