import * as React from 'react';
import { connect } from 'react-redux';

import {get_message_attachment_file, 
    delete_message_attachment,} from "../../redux/actions/user";
import { b64toBlob, getBase64MimeType } from '../../utils/file'
import loadingGif from '../../images/loading-gif.gif';

 function DisplayMessageFiles({
    setConfirmAlert,
    confirmAlert,
     refNumber, 
     userId,
     attachment,
     deleteAction,
     viewAttachment,
     sender,
     user,
     get_message_attachment_file,
  delete_message_attachment,}) {

    const [attachmentDoc, setAttachmentDoc] = React.useState({});
    const [thumbnail, setThumbnail] = React.useState(null);
    const subPath = ["Client User","Client HR", "Client Billing", "Client Admin"].includes(user.selectedrole) ? 'client' : ["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"].includes(user.selectedrole) ? 'subscriber' : 'user' ;
    
    let mimeType ='';
    const imageMimeTypes = ['jpg', 'jpeg', 'gif', 'png', 'svg']

    const deleteAttachment = () => {

        setConfirmAlert && setConfirmAlert({
            ...confirmAlert,
            confirmMsg: 'Are you sure you want to delete this attachment ?',
            visible: true,
            onConfirm: () => {
                delete_message_attachment(userId, refNumber,attachment.messageId, attachment.systemFileName, subPath);
            }
          })
        
    }

    const openFile = (url) => {
        if(imageMimeTypes.includes(attachmentDoc.mimeType) || attachmentDoc.mimeType === 'pdf'){
            window.open(url, "customWindow", "width=500, height=1040");
        }
        else{
            let a = document.createElement("a");
            a.href = attachmentDoc.viewAttachment;
            a.download = attachment.userFileName;
            a.click();
        }
         
    }

    React.useEffect(()=>{
        
        if(attachment.deleted !== 1){
            get_message_attachment_file(userId, refNumber,attachment.messageId, attachment.systemFileName, subPath);
        }
       
    },[])

    React.useEffect(()=>{
        
        if (attachment.deleted !== 1 && viewAttachment &&  viewAttachment[attachment.systemFileName] !== undefined) {
            mimeType = getBase64MimeType(viewAttachment[attachment.systemFileName])
            setAttachmentDoc({viewAttachment : viewAttachment[attachment.systemFileName], mimeType: mimeType});
        
          }
          else{
            setAttachmentDoc({});
          }
    },[attachment, viewAttachment])

    return (
        <div className={`mr-4 mb-3 mt-2 ${sender ? 'attachment-file-float-right' : 'attachment-file'}`} key={attachment.systemFileName}>
            { !attachmentDoc.mimeType &&  !attachment.systemFileName.toLowerCase().endsWith('docx') && !attachment.systemFileName.toLowerCase().endsWith('doc') && !attachment.systemFileName.toLowerCase().endsWith('pdf') && attachment.deleted !== 1  ?
            <div>
                <span className='ml-4'>
                    <img src={loadingGif}
                        className={`${!sender && `ml-n4`}`}
                        key={attachment.systemFileName}
                        alt="loader"
                        height="70" width="70" />
                </span>
            </div>
             : imageMimeTypes.includes(attachmentDoc.mimeType) ? (<div>
                <span className='ml-4'>
                    <img className={`${!sender && `ml-n4`}`} key={attachment.systemFileName} src={b64toBlob(attachmentDoc.viewAttachment)} height="70" width="70" />
                </span>
                {sender && <i className="fa-solid fa-xmark text-small crossIcon" role="button" onClick={deleteAttachment} ></i>}
                <br />{sender && (<><br /><br /><br /><br /></>)}
                <span role="button" className='file-badge-toast' onClick={() => openFile(`/view-attachment-popup/${userId}/${refNumber}/${attachment.messageId}/${attachment.systemFileName}/${subPath}`)}>{attachment.userFileName}</span>
            </div>) : 
               attachment && attachment.deleted === 1 ?
                (<span className='border rounded p-1 bg-light text-muted' >
                    <span className='font-weight-bold' disabled ><i className="fa-solid fa-ban"></i> Attachment deleted</span>
                </span>) :
                (<span className='border rounded p-1 ' >
                    <span role="button" className='file-badge-toast' onClick={() => openFile(`/view-attachment-popup/${userId}/${refNumber}/${attachment.messageId}/${attachment.systemFileName}/${subPath}`)}>{attachment.userFileName}</span>
                    {sender && <i className="fa-solid fa-xmark text-small text-white ml-1 crossIcon" role="button" onClick={deleteAttachment} ></i>}
                </span>)}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    viewAttachment: state.user.viewAttachment,
  });
export default connect(mapStateToProps, {
    get_message_attachment_file,
    delete_message_attachment,
})(DisplayMessageFiles);