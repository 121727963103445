import React from 'react';
import {
  Card, CardBody
} from "shards-react";
import {setDateForTable} from "../../utils/date";
import {Link} from "react-router-dom";
import {formatPhoneNumber, numberToMonetary} from "../../utils/general";

function UserJourney({
                       userId, userJourney, confirmAlert,currency,
                       setConfirmAlert, delete_user_journey_for_sub
                     }) {

  const userJourneyRows = userJourney.map(journey => {

    //converts field value to standard format according to fieldname
    function toStandardFormat(value, fieldName) {
      if(["Date Of Birth","HireDate","EndDate","BenefitsStartDate","BenefitsEndDate","PlanStartDate","MyMatchStartDate","ClientMatchStartDate"].includes(fieldName))
        return setDateForTable(value)
      else if (["Phone Home","Phone Work","Phone Mobile"].includes(fieldName))
        return formatPhoneNumber(value)
      else if (["Compensation","Contribution","Client Contribution","AgencyFee","PayRate","BillRate","ClientCap","HSAAmount","HSACarryOver",].includes(fieldName))
        return currency+numberToMonetary(value)
      else if (["MyMatch","ClientMatch"].includes(fieldName))
        return value+"%"
      else return value
    }

    return <tr key={journey.journeyId}>
      <td>{journey.consultantName}</td>
      <td className='text-center'>{journey.placementId}</td>
      <td>{journey.fieldName}</td>
      <td>{toStandardFormat(journey.initialValue,journey.fieldName)}</td>
      <td>{toStandardFormat(journey.newValue,journey.fieldName)}</td>
      <td
        className='text-center'>{setDateForTable(journey.changeDate)}</td>
      <td
        className='text-center'>{setDateForTable(journey.effectiveDate)}</td>
      <td className='text-center'>
        <Link
          onClick={e => {
            e.preventDefault();
            setConfirmAlert({
              confirmMsg: "Are you sure you want to delete this journey?",
              visible: true,
              onConfirm: () => {
                delete_user_journey_for_sub(userId, journey.journeyId)
                setConfirmAlert({
                  ...confirmAlert,
                  visible: false
                })
              }
            })
          }}>Delete</Link>
      </td>
    </tr>
  });
  return (
    <Card id='userJourney' small className="mb-4 w-100">
      <>
        <span className="iconBox"><i
          className="fas fa-suitcase iconStyle"/></span>
        <h4 className="m-0 section-title headerStyle">User Journey</h4>
      </>
      <CardBody className='p-0 pb-3'>
        <table className="table mb-0 mt-4">
          <thead className="bg-light">
          <tr>
            <th scope="col" className="border-0">
              HR Consultant
            </th>
            <th scope="col" className="border-0">
              Placement ID
            </th>

            <th scope="col" className="border-0">
              Field Name
            </th>
            <th scope="col" className="border-0">
              Initial Value
            </th>
            <th scope="col" className="border-0">
              New Value
            </th>

            <th scope="col" className="border-0">
              Change Date
            </th>
            <th scope="col" className="border-0">
              Effective Date
            </th>
            <th scope="col" className="border-0">
              Action
            </th>

          </tr>
          </thead>
          <tbody>
          {userJourneyRows}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}

export default UserJourney;
