import React ,{useEffect , useState} from 'react';
import TabBar from "../../components/worker/view-profile/TabBar";
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, FormInput,
  FormSelect,
  Row
} from "shards-react";
import CustomCard from '../../components/common/CustomCard';
import InputBox from '../../components/common/InputBox';



function SystemAdminUserEdit() {

  const personalData = 
    {id:"personal", data:[{label:"Pronoun", placeholder:""},
    {}
  ]}

  return (
    <Container fluid className="main-content-container px-0">
    <div className='profileNav'>
      <TabBar/>
    </div>
    <Row className='px-4 m-0 view-user-profile main'>
      <CustomCard  icon={false} iconClass="fas fa-id-badge iconStyle">
        <InputBox  label="Pronoun" onChangeFunc={()=>console.log("hello")}  />
      </CustomCard>
    </Row>
    </Container>
  );
}

export default SystemAdminUserEdit;
