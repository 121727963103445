import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  Col,
  Container,
  Row
} from "shards-react";
import TabBar from '../../components/worker/view-profile/TabBar';
import PersonalInfo from '../../components/worker/view-profile/PersonalInfo';
import ContactInfo from '../../components/worker/view-profile/ContactInfo';
import EmploymentInfo
  from '../../components/worker/view-profile/EmploymentInfo';
import Leaves from '../../components/worker/view-profile/Leaves';
import BenefitsInfo from '../../components/worker/view-profile/BenefitsInfo';
import PerksInfo from '../../components/worker/view-profile/PerksInfo';
import UserJourney from '../../components/worker/view-profile/UserJourney';
import PageTitle from '../../components/common/PageTitle';
import PensionPlan from "../../components/worker/view-profile/PensionPlan";
import {
  get_client_view_worker_profile,
  get_worker_journey_for_client,
  clear_client_view_worker_profile
} from "../../redux/actions/client/clientWorkers";
import Allowances from "../../components/worker/view-profile/Allowances";
import CompensationInfo from '../../components/worker/view-profile/CompensationInfo';

function ClientViewWorkerProfile(
  {
    user,
    workerForClientProfile,
    get_client_view_worker_profile,
    get_worker_journey_for_client,
    workerJourneyForClient,
    clear_client_view_worker_profile,
    changePageMeta
  }
) {
  let {id} = useParams();

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(workerForClientProfile).length > 0 && workerForClientProfile.personalInfo && (workerForClientProfile.personalInfo.firstName.trim() || workerForClientProfile.personalInfo.lastName.trim())) {
      changePageMeta(`Worker Profile - ${workerForClientProfile.personalInfo.firstName} ${workerForClientProfile.personalInfo.lastName} ${(workerForClientProfile.personalInfo.nickName?`(${workerForClientProfile.personalInfo.nickName})`:'')}`)
    }
  }, [workerForClientProfile]);

  let userPermissions = user.userRoles.find(role => role.role === "Client User" || role.role === "Client HR" || role.role === "Client Billing" || role.role === "Client Admin").permissions
  useEffect(() => {
    get_client_view_worker_profile(id);
    if(userPermissions.includes("Journey Information"))
      get_worker_journey_for_client(id);
    return () => clear_client_view_worker_profile()
  }, [id]);

  let hiddenSections = [];
  if (Object.keys(workerForClientProfile).length > 0) {
    if (!userPermissions.includes("Allowance") || !workerForClientProfile.allowanceTypes || workerForClientProfile.allowanceTypes.filter(type => type.amount !== 0).length === 0)
      hiddenSections.push("allowances")
    if (!userPermissions.includes("Benefits") || !workerForClientProfile.benefitsInfo || ["benefitsCoverage", "benefitsClass", "benefitsCertificateNo", "benefitsStartDate", "contribution", "clientContribution"].filter(key => workerForClientProfile.benefitsInfo[key] !== "0" && workerForClientProfile.benefitsInfo[key] !== 0 && workerForClientProfile.benefitsInfo[key] !== "").length === 0)
      hiddenSections.push("benefits")
    if (!userPermissions.includes("HSA Information") || !workerForClientProfile.perksInfo || ["HSAProvider", "HSAAmount", "HSACarryOver", "HSAWaitingPeriod"].filter(key => workerForClientProfile.perksInfo[key] !== "0" && workerForClientProfile.perksInfo[key] !== 0 && workerForClientProfile.perksInfo[key] !== "").length === 0)
      hiddenSections.push("perks")
    if (!userPermissions.includes("Pension Information") || !workerForClientProfile.pensionPlan || ["pensionPlan", "planNo", "clientCap", "planStartDate", "vestingPeriod", "myMatch", "clientMatch", "frequency"].filter(key => workerForClientProfile.pensionPlan[key] !== "0" && workerForClientProfile.pensionPlan[key] !== 0 && workerForClientProfile.pensionPlan[key] !== "").length === 0)
      hiddenSections.push("pension")
  }

  if (!userPermissions.includes("Personal Information")) hiddenSections.push("personal")
  if (!userPermissions.includes("Contact Information")) hiddenSections.push("contact")
  if (!userPermissions.includes("Employment Information") && !userPermissions.includes("Compensation")) hiddenSections.push("employment")
  if (!userPermissions.includes("Leaves")) hiddenSections.push("leaves")
  if (!userPermissions.includes("Journey Information")) hiddenSections.push("userJourney")

  return (
    <Container fluid className="main-content-container px-0">
      <div className='profileNav offset-md-3 col-lg-10 offset-lg-2'>

        <Col sm={12} className='d-md-none mx-md-4'>
          <Row noGutters className="page-header pt-4 pb-2">
            <PageTitle title="User Profile" subtitle="View" md="12"
                       className="ml-sm-auto mr-sm-auto"/>

          </Row>
        </Col>

        <TabBar hiddenSections={hiddenSections}/>
      </div>

      <Row className='px-4 m-0 view-user-profile view-worker-profile main'>

       <div className={"w-100 position-relative"}>
         <h4 className="m-0 section-title idStyle">Ref# {workerForClientProfile.userId}</h4>
         {(!hiddenSections.includes("personal")) &&
         <PersonalInfo
           {...workerForClientProfile.personalInfo}
           userId={workerForClientProfile.userId}
           employmentStatus={workerForClientProfile.employmentStatus}
           dob={(workerForClientProfile.personalInfo) ? workerForClientProfile.personalInfo.dateOfBirth : ""}
           viewProfile={false}
         />}

         {(!hiddenSections.includes("contact")) && <ContactInfo
           {...workerForClientProfile.contactInfo}
           country={(workerForClientProfile.contactInfo) ? workerForClientProfile.contactInfo.countryName : ""}
           state={(workerForClientProfile.contactInfo) ? workerForClientProfile.contactInfo.regionName : ""}
           city={(workerForClientProfile.contactInfo) ? workerForClientProfile.contactInfo.cityName : ""}
           viewProfile={false}
         />}

         {(!hiddenSections.includes("employment")) && <EmploymentInfo
           {...workerForClientProfile.employmentInfo}
           startDate={(workerForClientProfile.employmentInfo) ? workerForClientProfile.employmentInfo.hireDate : ""}
           userPermissions={userPermissions}
           viewProfile={false}
         />}
          {(!hiddenSections.includes("employment")) && <CompensationInfo
            compensationInfos={workerForClientProfile.compensationInfo}
            userPermissions={userPermissions}
            viewProfile={false}
          />}
         {/*(!hiddenSections.includes('allowances')) && <Allowances
           allowanceTypes={workerForClientProfile.allowanceTypes}/>*/}

         {(!hiddenSections.includes('leaves')) && <Leaves
           leaves={workerForClientProfile.leavePolicies || []}
           viewProfile={false}
         />}
         {(!hiddenSections.includes('benefits')) && <BenefitsInfo
           {...workerForClientProfile.benefitsInfo}
           viewProfile={false}
         />}

         {(!hiddenSections.includes('perks')) && <PerksInfo
           {...workerForClientProfile.perksInfo}
           viewProfile={false}
         />}

         {(!hiddenSections.includes('pension')) && <PensionPlan
           {...workerForClientProfile.pensionPlan}
           pensionPlanFrequency={(workerForClientProfile.pensionPlan) ? workerForClientProfile.pensionPlan.frequency : ""}
           viewProfile={false}
         />}
         {(!hiddenSections.includes('userJourney')) && <UserJourney
           artisanJourney={workerJourneyForClient}
           viewProfile={false}
         />}
       </div>

      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user:state.user.user,
  workerForClientProfile: state.clientWorkers.workerForClientProfile,
  workerJourneyForClient: state.clientWorkers.workerJourneyForClient,
});
export default connect(mapStateToProps, {
  get_client_view_worker_profile,
  get_worker_journey_for_client,
  clear_client_view_worker_profile
})(ClientViewWorkerProfile);
