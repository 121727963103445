import React from 'react';
import {
  Button,
  Card, CardBody, CardFooter,
  CardHeader,
  Col, Form, FormGroup, FormInput, FormSelect,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {setDateForTable} from "../../../utils/date";

function UserJourney({artisanJourney,viewProfile}) {
  const rows = artisanJourney.map((journey) => {

    return (
      <tr>
        <td><label>{journey.consultantName}</label></td>
        <td><label>{journey.fieldName}</label></td>

        <td className='text-right'><label>{journey.initialValue}</label></td>

        <td className='text-right'><label>{journey.newValue}</label></td>

        <td className='text-center'><label>{setDateForTable(journey.changeDate)}</label></td>

        <td className='text-center'><label>{setDateForTable(journey.effectiveDate)}</label></td>
      </tr>
    )
  })

  return (
    <Card id='userJourney' small className="details-card mb-4 w-100">
      {viewProfile?
      <CardHeader className="border-bottom">
        <h4 className="m-0 section-title">User Journey</h4>
      </CardHeader>:
      <>
      <span className="iconBox"><i className="fas fa-suitcase iconStyle"></i></span>
      <h4 className="m-0 section-title headerStyle">User Journey</h4>
      </>
      }
      <CardBody className={viewProfile?'p-0':'p-0 pb-3'}>
        <table className="table mb-0 mt-4">
          <thead className="bg-light">
          <tr>
            <th scope="col" className="border-0">
              HR Consultant
            </th>
            <th scope="col" className="border-0">
              Field Name
            </th>

            <th scope="col" className="text-right border-0">
              Initial Value
            </th>
            <th scope="col" className="text-right border-0">
              New Value
            </th>
            <th scope="col" className="text-center border-0">
              Change Date
            </th>
            <th scope="col" className="text-center border-0">
              Effective Date
            </th>

          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}

export default UserJourney;
