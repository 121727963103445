import React, {useState} from 'react';
import {Link} from "react-router-dom";
import ReactTable from "react-table";

import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container,
  FormSelect,
  Row
} from "shards-react";
import {numberToMonetary} from "../../utils/general";
import {setDateForTable} from "../../utils/date";
export default function ConfigMileageRange({mileageRangeList}) {
  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,
  });

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const {pageSizeOptions, pageSize} = formData;
  const tableData = mileageRangeList;
  const tableColumns = [
    {
      Header: "Country",
      accessor: "countryName",
      maxWidth: 150,
      className: "justify-content-start text-left text-wrap"
    },
    {
      Header: "State/Province",
      accessor: "regionName",
      maxWidth: 150,
      className: "justify-content-start text-left text-wrap"
    },
    {
      Header: "Rate Start Date",
      accessor: "rateStartDate",
      maxWidth: 150,
      className: "justify-content-start text-left text-wrap",
      Cell: row => setDateForTable(row.original.rateStartDate),
    },{
      Header: "Rate End Date",
      accessor: "rateEndDate",
      maxWidth: 150,
      className: "justify-content-start text-left text-wrap",
      Cell: row => setDateForTable(row.original.rateEndDate),
    },
    {
      Header: "Level",
      accessor: "level",
      maxWidth: 50,
      className: "justify-content-center text-wrap"
    },{
      Header: "Distance Unit",
      accessor: "distanceUnit",
      maxWidth: 150,
      className: "justify-content-start text-left text-wrap"
    },
    {
      Header: "Rate",
      accessor: "rate",
      maxWidth: 400,
      className: "justify-content-end text-right",
      Cell:row=>numberToMonetary(row.original.rate)
    },
    {
      Header: "Range Start",
      accessor: "startRange",
      maxWidth: 500,
      className: "justify-content-end text-right",
      Cell:row=>numberToMonetary(row.original.startRange)
    },
    {
      Header: "Range End",
      accessor: "endRange",
      className: "justify-content-end text-right",
      Cell:row=> row.original.endRange==="Unlimited"?"Unlimited": numberToMonetary(row.original.endRange)
    },
    {
      Header: "Ref #",
      accessor: "refNum",
      className: "text-center",
      maxWidth:100,
      Cell: row => <Link to={'sub-edit-mileage-range/'+row.original.refNum}>{row.original.refNum}</Link>
    }
  ];
  return (
    <div className="mt-5 position-relative">
      <span className="tableHeader">Mileage Rate</span>
      <Card className="tableCard p-0 border-card">
        <CardHeader className="p-0 pt-2">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col
                className="file-manager__filters__rows d-flex align-items-center col-6">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col
                className="file-manager__filters__search d-flex justify-content-end col-6">
                <Link to="/sub-add-mileage-range/0">
                  <Button className="my-2 py-2 mx-2" size='sm'
                          theme="accent">Add Mileage Rate</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
