import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  setDateForTable,
  utcTimestampToLocalTimeString,
  setInputDate
} from "../../utils/date";
import {useHistory, useParams} from "react-router-dom";
import {
  Button, Card,
  Col, Container, Form,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import ConversationsArea
  from "../../components/message-center/ConversationsArea";
import ConfirmAlert from "../../components/common/ConfirmAlert";
import {
  clear_conversation_details,
  user_get_conversation_detail,
  user_get_message_category_list,
  user_send_message,
  user_set_conversation_status,
  upload_attachment,
  remove_attachment,
  remove_all_attachments
} from "../../redux/actions/user";
import {setAlert} from "../../redux/actions/alert";
import DisplayMessageFiles from '../../components/common/DisplayMessageFiles';
import { validateFileName, validateDuplicateFileName } from '../../utils/general';
function UserAddEditMessage({
                              user,
                              conversation,
                              messageCategoryList,
                              user_get_message_category_list,
                              user_get_conversation_detail,
                              user_set_conversation_status,
                              clear_conversation_details,
                              user_send_message,
                              upload_attachment,
                              remove_attachment,
                              remove_all_attachments,
                              setAlert
                            }) {

  let {refNum,msgCategory} = useParams();
  const history = useHistory();


  const [formData, setFormData] = useState({
    category: "",
    msg: "",
  });


  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null,
  });

  const [attachmentFiles, setAttachmentFiles] = useState([])
  const [attachmentFileNames, setAttachmentFileNames]  = useState([]);
  const attachmentFileNamesRef = useRef([]);

  const [uploadingAttachment, setUploadingAttachment] = useState(false);
   const handleUpload = async (e, fileRef) => {
     e.preventDefault();
     const supportedExtensions = ['.doc', '.docx', '.png','.jpeg', '.pdf']
     var file = e.target.files[0];
     let fileSize = file !== undefined ?  file.size / (1024 * 1024) : 0;
     let fileExtension = file.name.substring(file.name.lastIndexOf('.'), file.name.length);

     fileRef.current.value = ""

     if(validateDuplicateFileName(file.name, attachmentFiles)) return;
     let valid = validateFileName(file.name);

    if(!valid){
      setAlert("File name should only contain letters [a-z, A-Z], numbers [0-9], space [' '], period [.], underscore [_] and hyphen [-].","danger");
      return;
    }

     if(!supportedExtensions.includes(fileExtension.toLowerCase())){
       setAlert("Please attach JPEG, PNG, PDF, DOC only.","danger");
       return;
     }
     
    //  if(attachmentFiles.length === 8){
    //    setAlert("Maximum 8 Attachments Allowed.","danger");
    //    return;
    //  }
 
     for(let file of attachmentFiles){
       fileSize = fileSize + (file.size / (1024 * 1024))
     }
 
     if(fileSize > 8){
       setAlert("Maximum File Size Exceeded.","danger");
       return;
     }
     
     //setUploadingAttachment(true);
     setAttachmentFiles([...attachmentFiles, file]);
     let fileName = await upload_attachment(file,user.userId, 'user');
    
   if(fileName === null){
    let filteredAttachments = attachmentFiles.filter(file1 => file1.name !== file.name);
    setAttachmentFiles([...filteredAttachments]);
    return;
   }

     if(fileName && !fileName.includes(file.name)){ 
       return;
     }
     //setUploadingAttachment(false);
     //attachmentFileNames.push(fileName)
     attachmentFileNamesRef.current.push(fileName);
     setAttachmentFileNames([...attachmentFileNamesRef.current]);


 
   }

   const renderFiles = ()=>{

    const removeFile = async (file)=>{

    if(uploadingAttachment) return;

      let filteredAttachments = attachmentFiles.filter(f => f.name !== file.name);
    let fileName = attachmentFileNamesRef.current.filter(fileName => fileName && fileName.includes(file.name));
    if(fileName.length === 0)
    {
      //setAttachmentFiles([...filteredAttachments]);
      return;
    }
    let response = await remove_attachment(user.userId, fileName[0], 'user');
    setAttachmentFiles([...filteredAttachments]);
    attachmentFileNamesRef.current = [...attachmentFileNamesRef.current.filter(f => f !== fileName[0])];
    setAttachmentFileNames([...attachmentFileNames.filter(f => f !== fileName[0])])
    }

    return  (<div className='pr-2 text-nowrap overflow-auto'>{attachmentFiles.map(file=>{

       return(
         <span className='border rounded mr-2 pl-1 pr-1' key={file.name} >
          <small className='font-weight-bold'>
            <span className='file-badge-text'>{(file.name.length > 27) ? file.name.substring(0,25) + file.name.substring(file.name.lastIndexOf('.'),file.name.length)  : file.name}</span>
            <i className="fa-solid fa-xmark text-small ml-1" style={{color:'#ec2b2b'}} role="button" onClick={()=> removeFile(file)} ></i>
           </small>
          </span>
       )
     })}
     </div> 
     )
   }

   const removeAllAttachmnts = (attachmentFileNames)=>{

    let attachmentFiles = attachmentFileNames.toString();
    remove_all_attachments(user.userId, attachmentFiles, 'user');
    
   }

  useEffect(() => {
    if (refNum === "0") {
      user_get_message_category_list()
    } else {
      user_get_conversation_detail(refNum)
    }
    return () => {
      clear_conversation_details();
      removeAllAttachmnts(attachmentFileNamesRef.current);
    }

  }, []);


  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const getFormOptions = ()=>{
    return(
      <ListGroup flush>
                <ListGroupItem className="border-card p-3 mt-3">
                  <Row>
                    <Col>
                      <Row form>
                        <Col
                          lg="4"
                          className="form-group p-2 m-0 ">
                          <label className='font-weight-bold'>Category</label>
                          {(refNum === '0') ?
                            <FormSelect className="form-control " name='category'
                                        value={formData.category}
                                        onChange={onChange}
                                        required>
                              <option value=''>Select Category</option>
                              {category}
                            </FormSelect> : <span
                              className="inputBoxClr form-control">{conversation.categoryName}</span>}
                        </Col>
                        <Col lg="4" className="form-group p-2 mb-0">
                          <label className='font-weight-bold'>Request Date</label>
                          <span
                            className="inputBoxClr overflow-hidden form-control">{setDateForTable(refNum === "0" ? setInputDate(new Date()) : conversation.createdDate)}</span>
                        </Col>
                        <Col lg="4" className="form-group p-2 mb-0">
                          <label className='font-weight-bold'>Status</label>
                          <span
                            className="inputBoxClr overflow-hidden form-control">{(refNum === "0" ? "Unread" : conversation.status)}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
    );
   }
  let msgRead;
  let messageItems = ((conversation.refNum && conversation.refNum == refNum) ? conversation.messages : []).map(message => {
    if (user.userId !== message.userId) {
      //logged in user's messages will float to left
      msgRead = true
      return (
        <div key={message.messageTime + message.messageId}
        className="d-flex flex-column mb-3 mr-5 pr-5 mt-4">
          <span
            className="small">{utcTimestampToLocalTimeString(message.messageTime)} by {message.userName}</span>
          <span>{message.messageBody}</span>
          {message.messageAttachments  && message.messageAttachments.map(attachment =>{
            return <DisplayMessageFiles 
            setConfirmAlert={setConfirmAlert} 
            confirmAlert={confirmAlert} 
            key={conversation.refNum + attachment.messageId + attachment.systemFileName} 
            refNumber={conversation.refNum}  
            userId={message.userId} 
            attachment={attachment} 
            sender={false}/>
          })}
        </div>)
    } else {
      //non-logged in user's messages will float to right
      return (
        <div key={message.messageTime + message.messageId}
          className="d-flex flex-column align-items-end mb-3 ml-5 pl-5 pr-2 mt-4">
          <span
            className="small">{utcTimestampToLocalTimeString(message.messageTime)} by {message.userName}</span>
          <span>{message.messageBody}</span>
          {message.messageAttachments  && message.messageAttachments.map(attachment =>{
            return <DisplayMessageFiles 
            setConfirmAlert={setConfirmAlert} 
            confirmAlert={confirmAlert} 
            key={conversation.refNum + attachment.messageId + attachment.systemFileName} 
            refNumber={conversation.refNum}  
            userId={message.userId} 
            attachment={attachment} 
            sender={true}/>
          })}
        </div>)
    }
  })

 // useEffect(() => {
  //  if (conversation.status === "Unread" && msgRead) {
  //    user_set_conversation_status(conversation.refNum, "In-Process")
   // }
  //}, [conversation]);


  const onSend = async (e) => {
    e.preventDefault();
     await user_send_message(refNum, formData.category, formData.msg, JSON.stringify(attachmentFileNamesRef.current));
     setAttachmentFileNames([]);
     attachmentFileNamesRef.current =[]
    history.goBack();
  };


  const category = messageCategoryList.map((data) => (
    <option value={data.categoryId}>{data.categoryName}</option>
  ));
  
  useEffect(() => {
    if(msgCategory && messageCategoryList)
    {
      let categoryItem = messageCategoryList.filter(msgItem => msgItem.categoryName === msgCategory);
      if(categoryItem.length > 0)
      {
      setFormData({...formData, category: categoryItem[0].categoryId});
      }
    }
  },[messageCategoryList]);
  

  function goBack(e) {
    e.preventDefault();
    if(formData.msg){
      setConfirmAlert({
        ...confirmAlert,
        confirmMsg: 'Are you sure you want to exit this page? You will lose unsent data.',
        visible: true,
        onConfirm: () => {
          history.goBack();
        }
      })
    }else
      history.goBack();
  }

  useEffect(()=> {
    setUploadingAttachment(attachmentFiles.length !== attachmentFileNames.length)
  }, [attachmentFiles, attachmentFileNames])

  return (
    <>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Container fluid className="main-content-container px-4 py-3 mt-4">
        <Form onSubmit={onSend} className="mb-4">
          {/*<Card small
                className="mb-5 w-100 details-card mt-3 mb-4 d-flex personalParent">
          <span className="iconBox"><i
            className="fas fa-info-circle iconStyle"/></span>10
            <h4 className="m-0 section-title headerStyle">Information</h4>
            <h4 className="m-0 section-title idStyle">Ref# {refNum}</h4>
            <ListGroup flush>
              <ListGroupItem className="border-card p-3 mt-3">
                <Row>
                  <Col>
                    <Row form>
                      <Col
                        lg="3"
                        className="form-group p-2 m-0">
                        <label>Category</label>
                        {(refNum === '0') ?
                          <FormSelect className="form-control" name='category'
                                      value={formData.category}
                                      onChange={onChange}
                                      required>
                            <option value=''>Select Category</option>
                            {category}
                          </FormSelect> : <span
                            className="inputBoxClr   form-control">{conversation.categoryName}</span>}
                      </Col>
                      <Col lg="3" className="form-group p-2 mb-0">
                        <label>Request Date</label>
                        <span
                          className="inputBoxClr overflow-hidden form-control">{setDateForTable(refNum === "0" ? setInputDate(new Date()) : conversation.createdDate)}</span>
                      </Col>
                      <Col lg="3" className="form-group p-2 mb-0">
                        <label>Status</label>
                        <span
                          className="inputBoxClr overflow-hidden form-control">{(refNum === "0" ? "Unread" : conversation.status)}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
  </Card>*/}
          <ConversationsArea
            messageItems={messageItems}
            conversation={conversation}
            msg={formData.msg}
            onChange={onChange}
            Components={getFormOptions}
            handleUpload={handleUpload}
            renderFilesBadges={renderFiles()}
            uploading={uploadingAttachment}
            attachmentFileNames={attachmentFileNames}/>
        </Form>
        <Row className="px-3 pr-4">
          <Col sm="12" md="12" lg="12"
               className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
            <Button className="mx-1 mb-2 py-2 mx-md-2" size="sm"
                    theme="accent" onClick={goBack}>Back</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

UserAddEditMessage.propTypes = {
  user_get_conversation_detail: PropTypes.func.isRequired,
  user_get_message_category_list: PropTypes.func.isRequired,
  user_set_conversation_status: PropTypes.func.isRequired,
  user_send_message: PropTypes.func.isRequired,
  conversation: PropTypes.object.isRequired,
  messageCategoryList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  conversation: state.user.conversation,
  messageCategoryList: state.user.messageCategoryList,
});

export default connect(mapStateToProps, {
  user_get_conversation_detail,
  user_get_message_category_list,
  user_set_conversation_status,
  clear_conversation_details,
  user_send_message,
  upload_attachment,
  remove_attachment,
  remove_all_attachments,
  setAlert
})(UserAddEditMessage);
