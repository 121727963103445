/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useEffect,useState} from "react";
import {Link,useHistory,useParams} from "react-router-dom";
import {connect} from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button, Alert
} from "shards-react";
import PropTypes from "prop-types";
import {login} from "../redux/actions/user";

const Login = ({user, isAuthenticated, login, alerts}) => {

  const [state, setState] = useState({
    showPassword:false,
    show2FA:false,
    show2FAPass:false,
    username: '',
    password: '',
    twofa: '',
    showmsg:false,
    disablebutton:false
  });


  const history = useHistory()
  let {msg} = useParams();
  useEffect(() => {   
    if(msg && msg!==''&& msg==='success'){
      setState({
        ...state,
        showmsg: true
      })
    }else{
      setState({
        ...state,
        showmsg: false
      })
    }
  }, [msg]);
  
  //todo- in future we will define startup screens for subscriber and sys admin
  if (isAuthenticated) {
    if (user.selectedrole.startsWith("Subscriber")) {
      history.push('/sub-home')
    } else if (user.selectedrole.startsWith("Client")) {
      history.push('/client-home')
    } else if (user.selectedrole=== "Worker") {
      history.push('/worker-home')
    } else {
      history.push('/admin-search-subscribers')
    }

  }

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  function handleLogin(e) {
    e.preventDefault()
    setState({
      ...state,
      disablebutton: true
    })
    login(state.username, state.password, state.twofa)
    setTimeout(() =>  setState({
      ...state,
      disablebutton: false
    }), 3000);
  }


  const alertComponents = alerts.map((alert) => {
    return <Alert className="auth-form-errs rounded text-white text-center"
                  theme={alert.alertType} fade show>
      {alert.msg}
    </Alert>
  })

  return (
    <Container fluid
               className="main-content-container login-container h-100 px-1 py-4 py-md-0 px-lg-4">
      <Row noGutters className="h-100">
        <Col lg="4" md="5" className="auth-form mx-auto">
          <Card className={"mt-5"}>
            <CardBody>
            <div className="row justify-content-center">
            <label className="font-weight-bold">Access Your Account</label>
  </div>
            
              {/* <img
                className="auth-form__logo d-none d-md-table mx-auto mb-2"
                src={require("../images/logo.png")}
                alt="Shards Dashboards - Login Template"
              /> */}

              {alertComponents}
            
              {   state.showmsg && <div className="auth-form rounded bg-success text-white text-center">
      <label>  Password changed. Please log in with your new password.</label>
     </div>}
              {/* Form Fields */}
              <Form onSubmit={handleLogin}>
                <FormGroup className={"mb-1 mt-3"}>
                
                  <label className={"text-bold"}
                         htmlFor="exampleInputEmail1">Username</label>
                  <FormInput
                    type="email"
                    id="email"
                    placeholder="Enter Email Address"
                    autoComplete="email"
                    name='username'
                    value={state.username}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
             
                
                      <FormGroup className={"mb-1"}>
                  <label className={"text-bold"}
                         htmlFor="password">Password</label>
<div className={"input-group input-group-seamless"}>
<FormInput
                      type={state.showPassword?"text":"password"}
                      id="password"
                      className={"form-control rounded-right"}
                      placeholder="Enter password"
                      autoComplete="current-password"
                      name='password'
                      value={state.password}
                      onChange={onChange}
                      required
                    />
                   <div className="input-group-append" > 
                   <span className="input-group-text"><i onClick={e=>{setState({...state,showPassword:!state.showPassword})}} className={`fa ${state.showPassword?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>                    
                      </div> 
                   </div>
                </FormGroup>         
                <Link className={"mb-1 float-right"}  to={{pathname: "/forgotpassword"}}>Forgot Password?</Link>
                
                <FormGroup className={"mb-4 mt-5"}>
                 
                  <label className={"text-bold mb-1 pr-5"} htmlFor="twofa">Two-Factor
                    authentication</label>
                    
                    <i onClick={e=>{setState({...state,show2FA:!state.show2FA})}} className={` twofa float-right fa ${state.show2FA?"fa-minus":"fa-plus"} btn-accent`} aria-hidden="true"/>
                    <label>Required when two-factor authentication is enabled</label>
                    
                    {
                      state.show2FA && <div className="input-group  input-group-seamless" id="show_hide_eye">  <FormInput
                      type={state.show2FAPass?"text":"password"}
                      placeholder="Enter Two-Factor authentication"
                      name='twofa'
                      className={"form-control rounded-right"}
                      value={state.twofa}
                      onChange={onChange}
                    />
                     <div className="input-group-append" > 
                     
                     <span className="input-group-text"> <i onClick={e=>{setState({...state,show2FAPass:!state.show2FAPass})}} className={`fa ${state.show2FAPass?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                    </div> 
                   </div> 
                    }
                  
                 
                      
                    
                  
                </FormGroup>

                <Button disabled={state.disablebutton}
                  theme="accent"
                  className="d-table mx-auto px-4 w-100 text-bold mb-1 text-uppercase login-btn"
                  type="submit"
                >
                  LOGIN
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  alerts: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user.user,
  isAuthenticated: state.user.isAuthenticated,
  alerts: state.alert
});

export default connect(mapStateToProps, {login})(Login);
