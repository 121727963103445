import React from 'react';
import {useHistory} from "react-router-dom";
import {Button, Container} from "shards-react";

function Reports(props) {
  const history = useHistory()
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <div className="error">
        <div className="error__content">
          <h2>Stay Tuned</h2>
          <h3>Reports are coming soon!</h3>
          <p>We're going to launch reports page very soon.</p>
          <Button className={"py-2"} size={"sm"} theme={"accent"} onClick={history.goBack}>Go Back</Button>
        </div>
      </div>
    </Container>
  );
}

export default Reports;
