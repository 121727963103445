import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  Button,
  Card,
  Col, Container,
  Form, FormTextarea,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {setDateForTable} from "../../utils/date";
import {formatNotesString, numberToMonetary} from "../../utils/general";
import {useHistory, useParams} from "react-router-dom";
import {
  approve_sub_expense_item,
  approve_sub_mileage_item,
  sub_get_expense_report_for_review,
  reject_sub_expense_item,
  reject_sub_expense_report,
  reject_sub_mileage_item,
  review_sub_expense_report,
  update_expense_item_list,
  review_sub_expense_item, review_sub_mileage_item
} from "../../redux/actions/subscriber/subExpenses";
import {setAlert} from "../../redux/actions/alert";
import ExpenseItemsCard
  from "../../components/subscriber/review-expense-report/ExpenseItemsCard";
import MileageItemsCard
  from "../../components/subscriber/review-expense-report/MileageItemsCard";
import InfoTooltip from "../../components/common/InfoTooltip";

function SubscriberApproveExpenseReport({
                                          expenseSheet,
                                          sub_get_expense_report_for_review,
                                          review_sub_expense_report,
                                          reject_sub_mileage_item,
                                          reject_sub_expense_item,
                                          approve_sub_mileage_item,
                                          approve_sub_expense_item,
                                          review_sub_expense_item,
                                          review_sub_mileage_item,
                                          update_expense_item_list,
                                          rejectedExpenseItems,
                                          rejectedMileageItems,
                                          initialRejectedExpenseItems,
                                          initialRejectedMileageItems,
                                          setAlert,
                                          changePageMeta,
                                          title
                                        }) {

  let {sheetRefNumber} = useParams();
  const history = useHistory();
  let editableKeyToFocus = useRef(null);

  const [notes, setNotes] = useState("");
  let apiNotes = (expenseSheet.notes) ? formatNotesString(expenseSheet.notes) + "\n" : "";
  let onNotesChange = (e) => {
    editableKeyToFocus.current = "notes";
    setNotes(e.target.value.substr(apiNotes.length))
  };

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(expenseSheet).length > 0 && expenseSheet.workerName.trim()) {
      changePageMeta(`${title} - ${expenseSheet.workerName}`)
    }
  }, [expenseSheet]);

  useEffect(() => {
    if (
      sheetRefNumber !== null &&
      sheetRefNumber !== '' &&
      sheetRefNumber !== '0' &&
      sheetRefNumber !== 0
    ) {
      if (!Object.keys(expenseSheet).length)
        sub_get_expense_report_for_review(sheetRefNumber);
    }

  }, [sheetRefNumber]);


  let reviewReport = async () => {
    let reviewStageRejectedMileageItems=rejectedMileageItems.filter(item=> initialRejectedMileageItems.indexOf(item))
    let reviewStageRejectedExpenseItems=rejectedExpenseItems.filter(item=> initialRejectedExpenseItems.indexOf(item))
    if (!notes && (reviewStageRejectedMileageItems.length || reviewStageRejectedExpenseItems.length))
      setAlert("Please add rejection note for rejected items")
    else {
      await review_sub_expense_report(sheetRefNumber, notes, rejectedExpenseItems, rejectedMileageItems, expenseSheet.expenseItemList, expenseSheet.mileageItemList);
      history.goBack();
    }

  };

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Card small className="details-card my-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {sheetRefNumber}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Report Date</label>
                      <span
                        className='text-light form-control'>{setDateForTable(expenseSheet.date)}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Report Name</label>
                      <span
                        className='text-light form-control'>{expenseSheet.description}</span>
                    </Col>

                    <Col sm="12" md="6" lg="3"
                         className="form-group p-2 mb-0 offset-3 d-flex align-items-end justify-content-end">
                      <span className="text-bold text-nowrap py-2">Expense Report Total: {numberToMonetary(expenseSheet.approvedReportTotal)}</span>
                    </Col>

                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>


      <ExpenseItemsCard
        expenseItemList={expenseSheet.expenseItemList}
        submittedItemTotal={String(expenseSheet.submittedItemTotal)}
        updateExpenseItemList={update_expense_item_list}
        rejectExpenseItem={reject_sub_expense_item}
        reviewExpenseItem={review_sub_expense_item}
        sheetRefNumber={sheetRefNumber}
        approveItemRoute={(["Approved", "Rejected", "Reviewed"].includes(expenseSheet.status)) ? "sub-review-expense-item" : "sub-approve-expense-item"}
        editableKeyToFocus={editableKeyToFocus}
        status={expenseSheet.status}
      />

      <MileageItemsCard
        mileageItemList={expenseSheet.mileageItemList}
        submittedMileageTotal={String(expenseSheet.submittedMileageTotal)}
        submittedYTD={expenseSheet.submittedYTD}
        approvedYTD={expenseSheet.approvedYTD}
        reviewedYTD={expenseSheet.reviewedYTD}
        totalYTD={expenseSheet.totalYTD}

        rejectMileageItem={reject_sub_mileage_item}
        reviewMileageItem={review_sub_mileage_item}
        sheetRefNumber={sheetRefNumber}
        approveItemRoute={(["Approved", "Rejected", "Reviewed"].includes(expenseSheet.status)) ? "sub-review-mileage-item" : "sub-approve-mileage-item"}
        status={expenseSheet.status}
      />

      <Card small className="my-4 w-100">
        <span className="tableHeader"><label htmlFor="notes"
                                             className="text-bold m-0">Notes</label></span>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>

                <Col sm="12" md="12" className="form-group p-2 m-0">
                  <FormTextarea
                    id="notes"
                    rows="5"
                    className="p-2"
                    name="notes"
                    autoFocus={"notes" === editableKeyToFocus.current}
                    onChange={onNotesChange}
                    value={apiNotes + notes}
                  />
                </Col>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>

      <Row className="px-3">
        <Col sm="12" md="12" lg="12"
             className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

          {(expenseSheet.status !== "Reviewed") &&
            <div className="d-flex align-items-center">
              <InfoTooltip msg=" A notification will be sent to all the users of this expense report"/>
              <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent" onClick={reviewReport}>Review</Button>
            </div>
          }

          <Button className="mx-1 py-2 mx-md-2" size="sm"
                  theme="accent" onClick={history.goBack}>Cancel</Button>
        </Col>
      </Row>
    </Container>
  );
}

SubscriberApproveExpenseReport.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  expenseSheet: PropTypes.object.isRequired,
  get_user_expense_sheet_details: PropTypes.func.isRequired,
  reject_sub_expense_report: PropTypes.func.isRequired,
  reject_sub_mileage_item: PropTypes.func.isRequired,
  reject_sub_expense_item: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  expenseSheet: state.subExpenses.expenseSheetForSub,
  rejectedExpenseItems: state.subExpenses.rejectedExpenseItems,
  initialRejectedExpenseItems: state.subExpenses.initialRejectedExpenseItems,
  rejectedMileageItems: state.subExpenses.rejectedMileageItems,
  initialRejectedMileageItems: state.subExpenses.initialRejectedMileageItems
});
export default connect(mapStateToProps, {
  sub_get_expense_report_for_review,
  review_sub_expense_report,
  reject_sub_expense_report,
  reject_sub_mileage_item,
  reject_sub_expense_item,
  approve_sub_mileage_item,
  review_sub_mileage_item,
  approve_sub_expense_item,
  review_sub_expense_item,
  update_expense_item_list,
  setAlert
})(SubscriberApproveExpenseReport);
