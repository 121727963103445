import {
  GET_ARTISAN_JOURNEY, GET_ARTISAN_JOURNEY_ERROR,
  GET_HIRE_DATE,
  GET_HIRE_DATE_ERROR,
  VIEW_ARTISAN_PROFILE,
  VIEW_ARTISAN_PROFILE_ERROR
} from "../../actions/types";


const initialState = {
  artisanProfile: {},
  artisanJourney:[],
  hireDate : 0
}


export const profile = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_ARTISAN_PROFILE:
      return {
        ...state,
        artisanProfile: action.payload.artisanProfile,
      };
    case VIEW_ARTISAN_PROFILE_ERROR:
      return {
        ...state,
        artisanProfile: {},
      };
      case GET_HIRE_DATE:
        return {
          ...state,
          hireDate: action.payload,
        };
        case GET_HIRE_DATE_ERROR:
          return {
            ...state,
            hireDate: 0,
          };
    case GET_ARTISAN_JOURNEY:
      return {
        ...state,
        artisanJourney: action.payload.userJourneyList,
      };
    case GET_ARTISAN_JOURNEY_ERROR:
      return {
        ...state,
        artisanJourney: [],
      };

    default:
      return state;
  }
}

export default profile;
