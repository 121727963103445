import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col, Container,
  Form,
  FormTextarea,
  ListGroup,
  ListGroupItem,
  Row,
  Alert
} from "shards-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { setDateForTable, setAPIDate, setInputDate } from "../../utils/date";
import {
  get_client_view_worker_leave,
  clear_worker_leave_details,
  approve_leave,
  reject_leave
} from "../../redux/actions/client/clientLeaves";
import { clear_leave_details } from "../../redux/actions/worker/leaves";
import InfoTooltip from "../../components/common/InfoTooltip";

function ClientViewLeave({
  get_client_view_worker_leave,
  leaveDetails,
  loginUserId,
  clear_leave_details,
  clear_worker_leave_details,
  changePageMeta,
  approve_leave,
  reject_leave
}) {
  const history = useHistory();
  let { refNum } = useParams();

  let approvePermissions=true;
  let user = JSON.parse(localStorage.getItem("user"));
  approvePermissions = user.userRoles.find(role => role.role === "Client User" || role.role === "Client HR" || role.role === "Client Billing" || role.role === "Client Admin").permissions.includes("Approve Leaves");

  const [formData, setFormData] = useState({
    userId: null,
    requestedDate: new Date().toISOString().slice(0, 10),
    leavePolicy: '',
    from: '',
    to: '',
    leaveDays: null,
    leaveHours: null,
    status: '',
    workerNotes: "",
    clientNote: "",
    deleted: false,
    leavePolicyEndDate: "",
    entitled:true
  });

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(leaveDetails).length > 0 && leaveDetails.workerName.trim()) {
      changePageMeta(`Leave - ${leaveDetails.workerName}`)
    }
  }, [leaveDetails]);

  useEffect(() => {
    get_client_view_worker_leave(refNum);
    return () => {
      clear_leave_details();
      clear_worker_leave_details()
    }
  }, []);

  let approveClickHandler = () => {
    approve_leave(formData.userId, refNum,formData.clientNote);
    history.goBack();
  };
  let rejectClickHandler = () => {
    reject_leave(formData.userId, refNum,formData.clientNote);
    history.goBack();
  };

  useEffect(() => {
    if (Object.keys(leaveDetails).length !== 0) {
      setFormData({
        ...formData,
        userId: leaveDetails.userId,
        allowFutureLeavesFlag: leaveDetails.allowFutureLeavesFlag,
        curBalance: leaveDetails.balance,
        requestedDate: setDateForTable(leaveDetails.requestedDate),
        leavePolicy: leaveDetails.leavePolicy,
        leavePolicyId: leaveDetails.leavePolicyId,
        from: setAPIDate(leaveDetails.fromDate),
        to: setAPIDate(leaveDetails.toDate),
        leavePolicyEndDate: setAPIDate(leaveDetails.leavePolicyEndDate),
        leaveDays: leaveDetails.leaveDays,
        leaveHours: leaveDetails.leaveHours,
        status: leaveDetails.status,
        workerNotes: leaveDetails.notes,
        clientNote: leaveDetails.clientNotes,
        deleted: leaveDetails.deleted,
        entitled: leaveDetails.entitled
      });
    }
  }, [leaveDetails]);

  const onChange = (e) => {
    debugger;
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  return (
    <React.Fragment>
      {(!formData.allowFutureLeavesFlag && parseFloat(formData.leaveDays) > parseFloat(formData.curBalance)) && formData.entitled ? <Alert theme="primary" className="text-white">This user do not have sufficient balance.</Alert> : ''}
      {(formData.deleted) ? <Alert theme="primary" className="text-white">{`This leave policy is deleted.`}</Alert> : ''}
      {(!formData.deleted && (formData.leavePolicyEndDate && formData.leavePolicyEndDate <= formData.to)) ? <Alert theme="primary" className="text-white">{`This leave is availed later than end date of the leave policy. This leave policy will be deleted on  ${setDateForTable(setInputDate(formData.leavePolicyEndDate))}.`}</Alert> : ''}
      <Container fluid className="main-content-container p-4">
        <Card small className="details-card mb-4 w-100">
          <h4 className="m-0 section-title idStyle">Ref# {refNum}</h4>
          <ListGroup flush>
            <ListGroupItem className="border-card p-3">
              <Row>
                <Col>
                  <Form>
                    <Row form>

                      {/* <Col lg="3" className="form-group p-2 m-0">
                      <label>Reference No</label>
                      <span className='text-light form-control'>{leaveDetails.refNum}</span>
                    </Col> */}

                      <Col lg="3" className="form-group p-2 m-0">
                        <label>Requested Date</label>
                        <span
                          className='text-light form-control'>{setDateForTable(leaveDetails.requestedDate)}</span>
                      </Col>

                      <Col lg="3" className="form-group p-2 m-0">
                        <label>Leave Type</label>
                        <span
                          className='text-light form-control'>{leaveDetails.leavePolicy}</span>
                      </Col>

                      <Col lg="3" className="form-group p-2 m-0">
                        <label>Status</label>
                        <span
                          className='text-light form-control'>{leaveDetails.status}</span>
                      </Col>


                      <Col lg="3" className="form-group p-2 m-0">
                        <label>From</label>
                        <span
                          className='text-light form-control'>{setDateForTable((leaveDetails.fromDate))}</span>
                      </Col>

                      <Col lg="3" className="form-group p-2 m-0">
                        <label>To</label>
                        <span
                          className='text-light form-control'>{setDateForTable(leaveDetails.toDate)}</span>
                      </Col>


                      <Col lg="3" className="form-group p-2 m-0">
                        <label>Leave Days</label>
                        <span
                          className='text-light form-control'>{parseFloat(leaveDetails.leaveDays).toFixed(2)}</span>
                      </Col>

                      <Col lg="3" className="form-group p-2 m-0">
                        <label>Leave Hours</label>
                        <span
                          className='text-light form-control'>{parseFloat(leaveDetails.leaveHours).toFixed(2)}</span>
                      </Col>


                      <Col sm="12" md="12" className="form-group p-2 m-0">
                        <label htmlFor="notes">Worker Notes</label>
                        <FormTextarea
                          id="notes"
                          rows="10"
                          readOnly
                          className="bg-white text-bold"
                          value={leaveDetails.notes}
                        />
                      </Col>

                      <Col sm="12" md="12" className="form-group p-2 m-0">
                        <label htmlFor="clientNote">Client Notes</label>
                        <FormTextarea
                          id="clientNote"
                          name="clientNote"
                          rows="10"
                          className="bg-white text-bold"
                          value={formData.clientNote}
                          onChange={onChange}
                        />
                      </Col>

                    </Row>
                    <Row className="px-3">

                      <Col sm="12" md="12" lg="12"
                        className=" form-group p-2 m-0 d-flex align-items-center justify-content-center justify-content-md-end">

                          {refNum != 0 && approvePermissions && formData.status && formData.status !== "Rejected" && formData.status !== "Approved" &&
                          <div className="d-flex align-items-center">
                              <InfoTooltip msg=" A notification will be sent to all the users of this expense report"/>
                              <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="primary"
                                disabled={formData.deleted || (formData.leavePolicyEndDate != 0 && formData.leavePolicyEndDate <= formData.to) || loginUserId!=leaveDetails.approverId}
                                onClick={approveClickHandler}>Approve
                              </Button>
                          </div>}

                          {refNum != 0 && approvePermissions && formData.status && formData.status !== "Approved" && formData.status !== "Rejected" &&
                          <div className="d-flex align-items-center">
                              <InfoTooltip msg="A notification will be sent to the applicant and respective HR Representative " />
                              <Button className="mx-1 py-2 mx-md-2" size="sm"
                                  theme="primary"
                                  disabled={loginUserId!=leaveDetails.approverId}
                                  onClick={rejectClickHandler}>Reject
                          </Button>
                          </div>}

                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                          theme="accent"
                          onClick={e => history.goBack()}>Cancel</Button>

                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    </React.Fragment>
  );
}

ClientViewLeave.propTypes = {
  get_client_view_worker_leave: PropTypes.func.isRequired,
  leaveDetails: PropTypes.object.isRequired,

};
const mapStateToProps = (state) => ({
  leaveDetails: state.clientLeaves.clientWorkerLeaveDetails,
  loginUserId:state.user.user.userId
});

export default connect(mapStateToProps, {
  get_client_view_worker_leave,
  clear_leave_details,
  clear_worker_leave_details,
  approve_leave,
  reject_leave
})(ClientViewLeave);
