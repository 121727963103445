import React from 'react';
import {
  Card,
  CardHeader,
  Col, Form, ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import PropTypes from "prop-types";
import {setDateForTable} from "../../../utils/date";
import {numberToMonetary} from "../../../utils/general";

const BenefitsInfo = (props) => {
  const {viewProfile} = props
  return (
    <Card id='benefits' small className=" details-card mb-5 w-100">
    {viewProfile?
      <CardHeader className="border-bottom">
        <h4 className="m-0 section-title">Benefits Info</h4>
      </CardHeader>:
      <>
      <span className="iconBox"><i className="fas fa-trophy iconStyle"/></span>
      <h4 className="m-0 section-title headerStyle">Benefits - General</h4>
      </>
      }
      <ListGroup flush>
        <ListGroupItem className={viewProfile?"p-3":"p-3 mt-3"}>
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Benefits Coverage</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.benefitsCoverage}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Benefits Class</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.benefitsClass}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Benefits Certificate Number</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.benefitsCertificateNo}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Benefits Start Date</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{setDateForTable(props.benefitsStartDate)}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>My Contribution</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{numberToMonetary(props.contribution)} monthly</span>
                  </Col>


                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Client Contribution</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{numberToMonetary(props.clientContribution)} monthly</span>
                  </Col>



                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

BenefitsInfo.propTypes={
  benefitsInfo:PropTypes.object.isRequired
}

export default BenefitsInfo;
