import React, {useState} from 'react';
import {Tooltip} from "shards-react";
import shortid from "shortid";

function InfoTooltip({msg,placement="right",cssClassName="", infoClass=""}) {

  const [open, setOpen] = useState(false);
  const [toolTipID, setToolTipID] = useState("tooltip-"+shortid.generate());

  let toggle=()=>{
    setOpen(!open)
  }


  return (
    <>
      <i id={toolTipID} className={`tooltip-icon mx-1 fas fa-info-circle ${infoClass}`}/>
      <Tooltip
        open={open}
        placement={placement}
        target={"#" + toolTipID}
        toggle={toggle}
        innerClassName={cssClassName}
      
      ><div dangerouslySetInnerHTML={{ __html: msg }} />
        
        </Tooltip>
    </>
  )
    ;
}

export default InfoTooltip;
